import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Advertence, AdvertenceStatus } from "./types/Advertence";

export interface GetAdvertenceParams {
  pageSize: string
  currentPage: string
  status: AdvertenceStatus | 'all'
}

export interface GetAdvertencesResponse {
  advertences: Advertence[]
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
  }
}


export async function getAdvertences({
  currentPage,
  pageSize,
  status,
}: GetAdvertenceParams) {
  try {
    const response = await api.get<GetAdvertencesResponse>('/advertences', {
      params: {
        pageSize,
        currentPage,
        status
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
