import axios from "axios";
import { useMutation } from "react-query";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

export interface RegisterCltDriverInput {
  cellphone: string
  rg: string
  birth_date: string
  address: string
  days_of_work: number[]
  start_work_hour: string
  end_work_hour: string
  start_interval_hour: string
  end_interval_hour: string
  start_weekend_work_hour: string | null
  end_weekend_work_hour: string | null
  salary: string
  family_salary: 'yes' | 'no'
  admission_date: string
  regional: string
  office: string
  cost_center: string
  benefits: string[]
  vr_value: string
  va_payment_type: string
  va_value: string
  motorcycle_location_value: number
  business_days_daily_km_in_meters?: number | null
  business_days_fuel_price_in_cents?: number | null
  weekend_days_daily_km_in_meters?: number | null
  weekend_days_fuel_price_in_cents?: number | null
  cellphone_cost_help: number
  toll_value: string
  vt_value: string
  fuel_aid_value: string
  driver_id: string
  email: string
  home_work_monthly_value_in_cents?: number | null
}

interface UpdateCltDriverRequest {
  id: string
  input: RegisterCltDriverInput
}

async function registerCltDriverReqFunction(input: RegisterCltDriverInput) {
  try {
    await api.post('/clt-drivers', input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

async function updateCltDriverReqFunction({id, input}: UpdateCltDriverRequest) {
  try {
    await api.put(`/clt-drivers/${id}`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

async function deleteCltDriverReqFunction(id: string) {
  try {
    await api.delete(`/clt-drivers/${id}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

interface CreateCltDriverExtraDiscountInput {
  type: 'EXTRA' | 'DESCONTO'
  serviceDate: string
  value: number
  description: string
  serviceType: 'EXPRESS' | 'BUSINESS'
  serviceRoute: string
  serviceProtocol: number
  attachmentsIds: string[]
}

interface CreateCltDriverExtraDiscountReqFunctionProps {
  cltDriverId: string
  input: CreateCltDriverExtraDiscountInput
}

async function createCltDriverExtraDiscountReqFunction({
  cltDriverId,
  input
}: CreateCltDriverExtraDiscountReqFunctionProps) {
  try {
    await api.post(`/clt-drivers/${cltDriverId}/extras-discounts/`, input)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

async function approveCltDriverExtraDiscountReqFunction(
  cltDriverExtraDiscountId: string
) {
  try {
    await api.patch(`/clt-drivers/extras-discounts/${cltDriverExtraDiscountId}/approve`)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

async function effectiveCltDriverExtraDiscountReqFunction(
  cltDriverExtraDiscountId: string
) {
  try {
    await api.patch(`/clt-drivers/extras-discounts/${cltDriverExtraDiscountId}/effective`)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

async function rejectCltDriverExtraDiscountReqFunction(
  cltDriverExtraDiscountId: string
) {
  try {
    await api.patch(`/clt-drivers/extras-discounts/${cltDriverExtraDiscountId}/reject`)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

async function deleteCltDriverExtraDiscountReqFunction(
  cltDriverExtraDiscountId: string
) {
  try {
    await api.delete(`/clt-drivers/extras-discounts/${cltDriverExtraDiscountId}`)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

type UpdateCltDriverExtraDiscountInput = CreateCltDriverExtraDiscountInput

interface UpdateCltDriverExtraDiscountReqFunctionProps {
  cltDriverId: string
  extraDiscountId: string
  input: UpdateCltDriverExtraDiscountInput
}

async function updateCollectorExtraDiscountReqFunction({
  cltDriverId,
  extraDiscountId,
  input
}: UpdateCltDriverExtraDiscountReqFunctionProps) {
  try {
    await api.put(`/clt-drivers/${cltDriverId}/extras-discounts/${extraDiscountId}`, input)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

export function useCltDriverFunctions() {

  const registerCltDriver = useMutation(
    'registerCltDriver', registerCltDriverReqFunction
  )

  const updateCltDriver = useMutation(
    'updateCltDriver', updateCltDriverReqFunction
  )

  const deleteCltDriver = useMutation(
    'deleteCltDriver', deleteCltDriverReqFunction
  )

  const createCltDriverExtraDiscount = useMutation(
    'createCltDriverExtraDiscount', createCltDriverExtraDiscountReqFunction
  )

  const updateCltDriverExtraDiscount = useMutation(
    'updateCltDriverExtraDiscount', updateCollectorExtraDiscountReqFunction
  )

  const approveCltDriverExtraDiscount = useMutation(
    'approveCltDriverExtraDiscount', approveCltDriverExtraDiscountReqFunction
  )

  const effectiveCltDriverExtraDiscount = useMutation(
    'approveCltDriverExtraDiscount', effectiveCltDriverExtraDiscountReqFunction
  )

  const rejectCltDriverExtraDiscount = useMutation(
    'rejectCltDriverExtraDiscount', rejectCltDriverExtraDiscountReqFunction
  )

  const deleteCltDriverExtraDiscount = useMutation(
    'deleteCltDriverExtraDiscount', deleteCltDriverExtraDiscountReqFunction
  )


  return {
    registerCltDriver,
    updateCltDriver,
    deleteCltDriver,
    createCltDriverExtraDiscount,
    updateCltDriverExtraDiscount,
    approveCltDriverExtraDiscount,
    effectiveCltDriverExtraDiscount,
    rejectCltDriverExtraDiscount,
    deleteCltDriverExtraDiscount,
  }
}
