import { Button, Flex, FormControl, FormLabel, IconButton, Input, InputGroup, InputLeftAddon, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Progress, Stack, Table, Tbody, Td, Text, Textarea, Tr } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { set } from "date-fns";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";
import { useCurrency } from "react-hook-currency";
import { ChangeEvent } from "react";
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa";
import { validateHasFile } from "utils/fileValidation";
import { completeFinancialAudit } from "api/audits/completeFinancialAudit";

interface AuditFinancialProps {
  onClose: () => void
  auditId: string
}


interface AuditFinancialSchema {
  realizedDate: string
  reportAttachmentId: FileList
  deviationFoundValueInCents: number
  nonCompliance: string
  status: string
}

const auditFinancialSchema = yup.object({
  realizedDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
  reportAttachmentId: yup.mixed().test(value => validateHasFile(value)).required(),
  deviationFoundValueInCents: yup.number().required().transform((value, originalValue) => Math.ceil(transformStringToNumber(originalValue) * 100)),
  nonCompliance: yup.string()
})


export function AuditFinancial({
  auditId,
  onClose
}: AuditFinancialProps) {

  const { mutation: uploadReportAttachmentFn, uploadProggress: uploadProggressReportAttachmentPhoto } = useAttachmentMutation()

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<AuditFinancialSchema>({
    resolver: yupResolver(auditFinancialSchema)
  })

  const [reportAttachmentId] = useWatch({
    control,
    name: ['reportAttachmentId'],
  })

  const {
    format: currencyFormat, onChange: onCurrencyChange,
  } = useCurrency({
    style: 'decimal'
  })



  async function handleUploadReportAttachmentPhoto(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadReportAttachmentFn.mutateAsync(formData)

  }


  const { mutateAsync: completeFinancialAuditFn } = useMutation({
    mutationFn: completeFinancialAudit,
    onSuccess() {
      queryClient.invalidateQueries(['audits'])
      onClose()
    }
  })

  async function handleAuditFinancial(values: AuditFinancialSchema) {
    await promiseMessage(completeFinancialAuditFn({
      body: {
        ...values,
        reportAttachmentId: uploadReportAttachmentFn?.data?.attachment?.id,
      },
      auditId
    }), 'Auditoria realizada!')
  }


  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Auditoria Financeira
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleAuditFinancial)}
        maxH='600px'
        overflowY='scroll'
      >
        <FormControl isInvalid={!!errors?.realizedDate} mt={3}>
          <FormLabel fontSize="sm">
            Data realizada
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('realizedDate')}
            name="realizedDate"
            type="date"
            size="sm"
            rounded="md"
            w={["full", "full", "sm"]}
          />
        </FormControl>
        <Stack
          direction="column"
          w="full"
          mt="3"
        >
          <Button
            as={FormLabel}
            htmlFor="reportAttachmentId"
            lineHeight="1"
            leftIcon={<FaFileImport />}
            size="sm"
            w="min"
            cursor="pointer"
            border={!!errors?.reportAttachmentId && '2px solid'}
            borderColor={(!!errors?.reportAttachmentId) && 'red.500'}
          >
            Relatório
          </Button>
          <FormControl isInvalid={!!errors?.reportAttachmentId}>
            <Input
              {...register('reportAttachmentId')}
              name="reportAttachmentId"
              id="reportAttachmentId"
              type="file"
              hidden
              onChangeCapture={handleUploadReportAttachmentPhoto}
            />
          </FormControl>
        </Stack>

        {reportAttachmentId && (
          <Table size="sm">
            <Tbody>
              {Object.entries(reportAttachmentId).map(([key, file]) => {
                return (
                  <Tr key={key}>
                    <Td fontSize="xs" maxW="100px">{file.name}</Td>
                    <Td fontSize="xs" w="200px">
                      <Progress size="sm" rounded="md" value={uploadProggressReportAttachmentPhoto} />
                    </Td>
                    <Td fontSize="xs" isNumeric>
                      {uploadReportAttachmentFn.data && (
                        <IconButton
                          aria-label="Visualizar anexo"
                          as={Link}
                          size="sm"
                          icon={<FaExternalLinkAlt />}
                          href={uploadReportAttachmentFn.data.attachment.link}
                          isExternal
                        />
                      )}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        )}
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >

          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors?.deviationFoundValueInCents}>
              <FormLabel fontSize="sm">
                Desvio encontrado
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Controller
                name="deviationFoundValueInCents"
                control={control}
                render={({ field }) => {
                  return (
                    <InputGroup
                      size="sm"
                    >
                      <InputLeftAddon
                        borderTopLeftRadius="md"
                        borderBottomLeftRadius="md"
                      >
                        R$
                      </InputLeftAddon>
                      <Input
                        {...register("deviationFoundValueInCents")}
                        size="sm"
                        rounded="md"
                        w="full"
                        name={field.name}
                        defaultValue={currencyFormat('000')}
                        onChange={e => field.onChange(onCurrencyChange(e))}
                      />
                    </InputGroup>
                  )
                }}
              />

            </FormControl>
          </Stack>
        </Stack>
        <FormControl isInvalid={!!errors.nonCompliance} mt={3}>
          <FormLabel>
            Não conformidade
          </FormLabel>
          <Textarea
            {...register('nonCompliance')}
            placeholder="Descreva a não conformidade..."
            size='sm'
            rounded='md'
          />
        </FormControl>
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Auditar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
