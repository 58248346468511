import { Box, Button, FormControl, FormLabel, HStack, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Radio, RadioGroup, Stack, Text, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { GetVacationsResponse } from "api/vacations/getVacations";
import { planVacation } from "api/vacations/planVacation";
import { set } from "date-fns";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect } from "react";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { getInputDateYearsMonthAndDateSplited } from "utils/getInputDateYearsMonthAndDateSplited";
import * as yup from "yup"

interface PlanVacationSchema {
  isWithPeriodsDivision: 'yes' | 'no'
  periodsQuantity: number
  periods: {
    period: number
    startDate: string
    endDate: string
    withAllowance: 'yes' | 'no'
  }[]
}

interface PlanVacationProps {
  vacationId: string
}

const planVacationSchema = yup.object({
  isWithPeriodsDivision: yup.string().required(),
  periodsQuantity: yup.number().required(),
  periods: yup
    .array()
    .test({
      message: 'Quantidade de períodos incorreta!',
      test: (value, context) => value.length === context.parent.periodsQuantity
    })
    .of(yup.object({
      startDate: yup.string().required(),
      endDate: yup.string().required(),
      withAllowance: yup.string().required(),
    }))
})

export function PlanVacation({ vacationId }: PlanVacationProps) {
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<PlanVacationSchema>({
    defaultValues: {
      periods: [
        { period: 1, startDate: '', endDate: '' }
      ],
      periodsQuantity: 1,
      isWithPeriodsDivision: 'no'
    },
    resolver: yupResolver(planVacationSchema)
  })

  const [periodsQuantity, isWithPeriodsDivision] = useWatch({
    control,
    name: ['periodsQuantity', 'isWithPeriodsDivision']
  })

  const {
    fields: periodsFields,
    update: updatePeriodField,
  } = useFieldArray({
    control,
    name: 'periods'
  })

  useEffect(() => {
    if (periodsQuantity) {
      setValue('periods', [])

      Array.from({ length: periodsQuantity }).forEach((_, index) => {
        updatePeriodField(index, { period: index + 1, startDate: '', endDate: '' });
      })
    }

  }, [periodsQuantity, updatePeriodField, setValue])

  const queryClient = useQueryClient()

  const { mutateAsync: planVacationFn } = useMutation({
    mutationFn: planVacation,
    onSuccess(_data, variables) {
      const cached = queryClient.getQueriesData<GetVacationsResponse>({
        queryKey: ['vacations']
      })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          vacations: cachedValue.vacations.map((vacation) => {
            if (vacation.id === variables.vacationId) {
              return { ...vacation, status: 'planned' }
            }

            return vacation
          })
        })
      })
    },
  })

  const { promiseMessage } = useToastify()

  async function handlePlanVacation(values: PlanVacationSchema) {
    await promiseMessage(planVacationFn({
      body: {
        ...values,
        periods: values.periods.map(period => {
          return {
            ...period,
            startDate: set(new Date(), {
              ...getInputDateYearsMonthAndDateSplited(period.startDate)
            }),
            endDate: set(new Date(), {
              ...getInputDateYearsMonthAndDateSplited(period.endDate)
            }),
            withAllowance: period.withAllowance === 'yes'
          }
        })
      },
      vacationId,
    }), 'Férias planejadas com sucesso!')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Planejar férias
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        <Box
          as="form"
          w="full"
          onSubmit={handleSubmit(handlePlanVacation)}
        >
          <FormControl isInvalid={!!errors.isWithPeriodsDivision}>
            <FormLabel fontSize="sm">
              Férias em períodos?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Controller
              control={control}
              name="isWithPeriodsDivision"
              render={({ field }) => {
                return (
                  <RadioGroup
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                  >
                    <HStack spacing={3} fontSize="sm">
                      <Radio value="yes">
                        <Text fontSize="sm">Sim</Text>
                      </Radio>
                      <Radio value="no">
                        <Text fontSize="sm">Não</Text>
                      </Radio>
                    </HStack>

                  </RadioGroup>
                )
              }}
            />
          </FormControl>

          <FormControl isInvalid={!!errors.isWithPeriodsDivision} mt={3}>
            <FormLabel fontSize="sm">
              Quantidade de períodos
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Input
              {...register("periodsQuantity")}
              name="periodsQuantity"
              type="number"
              disabled={isWithPeriodsDivision === 'no'}
              size="sm"
              rounded="md"
            />
          </FormControl>

          <FormLabel fontSize="sm" mt={3}>
            Períodos
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          {periodsFields.map((field, index) => {
            return (
              <VStack
                spacing={3}

                outline="1px solid"
                rounded="lg"
                key={field.id}
                outlineColor="gray.100"
                p={2}
                _notFirst={{
                  mt: 3
                }}
                position="relative"
              >
                <Text
                  position="absolute"
                  top={-1}
                  outline="4px solid"
                  outlineColor="white"
                  left={-2}
                  rounded="full"
                  bg="blue.500"
                  w="20px"
                  h="20px"
                  fontSize="sm"
                  as="b"
                  textColor="white"
                  textAlign="center"
                >
                  {field.period}
                </Text>

                <Stack
                  spacing={3}
                  direction={{ base: 'column', lg: 'row' }}
                  w="full"
                >
                  <FormControl
                    isInvalid={
                      errors.periods ? !!errors.periods[index].startDate : false
                    }
                    mt={3}
                  >
                    <FormLabel fontSize="sm">
                      Data inicial
                      <Text as="sup" color="red.500">*</Text>
                    </FormLabel>

                    <Input
                      {...register(`periods.${index}.startDate`)}
                      name={`periods.${index}.startDate`}
                      type="date"
                      size="sm"
                      rounded="md"
                    />
                  </FormControl>

                  <FormControl
                    isInvalid={
                      errors.periods ? !!errors.periods[index].endDate : false
                    }
                    mt={3}
                  >
                    <FormLabel fontSize="sm">
                      Data final
                      <Text as="sup" color="red.500">*</Text>
                    </FormLabel>

                    <Input
                      {...register(`periods.${index}.endDate`)}
                      name={`periods.${index}.endDate`}
                      type="date"
                      size="sm"
                      rounded="md"
                    />
                  </FormControl>
                </Stack>

                <FormControl
                  isInvalid={
                    errors.periods ? !!errors.periods[index].withAllowance : false
                  }
                >
                  <FormLabel fontSize="sm">
                    Funcionário irá abonar 1/3?
                    <Text as="sup" color="red.500">*</Text>
                  </FormLabel>

                  <Controller
                    control={control}
                    name={`periods.${index}.withAllowance`}
                    render={({ field }) => {
                      return (
                        <RadioGroup
                          name={field.name}
                          onChange={field.onChange}
                          value={field.value}
                        >
                          <HStack spacing={3} fontSize="sm" w="full">
                            <Radio value="yes">
                              <Text fontSize="sm">Sim</Text>
                            </Radio>
                            <Radio value="no">
                              <Text fontSize="sm">Não</Text>
                            </Radio>
                          </HStack>

                        </RadioGroup>
                      )
                    }}
                  />
                </FormControl>

              </VStack>
            )
          })}

          <HStack
            w="full"
            justify="end"
            mt={3}
          >
            <Button
              type="submit"
              colorScheme="blue"
              ml="auto"
              size="sm"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Salvar
            </Button>
          </HStack>
        </Box>


      </ModalBody>
    </ModalContent>
  )
}
