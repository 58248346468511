import { Box, Flex, Icon, IconButton, Td, Text, Tooltip, Tr, Link as ChakraLink, Button } from "@chakra-ui/react"
import { formatDate } from "utils/DateFunctions/formatDate"
import { serviceHandleStep } from '../../../utils/ServiceFunctions/serviceDict'
import { FaInfoCircle, FaSearch } from "react-icons/fa"
import { Link } from "react-router-dom"
import { serviceDelay } from "utils/serviceDelay"
import { formatTimeByTimezoneUser } from "utils/formatTimeByTimezoneUser"
import { format } from "date-fns"
import { FaArrowRight } from "react-icons/fa6"
import { useAuth } from "hooks/auth/useAuth"

interface DeliveriesTableRowProps {
  service: {
    id: string
    protocol: number,
    customer: string
    vehicle: string
    source_addresses: string[],
    source_cities: string[],
    delivered_addresses: string[] | null,
    destination_cities: string[],
    collect_hour_start: string
    collect_hour_end: string
    service_type: string
    board_hour: string
    shipping: string
    source_branch: string
    destination_branch: string
    delivery_date: string
    delivery_hour: string
    observations: string
    source_collector: string
    board_volume: number
    cte: number
    tracker: number
    step: string
    destination_collector: string
    source_tz: string
    destination_tz: string
    tracker_link: string | null
    has_occurrence: boolean
    destination_addresses: {
      id: string
      address: string
    }[]
    going_delivery_addresses: {
      id: string
      address: string
    }[] | null
  }
}

export function DeliveriesTableRow({ service }: DeliveriesTableRowProps) {
  const { userLogged } = useAuth()

  const serviceInDelay = serviceDelay({
    timezoneOffset: service.destination_tz,
    serviceDate: service.delivery_date,
    serviceHour: service.delivery_hour
  })


  const userCanDoServiceMaintenance = userLogged?.permissions.includes('view-maintenance-service')
  const userCanValidateFiscalRetention = userLogged?.permissions.includes('validate-fiscal-retention')
  const userCanDoServiceLanding = userLogged?.permissions.includes('add-landing-service')
  const userCanAvailableService = userLogged?.permissions.includes('add-available-service')
  const userCanAllocateService = userLogged?.permissions.includes('add-allocated-service')
  const userCanValidateBoardService = userLogged?.permissions.includes('valid-boarding-service')
  const userCanDeliveryAddress = userLogged?.permissions.includes('add-delivery-service')

  const pathRedirect = '/relacao/servicos?tab=deliveries&page=1'

  return (
    <Tr
      key={service.protocol}
      color={service.has_occurrence ? 'yellow.400' : serviceInDelay ? 'red.400' : ''}
    >
      <Td>
        <Flex align="center" gap="3">
          <IconButton
            as={Link}
            to={`/servico/detalhes/${service.id}`}
            aria-label="Detalhes do serviço"
            size="sm"
            icon={<FaSearch />}
            variant="ghost"
          />

          {(service.step === 'toBoardValidate' && userCanValidateBoardService) && (
            <Button
              as={Link}
              size="xs"
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              rounded="md"
              variant="ghost"
              to={{
                pathname: `/servicos/embarques/validar/${service.id}`,
                state: {
                  path: pathRedirect
                }
              }}
            >
              Validar embarque
            </Button>
          )}

          {(service.step === 'toAllocateService' && userCanAllocateService) && (
            <Button
              as={Link}
              size="xs"
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              rounded="md"
              variant="ghost"
              to={{
                pathname: `/servicos/alocar/${service.id}`,
                state: {
                  path: pathRedirect
                }
              }}
            >
              Alocar
            </Button>
          )}

          {(service.step === 'toAvailableService' && userCanAvailableService) && (
            <Button
              as={Link}
              size="xs"
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              rounded="md"
              variant="ghost"
              to={{
                pathname: `/servicos/disponibilizar/desembarque/${service.id}`,
                state: {
                  path: pathRedirect
                }
              }}
            >
              Disponibilizar
            </Button>
          )}

          {(['toLandingService', 'landingService'].includes(service.step) && userCanDoServiceLanding) && (
            <Button
              as={Link}
              size="xs"
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              rounded="md"
              variant="ghost"
              to={{
                pathname: `/servicos/desembarques/${service.id}`,
                state: {
                  path: pathRedirect
                }
              }}
            >
              Desembarcar
            </Button>
          )}

          {(['toMaintenanceService', 'ongoingMaintenanceService'].includes(service.step) && userCanDoServiceMaintenance) && (
            <Button
              as={Link}
              size="xs"
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              rounded="md"
              variant="ghost"
              to={{
                pathname: `/servico/manutencao/${service.id}`,
                state: {
                  path: pathRedirect
                }
              }}
            >
              Realizar manutenção
            </Button>
          )}

          {(service.step === 'toValidateFiscalRetention' && userCanValidateFiscalRetention) && (
            <Button
              as={Link}
              size="xs"
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              rounded="md"
              variant="ghost"
              to={{
                pathname: `/servico/retencao-fiscal/validar/${service.id}`,
                state: {
                  path: pathRedirect
                }
              }}
            >
              Validar retenção fiscal
            </Button>
          )}

          {(['toDeliveryService', 'deliveringService'].includes(service.step) && userCanDeliveryAddress) && (
            <Button
              as={Link}
              size="xs"
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              rounded="md"
              variant="ghost"
              to={{
                pathname: `/servicos/entregas/${service.id}`,
                state: {
                  path: pathRedirect
                }
              }}
            >
              Entregar
            </Button>
          )}
        </Flex>
      </Td>
      <Td>{service.protocol}</Td>
      <Td>
        <Text maxW="300px" whiteSpace="pre-line">
          {service.customer}
        </Text>
      </Td>
      <Td>
        {service.service_type === 'DEDICADO' ? (
          service?.source_addresses?.map((address, index) => (
            <Text w="300px" key={index} whiteSpace="pre-line">
              {service.source_addresses.length > 1 && index + 1 + '.'} {address}
            </Text>
          ))
        ) : (
          <Text w="300px" whiteSpace="pre-line">
            Material/Materiais de {' '}
            {service?.source_cities?.map((city) => city).join(', ')}
          </Text>
        )}
      </Td>
      <Td>
        {service.service_type === 'DEDICADO' ? (
          'Serviço Dedicado'
        ) : (
          <>
            {`Retirada de ${service.board_volume ?? 'Não informado/encontrado'} vol(s).na ${' '}`}
            <br />
            {`${service.shipping} - ${service.destination_branch} ${' '}`}
            <br />
            {`CTe: ${service.cte ?? 'Não informado/encontrado'},`}
            <br />
            {service.tracker_link ? (
              <>
                Rastreador: <ChakraLink
                  href={service.tracker_link}
                  isExternal
                >
                  {service.tracker}
                </ChakraLink>
              </>
            ) : (
              <>
                {`Rastreador: ${service.tracker ?? 'Não informado/encontrado'}`}

              </>
            )}
          </>
        )}
      </Td>
      <Td>
        {service.destination_tz ? (
          <Flex>
            {format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service.delivery_date), timezoneOffset: service.destination_tz }), 'dd/MM/yyyy')}

            {Number(service.destination_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
              <Tooltip label={service.destination_tz}>
                <Box as="span" ml="2">
                  <Icon as={FaInfoCircle} />
                </Box>
              </Tooltip>
            )}
          </Flex>
        ) : (
          formatDate.handle(service.delivery_date, "DateWithoutHourToShow")
        )}
      </Td>
      <Td>
        {service.destination_tz ? (
          <Flex>
            {format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service.delivery_hour), timezoneOffset: service.destination_tz }), 'HH:mm')}
            {Number(service.destination_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
              <Tooltip label={service.destination_tz}>
                <Box as="span" ml="2">
                  <Icon as={FaInfoCircle} />
                </Box>
              </Tooltip>
            )}
          </Flex>
        ) : (
          formatDate.handle(service.delivery_hour, "DateOnlyWithHourMinute")
        )}
      </Td>
      <Td>
        {service?.destination_addresses?.map((address, index) => {
          if (
            service.delivered_addresses &&
            service.delivered_addresses.includes(address.address)
          ) {
            return (
              <Text as="del" w="300px" key={index} whiteSpace="pre-line">
                {service.destination_addresses.length > 1 && index + 1 + '.'} {address.address}
              </Text>
            )
          }

          if (['toDeliveryService', 'deliveringService'].includes(service.step)) {
            if (
              service.going_delivery_addresses &&
              service.going_delivery_addresses.find(goingAddress => goingAddress.id === address.id)
            ) {
              return (
                <Text w="300px" key={index} whiteSpace="pre-line" _hover={{
                  opacity: 0.7
                }}>
                  <Link
                    to={{
                      pathname: `/servicos/entregas/${service.id}/enderecos/${address.id}`,
                      state: {
                        path: pathRedirect
                      }
                    }}
                  >

                    {service.destination_addresses.length > 1 && index + 1 + '.'} {address.address}
                  </Link>
                </Text>
              )
            }

            return (
              <Text w="300px" key={index} whiteSpace="pre-line" _hover={{
                opacity: 0.7
              }}>
                <Link to={{
                  pathname: `/servicos/entregas/${service.id}/enderecos/${address.id}`,
                  state: {
                    path: pathRedirect
                  }
                  }}>
                  {service.destination_addresses.length > 1 && index + 1 + '.'} {address.address}
                </Link>
              </Text>
            )
          }

          return (
            <Text w="300px" key={index} whiteSpace="pre-line">
              {service.destination_addresses.length > 1 && index + 1 + '.'} {address.address}
            </Text>
          )
        }
        )}
      </Td>
      <Td>{serviceHandleStep(service.step)}</Td>
      <Td>{service.source_collector}</Td>
      <Td>{service.destination_collector}</Td>
    </Tr>
  )
}
