import { createContext, ReactNode, useCallback, useState } from "react";

export enum CollectorsExtrasDiscountsStatusEnum {
  'requested' = 'Solicitado',
  'approved' = 'Aprovado',
  'effectivated' = 'Efetivado',
  'rejected' = 'Rejeitado'
}

export type CollectorsExtrasDiscountsStatus = keyof typeof CollectorsExtrasDiscountsStatusEnum

interface CollectorsExtrasDiscountsStatusContextProps {
  status: CollectorsExtrasDiscountsStatus
  handleChangeStatus: (status: CollectorsExtrasDiscountsStatus) => void
}

interface CollectorsExtrasDiscountsStatusContextProviderProps {
  children: ReactNode
}

export const CollectorsExtrasDiscountsStatusContext = createContext(
  {} as CollectorsExtrasDiscountsStatusContextProps
)

export function CollectorsExtrasDiscountsStatusProvider({
  children
}: CollectorsExtrasDiscountsStatusContextProviderProps) {
  const [status, setStatus] = useState<CollectorsExtrasDiscountsStatus>('requested')

  const handleChangeStatus = useCallback((status: CollectorsExtrasDiscountsStatus) => {
    setStatus(status)
  }, [])

  return (
    <CollectorsExtrasDiscountsStatusContext.Provider value={{
      status,
      handleChangeStatus
    }}>
      {children}
    </CollectorsExtrasDiscountsStatusContext.Provider>
  )
}
