import axios from "axios";
import { useAuth } from "hooks/auth/useAuth";
import { useMutation, useQueryClient } from "react-query";
import { api } from "services/api";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";
import { RequestError } from "utils/errors/RequestErrors";

interface CreateProductInput {
  situation: string
  material_id: string
  general_provider_id: string
  warehouse_id: string,
  measure: string
  unit_cost: number
  min_stock: number
  standard_stock: number
  current_stock: number
}

interface UpdateProductRequestProps {
  id: string
  input: CreateProductInput
}

async function createProductReqFunction(input: CreateProductInput) {
  try {
    await api.post('/products', input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

async function updateProductReqFunction({
  id, input
}: UpdateProductRequestProps) {
  try {
    await api.put(`/products/${id}`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

async function deleteProductReqFunction(id: string) {
  try {
    await api.delete(`/products/${id}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

export function useProductFunctions() {

  const queryClient = useQueryClient()
  const { handleLogout, } = useAuth()

  const onRequestSuccess = async () => {
    await queryClient.invalidateQueries("products")
  }

  const onRequestError = async (error: RequestError) => {
    if (error.httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  const createProduct = useMutation('createProduct', createProductReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  const updateProduct = useMutation('updateProduct', updateProductReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  const deleteProduct = useMutation('deleteProduct', deleteProductReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  return { createProduct, updateProduct, deleteProduct }
}
