import { Box, Button, Checkbox, CheckboxGroup, FormControl, FormLabel, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Radio, RadioGroup, Stack, Text, Textarea, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { evaluateCarrier } from "api/carriers/evaluateCarrier";
import { GetCarriersResponse } from "api/carriers/getCarriers";
import { useToastify } from "hooks/toastify/useToastify";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";

interface EvaluateCarrierSchema {
  pendencies: string[]
  observations: string
  hasLoglifeUniversityRegister: 'yes' | 'no'
  hasTrainingRegister: 'yes' | 'no'
  hasVaccinesRegister: 'yes' | 'no'
  hasAsoRegister: 'yes' | 'no'
  withPendencies: 'yes' | 'no'
}

const evaluateCarrierSchema = yup.object({
  pendencies: yup.array().of(yup.string()).nullable(),
  observations: yup.string().required(),
  hasLoglifeUniversityRegister: yup.string().required(),
  hasTrainingRegister: yup.string().required(),
  hasVaccinesRegister: yup.string().required(),
  hasAsoRegister: yup.string().required(),
  withPendencies: yup.string().required(),
})

const pendencies = [
  { name: 'Nome completo' },
  { name: 'CPF' },
  { name: 'E-mail' },
  { name: 'Parceiro Loglife' },
  { name: 'Dose DT' },
  { name: 'Dose hepatite B' },
  { name: 'Dose tríplice viral' },
  { name: 'CNH' },
  { name: 'CRLV' },
  { name: 'Curso motofrete/ANTT' },
  { name: 'Foto da lateral' },
  { name: 'Foto da traseira' },
  { name: 'Foto interna' },
]

interface EvaluateCarrierProps {
  carrierId: string
  onClose: () => void
}

export function EvaluateCarrier({ carrierId, onClose }: EvaluateCarrierProps) {

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<EvaluateCarrierSchema>({
    resolver: yupResolver(evaluateCarrierSchema)
  })

  const queryClient = useQueryClient()

  const { mutateAsync: evaluateCarrierFn } = useMutation({
    mutationFn: evaluateCarrier,
    onSuccess(_data, variables) {
      const cached = queryClient.getQueriesData<GetCarriersResponse>({
        queryKey: ['carriers']
      })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          carriers: cachedValue.carriers.map((carrier) => {

            if (carrier.id === variables.carrierId) {
              return { ...carrier, status: variables.body.withPendencies ? 'pending' : 'qualified' }
            }

            return carrier
          })
        })
      })

      onClose()
    },
  })


  const { promiseMessage } = useToastify()

  async function handleEvaluateCarrier(values: EvaluateCarrierSchema) {
    await promiseMessage(evaluateCarrierFn({
      body: {
        ...values,
        hasAsoRegister: values.hasAsoRegister === 'yes',
        hasLoglifeUniversityRegister: values.hasLoglifeUniversityRegister === 'yes',
        hasTrainingRegister: values.hasTrainingRegister === 'yes',
        hasVaccinesRegister: values.hasVaccinesRegister === 'yes',
        withPendencies: values.withPendencies === 'yes'
      },
      carrierId
    }), 'Avaliação de portador realizada com sucesso! 🎉')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Avaliar portador
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        <Box
          as="form"
          onSubmit={handleSubmit(handleEvaluateCarrier)}
        >
          <FormControl>
            <FormLabel>
              Pendências
            </FormLabel>
            <Controller
              control={control}
              name="pendencies"
              render={({ field }) => {
                return (
                  <CheckboxGroup
                    value={field.value}
                    onChange={field.onChange}
                  >
                    <VStack
                      spacing={3}
                      p={2}
                      border="1px solid"
                      borderColor="gray.200"
                      rounded="md"
                      align="left"
                    >
                      {pendencies.map(pendency => {
                        return (
                          <Checkbox key={pendency.name} value={pendency.name}>
                            <Text fontSize="sm">
                              {pendency.name}
                            </Text>
                          </Checkbox>
                        )
                      })}
                    </VStack>

                  </CheckboxGroup>
                )
              }}
            />
          </FormControl>

          <FormControl mt={3} isInvalid={!!errors.observations}>
            <FormLabel>
              Observações
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Textarea
              {...register('observations')}
              name="observations"
            />
          </FormControl>

          <Stack
            mt={3}
            spacing={3}
            direction={{ base: 'column', lg: 'row' }}
          >
            <FormControl>
              <FormLabel>
                Cadastro na Universidade Loglife
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Controller
                control={control}
                name="hasLoglifeUniversityRegister"
                render={({ field }) => {
                  return (
                    <RadioGroup
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                    >
                      <VStack
                        spacing={3}
                        align="left"
                      >
                        <Radio value="yes">Sim</Radio>
                        <Radio value="no">Não</Radio>
                      </VStack>
                    </RadioGroup>
                  )
                }}
              />
            </FormControl>

            <FormControl>
              <FormLabel>
                Cadastro de treinamento
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Controller
                control={control}
                name="hasTrainingRegister"
                render={({ field }) => {
                  return (
                    <RadioGroup
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                    >
                      <VStack
                        spacing={3}
                        align="left"
                      >
                        <Radio value="yes">Sim</Radio>
                        <Radio value="no">Não</Radio>
                      </VStack>
                    </RadioGroup>
                  )
                }}
              />
            </FormControl>
          </Stack>

          <Stack
            mt={3}
            spacing={3}
            direction={{ base: 'column', lg: 'row' }}
          >
            <FormControl isInvalid={!!errors.hasVaccinesRegister}>
              <FormLabel>
                Cadastro de vacinas
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Controller
                control={control}
                name="hasVaccinesRegister"
                render={({ field }) => {
                  return (
                    <RadioGroup
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                    >
                      <VStack
                        spacing={3}
                        align="left"
                      >
                        <Radio value="yes">Sim</Radio>
                        <Radio value="no">Não</Radio>
                      </VStack>
                    </RadioGroup>
                  )
                }}
              />
            </FormControl>

            <FormControl isInvalid={!!errors.hasAsoRegister}>
              <FormLabel>
                Cadastro de ASO
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Controller
                control={control}
                name="hasAsoRegister"
                render={({ field }) => {
                  return (
                    <RadioGroup
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                    >
                      <VStack
                        spacing={3}
                        align="left"
                      >
                        <Radio value="yes">Sim</Radio>
                        <Radio value="no">Não</Radio>
                      </VStack>
                    </RadioGroup>
                  )
                }}
              />
            </FormControl>
          </Stack>

          <FormControl mt={3} isInvalid={!!errors.withPendencies}>
            <FormLabel>
              Pendência
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Controller
              control={control}
              name="withPendencies"
              render={({ field }) => {
                return (
                  <RadioGroup
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                  >
                    <VStack
                      spacing={3}
                      align="left"
                    >
                      <Radio value="yes">Sim</Radio>
                      <Radio value="no">Não</Radio>
                    </VStack>
                  </RadioGroup>
                )
              }}
            />
          </FormControl>

          <Stack align="end">
            <Button
              type="submit"
              colorScheme="blue"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Avaliar
            </Button>
          </Stack>
        </Box>
      </ModalBody>
    </ModalContent>
  )
}
