import { Button, HStack, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react"
import { ExternalSupport } from "api/externalSupports/_types/ExternalSupport"
import { useAuth } from "hooks/auth/useAuth"
import { FaArrowRight, FaSearch } from "react-icons/fa"
import { DesignateExternalSupport } from "./DesignateExternalSupport"
import { ExternalSupportDetail } from "./ExternalSupportDetail"
import { ExternalSupportStatus } from "./ExternalSupportStatus"
import { ResolveExternalSupport } from "./ResolveExternalSupport"

interface ExternalSupportsTableRowProps {
  externalSupport: ExternalSupport
}

export function ExternalSupportsTableRow({ externalSupport }: ExternalSupportsTableRowProps) {
  const { userLogged } = useAuth()

  const userCanDesignateExternalSupport = userLogged?.permissions.includes(
    'designate-external-support'
  )

  const userCanResolveExternalSupport = userLogged?.permissions.includes(
    'resolve-external-support'
  )
  const {
    isOpen: isExternalSupportDetailOpen,
    onToggle: onToggleExternalSupportDetail
  } = useDisclosure()

  const {
    isOpen: isDesignateExternalSupportOpen,
    onToggle: onToggleDesignateExternalSupport
  } = useDisclosure()

  const {
    isOpen: isResolveExternalSupportOpen,
    onToggle: onToggleResolveExternalSupport
  } = useDisclosure()

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Abrir detalhes do atendimento"
          variant="ghost"
          icon={<FaSearch />}
          size="sm"
          onClick={onToggleExternalSupportDetail}
        />

        <Modal
          isOpen={isExternalSupportDetailOpen}
          onClose={onToggleExternalSupportDetail}
          size="2xl"
        >
          <ModalOverlay />

          <ExternalSupportDetail externalSupportId={externalSupport.id} />
        </Modal>
      </Td>
      <Td>{externalSupport?.protocol}</Td>
      <Td>{externalSupport?.reason}</Td>
      <Td>
        <ExternalSupportStatus
          status={externalSupport.status}
        />
      </Td>
      <Td>
        <HStack spacing={3} align="center">
          {userCanDesignateExternalSupport && externalSupport.status === 'designating' && (
            <>
              <Button
                size="xs"
                rightIcon={<FaArrowRight />}
                onClick={onToggleDesignateExternalSupport}
                lineHeight={1}
              >
                Designar
              </Button>

              <Modal
                isOpen={isDesignateExternalSupportOpen}
                onClose={onToggleDesignateExternalSupport}
              >
                <ModalOverlay />

                <DesignateExternalSupport
                  onCloseModal={onToggleDesignateExternalSupport}
                  externalSupportId={externalSupport.id} />
              </Modal>
            </>
          )}
          {userCanResolveExternalSupport && externalSupport.status === 'resolving' && (
            <>
              <Button
                size="xs"
                rightIcon={<FaArrowRight />}
                onClick={onToggleResolveExternalSupport}
                lineHeight={1}
              >
                Resolver
              </Button>

              <Modal
                isOpen={isResolveExternalSupportOpen}
                onClose={onToggleResolveExternalSupport}
              >
                <ModalOverlay />

                <ResolveExternalSupport
                  onCloseModal={onToggleResolveExternalSupport}
                  externalSupportId={externalSupport.id} />
              </Modal>
            </>
          )}
        </HStack>
      </Td>
    </Tr>
  )
}
