import { useEffect } from "react"
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Flex, Spinner } from "@chakra-ui/react"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

import { useAuth } from "hooks/auth/useAuth"
import { Link, useHistory } from "react-router-dom"

import { useToastify } from "hooks/toastify/useToastify";

import { ProductForm, ProductFormInputs } from "./components/ProductForm";
import { useMaterials } from "hooks/material/useMaterials";

import { useProductFunctions } from "hooks/product/useProductFunctions";
import { useGeneralProviders } from "hooks/generalProvider/useGeneralProviders";
import { useWarehouses } from "hooks/warehouse/useWarehouses";

const createProductFormSchema = yup.object().shape({
  situation: yup.string().required("Campo Obrigatório"),
  material_id: yup.string().required("Campo Obrigatório"),
  general_provider_id: yup.string().required("Campo Obrigatório"),
  warehouse_id: yup.string().required("Campo Obrigatório"),
  measure: yup.string().required("Campo Obrigatório"),
  unit_cost: yup.string().required("Campo Obrigatório"),
  min_stock: yup.number().typeError("Campo Obrigatório").required("Campo Obrigatório"),
  standard_stock: yup.number().typeError("Campo Obrigatório").required("Campo Obrigatório"),
  current_stock: yup.number().typeError("Campo Obrigatório").required("Campo Obrigatório"),
})

export function CreateProductPage() {
  const formMethods = useForm<ProductFormInputs>({
    resolver: yupResolver(createProductFormSchema)
  })

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()
  const { promiseMessage } = useToastify()
  const { handleSubmit, formState: { isSubmitting } } = formMethods


  useEffect(() => {
    if (!userLogged.permissions.includes('create-product')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])


  const { data: materials, isFetching: isFetchingMaterials } = useMaterials()
  const { data: generalProviders, isFetching: isFetchingGeneralProviders } = useGeneralProviders()
  const { data: warehouses, isFetching: isFetchingWarehouses } = useWarehouses()

  const { createProduct: { mutateAsync: createProduct } } = useProductFunctions()

  async function handleCreateProduct({ ...values }: ProductFormInputs) {

    const successMessage = "Produto criado com sucesso!"

    await promiseMessage(createProduct(values, {
      onSuccess: () => redirectTo("/produtos")
    }), successMessage)
  }
  return (
    <StandardBackgroundForm title="Criar Produto" onSubmit={handleSubmit(handleCreateProduct)}>
      {(isFetchingMaterials && isFetchingGeneralProviders && isFetchingWarehouses) ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <ProductForm slug="criar" materials={materials} generalProviders={generalProviders} warehouses={warehouses}/>
        </FormProvider>
      )}

      <Flex
        mt={6}
        gap={4}
        direction={["column", "column", "row"]}
        align="center"
        justify={["center", "center", "flex-end"]}
      >
        <Button
          as={Link}
          colorScheme='gray'
          w={['full', 'full', 'min']}
          isLoading={isSubmitting}
          to='/produtos'
        >
          Lista de produtos
        </Button>
        <Button
          w={["full", "full", "min"]}
          colorScheme="blue"
          type="submit"
          isLoading={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}
