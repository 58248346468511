import { ProductResponse } from './dtos/Product';
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";

type QueryOptions = UseQueryOptions<ProductResponse, unknown, ProductResponse, QueryKey>

interface ProductQueryParams {
  q?: string
  situation?: string
  page_size?: number
  current_page?: number
}

interface UseProductOptions {
  queryKey?: string
  queryOptions?: QueryOptions
  queryParams?: ProductQueryParams
}

export function useProducts(options?: UseProductOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getProducts(queryParams?: ProductQueryParams) {

    try {
      const { data } = await api.get('/products', {
        params: {
          ...queryParams,
          q: queryParams.q === undefined ? '': queryParams.q,
          situation: queryParams.situation,
        }
      })

      return data

    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new Error("Erro inesperado!")
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([options?.queryKey || 'products', {...options?.queryParams}], ()=> getProducts(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}
