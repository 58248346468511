import { useToastify } from "hooks/toastify/useToastify"
import { useLLMUsers } from "hooks/user/useLLMUsers"
import { useMutation, useQueryClient } from "react-query"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Button, Flex, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Stack, Text } from "@chakra-ui/react";
import { designateExternalSupport } from "api/externalSupports/designateExternalSupport";
import { captalize } from "utils/captalize";
import { set } from "date-fns";

interface DesignateExternalSupportProps {
  onCloseModal: () => void
  externalSupportId: string
}

interface DesignateExternalSupportSchema {
  resolutionResponsibleId: string
  answerDeadline: string
}

const designateExternalSupportSchema = yup.object({
  resolutionResponsibleId: yup.string().required(),
  answerDeadline: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
})


export function DesignateExternalSupport({
  externalSupportId,
  onCloseModal
}: DesignateExternalSupportProps) {

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const {
    data: llmUsersData
  } = useLLMUsers({
    queryParams: {
      situation: 'ATIVO'
    }
  })
  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<DesignateExternalSupportSchema>({
    resolver: yupResolver(designateExternalSupportSchema)
  })

  const { mutateAsync: designateExternalSupportFn } = useMutation({
    mutationFn: designateExternalSupport,
    onSuccess() {
      queryClient.invalidateQueries(['externalSupports'])
      onCloseModal()
    }
  })

  async function handleDesignateExternalSupport(values: DesignateExternalSupportSchema) {
    await promiseMessage(designateExternalSupportFn({
      body: {
        ...values
      },
      routeParams: {
        externalSupportId
      }
    }), 'Designação realizada!')
  }
  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Designar Responsável
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        as="form"
        w="full"
        onSubmit={handleSubmit(handleDesignateExternalSupport)}
      >
        <FormLabel fontSize="sm" mt={3}>
          Responsável pela resolução
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <FormControl isInvalid={!!errors.resolutionResponsibleId}>
          <Select
            {...register('resolutionResponsibleId')}
            name="resolutionResponsibleId"
            placeholder="Selecione..."
            size="sm"
            rounded="md"
          >
            {llmUsersData?.users?.map((user) => {
              return (
                <option key={user.id} value={user.id}>{captalize(`${user.firstname} ${user.lastname}`)}</option>
              )
            })}
          </Select>
        </FormControl>
        <Stack
          direction="column"
          w="full"
          mt="3"
        >
          <FormControl isInvalid={!!errors?.answerDeadline}>
            <FormLabel fontSize="sm">
              Prazo para resposta
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('answerDeadline')}
              name="answerDeadline"
              type="date"
              size="sm"
              rounded="md"
            />
          </FormControl>
        </Stack>
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Salvar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
