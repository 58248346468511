import { IconButton, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner, Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react";
import { getAso } from "api/asos/getAso";
import { format } from "date-fns";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useQuery } from "react-query";
import { asoCollaboratorTypeMap, asoTypeMap } from "utils/asoMappers";
import { captalize } from "utils/captalize";
import { AsoStatus } from "./AsoStatus";

interface AsoDetailProps {
  asoId: string
}

export function AsoDetail({
  asoId,
}: AsoDetailProps) {

  const { data: asoData, isLoading: isAsoDataLoading } = useQuery({
    queryKey: ['aso', asoId],
    queryFn: () => getAso({ asoId })
  })

  return (
    <ModalContent>
      {isAsoDataLoading ? (
        <ModalBody
          p="6"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner />
        </ModalBody>
      ) : (
        <>
          <ModalHeader letterSpacing="tight">Detalhes do ASO</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <TableContainer>
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td>Regional</Td>
                    <Td isNumeric>{asoData.aso.regional ? asoData.aso.regional.code.toUpperCase() : '-'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td display="flex" justifyContent="flex-end">
                      <AsoStatus status={asoData.aso.status} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Colaborador</Td>
                    <Td isNumeric maxW="150px" overflow="hidden" textOverflow="ellipsis">
                      {captalize(`${asoData.aso.collaborator_name}`)}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Responsável</Td>
                    <Td isNumeric maxW="150px" overflow="hidden" textOverflow="ellipsis">
                      {asoData.aso.regional ? captalize(`${asoData.aso.regional.responsible.firstname} ${asoData.aso.regional.responsible.lastname}`) : '-'}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Data da solicitação</Td>
                    <Td isNumeric>{format(new Date(asoData.aso.created_at), 'dd/MM/yyyy')}</Td>
                  </Tr>
                  <Tr>
                    <Td>Tipo</Td>
                    <Td isNumeric>{asoTypeMap[asoData.aso.type]}</Td>
                  </Tr>
                  <Tr>
                    <Td>Tipo de colaborador</Td>
                    <Td isNumeric>{asoCollaboratorTypeMap[asoData.aso.collaborator_type]}</Td>
                  </Tr>
                  {asoData?.aso?.attachment && (
                    <Tr>
                      <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">{asoData?.aso?.attachment?.title}</Td>
                      <Td isNumeric>
                        <IconButton
                          aria-label="Abrir anexo"
                          as={Link}
                          href={asoData?.aso?.attachment?.link}
                          icon={<FaExternalLinkAlt />}
                          size="sm"
                          isExternal
                        />
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
        </>
      )}

    </ModalContent>
  )
}
