import { Box, Button, Divider, Flex, Heading, Icon, useDisclosure } from "@chakra-ui/react"

import { Link, useHistory } from "react-router-dom"
import { usePagination } from "@ajna/pagination"
import { useEffect, useState } from "react"
import { Pagination } from "components/Pagination/Pagination"
import { useToastify } from "hooks/toastify/useToastify"
import { useQueryClient } from "react-query"
import { FaCheckCircle, FaPen, FaPlus, FaTimes, FaTrash, FaUndoAlt } from "react-icons/fa"
import { useAuth } from "hooks/auth/useAuth"
import { useAggregateFunctions } from "hooks/aggregate/useAggregateFunctions"
import { useAggregatesExtrasDiscountsStatus } from "hooks/aggregate/useAggregatesExtrasDiscountsStatus"
import { AggregatesExtrasDiscountsStatusEnum } from "contexts/AggregatesExtrasDiscountsStatusContext"
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions"
import { TableFilterButton } from "components/Filters/TableFilterButton"
import { AggregateExtrasDiscountsListContextProvider } from "contexts/AggregateExtrasDiscountsListContext"
import { AggregateExtrasDiscountsFilterOptions } from "./components/AggregateExtrasDiscountsFilterOptions"
import { AggregateExtrasDiscountsTable } from "./components/AggregateExtrasDiscountsTable"
import { ExtraDiscount } from "hooks/extrasDiscounts/dtos/ExtrasDiscounts"
import { ViewExtraDiscountModal } from "components/Modal/ViewExtraDiscountModal"
import { formatDate } from "utils/DateFunctions/formatDate"
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton"

const aggregateListSearchOptions = [
  { key: 'aggregate', value: 'Motorista', checked: false },
  { key: 'period', value: 'Período', checked: false },
  { key: 'route', value: 'Rota', checked: false },
]
const headers = [
  { label: 'AGREGADO', key: 'aggregate' },
  { label: 'TIPO', key: 'type' },
  { label: 'DATA DO SERVIÇO', key: 'service_date' },
  { label: 'VALOR', key: 'value' },
  { label: 'ROTA', key: 'route' },
  { label: 'PROTOCOLO', key: 'protocol' },
  { label: 'DESCRIÇÃO', key: 'description' },
  { label: 'CRIADO POR', key: 'created_by' },
]

function formatValuesToReport(values: ExtraDiscount[]) {
  return values?.map((extraDiscount) => ({
    ...extraDiscount,
    aggregate: `${extraDiscount.aggregate.driver.firstname} ${extraDiscount.aggregate.driver.lastname} `,
    service_date: formatDate.handle(extraDiscount.service_date, 'DateWithoutHourToShow'),
    value: extraDiscount.value / 100,
    route: extraDiscount.service_route ?? '-',
    protocol: extraDiscount.service_protocol ?? '-',
    created_by: extraDiscount?.createdBy ? `${extraDiscount?.createdBy.firstname} ${extraDiscount?.createdBy.lastname}` : '-',
  }))
}


export function AggregatesExtrasDiscountsList() {
  const [extraDiscount, setExtraDiscount] = useState({} as ExtraDiscount)
  const [aggregatesExtrasDiscountsData, setAggregatesExtrasDiscountsData] = useState([] as ExtraDiscount[])

  const [totalPages, setTotalPages] = useState(1)
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()

  const { push: redirect, location } = useHistory()


  const {
    onLoadSetFilterOptions
  } = useFilterOptions()

  useEffect(() => {
    onLoadSetFilterOptions(aggregateListSearchOptions)
  }, [onLoadSetFilterOptions])


  const { status } = useAggregatesExtrasDiscountsStatus()

  const userHasPermissionToViewAggregateExtraDiscount =
    userLogged?.permissions.includes('view-aggregate-extra-discount')

  useEffect(() => {
    if (!userHasPermissionToViewAggregateExtraDiscount) {
      redirect('/')
    }
  }, [userHasPermissionToViewAggregateExtraDiscount, redirect])

  const statusFilter = location.search.match(`[?&]status=([^&]+)`)
  const currentPageFilter = location.search.match(`[?&]page=([^&]+)`)

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: totalPages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });

  const handleSetTotalPages = (total: number) => setTotalPages(total)
  const handleSetAggregatesExtrasDiscountsData = (aggregatesExtrasDiscounts: ExtraDiscount[]) => setAggregatesExtrasDiscountsData(aggregatesExtrasDiscounts)

  const handleChangePage = (page: number) => setCurrentPage(page)

  useEffect(() => {
    if (userHasPermissionToViewAggregateExtraDiscount) {
      redirect({
        pathname: '/extras-descontos/agregados',
        search: `?page=${currentPage}&status=${status}`
      })
    }
  }, [currentPage, redirect, userHasPermissionToViewAggregateExtraDiscount, status])

  const {
    approveAggregateExtraDiscount: {
      mutateAsync: approveAggregateExtraDiscount
    },
    rejectAggregateExtraDiscount: {
      mutateAsync: rejectAggregateExtraDiscount
    },
    deleteAggregateExtraDiscount: {
      mutateAsync: deleteAggregateExtraDiscount
    },
    effectiveAggregateExtraDiscount,
  } = useAggregateFunctions()

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  async function handleApproveAggregateExtraDiscount(
    cltDriverExtraDiscountId: string
  ) {
    await promiseMessage(approveAggregateExtraDiscount(
      cltDriverExtraDiscountId, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['aggregatesExtrasDiscounts'])
      }
    }
    ), 'Extra/Desconto de agregado aprovado com sucesso!')
  }

  async function handleEffectiveAggregateExtraDiscount(
    cltDriverExtraDiscountId: string
  ) {
    await promiseMessage(effectiveAggregateExtraDiscount.mutateAsync(
      cltDriverExtraDiscountId, {
      onSuccess: async () => {
        await queryClient.invalidateQueries('aggregatesExtrasDiscounts')
      }
    }
    ), 'Extra/Desconto de agregado efetivado com sucesso!')
  }

  async function handleRejectAggregateExtraDiscount(
    cltDriverExtraDiscountId: string
  ) {
    await promiseMessage(rejectAggregateExtraDiscount(
      cltDriverExtraDiscountId, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['aggregatesExtrasDiscounts'])
      }
    }
    ), 'Extra/Desconto de agregado recusado com sucesso!')
  }

  async function handleDeleteAggregateExtraDiscount(
    cltDriverExtraDiscountId: string
  ) {
    await promiseMessage(deleteAggregateExtraDiscount(
      cltDriverExtraDiscountId, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['aggregatesExtrasDiscounts'])
      }
    }
    ), 'Extra/Desconto de agregado excluido com sucesso!')
  }

  function handleUpdateAggregateExtraDiscount(extraDiscountId: string) {
    window.open(`/extra-desconto/${extraDiscountId}/agregado`, '_blank')
  }

  const handleRefetchTableData = async () => {
    await queryClient.invalidateQueries(['aggregatesExtrasDiscounts'])
  }

  const aggregatesExtrasDiscountsStatusActionsOptions = [
    {
      status: 'requested',
      actions: [
        {
          type: 'Aprovar',
          permission: 'approve-aggregate-extra-discount',
          icon: FaCheckCircle,
          handle: handleApproveAggregateExtraDiscount,
        },
        {
          type: 'Editar',
          permission: 'update-aggregate-extra-discount',
          icon: FaPen,
          handle: handleUpdateAggregateExtraDiscount
        },
        {
          type: 'Recusar',
          permission: 'reject-aggregate-extra-discount',
          icon: FaTimes,
          handle: handleRejectAggregateExtraDiscount
        },
        {
          type: 'Excluir',
          permission: 'delete-aggregate-extra-discount',
          icon: FaTrash,
          handle: handleDeleteAggregateExtraDiscount
        },
      ]
    },
    {
      status: 'approved',
      actions: [
        {
          type: 'Efetivar',
          permission: 'effective-aggregate-extra-discount',
          icon: FaCheckCircle,
          handle: handleEffectiveAggregateExtraDiscount
        },
        {
          type: 'Editar',
          permission: 'update-aggregate-extra-discount',
          icon: FaPen,
          handle: handleUpdateAggregateExtraDiscount
        },
        {
          type: 'Excluir',
          permission: 'delete-aggregate-extra-discount',
          icon: FaTrash,
          handle: handleDeleteAggregateExtraDiscount
        },
      ]
    },
    {
      status: 'rejected',
      actions: [
        {
          type: 'Excluir',
          permission: 'delete-aggregate-extra-discount',
          icon: FaTrash,
          handle: handleDeleteAggregateExtraDiscount
        },
      ]
    },
    {
      status: 'effectivated',
      actions: [
        {
          type: 'Editar',
          permission: 'update-aggregate-extra-discount',
          icon: FaPen,
          handle: handleUpdateAggregateExtraDiscount
        },
        {
          type: 'Excluir',
          permission: 'delete-aggregate-extra-discount',
          icon: FaTrash,
          handle: handleDeleteAggregateExtraDiscount
        },
      ]
    },
  ]

  const {
    isOpen: isViewExtraDiscountModalOpen,
    onOpen: onOpenViewExtraDiscountModal,
    onClose: onCloseViewExtraDiscountModal,
  } = useDisclosure()

  const handleOpenViewExtraDiscountModal = (extraDiscount: ExtraDiscount) => {
    setExtraDiscount(extraDiscount)
    onOpenViewExtraDiscountModal()
  }

  const csvReportProps = {
    data: formatValuesToReport(aggregatesExtrasDiscountsData),
    headers,
    filename: `extras-e-descontos-agregados.csv`,
  }

  return (
    <>
      <ViewExtraDiscountModal
        onClose={onCloseViewExtraDiscountModal}
        isOpen={isViewExtraDiscountModalOpen}
        extraDiscount={extraDiscount}
        type='aggregate'
      />

      <Box
        p={4}
        bg='white'
        rounded='lg'
      >
        <Flex
          gap={4}
          direction='column'
        >
          <Flex
            align="center"
            gap={6}
            justify={["center", "center", "space-between"]}
            direction={["column", "column", "row"]}
            flex="1"
            w="full"
          >
            <Heading size='lg'>Extras/Descontos de Agregados - {AggregatesExtrasDiscountsStatusEnum[status]}</Heading>
            <Button
              as={Link}
              size="sm"
              w={["full", "full", "min"]}
              colorScheme="gray"
              to="/extra-desconto/agregado/criar"
              leftIcon={<Icon as={FaPlus} />}
            >
              Novo
            </Button>
          </Flex>


          <Divider />

          <Flex gap={2}>
            <TableFilterButton />
            <Button colorScheme="blue" onClick={handleRefetchTableData}>
              <Icon as={FaUndoAlt} />{' '}
            </Button>
          </Flex>
          <AggregateExtrasDiscountsListContextProvider>
            <AggregateExtrasDiscountsFilterOptions />
            <AggregateExtrasDiscountsTable
              aggregatesExtrasDiscountsStatusActionsOptions={aggregatesExtrasDiscountsStatusActionsOptions}
              rowsPerPage={rowsPerPage}
              statusFilter={statusFilter}
              currentPageFilter={currentPageFilter}
              onSetTotalPages={handleSetTotalPages}
              onOpenViewExtraDiscountModal={handleOpenViewExtraDiscountModal}
              onSetAggregatesExtrasDiscountsData={handleSetAggregatesExtrasDiscountsData}
            />
          </AggregateExtrasDiscountsListContextProvider>
          <GenerateExcelReportButton csvReportProps={csvReportProps} />
          <Pagination
            currentPage={currentPage}
            pages={pages}
            pagesQuantity={pagesCount}
            handlePageChange={handleChangePage}
          />
        </Flex>
      </Box>
    </>
  )
}
