import * as yup from "yup"
import { Button, Flex, Spinner } from "@chakra-ui/react";
import { ExtrasDiscountsForm, ExtrasDiscountsFormInputs } from "components/Form/ExtrasDiscountsForm";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { Select } from "components/Inputs/SelectInput";
import { useToastify } from "hooks/toastify/useToastify";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect, useState } from "react";
import { useCltDrivers } from "hooks/cltDriver/useCltDrivers";
import { useCltDriverFunctions } from "hooks/cltDriver/useCltDriverFunctions";

interface CreateCltDriverExtraDiscountInputs extends Omit<ExtrasDiscountsFormInputs, 'extraDiscountAttachments'> {
  cltDriverId: string
  attachmentsIds: string[]
}

const createCltDriverExtraDiscountSchema = yup.object().shape({
  cltDriverId: yup.string().required('Campo obrigatório'),
  type: yup.string().required('Campo obrigatório'),
  serviceDate: yup.string().required('Campo obrigatório'),
  value: yup.string().required('Campo obrigatório'),
  description: yup.string().required('Campo obrigatório'),
  serviceType: yup.string().required('Campo obrigatório'),
  customerId: yup.mixed().when('serviceType', {
    is: 'BUSINESS',
    then: yup.string().required('Campo obrigatório'),
  }),
  serviceRoute: yup.mixed().when('serviceType', {
    is: 'BUSINESS',
    then: yup.string().required('Campo obrigatório'),
  }),
  serviceProtocol: yup.mixed().when('serviceType', {
    is: 'EXPRESS',
    then: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  })
})

export function CreateCltDriverExtraDiscount() {

  const { userLogged } = useAuth()

  const { push: redirect } = useHistory()

  const userHasPermissionToCreateCltDriverExtraDiscount =
    userLogged?.permissions.includes('create-clt-driver-extra-discount')

  const [attachmentsIds, setAttachmentsIds] = useState<string[]>([])

  useEffect(() => {
    if (!userHasPermissionToCreateCltDriverExtraDiscount) {
      redirect('/')
    }
  }, [redirect, userHasPermissionToCreateCltDriverExtraDiscount])

  const {
    data: cltDriversData,
    isFetching: isFetchingCltDriversData
  } = useCltDrivers()

  const cltDriversSelectOptions = cltDriversData?.cltDrivers?.map((cltDriver) => {
    return {
      key: cltDriver.id,
      value: cltDriver.id,
      showOption: `${cltDriver.driver.firstname} ${cltDriver.driver.lastname}`
    }
  })

  const formMethods = useForm<CreateCltDriverExtraDiscountInputs>({
    resolver: yupResolver(createCltDriverExtraDiscountSchema)
  })

  const { register, handleSubmit, formState: { errors } } = formMethods

  const {
    createCltDriverExtraDiscount: {
      mutateAsync: createCltDriverExtraDiscount
    }
  } = useCltDriverFunctions()

  const { promiseMessage } = useToastify()

  async function handleCreateCltDriverExtraDiscount(
    input: CreateCltDriverExtraDiscountInputs
  ) {
    await promiseMessage(createCltDriverExtraDiscount({
      cltDriverId: input.cltDriverId,
      input: {
        ...input,
        value: transformStringToNumber(input.value),
        attachmentsIds: attachmentsIds
      }
    }, {
      onSuccess: () => {
        redirect('/extras-descontos/motoristas-clt')
      }
    }), 'Extra/Desconto de motorista CLT criado com sucesso!')
  }

  const handleSetAttachmentsIds = (attachmentsIds : string[]) => setAttachmentsIds(attachmentsIds)

  return (
    <StandardBackgroundForm
      title='Criar Extra/Desconto de Motorista CLT'
      onSubmit={handleSubmit(handleCreateCltDriverExtraDiscount)}
    >
      {isFetchingCltDriversData ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <ExtrasDiscountsForm onSetAttachmentsIds={handleSetAttachmentsIds}>
            <Select
              {...register('cltDriverId')}
              name='cltDriverId'
              options={cltDriversSelectOptions}
              label='Motorista CLT'
              placeholder='Selecione uma opção...'
              error={errors.cltDriverId}
              required
            />
          </ExtrasDiscountsForm>
        </FormProvider>
      )}

      <Flex
        mt={4}
        gap={4}
        w='full'
        align='center'
        justifyContent='flex-end'
      >
        <Button
          type='submit'
          colorScheme='blue'
        >
          Salvar
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}
