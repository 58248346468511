import { Box, IconButton, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { getVacation } from "api/vacations/getVacation";
import { format } from "date-fns";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useQuery } from "react-query";

interface VacationDetailProps {
  vacationId: string
}

export function VacationDetail({ vacationId }: VacationDetailProps) {

  const {
    data: vacationResult,
    isLoading: isLoadingVacationResult
  } = useQuery({
    queryKey: ['vacation', vacationId],
    queryFn: () => getVacation({ vacationId })
  })

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Detalhes de férias
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        <Box
          maxH="800px"
          overflowY="scroll"
          p={3}
        >
          <Table size="sm">
            <Tbody>
              {isLoadingVacationResult ? (
                Array.from({ length: 10 }).map((_, index) => {
                  return (
                    <Tr>
                      <Td>
                        <Skeleton w="full" h="16px" />
                      </Td>
                      <Td isNumeric>
                        <Skeleton w="full" h="16px" />
                      </Td>
                    </Tr>
                  )
                })
              ) : (
                <>
                  <Tr>
                    <Td>Nome do colaborador</Td>
                    <Td isNumeric>{vacationResult?.vacation?.collaborator_name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Início aquisitivo</Td>
                    <Td isNumeric>{format(new Date(vacationResult?.vacation?.acquisition_start_date), 'dd/MM/yyyy')}</Td>
                  </Tr>
                  <Tr>
                    <Td>Fim aquisitivo</Td>
                    <Td isNumeric>{format(new Date(vacationResult?.vacation?.acquisition_end_date), 'dd/MM/yyyy')}</Td>
                  </Tr>
                  <Tr>
                    <Td>Data limite para gozo</Td>
                    <Td isNumeric>{format(new Date(vacationResult?.vacation?.deadline), 'dd/MM/yyyy')}</Td>
                  </Tr>
                  <Tr>
                    <Td>Data inicial para planejamento das férias</Td>
                    <Td isNumeric>{format(new Date(vacationResult?.vacation?.planning_start_date), 'dd/MM/yyyy')}</Td>
                  </Tr>
                  <Tr>
                    <Td>Regional</Td>
                    <Td isNumeric>{vacationResult?.vacation?.regional === 'other' ? 'Outra' : vacationResult?.vacation?.regional.toUpperCase()}</Td>
                  </Tr>
                  <Tr>
                    <Td>E-mail do colaborador</Td>
                    <Td isNumeric>{vacationResult?.vacation?.collaborator_email}</Td>
                  </Tr>
                </>
              )}

            </Tbody>
          </Table>

          <Text mt={3} letterSpacing="tighter" as="b">Períodos</Text>

          {vacationResult?.vacation?.periods.length > 0 && (
            vacationResult?.vacation.periods.map((period) => {
              return (
                <Box
                  key={period.id}
                  position="relative"
                  border="1px solid"
                  borderColor="gray.200"
                  mt={3}
                  rounded="xl"
                >
                  <Text
                    position="absolute"
                    top={-2}
                    left={-2}
                    h="20px"
                    w="20px"
                    fontSize="sm"
                    bg="blue.500"
                    rounded="full"
                    textColor="white"
                    textAlign="center"
                  >
                    {period.period}
                  </Text>
                  <Table size="sm">
                    <Tbody>
                      <Tr>
                        <Td>Data inicial</Td>
                        <Td isNumeric>{format(new Date(period.start_date), 'dd/MM/yyyy')}</Td>
                      </Tr>
                      <Tr>
                        <Td>Data final</Td>
                        <Td isNumeric>{format(new Date(period.end_date), 'dd/MM/yyyy')}</Td>
                      </Tr>
                      {period.payment_date && (
                        <Tr>
                          <Td>Data de pagamento</Td>
                          <Td isNumeric>{format(new Date(period.payment_date), 'dd/MM/yyyy')}</Td>
                        </Tr>
                      )}
                      {period.payment_value_in_cents && (
                        <Tr>
                          <Td>Valor de pagamento</Td>
                          <Td isNumeric>{(period.payment_value_in_cents / 100).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                          })}</Td>
                        </Tr>
                      )}
                      {period.cost_center && (
                        <Tr>
                          <Td>Centro de custo</Td>
                          <Td isNumeric>{period.cost_center}</Td>
                        </Tr>
                      )}
                      {period.description && (
                        <Tr>
                          <Td>Descrição</Td>
                          <Td isNumeric>{period.description}</Td>
                        </Tr>
                      )}
                      {period.paymentAttachments && (
                        period.paymentAttachments.map((paymentAttachment) => {
                          return (
                            <Tr>
                              <Td>{paymentAttachment?.attachment?.title}</Td>
                              <Td isNumeric>
                                <IconButton
                                  as={Link}
                                  aria-label="Abrir anexo de pagamento"
                                  size="sm"
                                  href={paymentAttachment?.attachment?.link}
                                  icon={<FaExternalLinkAlt />}
                                  isExternal
                                />
                              </Td>
                            </Tr>
                          )
                        })
                      )}
                      {period.is_signed_receipt !== null && (
                        <Tr>
                          <Td>Recibo assinado?</Td>
                          <Td isNumeric>{period.is_signed_receipt ? 'Sim' : 'Não'}</Td>
                        </Tr>
                      )}
                      {period.receiptAttachment && (
                        <Tr>
                          <Td>Recibo</Td>
                          <Td isNumeric>
                            <IconButton
                              as={Link}
                              aria-label="Abrir anexo de recibo"
                              size="sm"
                              href={period?.receiptAttachment?.link}
                              icon={<FaExternalLinkAlt />}
                              isExternal
                            />
                          </Td>
                        </Tr>
                      )}
                      {period.with_pontomais_allowance && (
                        <Tr>
                          <Td>Abonou ponto mais?</Td>
                          <Td isNumeric>{period.with_pontomais_allowance ? 'Sim' : 'Não'}</Td>
                        </Tr>
                      )}
                      {period.is_fuel_benefit_blocked && (
                        <Tr>
                          <Td>Beneficio de combustível bloqueado?</Td>
                          <Td isNumeric>{period.is_fuel_benefit_blocked ? 'Sim' : 'Não'}</Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </Box>
              )
            })
          )}
        </Box>


      </ModalBody>
    </ModalContent>
  )
}
