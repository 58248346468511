import { Button, Divider, Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner } from "@chakra-ui/react";

import { useParams } from 'react-router'

import { useBudgetLogs } from "hooks/budget/useBudgetLogs";
import { Fragment } from "react";
import { Input } from "components/Inputs/Input";
import { formatDate } from "utils/DateFunctions/formatDate";
import { CitiesProps } from "services/getFunctions/city/getCity";
import { GeneralCheckbox } from "components/Inputs/GeneralCheckbox";
import { Select } from "components/Inputs/SelectInput";
import { deadline_delivery } from "utils/customLists";
import { daysOfWeekOptions } from "utils/CustomLists/daysOfWeekOptions";
import { TextArea } from "components/Inputs/TextInput";
import { EmptyContentTableAlert } from "components/Alerts/EmptyContentTableAlert";
import { useAuth } from "hooks/auth/useAuth";

interface Params {
  id: string
}


interface BudgetLogsModalProps {
  isOpen: boolean
  onClose: () => void
  cities: CitiesProps[]
}

export function BudgetLogsModal({
  isOpen,
  onClose,
  cities,
}: BudgetLogsModalProps) {

  const { userLogged } = useAuth()

  const { id } = useParams<Params>()

  const {
    data: budgetLogsData,
    isFetching: isFetchingBudgetLogsData
  } = useBudgetLogs({
    budgetId: id,
    queryOptions: {
      enabled: !!id && isOpen
    }
  })

  const citiesCheckboxOptions = cities?.map(city => {
    return { key: city.id, value: city.id, showOption: city.name }
  })

  const daysServiceCheckboxOptions = daysOfWeekOptions.map(day => {
    return {
      ...day,
      value: day.value.toString()
    }
  })

  const budgetHasLogs = budgetLogsData?.logs.length > 0

  const isCustomerUserLogged = userLogged?.user_type === 'CLIENTE'

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Logs</ModalHeader>
        <ModalCloseButton />

        <ModalBody maxH='500px' h='full' overflow='auto'>
          {isFetchingBudgetLogsData ? (
            <Spinner />
          ) : (
            budgetHasLogs ? (
              <Flex gap={4} direction='column'>
                {budgetLogsData?.logs.map(log => {
                  return (
                    <Fragment key={log.id}>
                      <Heading size='sm'>Criado em: {formatDate.handle(log.created_at, 'DateWithDateAndHourMinute')}</Heading>
                      {log.user && !isCustomerUserLogged && (
                        <Heading size='sm'>
                          Por: {log.user.firstname} {log.user.lastname}
                        </Heading>
                      )}
                      {log.customer_id && (
                        <Input
                          name='customer_id'
                          value={log.customer.trading_firstname}
                          label='Cliente'
                          isDisabled
                          size='sm'
                        />
                      )}
                      {log.situation && (
                        <Input
                          name='situation'
                          value={log.situation}
                          label='Situação'
                          isDisabled
                          size='sm'
                        />
                      )}
                      {log.source_hub && (
                        <Input
                          name='source_hub'
                          value={log.source_hub.name}
                          label='HUB de origem'
                          isDisabled
                          size='sm'
                        />
                      )}
                      {log.destination_hub && (
                        <Input
                          name='destination_hub'
                          value={log.destination_hub.name}
                          label='HUB de destino'
                          isDisabled
                          size='sm'
                        />
                      )}

                      {log.crossdocking_hub && (
                        <Input
                          name='crossdocking_hub'
                          value={log.crossdocking_hub.name}
                          label='HUB Crossdocking'
                          isDisabled
                          size='sm'
                        />
                      )}
                      {log.source_cities && (
                        <GeneralCheckbox
                          onCheckboxChange={() => { }}
                          name="source_cities"
                          label="Cidade(s) de origem"
                          defaultCheckedOptions={log.source_cities}
                          checkboxOptions={citiesCheckboxOptions}
                          isDisabled

                        />
                      )}
                      {log.destination_cities && (
                        <GeneralCheckbox
                          onCheckboxChange={() => { }}
                          name="destination_cities"
                          label="Cidade(s) de destino"
                          defaultCheckedOptions={log.destination_cities}
                          checkboxOptions={citiesCheckboxOptions}
                          isDisabled

                        />
                      )}

                      {log.source_address_qty && (
                        <Input
                          name='source_address_qty'
                          value={log.source_address_qty}
                          label='Quantidade de endereços de origem'
                          isDisabled
                          size='sm'
                        />
                      )}

                      {log.destination_address_qty && (
                        <Input
                          name='destination_address_qty'
                          value={log.destination_address_qty}
                          label='Quantidade de endereços de destino'
                          isDisabled
                          size='sm'
                        />
                      )}
                      {log.deadline_delivery && (
                        <Input
                          name='deadline_delivery'
                          value={formatDate.handle(log.deadline_delivery, 'DateOnlyWithHourMinute')}
                          label='Horário limite de entrega'
                          isDisabled
                          size='sm'
                        />
                      )}

                      {log.deadline && (
                        <Select
                          name="deadline"
                          label="Prazo de entrega"
                          deadline_delivery={deadline_delivery}
                          placeholder="Selecione uma opção..."
                          isDisabled
                          required
                        />
                      )}
                      {log.service_type && (
                        <Input
                          name='service_type'
                          value={log.service_type}
                          label='Tipo de serviço'
                          isDisabled
                          size='sm'
                        />
                      )}
                      {log.franchising && (
                        <Input
                          name='franchising'
                          value={log.franchising}
                          label='Franquia'
                          isDisabled
                          size='sm'
                        />
                      )}
                      {log.modal && (
                        <Input
                          name='modal'
                          value={log.modal}
                          label='Modal'
                          isDisabled
                          size='sm'
                        />
                      )}
                      {log.vehicle && (
                        <Input
                          name='vehicle'
                          value={log.vehicle}
                          label='Veículo'
                          isDisabled
                          size='sm'
                        />
                      )}
                      {log.caixa_termica && (
                        <Input
                          name='caixa_termica'
                          value={log.caixa_termica}
                          label='Caixa Térmica'
                          isDisabled
                          size='sm'
                        />
                      )}
                      {log.gelo_seco && (
                        <Input
                          name='gelo_seco'
                          value={log.gelo_seco}
                          label='Gelo Seco'
                          isDisabled
                          size='sm'
                        />
                      )}
                      {log.gelox && (
                        <Input
                          name='gelox'
                          value={log.gelox}
                          label='Gelox'
                          isDisabled
                          size='sm'
                        />
                      )}
                      {log.isopor3l && (
                        <Input
                          name='isopor3l'
                          value={log.isopor3l}
                          label='Isopor 3L'
                          isDisabled
                          size='sm'
                        />
                      )}
                      {log.isopor7l && (
                        <Input
                          name='isopor7l'
                          value={log.isopor7l}
                          label='Isopor 7L'
                          isDisabled
                          size='sm'
                        />
                      )}
                      {log.terciaria3l && (
                        <Input
                          name='terciaria3l'
                          value={log.terciaria3l}
                          label='Terciária 3L'
                          isDisabled
                          size='sm'
                        />
                      )}
                      {log.terciaria8l && (
                        <Input
                          name='terciaria8l'
                          value={log.terciaria8l}
                          label='Terciária 8L'
                          isDisabled
                          size='sm'
                        />
                      )}
                      {log.embalagem_secundaria && (
                        <Input
                          name='embalagem_secundaria'
                          value={log.embalagem_secundaria}
                          label='Embalagem Secundária Ziplock'
                          isDisabled
                          size='sm'
                        />
                      )}

                      {log.price && (
                        <Input
                          name='price'
                          value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(log.price)}
                          label='Preço total'
                          isDisabled
                          size='sm'
                        />
                      )}
                      {log.price_unsuccessful_collect && (
                        <Input
                          name='price_unsuccessful_collect'
                          value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(log.price_unsuccessful_collect)}
                          label='Preço da coleta sem sucesso'
                          isDisabled
                          size='sm'
                        />
                      )}
                      {log.price_add_collect && (
                        <Input
                          name='price_add_collect'
                          value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(log.price_add_collect)}
                          label='Preço da coleta adicional'
                          isDisabled
                          size='sm'
                        />
                      )}
                      {log.price_add_delivery && (
                        <Input
                          name='price_add_delivery'
                          value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(log.price_add_delivery)}
                          label='Preço da entrega adicional'
                          isDisabled
                          size='sm'
                        />
                      )}
                      {log.transfer_budget && !isCustomerUserLogged && (
                        <Input
                          name='transfer_budget'
                          value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(log.transfer_budget)}
                          label='Valor do budget de transferência'
                          isDisabled
                          size='sm'
                        />
                      )}
                      {log.days_service && (
                        <GeneralCheckbox
                          onCheckboxChange={() => { }}
                          name='days_service'
                          label="Dias de atendimento"
                          defaultCheckedOptions={log.days_service.map(day => day.toString())}
                          checkboxOptions={daysServiceCheckboxOptions}
                          isDisabled

                        />
                      )}
                      {log.observation && (
                        <TextArea
                          name='observation'
                          value={log.observation}
                          label='Observações'
                          isDisabled
                          size='sm'
                        />
                      )}
                      <Divider />
                    </Fragment>
                  )
                })}
              </Flex>
            ) : (
              <EmptyContentTableAlert title="Ops..." description="Não há logs para este orçamento!" />
            )

          )}

        </ModalBody>

        <ModalFooter>
          <Flex gap={2}>
            <Button
              onClick={onClose}
              colorScheme='gray'
              variant='ghost'
            >
              Fechar
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>

    </Modal >
  )
}
