import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Stack,
  useMediaQuery,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiCheckCircle, FiEdit3, FiEye, FiTrash2 } from 'react-icons/fi'
import { useState, useEffect, useContext } from 'react'
import { Pagination } from '../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../utils/searchBoxFilter'
import { TableFilterButton } from '../../Filters/TableFilterButton'
import { useFilterOptions } from '../../../hooks/filterOptions/useFilterOptions'
import { EmptyContentTableAlert } from '../../Alerts/EmptyContentTableAlert'
import { useSwal } from '../../../hooks/swal/useSwal'
import { useAuth } from '../../../hooks/auth/useAuth'
import { RequestedUserProps } from '../../../utils/RequestFunctions/RequestedUser/requestRequestedUserFunctions'
import { useRequestedUserFunctions } from '../../../hooks/requestedUser/useRequestedUserFunctions'
import { UserContext, UserInput } from '../../../contexts/UserContext'

interface IProfileTableProps {
  requestedUsers: RequestedUserProps[]
}

const profileOptions = [
  { key: 'name', value: 'Nome', checked: false },
  { key: 'userType', value: 'Tipo de Usuário', checked: false },
  { key: 'owner', value: 'Solicitado Por', checked: false },
  { key: 'loglifeEmployee', value: 'Func. Loglife', checked: false },
  { key: 'customer', value: 'Pertence ao Cliente', checked: false },
  { key: 'collector', value: 'Pertence ao Coletador', checked: false },
]

const profileOrderOptions = [{ key: 'name', value: 'Nome' }]

export function RequestedUserTable({ requestedUsers }: IProfileTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const [searchedRequestedUsers, setSearchedRequestedUsers] = useState<
    RequestedUserProps[]
  >([])
  const [slicedRequestedUsers, setSlicedRequestedUsers] = useState<
    RequestedUserProps[]
  >([])
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  // SEARCHBOX FILTERS
  const [userTypeFiltered, setUserTypeFiltered] = useState('')
  const [ownerFiltered, setOwnerFiltered] = useState('')
  const [nameFiltered, setNameFiltered] = useState('')
  const [loglifeEmployeeFiltered, setLoglifeEmployeeFiltered] = useState('')
  const [customerFiltered, setCustomerFiltered] = useState('')
  const [collectorFiltered, setCollectorFiltered] = useState('')
  // ---------------------------------------------------------------------------- //

  // HOOKS
  const { createUser } = useContext(UserContext)
  const {
    deleteRequestedUser: { mutateAsync: deleteRequestedUser },
    validateRequestedUser: { mutateAsync: validateRequestedUser },
  } = useRequestedUserFunctions()
  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { confirmMessage, standardMessage } = useSwal()
  const { userLogged } = useAuth()
  // ---------------------------------------------------------------------------- //

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedRequestedUsers?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(profileOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(profileOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    function run() {
      if (userLogged?.user_type === 'COLETADOR') {
        const requestedUsersByCollectorId = requestedUsers.filter(
          (requestedUser) =>
            requestedUser.collector_id === userLogged?.collector_id,
        )
        setSlicedRequestedUsers(requestedUsersByCollectorId)
        return
      }

      if (userLogged?.user_type === 'CLIENTE') {
        const requestedUsersByCustomerId = requestedUsers?.filter(
          (requestedUser) => {
            if (requestedUser.customersNames) {
              return requestedUser?.customers.includes(userLogged?.customer_id)
            }

            return false
          }
        )
        setSlicedRequestedUsers(requestedUsersByCustomerId)
        return
      }

      setSlicedRequestedUsers(requestedUsers)
    }
    run()
  }, [requestedUsers, userLogged, currentPage])

  useEffect(() => {
    function run() {
      const newSlicedRequestedUsers = slicedRequestedUsers.filter(
        (requestedUser) => {
          const nameFilter = searchBoxFilter(
            `${requestedUser.firstname} ${requestedUser.lastname}`,
            nameFiltered,
          )
          const userTypeFilter = searchBoxFilter(
            requestedUser.user_type,
            userTypeFiltered,
          )
          const ownerFilter = searchBoxFilter(
            requestedUser.ownerIDRequestedUser.firstname,
            ownerFiltered,
          )
          const loglifeEmployeeFilter = searchBoxFilter(
            requestedUser.loglife_employee === true ? 'SIM' : 'NÃO',
            loglifeEmployeeFiltered,
          )
          const customerFilter = searchBoxFilter(
            requestedUser.customersNames
              ? requestedUser.customersNames.join(', ')
              : '-',
            customerFiltered,
          )
          const collectorFilter = searchBoxFilter(
            requestedUser.collector_id
              ? requestedUser.collectorIDRequestedUser.company_name
              : '-',
            collectorFiltered,
          )

          if (
            userTypeFiltered === '' &&
            ownerFiltered === '' &&
            nameFiltered === '' &&
            loglifeEmployeeFiltered === '' &&
            customerFiltered === '' &&
            collectorFiltered === ''
          ) {
            return requestedUser
          }
          return (
            userTypeFilter &&
            nameFilter &&
            ownerFilter &&
            loglifeEmployeeFilter &&
            customerFilter &&
            collectorFilter
          )
        },
      )

      if (orderOptionSelected.length > 0) {
        newSlicedRequestedUsers.sort(
          (a, b) =>
            (orderOptionSelected.includes('name') &&
              `${a.firstname} ${a.lastname}`.localeCompare(
                `${b.firstname} ${b.lastname}`,
              )) ||
            0,
        )
        setSearchedRequestedUsers(newSlicedRequestedUsers)
      } else {
        setSearchedRequestedUsers(newSlicedRequestedUsers)
      }
    }
    run()
  }, [
    userTypeFiltered,
    ownerFiltered,
    nameFiltered,
    loglifeEmployeeFiltered,
    customerFiltered,
    collectorFiltered,
    slicedRequestedUsers,
    orderOptionSelected,
  ])

  const handlePageChange = (page: number) => {
    return setCurrentPage(page)
  }

  const handleChangeSetter = (key: string, value: string) => {
    switch (key) {
      case 'name':
        return setNameFiltered(value)
      case 'userType':
        return setUserTypeFiltered(value)
      case 'owner':
        return setOwnerFiltered(value)
      case 'loglifeEmployee':
        return setLoglifeEmployeeFiltered(value)
      case 'customer':
        return setCustomerFiltered(value)
      case 'collector':
        return setCollectorFiltered(value)
    }
  }

  const handleDeleteRequestedUser = async (id: string) => {
    const hasConfirmed = await confirmMessage({
      title: 'Deseja excluir um usuário solicitado?',
    })

    if (hasConfirmed) {
      await deleteRequestedUser(id)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  async function handleValidateRequestedUserToCreate(
    id: string,
    requestedUser: UserInput,
  ) {
    const hasValidate = await confirmMessage({
      title: 'Deseja validar e criar um usuário solicitado?',
    })

    if (hasValidate) {
      const hasCreateUser = await createUser(requestedUser)
      if (hasCreateUser) {
        await validateRequestedUser(id)
      }
    } else {
      await standardMessage('Ação cancelada com êxito!')
    }
  }


  if (slicedRequestedUsers.length <= 0) {
    return (
      <EmptyContentTableAlert
        title="Oops!"
        description="Não há dados para mostrar aqui!"
      />
    )
  }

  return (
    <>
      <Flex
        mb="2"
        wrap="wrap"
        justify={isWideVersion ? 'space-between' : 'flex-start'}
      >
        <Box>
          <TableFilterButton />
        </Box>
        <Box w={isWideVersion ? '' : 'full'}>
          <Stack mt={isWideVersion ? '0' : '2'} direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Flex key={option.key}>
                  <SearchBox
                    placeholder={`Buscar ${option.value}...`}
                    handleSearch={(e) =>
                      handleChangeSetter(option.key, e.target.value)
                    }
                  />
                </Flex>
              ))}
          </Stack>
        </Box>
      </Flex>
      <Box overflowX="auto" w="full">
        <Table w="full" colorScheme="gray" variant="striped" size="sm">
          <Thead>
            <Tr>
              {profileOptions.map((option) => (
                <Th key={option.key} fontSize="12" color="blue.900">
                  {option.value.toUpperCase()}
                </Th>
              ))}
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {searchedRequestedUsers
              .slice(offset, offset + Number(itemLimit))
              .map((requestedUser, index) => (
                <Tr key={requestedUser.id}>
                  <Td fontSize="12">
                    {`${requestedUser.firstname} ${requestedUser.lastname}`.toUpperCase()}
                  </Td>
                  <Td fontSize="12">{requestedUser.user_type.toUpperCase()}</Td>
                  <Td fontSize="12">
                    {requestedUser.ownerIDRequestedUser.firstname.toUpperCase()}
                  </Td>
                  <Td fontSize="12">
                    {requestedUser.loglife_employee === true ? 'SIM' : 'NÃO'}
                  </Td>
                  <Td fontSize="12">
                    {requestedUser.customersNames
                      ? requestedUser.customersNames.map((customer) => customer).join(', ')
                      : '-'}
                  </Td>
                  <Td fontSize="12">
                    {requestedUser.collector_id
                      ? requestedUser.collectorIDRequestedUser.company_name
                      : '-'}
                  </Td>
                  <Td isNumeric>
                    {userLogged !== null && (
                      <>
                        {userLogged?.permissions.includes(
                          'view-requested-user',
                        ) && (
                            <Link
                              to={`/usuario-solicitado/visualizar/${requestedUser.id}`}
                            >
                              <Icon
                                cursor="pointer"
                                as={FiEye}
                                fontSize="20"
                                ml="4"
                                mt={['2', '2', '0']}
                              />
                            </Link>
                          )}
                        {userLogged?.permissions.includes(
                          'edit-requested-user',
                        ) && (
                            <Link
                              to={`/usuario-solicitado/editar/${requestedUser.id}`}
                            >
                              <Icon
                                cursor="pointer"
                                as={FiEdit3}
                                fontSize="20"
                                ml="4"
                                mt={['2', '2', '0']}
                              />
                            </Link>
                          )}
                        {userLogged?.permissions.includes(
                          'remove-requested-user',
                        ) && (
                            <Icon
                              cursor="pointer"
                              as={FiTrash2}
                              fontSize="20"
                              ml="4"
                              mt={['2', '2', '0']}
                              onClick={async () =>
                                handleDeleteRequestedUser(requestedUser.id)
                              }
                            />
                          )}
                        {userLogged?.permissions.includes('add-user') && (
                          <Icon
                            cursor="pointer"
                            as={FiCheckCircle}
                            fontSize="20"
                            ml="4"
                            mt={['2', '2', '0']}
                            onClick={async () =>
                              handleValidateRequestedUserToCreate(
                                requestedUser.id,
                                requestedUser,
                              )
                            }
                          />
                        )}
                      </>
                    )}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>
      <Pagination
        handlePageChange={handlePageChange}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />
    </>
  )
}
