import { Button, Flex, FormControl, FormLabel, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { approveCltIntercurrence } from "api/cltIntercurrences/approveCltIntercurrence"
import { GetCltIntercurrencesResponse } from "api/cltIntercurrences/getCltIntercurrences"
import { useToastify } from "hooks/toastify/useToastify"
import { Controller, useForm, useWatch } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import * as yup from "yup"

interface ApproveVacationCltIntercurrenceSchema {
  hasGenerateExtraDiscount: string
  withMotorcycleRental: string
  withTelephony: string
  withFuel: string
  withVaVr: string
  withVt: string
  withToll: string
  withExtras: string
  withPayroll: string
}

const approveVacationCltIntercurrenceSchema = yup.object({
  hasGenerateExtraDiscount: yup.string().required(),
  withMotorcycleRental: yup.mixed().when('hasGenerateExtraDiscount', {
    is: (value: string) => value !== 'n/a',
    then: yup.string().required()
  }),
  withTelephony: yup.mixed().when('hasGenerateExtraDiscount', {
    is: (value: string) => value !== 'n/a',
    then: yup.string().required()
  }),
  withFuel: yup.mixed().when('hasGenerateExtraDiscount', {
    is: (value: string) => value !== 'n/a',
    then: yup.string().required()
  }),
  withVaVr: yup.mixed().when('hasGenerateExtraDiscount', {
    is: (value: string) => value !== 'n/a',
    then: yup.string().required()
  }),
  withVt: yup.mixed().when('hasGenerateExtraDiscount', {
    is: (value: string) => value !== 'n/a',
    then: yup.string().required()
  }),
  withToll: yup.mixed().when('hasGenerateExtraDiscount', {
    is: (value: string) => value !== 'n/a',
    then: yup.string().required()
  }),
  withExtras: yup.mixed().when('hasGenerateExtraDiscount', {
    is: (value: string) => value !== 'n/a',
    then: yup.string().required()
  }),
  withPayroll: yup.mixed().when('hasGenerateExtraDiscount', {
    is: (value: string) => value !== 'n/a',
    then: yup.string().required()
  }),
})

interface ApproveVacationCltIntercurrenceProps {
  cltIntercurrenceId: string
}

export function ApproveVacationCltIntercurrence({
  cltIntercurrenceId
}: ApproveVacationCltIntercurrenceProps) {
  const {
    control,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    }
  } = useForm<ApproveVacationCltIntercurrenceSchema>({
    resolver: yupResolver(approveVacationCltIntercurrenceSchema)
  })

  const hasGenerateExtraDiscount = useWatch({
    control,
    name: 'hasGenerateExtraDiscount'
  })

  const isShowAllOtherFields = hasGenerateExtraDiscount !== 'n/a'

  const queryClient = useQueryClient()

  const { mutateAsync: approveCltIntercurrenceFn } = useMutation({
    mutationFn: approveCltIntercurrence,
    onSuccess: (_data, { routeParams }) => {
      const cached = queryClient.getQueriesData<GetCltIntercurrencesResponse>({
        queryKey: ['clt-intercurrences']
      })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          cltIntercurrences: cachedValue.cltIntercurrences.map((cltIntercurrence) => {
            if (cltIntercurrence.id === routeParams.cltIntercurrenceId) {
              return { ...cltIntercurrence, status: 'approved' }
            }

            return cltIntercurrence
          })
        })
      })
    }
  })

  const { promiseMessage } = useToastify()

  async function handleApproveCltIntercurrence(values: ApproveVacationCltIntercurrenceSchema) {
    const body = {}

    body['hasGenerateExtraDiscount'] = values.hasGenerateExtraDiscount

    if (values.hasGenerateExtraDiscount !== 'n/a') {

      body['withMotorcycleRental'] = values.withMotorcycleRental === 'yes'
      body['withTelephony'] = values.withTelephony === 'yes'
      body['withFuel'] = values.withFuel === 'yes'
      body['withVaVr'] = values.withVaVr === 'yes'
      body['withVt'] = values.withVt === 'yes'
      body['withToll'] = values.withToll === 'yes'
      body['withExtras'] = values.withExtras === 'yes'
      body['withPayroll'] = values.withPayroll === 'yes'
    }

    await promiseMessage(approveCltIntercurrenceFn({
      body,
      routeParams: {
        cltIntercurrenceId
      }
    }), 'Intercorrência aprovada com sucesso!')
  }

  return (
    <Flex
      direction="column"
      gap="3"
      as="form"
      onSubmit={handleSubmit(handleApproveCltIntercurrence)}
    >

      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing="3"
      >
        <FormControl isInvalid={!!errors.hasGenerateExtraDiscount}>
          <FormLabel>
            Vai gerar extra ou desconto?
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Controller
            name="hasGenerateExtraDiscount"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Stack direction="column">
                  <Radio value="yes">Sim</Radio>
                  <Radio value="no">Não</Radio>
                  <Radio value="n/a">N.A</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>

        {isShowAllOtherFields && (
          <FormControl isInvalid={!!errors.withMotorcycleRental}>
            <FormLabel>
              Aluguel de moto
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="withMotorcycleRental"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="column">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        )}

      </Stack>

      {isShowAllOtherFields && (
        <>
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing="3"
          >
            <FormControl isInvalid={!!errors.withTelephony}>
              <FormLabel>
                Telefonia
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Controller
                name="withTelephony"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="column">
                      <Radio value="yes">Sim</Radio>
                      <Radio value="no">Não</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>

            <FormControl isInvalid={!!errors.withFuel}>
              <FormLabel>
                Combustível
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Controller
                name="withFuel"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="column">
                      <Radio value="yes">Sim</Radio>
                      <Radio value="no">Não</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>

          </Stack>

          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing="3"
          >
            <FormControl isInvalid={!!errors.withVaVr}>
              <FormLabel>
                VA/VR
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Controller
                name="withVaVr"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="column">
                      <Radio value="yes">Sim</Radio>
                      <Radio value="no">Não</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>

            <FormControl isInvalid={!!errors.withVt}>
              <FormLabel>
                VT
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Controller
                name="withVt"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="column">
                      <Radio value="yes">Sim</Radio>
                      <Radio value="no">Não</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>

          </Stack>

          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing="3"
          >
            <FormControl isInvalid={!!errors.withToll}>
              <FormLabel>
                Pedágio
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Controller
                name="withToll"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="column">
                      <Radio value="yes">Sim</Radio>
                      <Radio value="no">Não</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>

            <FormControl isInvalid={!!errors.withExtras}>
              <FormLabel>
                Extras
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Controller
                name="withExtras"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="column">
                      <Radio value="yes">Sim</Radio>
                      <Radio value="no">Não</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Stack>

          <FormControl isInvalid={!!errors.withPayroll}>
            <FormLabel>
              Folha de pagamento
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="withPayroll"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="column">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </>
      )}

      <Button
        type="submit"
        colorScheme="blue"
        w="min"
        alignSelf="end"
        disabled={isSubmitting}
        isLoading={isSubmitting}
      >
        Salvar
      </Button>
    </Flex>
  )
}
