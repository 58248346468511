import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { ExternalSupport, ExternalSupportStatus } from "./_types/ExternalSupport";

export interface GetExternalSupportsParams {
  pageSize: number
  currentPage: number
  status?: ExternalSupportStatus | 'all'
  ownerId?: string
  reason?: string
  resolutionResponsibleId?: string
}

export interface GetExternalSupportsResponse {
  externalSupports: ExternalSupport[]
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
  }
}

export async function getExternalSupports({
  currentPage,
  pageSize,
  status,
  reason,
  ownerId,
  resolutionResponsibleId
}: GetExternalSupportsParams) {
  try {
    const response = await api.get<GetExternalSupportsResponse>('/external-supports', {
      params: {
        pageSize,
        currentPage,
        status,
        reason,
        ownerId,
        resolutionResponsibleId
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
