import { Menu, MenuButton, Button, MenuList, MenuOptionGroup, MenuItemOption } from "@chakra-ui/react";
import { AggregatesExtrasDiscountsStatus, AggregatesExtrasDiscountsStatusEnum } from "contexts/AggregatesExtrasDiscountsStatusContext";
import { useAggregatesExtrasDiscountsStatus } from "hooks/aggregate/useAggregatesExtrasDiscountsStatus";
import { FaChevronDown } from "react-icons/fa";

interface AggregatesExtrasDiscountsStatusOptions {
  key: keyof typeof AggregatesExtrasDiscountsStatusEnum
  value: keyof typeof AggregatesExtrasDiscountsStatusEnum
  showOption: string
}

const aggregatesExtrasDiscountsStatusOptions: AggregatesExtrasDiscountsStatusOptions[] = [
  { key: 'requested', value: 'requested', showOption: AggregatesExtrasDiscountsStatusEnum['requested'] },
  { key: 'approved', value: 'approved', showOption: AggregatesExtrasDiscountsStatusEnum['approved'] },
  { key: 'effectivated', value: 'effectivated', showOption: AggregatesExtrasDiscountsStatusEnum['effectivated'] },
  { key: 'rejected', value: 'rejected', showOption: AggregatesExtrasDiscountsStatusEnum['rejected'] }
]

export function AggregatesExtrasDiscountsTableStatusButton() {
  const { status, handleChangeStatus } = useAggregatesExtrasDiscountsStatus()

  return (
    <Menu>
      <MenuButton fontSize="12px" variant="ghost" size="sm" as={Button} rightIcon={<FaChevronDown />}>
        STATUS
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          defaultValue="requested"
          value={status}
          type="radio"
          onChange={
            (status: AggregatesExtrasDiscountsStatus) => handleChangeStatus(status)
          }
        >
          {aggregatesExtrasDiscountsStatusOptions.map((option) => {
            return (
              <MenuItemOption value={option.value} key={option.key}>{option.showOption}</MenuItemOption>
            )
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
