import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CheckPartnerSupportBody {
  conferenceChecklist: string[]
}

interface CheckPartnerSupportProps {
  body: CheckPartnerSupportBody
  routeParams: {
    partnerSupportId: string
  }
}
export async function checkPartnerSupport({
  body,
  routeParams
}: CheckPartnerSupportProps){
  try {
    const response = await api.put(`/partner-supports/${routeParams.partnerSupportId}/check`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
