import { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import * as yup from "yup"
import { Button, ButtonGroup, Flex, Spinner, Stack } from "@chakra-ui/react";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm"
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { useAuth } from "hooks/auth/useAuth";
import { useCollectors } from "hooks/collector/useCollectors";
import { useCollectorReport } from "hooks/report/useCollectorReport";
import { serviceHandleStep } from "utils/ServiceFunctions/serviceDict";
import { formatDate } from "utils/DateFunctions/formatDate";

// interface CollectorReportSelectData extends Omit<CollectorReportProps, "owner" | "customer" | "invoice_number" | "rnc_attachments"> {
//   customer: string
//   invoice_number: string | number
//   rnc_attachments: string[] | string
//   owner: string
// }


interface CollectorReportInputs {
  collectorId: string
  startDate: string
  endDate: string
  find_by: 'GERAL' | 'COLETADOR'
}

const collectorReportHeaders = [
  { label: 'PROTOCOLO', key: 'protocol' },
  { label: 'CLIENTE', key: 'customer' },
  { label: 'CENTRO DE CUSTO', key: 'costCenter' },
  { label: 'HUB ORIGEM', key: 'sourceHub' },
  { label: 'CIDADES ORIGEM', key: 'sourceCities' },
  { label: 'ENDEREÇOS ORIGEM', key: 'sourceAddresses' },
  { label: 'HUB DESTINO', key: 'destinationHub' },
  { label: 'CIDADES DESTINO', key: 'destinationCities' },
  { label: 'ENDEREÇOS DESTINO', key: 'destinationAddresses' },
  { label: 'DATA DA REALIZAÇÃO DO SERVIÇO', key: 'collect_date' },
  { label: 'QUANTIDADE ENDEREÇOS DE COLETA', key: 'source_address_quantity' },
  {
    label: 'QUANTIDADE ENDEREÇOS DE ENTREGA',
    key: 'destination_address_quantity',
  },
  { label: 'REMETENTE', key: 'recipient' },
  { label: 'DESTINATÁRIO', key: 'receiver' },
  { label: 'VEÍCULO', key: 'vehicle' },
  { label: 'OCORRÊNCIAS', key: 'occurrences' },
  {
    label: 'VALOR TOTAL OCORRÊNCIAS',
    key: 'occurrenceTotal',
  },
  { label: 'CUSTO DO SERVIÇO', key: 'serviceTotalCost' },
  { label: 'TOTAL GERAL', key: 'totalCost' },
  { label: 'MOTORISTAS DE COLETA', key: 'collectDrivers' },
  { label: 'MOTORISTAS DE ENTREGA', key: 'deliveryDrivers' },
  { label: 'ETAPA DO SERVIÇO', key: 'serviceStep' },
  { label: 'VALOR TOTAL MULTAS', key: 'collectorFineValue' },
  { label: 'EXTRAS/DESCONTOS', key: 'extrasDiscounts' },
  { label: 'OBSERVAÇÕES DO SERVIÇO', key: 'service_observations' }
]

const collectorBusinessExtrasDiscountsReportHeaders = [
  { label: 'TIPO', key: 'type' },
  { label: 'VALOR', key: 'value' },
  { label: 'ROTA', key: 'service_route' },
  { label: 'DESCRIÇÃO', key: 'description' },
  { label: 'SOLICITADO EM', key: 'created_at' },
]

const collectorBusinessBudgetsReportHeaders = [
  { label: 'CLIENTE', key: 'customer' },
  { label: 'APELIDO DA ROTA', key: 'route_nickname' },
  { label: 'COLETADOR', key: 'collector' },
  { label: 'MOTORISTA', key: 'driver' },
  { label: 'VEÍCULO', key: 'vehicle' },
  { label: 'BUDGET', key: 'budget' },
]

const findBySelectOptions = [
  { key: '0', value: 'GERAL', showOption: 'GERAL' },
  { key: '1', value: 'COLETADOR', showOption: 'COLETADOR' },
]

const schema = yup.object().shape({
  find_by: yup.string().required('Campo Obrigatório'),
  collectorId:yup.string().when('find_by', {
    is: 'COLETADOR',
    then: yup.string().required('Campo Obrigatório'),
  }),
  startDate: yup.string().required('Campo obrigatório!'),
  endDate: yup.string().required('Campo obrigatório!')
})


export function CollectorReport() {

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('view-collector-report')) {
      redirect('/')
    }
  }, [userLogged, redirect])

  const [
    enableCollectorReportRequest,
    setEnableCollectorReportRequest
  ] = useState(false)



  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<CollectorReportInputs>({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  })
  useEffect(() => {
    if (userLogged?.user_type === 'COLETADOR') {
      setValue('collectorId', userLogged?.collector_id)
    }
  }, [setValue, userLogged])

  const [
    startFilter,
    endFilter,
    selectedCollector,
    findBySelected
  ] = useWatch({
    control,
    name: ['startDate', 'endDate', 'collectorId', 'find_by']
  })

  const isFindByCollector = findBySelected === 'COLETADOR'

  const {
    data: collectors,
    isFetching: isFetchingCollectors
  } = useCollectors()

  const collectorsSelectOptions = collectors?.map((collector) => {
    return {
      key: collector.id,
      value: collector.id,
      showOption: collector.trading_name
    }
  })

  const {
    data: collectorReportData,
    isFetching: isCollectorReportRequestFetching
  } = useCollectorReport({
    queryOptions: {
      enabled: enableCollectorReportRequest,
      select: (data) => {
        return {
          businessExtrasDiscounts: data.businessExtrasDiscounts.map((extraDiscount) => {
            return {
              ...extraDiscount,
              value: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(extraDiscount.value / 100),
              created_at: formatDate.handle(extraDiscount.created_at, "DateWithoutHourToShow")
            }
          }),
          reportData: data.reportData.map((collectorReportData) => {
            return {
              ...collectorReportData,
              serviceStep: serviceHandleStep(collectorReportData.serviceStep)
            }
          }),
          businessBudgets: data.businessBudgets.map((businessBudget)=>{
            return {
              ...businessBudget
            }
          })
        }
      }
    },
    queryParams: {
      startDate: startFilter,
      endDate: endFilter,
      collectorId: selectedCollector ? selectedCollector : null
    }
  })

  async function handleGenerateReport() {
    setEnableCollectorReportRequest(() => true)
    setTimeout(() => {
      setEnableCollectorReportRequest(() => false)
    }, 500)
  }

  const collectorTradingName = collectors?.find((collector) => collector.id === selectedCollector)?.trading_name

  const collectorCsvReportFilename = `relatorio-coletador-${collectorTradingName ? collectorTradingName : 'geral'}-${startFilter}-${endFilter}.csv`

  const collectorCsvReportProps = {
    data: collectorReportData?.reportData ?? [],
    headers: collectorReportHeaders,
    filename: collectorCsvReportFilename,
  }

  const collectorBusinessExtrasDiscountsReportFilename = `relatorio-coletador-extras-descontos-business-${collectorTradingName ? collectorTradingName : 'geral'}-${startFilter}-${endFilter}.csv`

  const collectorBusinessExtrasDiscountsCsvReportProps = {
    data: collectorReportData?.businessExtrasDiscounts ?? [],
    headers: collectorBusinessExtrasDiscountsReportHeaders,
    filename: collectorBusinessExtrasDiscountsReportFilename,
  }

  const collectorBusinessBudgetsCsvReportProps = {
    data: collectorReportData?.businessBudgets ?? [],
    headers: collectorBusinessBudgetsReportHeaders,
    filename: 'relatorio-coletador-orçamentos-business.csv',
  }

  return (
    <StandardBackgroundForm
      title='Relatório Coletador'
      onSubmit={handleSubmit(handleGenerateReport)}
    >
      {isFetchingCollectors ? (
        <Spinner />
      ) : (
        <Flex
          gap={4}
          direction='column'
        >
          <Select
            {...register('find_by')}
            name='find_by'
            options={findBySelectOptions}
            label='Pesquisar por'
            placeholder='Selecione uma opção...'
            error={errors.find_by}
            required
          />
          {isFindByCollector && (
            <Select
              {...register('collectorId')}
              label='Coletador'
              name='collectorId'
              placeholder='Selecione uma opção...'
              options={collectorsSelectOptions}
              isDisabled={userLogged?.user_type === 'COLETADOR'}
              error={errors.collectorId}
              required
            />
          )}
          <Stack
            w='full'
            spacing={4}
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register('startDate')}
              name='startDate'
              label='Data inicial do filtro'
              type='date'
              error={errors.startDate}
              required />

            <Input
              {...register('endDate')}
              name='endDate'
              label='Data final do filtro'
              type='date'
              error={errors.endDate}
              required />
          </Stack>

          <Flex gap={2} direction={["column", "column", "row"]} justify={['center', 'center', 'flex-end']}>
            <Button
              isLoading={isCollectorReportRequestFetching}
              type='submit'
              w={['full', 'full', 'min']}
              colorScheme='blue'
            >
              Gerar relatório
            </Button>
            {collectorReportData && (
              <ButtonGroup>
                <Stack w="full">
                  <GenerateExcelReportButton
                    mt={-4}
                    csvReportProps={collectorCsvReportProps}
                  />
                </Stack>

                <Stack w="full">
                  <GenerateExcelReportButton
                    flex={1}
                    mt={-4}
                    buttonTitle='Extras/Descontos'
                    csvReportProps={collectorBusinessExtrasDiscountsCsvReportProps}
                  />
                </Stack>

                <Stack w="full">
                  <GenerateExcelReportButton
                    flex={1}
                    mt={-4}
                    buttonTitle='Orçamentos Business'
                    csvReportProps={collectorBusinessBudgetsCsvReportProps}
                  />
                </Stack>
              </ButtonGroup>
            )}
          </Flex>
        </Flex>
      )}

    </StandardBackgroundForm >
  )
}
