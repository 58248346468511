import { Button, Flex, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { cancelAudit } from "api/audits/cancelAudit";
import { Audit } from "api/audits/_types/Audit";
import { Customer } from "api/customers/_types/Customer";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { FaArrowRight, FaSearch, FaTimes } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { captalize } from "utils/captalize";
import { AuditDetail } from "./AuditDetail";
import { AuditStatus } from "./AuditStatus";
import { RealizeAudit } from "./RealizeAudit";

interface AuditsTableRowProps {
  audit: Audit & {
    customer: Customer
  }
}

export function AuditsTableRow({
  audit
}: AuditsTableRowProps) {

  const { userLogged } = useAuth()
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const userCanCompleteAudit = userLogged?.permissions?.includes('complete-audit')
  const userCanCancelAudit = userLogged?.permissions?.includes('cancel-audit')

  const {
    isOpen: isAuditDetailModalOpen,
    onToggle: onToggleAuditDetailModal,
  } = useDisclosure()

  const {
    isOpen: isRealizeAuditModalOpen,
    onOpen: onOpenRealizeAuditModal,
    onClose: onCloseRealizeAuditModal
  } = useDisclosure()

  const { mutateAsync: cancelAuditFn } = useMutation({
    mutationFn: cancelAudit,
    onSuccess: () => {
      queryClient.invalidateQueries(['audits'])

    }
  })

  async function handleCancelAudit() {
    await promiseMessage(
      cancelAuditFn({ auditId: audit.id }),
      'Auditoria cancelada com sucesso!'
    )
  }

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label=""
          icon={<FaSearch />}
          variant="ghost"
          size="sm"
          onClick={onToggleAuditDetailModal}
        />
        <Modal
          isOpen={isAuditDetailModalOpen}
          onClose={onToggleAuditDetailModal}
        >
          <ModalOverlay />
          <AuditDetail auditId={audit.id} />

        </Modal>
      </Td>
      <Td>{format(new Date(audit?.scheduled_date), 'dd/MM/yyyy')}</Td>
      <Td>{captalize(audit.type)}</Td>
      <Td>{captalize(audit?.customer.trading_firstname)}</Td>
      <Td>{audit.collaborator ? captalize(audit.collaborator) : 'N/A'}</Td>
      <Td>
        <AuditStatus status={audit.status} />
      </Td>
      <Td>
        <Flex align="center" gap="2">
          {userCanCompleteAudit && audit.status === 'pending' && (
            <>
              <Modal
                isOpen={isRealizeAuditModalOpen}
                onClose={onCloseRealizeAuditModal}
                size="4xl"
              >
                <ModalOverlay />

                <RealizeAudit
                  audit={audit}
                  onClose={onCloseRealizeAuditModal}
                />
              </Modal>

              <Button
                size="xs"
                lineHeight="1"
                leftIcon={<FaArrowRight />}
                onClick={onOpenRealizeAuditModal}
              >
                Auditar
              </Button>
            </>
          )}
          {userCanCancelAudit && audit?.status === 'pending' && (
            <Button
              size="xs"
              lineHeight="1"
              leftIcon={<FaTimes />}
              variant="ghost"
              onClick={handleCancelAudit}
            >
              Cancelar
            </Button>
          )}

        </Flex>
      </Td>
    </Tr>
  )
}
