import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { StandardBackgroundForm } from "../../../components/Form/StandardBackgroundForm";
import { RequestServiceForm, RequestServiceFormInputs } from "./components/forms/RequestServiceForm";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { differenceInDays, differenceInHours, getYear, set } from "date-fns";
import { useSwal } from "../../../hooks/swal/useSwal";
import { useRequestServiceFunctions } from "../../../hooks/services/request/useRequestServiceFunctions";
import { useAuth } from "../../../hooks/auth/useAuth";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { api } from "../../../services/api";
import { ServiceProps } from "../../../contexts/ServiceContext";
import { ServicesToAddAddressModal } from "../../../components/Modal/Service/AddCollectAddress/ServicesToAddAddressModal";
import { useHoliday } from "../../../hooks/holiday/useHoliday";
import { HolidayProps } from "../../../services/getFunctions/holiday/getHoliday";
import { useAddresses } from "../../../hooks/address/useAddresses";
import { Address } from "hooks/address/dtos/Address";
import { formatDate } from "utils/DateFunctions/formatDate";
import { useBudget } from "hooks/budget/useBudget";
import { useLogFunctions } from "hooks/log/useLogFunctions";
import { validateFileSize } from "utils/fileValidation";

function compareCollectDateWithDateNow(compareType: "lessThan" | "biggerThan", value?: string) {
  if (value) {
    const [year, month, date] = value.split("-").map(value => Number(value))
    const collectDate = set(new Date(), {
      date,
      month: month - 1,
      year
    })

    const difference = differenceInDays(
      collectDate,
      new Date()
    )

    if (compareType === "lessThan") {
      if (difference < 0) return false


      return true
    }

    if (compareType === "biggerThan") {
      if (difference > 15) return false


      return true
    }
  }

  return false
}

function transformHours(value: string) {
  return Number(value.replace(":", "."))
}

function transformInputDateAndInputHourToDate(inputDate: string, inputHour: string) {
  const [year, month, date] = inputDate.split("-").map(value => Number(value))
  const [hours, minutes] = inputHour.split(":").map(value => Number(value))

  return set(new Date(), {
    year,
    month: month - 1,
    date,
    hours,
    minutes,
    seconds: 0
  })
}

function filterHolidayByDate(
  date: Date,
  action: 'entrega' | 'coleta',
  requestedAddresses: string[],
  holidays: HolidayProps[] | undefined,
  addresses: Address[] | undefined,
) {


  const existsHolidayOnDate = holidays?.find(holiday => {
    const holidayWithDefaultHours = set(new Date(holiday.date), {
      year: getYear(new Date()),
      hours: 12,
      minutes: 0,
      seconds: 0
    })

    const difference = Math.ceil(differenceInHours(
      holidayWithDefaultHours,
      date
    ) / 24)

    return difference === 0
  })


  let holidayConfirmMessage = ''

  if (existsHolidayOnDate) {
    const isNationalHoliday = existsHolidayOnDate.national

    console.log(existsHolidayOnDate, action)

    if (isNationalHoliday) {
      holidayConfirmMessage = String(
        `A data da ${action} é feriado nacional de ${existsHolidayOnDate.name}. Deseja continuar?`,
      )
    } else {
      const requestedAddressesCities = addresses?.filter(
        address => requestedAddresses.includes(address.id))
        .map(address => address.city_id)

      const existsHolidayOnSomeCity =
        requestedAddressesCities?.includes(existsHolidayOnDate.city_id)

      if (existsHolidayOnSomeCity) {
        holidayConfirmMessage = String(
          `A data selecionada para ${action} é feriado de ${existsHolidayOnDate.name} na cidade ${existsHolidayOnDate.cityIDHoliday.name}, deseja continuar?`,
        )
      }
    }
  }

  return holidayConfirmMessage
}

const requestServiceSchema = yup.object().shape({
  customer_id: yup.string().required("Campo obrigatório"),
  budget_id: yup.string().required("Campo obrigatório"),
  is_unique_collect_address_service: yup.boolean(),
  source_address_id: yup
    .array()
    .min(1, "Campo obrigatório")
    .required("Campo obrigatório")
    .when("is_unique_collect_address_service", {
      is: true,
      then: yup.array()
        .min(1, "Campo obrigatório")
        .max(1, "O orçamento não possui cobrança para endereços adicionais, selecione apenas 1 endereço")
        .required("Campo obrigatório")
    }),
  destination_address_id: yup.array().min(1, "Campo obrigatório").required("Campo obrigatório"),
  deadline: yup.number(),
  service_type: yup.string(),
  modal: yup.string(),
  vehicle: yup.string(),
  franchising: yup.number(),
  caixa_termica: yup.number().typeError("Campo obrigatório").min(0, "A quantidade dever ser maior ou igual a zero"),
  gelo_seco: yup.number().typeError("Campo obrigatório").min(0, "A quantidade dever ser maior ou igual a zero"),
  gelox: yup.number().typeError("Campo obrigatório").min(0, "A quantidade dever ser maior ou igual a zero"),
  isopor3l: yup.number().typeError("Campo obrigatório").min(0, "A quantidade dever ser maior ou igual a zero"),
  isopor7l: yup.number().typeError("Campo obrigatório").min(0, "A quantidade dever ser maior ou igual a zero"),
  terciaria3l: yup.number().typeError("Campo obrigatório").min(0, "A quantidade dever ser maior ou igual a zero"),
  terciaria8l: yup.number().typeError("Campo obrigatório").min(0, "A quantidade dever ser maior ou igual a zero"),
  embalagem_secundaria: yup.number().typeError("Campo obrigatório").min(0, "A quantidade dever ser maior ou igual a zero"),
  collect_date: yup.string().required("Campo obrigatório")
    .test({
      message: "A data da coleta não pode ser inferior à data atual",
      test: value => compareCollectDateWithDateNow("lessThan", value) === true
    })
    .test({
      message: "A data da coleta não pode ser superior à 15 dias da data atual",
      test: value => compareCollectDateWithDateNow("biggerThan", value) === true
    }),
  collect_hour_start: yup.string().required("Campo obrigatório"),
  collect_hour_end: yup.string().required("Campo obrigatório").test('validate_collect_start_and_end',
    "O horário final da coleta precisa ter diferença de ao menos 1 hora do inicial",
    function (value, context) {
      const differenceBettweenCollectStartAndEnd = value
        ? transformHours(value as string) - transformHours(context.parent.collect_hour_start)
        : false
      return differenceBettweenCollectStartAndEnd >= 1
    }
  ),
  material_type: yup.string().required("Campo obrigatório"),
  destination_addresses_input: yup.array().when("material_type", {
    is: (value: string) => ["CORRELATOS", "CARGA GERAL"].includes(value),
    then: yup.array().of(yup.object({
      address_id: yup.string().required("Campo obrigatório"),
      quantity: yup.number().typeError("Campo obrigatório").min(0, "O valor precisa ser maior ou igual a zero")
    }))
  }),
  gelo_seco_addresses: yup.array().of(yup.object({
    address_id: yup.string().required("Campo obrigatório"),
    quantity: yup.number().typeError("Campo obrigatório").min(1, "O valor precisa ser no mínimo igual a 1")
  })),
  attachments: yup.mixed()
    .test("fileSize", "É suportado o upload de arquivo somente até 10Mb", value => validateFileSize(value)),
  requested_collect_addresses_observations: yup.array().of(yup.object().shape({
    address_id: yup.string(),
    observation: yup.string()
  })),
  observation: yup.string(),
});

export function RequestService() {
  const [servicesWithSameBudgetToAddAddresses, setServicesWithSameBudgetToAddAddress] = useState<ServiceProps[]>([])

  const [confirmBillingExtra, setConfirmBillingExtra] = useState(false)

  const { requestService: {
    mutateAsync: requestService,
    isSuccess: isRequestServiceSuccess,
  }, updateRequestedServiceAttachmentPhoto: { mutateAsync: updateRequestedServiceAttachmentPhoto } } = useRequestServiceFunctions()

  const { createLog: { mutateAsync: createLog } } = useLogFunctions()
  const { data: addresses } = useAddresses()
  const {
    holidays: { data: holidays, isLoading: isHolidaysLoading }
  } = useHoliday(null, true)

  const formMethods = useForm<RequestServiceFormInputs>({
    defaultValues: {
      is_unique_collect_address_service: false
    },
    mode: "onBlur",
    resolver: yupResolver(requestServiceSchema)
  })

  const { handleSubmit, watch, formState: { isSubmitting } } = formMethods

  const budget_id = watch("budget_id")
  const stepsToFilterServicesByBudget = [
    'toValidateRequestedService',
    'toCollectService',
    'collectingService',
    'toValidateAdditionalCollectAddresses'
  ]

  const customerSelected = watch("customer_id")
  const isCustomerSelected = !!customerSelected

  const {
    budgets: { data: budgets, isLoading: isBudgetsLoading, refetch: refetchBudgets }
  } = useBudget(null, isCustomerSelected, false, customerSelected)

  useEffect(() => {
    if (!!customerSelected) refetchBudgets()
  }, [customerSelected, refetchBudgets])

  const activeBudgets = budgets?.filter(budget => budget.situation === "ATIVO")

  const budgetFiltered = activeBudgets?.filter(budget => budget.id === budget_id)


  async function fetchServicesByBudgetId() {
    try {
      const { data } = await api.get<ServiceProps[]>("/service", {
        params: {
          budget_id,
          steps: stepsToFilterServicesByBudget.join(",")
        }
      })

      return data
    } catch (error: any) {
      throw new Error(error.response.data.message)
    }
  }

  const {
    data: servicesByBudgetId,
    refetch: refetchServices,
    isFetching: isFetchingServices
  } = useQuery('servicesByBudgetId', fetchServicesByBudgetId, {
    enabled: !!budget_id,
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (!!budget_id) refetchServices()
  }, [budget_id, refetchServices])

  const { confirmMessage, standardMessage, addAddressConfirmMessage } = useSwal()
  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()
  const {
    onOpen: onOpenAddAdrressesModal,
    onClose: onCloseAddAddressesModal,
    isOpen: isAddAddressesModalOpen
  } = useDisclosure()

  const userHasPermissionToRequestService = userLogged?.permissions.includes("add-single-service")
  const userHasCreateAddressPermission = userLogged?.permissions.includes("add-address")

  useEffect(() => {
    if (!userHasPermissionToRequestService) {
      redirectTo("/")
    }
  }, [userHasPermissionToRequestService, redirectTo])

  useEffect(() => {
    if (isRequestServiceSuccess) {
      redirectTo("/servicos/solicitados")
    }
  }, [isRequestServiceSuccess, redirectTo])


  async function handleRequestService({
    customer_id,
    budget_id,
    source_address_id,
    destination_address_id,
    deadline,
    service_type,
    modal,
    vehicle,
    franchising,
    caixa_termica,
    gelo_seco,
    gelox,
    isopor3l,
    isopor7l,
    terciaria3l,
    terciaria8l,
    embalagem_secundaria,
    collect_date,
    collect_hour_start,
    collect_hour_end,
    delivery_date,
    delivery_hour,
    material_type,
    destination_addresses_input,
    gelo_seco_addresses,
    observation,
    attachments,
    requested_collect_addresses_observations
  }: RequestServiceFormInputs) {

    const [collectYear, collectMonth, collectDay] = collect_date.split('-')

    const servicesWithSameBudgetToSameCollectDate = servicesByBudgetId
      .filter(service => {
        const [
          requestedServiceCollectDay,
          requestedServiceCollectMonth,
          requestedServiceCollectYear
        ] = formatDate.handle(service.serviceIDRequested.collect_date, "DateWithoutHourToShow").split('/')

        if (
          requestedServiceCollectDay === collectDay &&
          requestedServiceCollectMonth === collectMonth &&
          requestedServiceCollectYear === collectYear
        ) {
          return true
        }

        return false
      })

    const hasAnotherServicesWithSameBudgetIdAndCollectDate = servicesWithSameBudgetToSameCollectDate.length

    const budgetMaterials = [
      { key: 1, name: 'Caixa Térmica', value: budgetFiltered[0].caixa_termica },
      { key: 2, name: 'Gelo Seco', value: budgetFiltered[0].gelo_seco },
      { key: 3, name: 'Gelox', value: budgetFiltered[0].gelox },
      { key: 4, name: 'Isopor 3l', value: budgetFiltered[0].isopor3l },
      { key: 5, name: 'Isopor 7l', value: budgetFiltered[0].isopor7l },
      { key: 6, name: 'Terciária 3l', value: budgetFiltered[0].terciaria3l },
      { key: 7, name: 'Terciária 8l', value: budgetFiltered[0].terciaria8l },
      { key: 8, name: 'Embalagem Secundária Ziplock', value: budgetFiltered[0].embalagem_secundaria },
    ];


    const materialsForm = [
      { key: 1, name: 'Caixa Térmica', value: caixa_termica },
      { key: 2, name: 'Gelo Seco', value: gelo_seco },
      { key: 3, name: 'Gelox', value: gelox },
      { key: 4, name: 'Isopor 3l', value: isopor3l },
      { key: 5, name: 'Isopor 7l', value: isopor7l },
      { key: 6, name: 'Terciária 3l', value: terciaria3l },
      { key: 7, name: 'Terciária 8l', value: terciaria8l },
      { key: 8, name: 'Embalagem Secundária Ziplock', value: embalagem_secundaria },
    ];

    const materialAdditional = materialsForm.reduce((acc, curr) => {
      const currentBudgetMaterial = budgetMaterials.find(
        budgetMaterial => budgetMaterial.name === curr.name,
      );
      if (curr.value > currentBudgetMaterial.value) {
        acc.push({
          key: curr.key,
          name: curr.name,
          value: curr.value,
          valueBudget: currentBudgetMaterial.value,
        });
      }
      return acc;
    }, []);

    const hasMaterialAdditional = materialAdditional.length !== 0

    if (hasMaterialAdditional) {
      const materials = materialAdditional.map((material) => {
        return `${material.name} - ${material.value}. \n Quantidade solicitada no orçamento: ${material.valueBudget} `
      }).join(',\n')

      const hasConfirmBillingExtra = await confirmMessage({ title: 'Os materiais abaixo serão considerados como extra e gerarão cobrança. Deseja continuar?', text: `${materials}`, buttons: ['Não', 'Sim'] })

      const responseModalConfirmBillingExtra = hasConfirmBillingExtra === null ? false : true;

      setConfirmBillingExtra(responseModalConfirmBillingExtra)

      if (!responseModalConfirmBillingExtra) {
        return;
      }
    }


    if (hasAnotherServicesWithSameBudgetIdAndCollectDate) {
      setServicesWithSameBudgetToAddAddress(servicesWithSameBudgetToSameCollectDate)

      const hasAddAddressesToSomeAnotherService = await addAddressConfirmMessage({
        title:
          'Há um serviço ainda não coletado em andamento com o orçamento selecionado. Deseja adicionar endereços a ele?',
      })

      if (hasAddAddressesToSomeAnotherService) {
        const hasOnlyOneServiceWithSameBudgetIdToAddAddresses = servicesByBudgetId.length === 1

        if (hasOnlyOneServiceWithSameBudgetIdToAddAddresses) {
          return redirectTo(`/servico/detalhes/${servicesByBudgetId[0].id}`)
        }

        return onOpenAddAdrressesModal()
      }
    }

    const collectDateToCompareWithHoliday = transformInputDateAndInputHourToDate(
      collect_date,
      "12:00"
    )

    const holidayCollectDateConfirmMessage = filterHolidayByDate(
      collectDateToCompareWithHoliday,
      'coleta',
      source_address_id,
      holidays,
      addresses
    )

    const deliveryDateToCompareWithHoliday = transformInputDateAndInputHourToDate(
      delivery_date,
      "12:00"
    )

    const holidayDeliveryDateConfirmMessage = filterHolidayByDate(
      deliveryDateToCompareWithHoliday,
      'entrega',
      destination_address_id,
      holidays,
      addresses
    )

    const hasCreateServiceInHolidayByCollectDate =
      !!holidayCollectDateConfirmMessage
        ? await confirmMessage({
          title: holidayCollectDateConfirmMessage,
        })
        : true
    const hasCreateServiceInHolidayByDeliveryDate =
      !!holidayDeliveryDateConfirmMessage
        ? await confirmMessage({
          title: holidayDeliveryDateConfirmMessage,
        })
        : true


    if (
      hasCreateServiceInHolidayByCollectDate ||
      hasCreateServiceInHolidayByDeliveryDate
    ) {
      const hasRequestService = await confirmMessage({ title: "Deseja solicitar um serviço?" })

      if (hasRequestService) {

        const collectDateWithCollectHourStart = transformInputDateAndInputHourToDate(
          collect_date,
          collect_hour_start
        )

        const collectDateWithCollectHourEnd = transformInputDateAndInputHourToDate(
          collect_date,
          collect_hour_end
        )

        const deliveryDateWithDeliveryHour = transformInputDateAndInputHourToDate(
          delivery_date,
          delivery_hour
        )

        const requestServiceInput = {
          customer_id,
          budget_id,
          source_address_id,
          destination_address_id,
          deadline,
          service_type,
          modal,
          vehicle,
          franchising,
          caixa_termica,
          gelo_seco,
          gelox,
          isopor3l,
          isopor7l,
          terciaria3l,
          terciaria8l,
          embalagem_secundaria,
          collect_date: collectDateWithCollectHourStart,
          collect_hour_start: collectDateWithCollectHourStart,
          collect_hour_end: collectDateWithCollectHourEnd,
          delivery_date: deliveryDateWithDeliveryHour,
          delivery_hour: deliveryDateWithDeliveryHour,
          material_type,
          destination_addresses_input,
          gelo_seco_addresses,
          owner: userLogged?.id!,
          requested_collect_addresses_observations: requested_collect_addresses_observations ? requested_collect_addresses_observations : null,
          observation,
        }

        if (confirmBillingExtra) {
          await createLog({
            user_id: userLogged.id,
            action: 'ACEITOU SOLICITAÇÃO DE SERVIÇO COM COBRANÇA DE MATERIAL EXTRA',
          })
        }

        await requestService(requestServiceInput, {
          onSuccess: async (data) => {
            if (attachments.length !== 0) {
              const formData = new FormData()

              Object.entries(attachments).forEach(([key, file]) => {
                formData.append('attachments', file.attachment[0])
              })
              await updateRequestedServiceAttachmentPhoto({ requestedServiceId: data.id, input: formData })
            }
          }
        })

      } else {
        standardMessage("Ação cancelada com êxito!")
      }
    } else {
      standardMessage("Ação cancelada com êxito!")
    }

  }

  return (
    <StandardBackgroundForm
      onSubmit={handleSubmit(handleRequestService)}
      title="Solicitar serviço"
    >
      <ServicesToAddAddressModal
        isModalOpen={isAddAddressesModalOpen}
        onClose={onCloseAddAddressesModal}
        servicesToAddAddress={servicesWithSameBudgetToAddAddresses}
      />

      <FormProvider {...formMethods}>
        <RequestServiceForm budgets={budgets} isBudgetsLoading={isBudgetsLoading} />
      </FormProvider>

      <Flex
        mt={6}
        gap={4}
        direction={["column", "column", "row"]}
        justify={["center", "center", "flex-end"]}
        align="center"
      >
        <Button
          w={["full", "full", "min"]}
          type="submit"
          isLoading={isSubmitting || isFetchingServices || isHolidaysLoading || isBudgetsLoading}
          colorScheme="blue"
        >
          Solicitar
        </Button>
        <Button
          w={["full", "full", "min"]}
          as={Link}
          to="/servicos"
          type="submit"
          colorScheme="gray"
        >
          Serviços
        </Button>
        {userHasCreateAddressPermission && (
          <Button
            w={["full", "full", "min"]}
            as={Link}
            to="/endereco/adicionar"
            type="submit"
            colorScheme="gray"
          >
            Adicionar Endereço
          </Button>
        )}
      </Flex>
    </StandardBackgroundForm>
  )
}
