import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { InternClt, InternCltStatus } from "./_types/internClt";

interface GetInternCltsParams {
  pageSize: number
  currentPage: number
  status?: InternCltStatus | 'all'
  name?: string
}

export interface GetInternCltsResponse {
  internClts: InternClt[]
  meta: {
    currentPage: number
    pageSize: number
    totalPages: number
  }
}

export async function getInternClts({
  pageSize,
  currentPage,
  status,
  name,
}: GetInternCltsParams) {
  try {
    const { data } = await api.get<GetInternCltsResponse>('/intern-clts', {
      params: {
        pageSize,
        currentPage,
        status,
        name,
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

