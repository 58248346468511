
import axios from "axios";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { Collector } from "hooks/collector/dtos/Collector";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { PartnerSupport } from "./_types/PartnerSupport";

interface GetPartnerSupportResponse {
  partnerSupport: PartnerSupport & {
    collector: Collector,
    attendanceResponsible: {
      id: string
      firstname: string
      lastname: string
    },
    requestAttachment: Attachment
    additiveAttachment: Attachment
  }
}

interface GetPartnerSupportParams {
  partnerSupportId: string
}

export async function getPartnerSupport({
  partnerSupportId
}: GetPartnerSupportParams) {
  try {
    const response = await api.get<GetPartnerSupportResponse>(`/partner-supports/${partnerSupportId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
