import { Button, Divider, Flex, Icon, IconButton, Spinner, Stack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { ProviderProps } from 'contexts/ProviderContext'
import { useFieldArray, useForm } from 'react-hook-form'
import { FaPlus, FaTrash } from 'react-icons/fa'
import { useInput } from '../../../hooks/input/useInput'
import { CitiesProps } from '../../../services/getFunctions/city/getCity'
import { negative_positive } from '../../../utils/customLists'
import { statesWithUfList } from '../../../utils/CustomLists/statesWithUfList'
import { switchRouteTypes } from '../../../utils/CustomLists/switchRouteTypes'
import { PriceProps } from '../../../utils/RequestFunctions/Price/requestPriceFunctions'
import { switchPriceSchema } from '../../../validations/priceSchema'
import { SubmitButton } from '../../Buttons/SubmitButton'
import { Input } from '../../Inputs/Input'
import { Select } from '../../Inputs/SelectInput'
import { GeneralContentLoading } from '../../Loading/GeneralContentLoading'
import { ConsultPriceTable } from '../../Tables/Price/ConsultPriceTable'
import { StandardBackgroundForm } from '../StandardBackgroundForm'

interface IFormInputProps {
  route_type: string
  taxed_weight: number
  has_materials: string
  materials: {
    input_id: string
    quantity: number
  }[]
  includeGeloSeco: string
  geloSecoQuantity: number
  vehicle: string
  source_cities: {
    source_uf: string
    source_city_id: string
  }[]
  destination_cities: {
    destination_uf: string
    destination_city_id: string
  }[]
}

interface IRequestedMaterialsProps {
  material: string
  quantity: number
  price: number
  totalPrice: string
}

interface IConsultPriceProps {
  submit: (values: IFormInputProps) => Promise<void>
  cities: CitiesProps[]
  providers: ProviderProps[]
  prices?: PriceProps[]
  isConsultPriceLoading: boolean
  isConsultPriceSuccess: boolean
}

export const vehicle_types = [
  { key: "0", value: 'CARRO', showOption: 'CARRO' },
  { key: "1", value: 'MOTO', showOption: 'MOTO' },
  { key: "2", value: 'CAMINHÃO', showOption: 'CAMINHÃO' },
  { key: "3", value: 'TODOS', showOption: 'TODOS' },
]

export function ConsultPriceForm({
  submit,
  cities,
  providers,
  prices,
  isConsultPriceLoading,
  isConsultPriceSuccess,
}: IConsultPriceProps) {
  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(switchPriceSchema.handle('consultar')),
    defaultValues: {
      route_type: 'AMBOS',
      taxed_weight: 0,
      has_materials: '',
      materials: [],
      vehicle: '',
      source_cities: [{ source_uf: '', source_city_id: '' }],
      destination_cities: [{ destination_uf: '', destination_city_id: '' }]
    }
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'materials'
  })

  const {
    fields: sourceCityFields,
    append: appendSourceCityField,
    remove: removeSourceCityField } = useFieldArray({
      control,
      name: 'source_cities'
    })

  const {
    fields: destinationCityFields,
    append: appendDestinationCityField,
    remove: removeDestinationCityField } = useFieldArray({
      control,
      name: 'destination_cities'
    })

  const {
    inputs: { data: inputs, isLoading: isInputsLoading }
  } = useInput(null, true, false)
  const listMaterialRemoveOptionsSelect = ['LACRE', 'ALMOFADA ABSORVENTE', 'ADESIVO VEÍCULO', 'EMBALAGEM SECUNDÁRIA (POTE 250 ML)', 'EMBALAGEM SECUNDÁRIA CANISTER 1L', 'FITA PERSONALIZADA']
  const newListInputs = inputs?.filter(input => !listMaterialRemoveOptionsSelect.includes(input.name))


  const hasMaterials = watch('has_materials')
  const isConsultWithMaterials = hasMaterials === "SIM"

  const materialsSelected = isSubmitted ? getValues('materials') : []
  const isMaterialsSelected = !!materialsSelected.length

  const materialsToConsultTable = isMaterialsSelected && isConsultWithMaterials
    ? inputs?.reduce((acc, curr) => {
      materialsSelected.forEach(material => {
        if (material.input_id === curr.id) {
          if (curr.name === 'EMBALAGEM SECUNDÁRIA ZIPLOCK') {
            const price = inputs.reduce((totalPrice, input) => {
              if (input.name === 'LACRE' || input.name === 'EMBALAGEM SECUNDÁRIA ZIPLOCK' || input.name === 'ALMOFADA ABSORVENTE') {
                totalPrice += input.unit_price
              }

              return totalPrice
            }, 0)

            acc.push({
              material: curr.name,
              quantity: material.quantity,
              price,
              totalPrice: (material.quantity * price).toFixed(2)
            })
          } else {
            acc.push({
              material: curr.name,
              quantity: material.quantity,
              price: curr.unit_price,
              totalPrice: (material.quantity * curr.unit_price).toFixed(2)
            })
          }
        }
      })
      return acc
    }, [] as Array<IRequestedMaterialsProps>)
    : []

  const sourceUfsStateAndCities = watch('source_cities')
  const destinationUfsStateAndCities = watch('destination_cities')

  const hasIncludeGeloSeco = watch('includeGeloSeco')
  const isIncludeGeloSeco = hasIncludeGeloSeco === 'SIM'
  const geloSecoQuantity = watch('geloSecoQuantity')


  if (isInputsLoading) {
    return <GeneralContentLoading />
  }

  return (
    <StandardBackgroundForm
      onSubmit={handleSubmit(submit)}
      title="Consultar Preços"
    >
      <Stack direction="column" spacing="24px" mt="4">
        <Flex
          border="1px solid"
          borderColor="gray.200"
          p="4"
          rounded="lg"
          direction="column"
        >
          {sourceCityFields?.map((field, index) => {
            const selectedCitiesIds = sourceUfsStateAndCities
              .filter((_, idx) => idx !== index)
              .map(city => city.source_city_id)

            const citiesBySourceUf = cities
              .filter(city => city.state === sourceUfsStateAndCities[index].source_uf.toUpperCase())
              .filter(city => !selectedCitiesIds.includes(city.id))

            return (
              <Stack
                key={field.id}
                spacing={6}
                direction={["column", "column", "row"]}
                w="full"
                mt={5}
              >
                <Select
                  {...register(`source_cities.${index}.source_uf`)}
                  name={`source_cities.${index}.source_uf`}
                  label="UF Origem"
                  statesWithUf={statesWithUfList}
                  placeholder="Selecione uma opção..."
                  error={errors.source_cities
                    ? errors?.source_cities[index]?.source_uf
                    : undefined
                  }
                  required
                />
                <Select
                  {...register(`source_cities.${index}.source_city_id`)}
                  name={`source_cities.${index}.source_city_id`}
                  label="Cidade Origem"
                  citiesBack={citiesBySourceUf}
                  placeholder="Selecione uma opção..."
                  error={errors.source_cities
                    ? errors?.source_cities[index]?.source_city_id
                    : undefined
                  }
                  required
                />
                {sourceCityFields.length > 1 && (
                  <Flex
                    alignItems='end'
                  >
                    <IconButton
                      aria-label="Remover cidade e uf de origem"
                      icon={<FaTrash />}
                      variant="ghost"
                      onClick={() => removeSourceCityField(index)}
                      size='lg'
                    />
                  </Flex>
                )}
              </Stack>
            )
          })}
          <Button
            size="sm"
            leftIcon={<FaPlus />}
            colorScheme="blue"
            onClick={() => appendSourceCityField({ source_uf: '', source_city_id: '' })}
            mt="3"
            alignSelf="end"
          >
            Adicionar
          </Button>
        </Flex>
        <Flex
          border="1px solid"
          borderColor="gray.200"
          p="4"
          rounded="lg"
          direction="column"
        >
          {destinationCityFields?.map((field, index) => {
            const selectedCitiesIds = destinationUfsStateAndCities
              .filter((_, idx) => idx !== index)
              .map(city => city.destination_city_id)

            const citiesByDestinationUf = cities
              .filter(city => city.state === destinationUfsStateAndCities[index].destination_uf.toUpperCase())
              .filter(city => !selectedCitiesIds.includes(city.id))

            return (
              <Stack
                key={field.id}
                spacing={6}
                direction={["column", "column", "row"]}
                w="full"
                mt={5}
              >
                <Select
                  {...register(`destination_cities.${index}.destination_uf`)}
                  name={`destination_cities.${index}.destination_uf`}
                  label="UF Destino"
                  statesWithUf={statesWithUfList}
                  placeholder="Selecione uma opção..."
                  error={errors?.destination_cities
                    ? errors?.destination_cities[index]?.destination_uf
                    : undefined
                  }
                  required
                />
                <Select
                  {...register(`destination_cities.${index}.destination_city_id`)}
                  name={`destination_cities.${index}.destination_city_id`}
                  label="Cidade Destino"
                  citiesBack={citiesByDestinationUf}
                  placeholder="Selecione uma opção..."
                  error={errors.destination_cities
                    ? errors?.destination_cities[index]?.destination_city_id
                    : undefined
                  }
                  required
                />
                {destinationCityFields.length > 1 && (
                  <Flex
                    alignItems='end'
                  >
                    <IconButton
                      aria-label="Remover cidade e uf de origem"
                      icon={<FaTrash />}
                      variant="ghost"
                      onClick={() => removeDestinationCityField(index)}
                      size='lg'
                    />
                  </Flex>
                )}
              </Stack>
            )
          })}
          <Button
            size="sm"
            leftIcon={<FaPlus />}
            colorScheme="blue"
            onClick={() => appendDestinationCityField({ destination_uf: '', destination_city_id: '' })}
            mt="3"
            alignSelf="end"
          >
            Adicionar
          </Button>
        </Flex>
        <Select
          {...register('route_type')}
          name="route_type"
          label="Tipo de Rota"
          routeTypes={switchRouteTypes.handle()}
          placeholder="Selecione uma opção..."
          error={errors.route_type}
          required
          isDisabled
        />
        <Select
          {...register("vehicle")}
          name="vehicle"
          label="Veículo"
          placeholder="Selecione uma opção..."
          options={vehicle_types}
          error={errors.vehicle}
          required
        />
        <Input
          {...register('taxed_weight')}
          name="taxed_weight"
          label="Peso Taxado"
          type="number"
          error={errors.taxed_weight}
          required
        />

        <Select
          {...register('has_materials')}
          name="has_materials"
          label="Consulta com materiais?"
          placeholder="Selecione uma opção..."
          negative_positive={negative_positive}
          required
        />

        {isConsultWithMaterials && (
          <>
            {fields.map((field, index) => (
              <>
                <Flex gap={4} flex="1" flexDirection={["column", "column", "row"]} alignItems="center" justifyContent="center">
                  <Stack w="full" spacing={6} direction={['column', 'column', 'row']}>
                    <Select
                      {...register(`materials.${index}.input_id`)}
                      name={`materials.${index}.input_id`}
                      label="Material"
                      inputs={newListInputs}
                      placeholder="Selecione um material..."
                      error={errors.materials ? errors?.materials[index]?.input_id : undefined}
                      required
                    />

                    <Input
                      {...register(`materials.${index}.quantity`)}
                      name={`materials.${index}.quantity`}
                      label="Quantidade"
                      type="number"
                      error={errors.materials ? errors?.materials[index]?.quantity : undefined}
                      required
                    />
                  </Stack>
                  <Button
                    onClick={() => remove(index)}
                    mt={['0', '0', '8']}
                    w={['full', 'full', '200px']}
                    size="lg"
                  >
                    Remover
                  </Button>

                </Flex>
                <Divider />
              </>
            ))}


            <Button
              onClick={() => append({ input_id: '', quantity: 0 })}
            >
              <Icon as={FaPlus} />
            </Button>
          </>
        )}
        <Select
          {...register('includeGeloSeco')}
          name="includeGeloSeco"
          label="Incluir Gelo Seco?"
          error={errors.includeGeloSeco}
          negative_positive={negative_positive}
          placeholder="Selecione uma opção..."
          required
        />
        {isIncludeGeloSeco && (
          <Stack w="full" spacing={6} direction={['column', 'column', 'row']}>
            <Input
              {...register(`geloSecoQuantity`)}
              name={`geloSecoQuantity`}
              label="Quantidade"
              type="number"
              error={errors.geloSecoQuantity}
              required
            />
          </Stack>
        )}
      </Stack>
      <Flex mt="4" justifyContent="flex-end">
        <Stack direction="row">
          <SubmitButton action="Buscar" isSubmitting={isSubmitting} />
        </Stack>
      </Flex>

      {isConsultPriceLoading && (
        <Flex justifyContent="flex-start" alignItems="center">
          <Spinner />
        </Flex>
      )}

      {isConsultPriceSuccess && prices && (
        <Stack mt="4">
          <ConsultPriceTable
            prices={prices}
            providers={providers}
            hasGeloSeco={isIncludeGeloSeco}
            geloSecoQuantity={geloSecoQuantity}
            materials={materialsToConsultTable}
            inputs={inputs}
            taxedWeight={watch('taxed_weight')}
          />
        </Stack>
      )}
    </StandardBackgroundForm>
  )
}
