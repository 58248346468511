import { Button, Flex, Input, Select } from "@chakra-ui/react";
import { InternCltStatus } from "api/internClts/_types/internClt";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface InternCltsTableFiltersSchema {
  name: string
  status: InternCltStatus | 'all'
}

export function InternCltsTableFilters() {
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const name = searchParams.get('hub')
  const status = searchParams.get('status') ?? 'all'

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<InternCltsTableFiltersSchema>({
    defaultValues: {
      name,
      status: status as InternCltStatus | 'all',
    }
  })

  async function handleFilterInternClts(data: InternCltsTableFiltersSchema) {
    if (data.name) {
      searchParams.set('name', data.name)
    } else {
      searchParams.delete('name')
    }

    if (
      data.status) {
      searchParams.set('status', data.status)
    } else {
      searchParams.delete('status')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleClearFilters() {
    searchParams.delete('collaborator')
    searchParams.delete('hub')
    searchParams.delete('status')

    searchParams.set('page', '1')

    reset({
      name: '',
      status: 'all'
    })

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterInternClts)}
    >

      <Input
        {...register("name")}
        name="name"
        placeholder="Nome"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />

      <Select
        {...register("status")}
        name="status"
        defaultValue="all"
        size="sm"
        rounded="md"
        w={["full", "full", "250px"]}
      >
        <option value="all">Todos status</option>
        <option value="active">Ativo</option>
        <option value="inactive">Inativo</option>
      </Select>

      <Button
        size="sm"
        leftIcon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={{ base: "full", lg: 'min' }}
      >
        Filtrar resultados
      </Button>

      <Button
        size="sm"
        leftIcon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        w={{ base: "full", lg: 'min' }}
        onClick={handleClearFilters}
      >
        Remover filtros
      </Button>

    </Flex>
  )
}
