import { Button, Icon, IconButton, Link, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Skeleton, Table, Tbody, Td, Tr } from "@chakra-ui/react"
import { billSporadic } from "api/sporadics/billSporadic";
import { cancelSporadic } from "api/sporadics/cancelSporadic";
import { getSporadic } from "api/sporadics/getSporadic"
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { FaCheckCircle, FaExternalLinkAlt, FaTimesCircle } from "react-icons/fa";
import { useMutation, useQuery, useQueryClient } from "react-query"
import { captalize } from "utils/captalize";
import { SporadicStatus } from "./SporadicStatus";

interface SporadicDetailProps {
  sporadicId: string
  onClose: () => void
}

export function SporadicDetail({
  sporadicId,
  onClose
}: SporadicDetailProps) {

  const { userLogged } = useAuth()
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const userCanBillSporadic = userLogged?.permissions.includes('bill-sporadic')
  const userCanCancelSporadic = userLogged?.permissions.includes('cancel-sporadic')


  const {
    data: sporadicResult,
    isLoading: isLoadingSporadicResult
  } = useQuery({
    queryKey: ['sporadic', sporadicId],
    queryFn: () => getSporadic({ sporadicId })
  })

  const { mutateAsync: billSporadicFn } = useMutation({
    mutationFn: billSporadic,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'in-billing' })
      queryClient.invalidateQueries({ queryKey: 'billed' })
      queryClient.invalidateQueries({ queryKey: 'sporadics' })
      onClose()
    },
  })

  const { mutateAsync: cancelSporadicFn } = useMutation({
    mutationFn: cancelSporadic,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'in-billing' })
      queryClient.invalidateQueries({ queryKey: 'canceled' })
      queryClient.invalidateQueries({ queryKey: 'sporadics' })
      onClose()

    },
  })

  async function handleBillSporadic() {
    await promiseMessage(billSporadicFn({ sporadicId }), 'Esporádico faturado!')
  }

  async function handleCancelSporadic() {
    await promiseMessage(cancelSporadicFn({ sporadicId }), 'Esporádico cancelado!')
  }


  return (
    <ModalContent maxW='750px'>
      <ModalHeader>
        Detalhes do Esporádico
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Table size="sm">
          <Tbody>
            {isLoadingSporadicResult ? (
              Array.from({ length: 10 }).map((_, index) => {
                return (
                  <Tr>
                    <Td>
                      <Skeleton w="full" h="16px" />
                    </Td>
                    <Td isNumeric>
                      <Skeleton w="full" h="16px" />
                    </Td>
                  </Tr>
                )
              })
            ) : (
              <>
                <Tr>
                  <Td>Protocolo</Td>
                  <Td isNumeric>{sporadicResult?.sporadic.protocol}</Td>
                </Tr>

                <Tr>
                  <Td>Solicitante</Td>
                  <Td isNumeric>
                    {sporadicResult?.sporadic?.applicant}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Regional</Td>
                  <Td isNumeric>
                    {sporadicResult?.sporadic?.regional?.name}
                  </Td>
                </Tr>
                <Tr>
                <Td>Status</Td>
                    <Td display="flex" justifyContent="flex-end">
                      <SporadicStatus status={sporadicResult?.sporadic?.status} />
                    </Td>
                </Tr>
                <Tr>
                  <Td>Data inicial para realização</Td>
                  <Td isNumeric>
                    {format(new Date(sporadicResult?.sporadic?.startDate), "dd/MM/yyyy '-' HH:mm")}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Data final para realização</Td>
                  <Td isNumeric>
                    {format(new Date(sporadicResult?.sporadic?.finalDate), "dd/MM/yyyy '-' HH:mm")}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Tipo de veículo</Td>
                  <Td isNumeric>
                    {captalize(sporadicResult?.sporadic?.vehicle)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Tipo de serviço</Td>
                  <Td isNumeric>
                    {captalize(sporadicResult?.sporadic?.serviceType)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Motivo</Td>
                  <Td isNumeric>
                    {captalize(sporadicResult?.sporadic?.reason)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Centro de custo</Td>
                  <Td isNumeric>
                    {captalize(sporadicResult?.sporadic?.costCenter)}
                  </Td>
                </Tr>
                <Tr>
                  <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">E-mail da solicitação</Td>
                  <Td isNumeric>
                    <IconButton
                      aria-label="Abrir anexo"
                      as={Link}
                      href={sporadicResult?.sporadic?.emailRequestAttachment?.link}
                      icon={<FaExternalLinkAlt />}
                      size="sm"
                      isExternal
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>Responsável</Td>
                  <Td isNumeric>
                    {captalize(sporadicResult?.sporadic?.responsible)}
                  </Td>
                </Tr>
              </>
            )}
          </Tbody>
        </Table>
      </ModalBody>
      {sporadicResult?.sporadic.status === 'in-billing' && (
        <ModalFooter gap={4}>
          {userCanCancelSporadic && (
            <Button
              size="sm"
              leftIcon={<Icon as={FaTimesCircle} />}
              variant="outline"

              onClick={handleCancelSporadic}
            >
              Cancelar
            </Button>
          )}
          {userCanBillSporadic && (
            <Button
              size="sm"
              leftIcon={<Icon as={FaCheckCircle} />}
              colorScheme='blue'
              onClick={handleBillSporadic}
            >
              Faturar
            </Button>
          )}
        </ModalFooter>
      )}
    </ModalContent>
  )
}
