import { Button, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { Aso } from "api/asos/aso";
import { GetAsosResponse } from "api/asos/getAsos";
import { inactivateAso } from "api/asos/inactivateAso";
import { Regional } from "api/regionals/_types/Regional";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { FaFileImport, FaSearch, FaTimes } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { asoTypeMap } from "utils/asoMappers";
import { AsoDetail } from "./AsoDetail";
import { AsoStatus } from "./AsoStatus";
import { SendAsoAttachment } from "./SendAsoAttachment";

interface AsoTableRowProps {
  aso: Aso & { regional: Regional }
}

export function AsosTableRow({ aso }: AsoTableRowProps) {
  const { userLogged } = useAuth()

  const userHasSendAsoAttachmentPermission = userLogged?.permissions.includes('send-aso-attachment')
  const userHasInactivateAsoPermission = userLogged?.permissions.includes('inactivate-aso')

  const {
    isOpen: isDetailModalOpen,
    onOpen: onOpenDetailModal,
    onClose: onCloseDetailModal,
  } = useDisclosure()

  const {
    isOpen: isSendAttachmentModalOpen,
    onOpen: onOpenSendAttachmentModal,
    onClose: onCloseSendAttachmentModal,
  } = useDisclosure()

  const queryClient = useQueryClient()

  const { mutateAsync: inactivateAsoFn } = useMutation({
    mutationFn: inactivateAso,
    onSuccess(_data, { asoId }) {
      const cachedAsos = queryClient.getQueriesData<GetAsosResponse>({
        queryKey: ['asos']
      })

      cachedAsos.forEach(([cachedKey, cachedData]) => {

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          asos: cachedData.asos.map((aso) => {
            if (aso.id === asoId) {
              return {
                ...aso,
                status: 'inactive',
                inactivated_at: new Date().toISOString(),
              }
            }

            return aso
          })
        })
      })
    },
  })

  const { promiseMessage } = useToastify()

  async function handleInactivateAso() {
    await promiseMessage(inactivateAsoFn({
      asoId: aso.id
    }), 'Aso inativada!')
  }

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar aso"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onOpenDetailModal}
        />

        <Modal
          isOpen={isDetailModalOpen}
          onClose={onCloseDetailModal}
          isCentered
        >
          <ModalOverlay />

          <AsoDetail asoId={aso.id} />

        </Modal>
      </Td>
      <Td>{aso.regional ? aso.regional.code.toUpperCase() : '-'}</Td>
      <Td>
        <AsoStatus status={aso.status} />
      </Td>
      <Td>{format(new Date(aso.created_at), 'dd/MM/yyyy')}</Td>
      <Td>{asoTypeMap[aso.type]}</Td>
      <Td>{aso.collaborator_name}</Td>
      <Td>{aso.status === 'active' ? (
        format(new Date(aso.due_date), 'dd/MM/yyyy')
      ) : (
        '-'
      )}</Td>
      <Td>
        {(aso.status === 'pending' && userHasSendAsoAttachmentPermission) && (
          <Button
            leftIcon={<FaFileImport />}
            lineHeight="1"
            size="xs"
            variant="outline"
            onClick={onOpenSendAttachmentModal}
          >
            Anexar
          </Button>
        )}

        <Modal
          isOpen={isSendAttachmentModalOpen}
          onClose={onCloseSendAttachmentModal}
          isCentered
        >
          <ModalOverlay />

          <SendAsoAttachment asoId={aso.id} onCloseModal={onCloseSendAttachmentModal} />
        </Modal>

      </Td>
      <Td>
        {(aso.status !== 'inactive' && userHasInactivateAsoPermission) && (
          <Button
            leftIcon={<FaTimes />}
            lineHeight="1"
            size="xs"
            variant="ghost"
            onClick={handleInactivateAso}
          >
            Inativar
          </Button>
        )}
      </Td>
    </Tr>
  )
}
