import { IconButton, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { getCltIntercurrence } from "api/cltIntercurrences/getCltIntercurrence";
import { format } from "date-fns";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useQuery } from "react-query";
import { captalize } from "utils/captalize";
import { cltIntercurrenceBooleanWithNAType, cltIntercurrencePartnerTypeMap, cltIntercurrenceTypeMap } from "utils/cltIntercurrenceMappers";

interface CltIntercurrenceDetailProps {
  cltIntercurrenceId: string
}

export function CltIntercurrenceDetail({
  cltIntercurrenceId
}: CltIntercurrenceDetailProps) {
  const {
    data: cltIntercurrenceResult,
    isLoading: isLoadingCltIntercurrenceResult
  } = useQuery({
    queryKey: ['clt-intercurrence', cltIntercurrenceId],
    queryFn: () => getCltIntercurrence({ routeParams: { cltIntercurrenceId } })
  })

  if (isLoadingCltIntercurrenceResult) {
    return (
      <ModalContent>
        <ModalHeader>
          <Skeleton w="150px" />
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <Skeleton h="15px" />
          <Skeleton h="15px" />
          <Skeleton h="15px" />
          <Skeleton h="15px" />
          <Skeleton h="15px" />
        </ModalBody>
      </ModalContent>
    )
  }

  return (
    <ModalContent>
      <ModalHeader>
        Detalhes da Intercorrência
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Td>Tipo de intercorrência</Td>
              <Td isNumeric>{cltIntercurrenceTypeMap[cltIntercurrenceResult?.cltIntercurrence?.type]}</Td>
            </Tr>
            <Tr>
              <Td>Descrição</Td>
              <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.description}</Td>
            </Tr>
            <Tr>
              <Td>Regional</Td>
              <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.regional.toUpperCase()}</Td>
            </Tr>
            <Tr>
              <Td>Tipo de parceiro</Td>
              <Td isNumeric>{cltIntercurrencePartnerTypeMap[cltIntercurrenceResult?.cltIntercurrence?.partner_type]}</Td>
            </Tr>
            <Tr>
              <Td>Colaborador</Td>
              <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.collaborator_name}</Td>
            </Tr>
            <Tr>
              <Td>Data da intercorrência</Td>
              <Td isNumeric>{format(new Date(cltIntercurrenceResult?.cltIntercurrence?.date), 'dd/MM/yyyy')}</Td>
            </Tr>
            {cltIntercurrenceResult?.cltIntercurrence?.type === 'work-accident' && (
              <>
                <Tr>
                  <Td>Horário da intercorrência</Td>
                  <Td isNumeric>{format(new Date(cltIntercurrenceResult?.cltIntercurrence?.date), 'HH:mm')}</Td>
                </Tr>
                <Tr>
                  <Td>Foi acidente de trajeto, durante a jornada ou doença ocupacional?</Td>
                  <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.workAccidentIntercurrence?.is_trajectory_or_occupational_accident ? 'Sim' : 'Não'}</Td>
                </Tr>
                <Tr>
                  <Td>Endereço do acidente</Td>
                  <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.workAccidentIntercurrence?.accident_address}</Td>
                </Tr>
                <Tr>
                  <Td>Parte do corpo atingida</Td>
                  <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.workAccidentIntercurrence?.body_part}</Td>
                </Tr>
                <Tr>
                  <Td>Hora do atendimento médico</Td>
                  <Td isNumeric>{format(new Date(cltIntercurrenceResult?.cltIntercurrence?.workAccidentIntercurrence?.medical_care_time), 'HH:mm')}</Td>
                </Tr>
                <Tr>
                  <Td>CID do atestado médico</Td>
                  <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.workAccidentIntercurrence?.medical_certificate_cid}</Td>
                </Tr>
                <Tr>
                  <Td>Nome do médico</Td>
                  <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.workAccidentIntercurrence?.medical_name}</Td>
                </Tr>
                <Tr>
                  <Td>CRM do médico</Td>
                  <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.workAccidentIntercurrence?.medical_crm}</Td>
                </Tr>
              </>
            )}
            {cltIntercurrenceResult?.cltIntercurrence?.type === 'miss-work' && cltIntercurrenceResult?.cltIntercurrence?.missWorkIntercurrence?.attachment && (
              <Tr>
                <Td>Atestado</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label="Atestado da intercorrência"
                    icon={<FaExternalLinkAlt />}
                    size="sm"
                    as={Link}
                    href={cltIntercurrenceResult?.cltIntercurrence?.missWorkIntercurrence?.attachment?.link}
                    isExternal
                  />
                </Td>
              </Tr>
            )}

            {cltIntercurrenceResult?.cltIntercurrence?.type === 'patrimony-without-return' && (
              <Tr>
                <Td>Valor total do desconto</Td>
                <Td isNumeric>
                  {(cltIntercurrenceResult?.cltIntercurrence?.patrimonyWithoutReturnIntercurrence?.discount_total_value_in_cents / 100).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </Td>
              </Tr>
            )}

            {cltIntercurrenceResult?.cltIntercurrence?.type === 'extra-hours' && (
              <Tr>
                <Td>Quantidade de minutos</Td>
                <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.extraHoursIntercurrence?.minutes_quantity}</Td>
              </Tr>
            )}

            {cltIntercurrenceResult?.cltIntercurrence?.type === 'late-or-early-departure' && (
              <Tr>
                <Td>Quantidade de minutos</Td>
                <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.lateOrEarlyDepartureIntercurrence?.minutes_quantity}</Td>
              </Tr>
            )}

            {cltIntercurrenceResult?.cltIntercurrence?.type === 'ticket-log-additional-value' && (
              <Tr>
                <Td>Placa do funcionário</Td>
                <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.ticketLogAdditionalValueIntercurrence?.employee_car_license_plate}</Td>
              </Tr>
            )}

            {cltIntercurrenceResult?.cltIntercurrence?.has_generate_extra_discount && (
              <Tr>
                <Td>Vai gerar extra/desconto</Td>
                <Td isNumeric>{cltIntercurrenceBooleanWithNAType[cltIntercurrenceResult?.cltIntercurrence?.has_generate_extra_discount]}</Td>
              </Tr>
            )}
            {cltIntercurrenceResult?.cltIntercurrence?.with_motorcycle_rental !== null && (
              <Tr>
                <Td>Aluguel de moto</Td>
                <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.with_motorcycle_rental ? 'Sim' : 'Não'}</Td>
              </Tr>
            )}
            {cltIntercurrenceResult?.cltIntercurrence?.with_telephony !== null && (
              <Tr>
                <Td>Telefonia</Td>
                <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.with_telephony ? 'Sim' : 'Não'}</Td>
              </Tr>
            )}
            {cltIntercurrenceResult?.cltIntercurrence?.with_fuel !== null && (
              <Tr>
                <Td>Combustível</Td>
                <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.with_fuel ? 'Sim' : 'Não'}</Td>
              </Tr>
            )}
            {cltIntercurrenceResult?.cltIntercurrence?.with_va_vr !== null && (
              <Tr>
                <Td>VA/VR</Td>
                <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.with_va_vr ? 'Sim' : 'Não'}</Td>
              </Tr>
            )}
            {cltIntercurrenceResult?.cltIntercurrence?.with_vt !== null && (
              <Tr>
                <Td>VT</Td>
                <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.with_vt ? 'Sim' : 'Não'}</Td>
              </Tr>
            )}
            {cltIntercurrenceResult?.cltIntercurrence?.with_toll !== null && (
              <Tr>
                <Td>Pedágio</Td>
                <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.with_toll ? 'Sim' : 'Não'}</Td>
              </Tr>
            )}
            {cltIntercurrenceResult?.cltIntercurrence?.with_extras !== null && (
              <Tr>
                <Td>Extras</Td>
                <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.with_extras ? 'Sim' : 'Não'}</Td>
              </Tr>
            )}
            {cltIntercurrenceResult?.cltIntercurrence?.with_payroll !== null && (
              <Tr>
                <Td>Folha de pagamento</Td>
                <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.with_payroll ? 'Sim' : 'Não'}</Td>
              </Tr>
            )}

            {cltIntercurrenceResult?.cltIntercurrence?.type === 'miss-work' && cltIntercurrenceResult?.cltIntercurrence?.missWorkIntercurrence && (
              <>
                {cltIntercurrenceResult?.cltIntercurrence?.missWorkIntercurrence?.is_certificate_presented !== null && (
                  <Tr>
                    <Td>Atestado apresentado?</Td>
                    <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.missWorkIntercurrence?.is_certificate_presented ? 'Sim' : 'Não'}</Td>
                  </Tr>
                )}
                {cltIntercurrenceResult?.cltIntercurrence?.missWorkIntercurrence?.has_paid_the_hours_debited_at_point !== null && (
                  <Tr>
                    <Td>Abonou as horas debitadas no ponto?</Td>
                    <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.missWorkIntercurrence?.has_paid_the_hours_debited_at_point ? 'Sim' : 'Não'}</Td>
                  </Tr>
                )}
                {cltIntercurrenceResult?.cltIntercurrence?.missWorkIntercurrence?.employee_clocked_in_for_the_day !== null && (
                  <Tr>
                    <Td>Funcionário bateu ponto no dia?</Td>
                    <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.missWorkIntercurrence?.employee_clocked_in_for_the_day ? 'Sim' : 'Não'}</Td>
                  </Tr>
                )}
              </>
            )}

            {cltIntercurrenceResult?.cltIntercurrence?.type === 'patrimony-without-return' && cltIntercurrenceResult?.cltIntercurrence?.patrimonyWithoutReturnIntercurrence && (
              <>
                {cltIntercurrenceResult?.cltIntercurrence?.patrimonyWithoutReturnIntercurrence?.was_entry_made_on_termination_receipt !== null && (
                  <Tr>
                    <Td>Atestado apresentado?</Td>
                    <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.patrimonyWithoutReturnIntercurrence?.was_entry_made_on_termination_receipt ? 'Sim' : 'Não'}</Td>
                  </Tr>
                )}
              </>
            )}

            {cltIntercurrenceResult?.cltIntercurrence?.type === 'extra-hours' && cltIntercurrenceResult?.cltIntercurrence?.extraHoursIntercurrence && (
              <>
                {cltIntercurrenceResult?.cltIntercurrence?.extraHoursIntercurrence?.with_vacation_payroll !== null && (
                  <Tr>
                    <Td>Folha de pagamento - HE Feriado</Td>
                    <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.extraHoursIntercurrence?.with_vacation_payroll ? 'Sim' : 'Não'}</Td>
                  </Tr>
                )}
                {cltIntercurrenceResult?.cltIntercurrence?.extraHoursIntercurrence?.vacation_payroll_description !== null && (
                  <Tr>
                    <Td>Descrição - HE Feriado</Td>
                    <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.extraHoursIntercurrence?.vacation_payroll_description}</Td>
                  </Tr>
                )}
                {cltIntercurrenceResult?.cltIntercurrence?.extraHoursIntercurrence?.with_pontomais_adjust !== null && (
                  <Tr>
                    <Td>Ajuste no ponto mais realizado?</Td>
                    <Td isNumeric>{cltIntercurrenceBooleanWithNAType[cltIntercurrenceResult?.cltIntercurrence?.extraHoursIntercurrence?.with_pontomais_adjust]}</Td>
                  </Tr>
                )}
                {cltIntercurrenceResult?.cltIntercurrence?.extraHoursIntercurrence?.customer !== null && (
                  <Tr>
                    <Td>Cliente</Td>
                    <Td isNumeric>{captalize(cltIntercurrenceResult?.cltIntercurrence?.extraHoursIntercurrence?.customer?.trading_firstname)}</Td>
                  </Tr>
                )}
                {cltIntercurrenceResult?.cltIntercurrence?.extraHoursIntercurrence?.businessBudget !== null && (
                  <Tr>
                    <Td>Cidade/Rota</Td>
                    <Td isNumeric>{captalize(cltIntercurrenceResult?.cltIntercurrence?.extraHoursIntercurrence?.businessBudget?.route_nickname)}</Td>
                  </Tr>
                )}
                {cltIntercurrenceResult?.cltIntercurrence?.extraHoursIntercurrence?.amount_to_be_charged_in_cents !== null && (
                  <Tr>
                    <Td>Valor a ser cobrado</Td>
                    <Td isNumeric>
                      {(cltIntercurrenceResult?.cltIntercurrence?.extraHoursIntercurrence?.amount_to_be_charged_in_cents / 100).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      })}
                    </Td>
                  </Tr>
                )}
              </>
            )}

            {cltIntercurrenceResult?.cltIntercurrence?.type === 'turn-change' && cltIntercurrenceResult?.cltIntercurrence?.turnChangeIntercurrence && (
              <>
                {cltIntercurrenceResult?.cltIntercurrence?.turnChangeIntercurrence?.with_pontomais_adjust !== null && (
                  <Tr>
                    <Td>Ajuste no ponto mais realizado?</Td>
                    <Td isNumeric>{cltIntercurrenceBooleanWithNAType[cltIntercurrenceResult?.cltIntercurrence?.turnChangeIntercurrence?.with_pontomais_adjust]}</Td>
                  </Tr>
                )}
                {cltIntercurrenceResult?.cltIntercurrence?.turnChangeIntercurrence?.was_changed_driver_on_business_budget !== null && (
                  <Tr>
                    <Td>Se mudança de rota, realizado alteração do motorista no orçamento business?</Td>
                    <Td isNumeric>{cltIntercurrenceBooleanWithNAType[cltIntercurrenceResult?.cltIntercurrence?.turnChangeIntercurrence?.was_changed_driver_on_business_budget]}</Td>
                  </Tr>
                )}
                {cltIntercurrenceResult?.cltIntercurrence?.turnChangeIntercurrence?.turn_change_checklist !== null && (
                  <Tr>
                    <Td>Checklist</Td>
                    <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.turnChangeIntercurrence?.turn_change_checklist.map(value => {
                      return (
                        <Text>
                          {value}
                          <br />
                        </Text>
                      )
                    })}</Td>
                  </Tr>
                )}
              </>
            )}

            {cltIntercurrenceResult?.cltIntercurrence?.type === 'late-or-early-departure' && cltIntercurrenceResult?.cltIntercurrence?.lateOrEarlyDepartureIntercurrence && (
              <>
                {cltIntercurrenceResult?.cltIntercurrence?.lateOrEarlyDepartureIntercurrence?.with_pontomais_adjust !== null && (
                  <Tr>
                    <Td>Ajuste no ponto mais realizado?</Td>
                    <Td isNumeric>{cltIntercurrenceBooleanWithNAType[cltIntercurrenceResult?.cltIntercurrence?.lateOrEarlyDepartureIntercurrence?.with_pontomais_adjust]}</Td>
                  </Tr>
                )}
              </>
            )}

            {cltIntercurrenceResult?.cltIntercurrence?.type === 'ticket-log-additional-value' && cltIntercurrenceResult?.cltIntercurrence?.ticketLogAdditionalValueIntercurrence && (
              <>
                {cltIntercurrenceResult?.cltIntercurrence?.ticketLogAdditionalValueIntercurrence?.additional_value_was_released !== null && (
                  <Tr>
                    <Td>Valor adicional liberado?</Td>
                    <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.ticketLogAdditionalValueIntercurrence?.additional_value_was_released ? 'Sim' : 'Não'}</Td>
                  </Tr>
                )}
                {cltIntercurrenceResult?.cltIntercurrence?.ticketLogAdditionalValueIntercurrence?.release_date !== null && (
                  <Tr>
                    <Td>Data efetiva da liberação</Td>
                    <Td isNumeric>{format(new Date(cltIntercurrenceResult?.cltIntercurrence?.ticketLogAdditionalValueIntercurrence?.release_date), 'dd/MM/yyyy')}</Td>
                  </Tr>
                )}
                {cltIntercurrenceResult?.cltIntercurrence?.ticketLogAdditionalValueIntercurrence?.released_value_in_cents !== null && (
                  <Tr>
                    <Td>Data efetiva da liberação</Td>
                    <Td isNumeric>
                      {(cltIntercurrenceResult?.cltIntercurrence?.ticketLogAdditionalValueIntercurrence?.released_value_in_cents / 100).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      })}
                    </Td>
                  </Tr>
                )}
              </>
            )}

            {cltIntercurrenceResult?.cltIntercurrence?.type === 'other' && cltIntercurrenceResult?.cltIntercurrence?.otherIntercurrence && (
              <>
                {cltIntercurrenceResult?.cltIntercurrence?.otherIntercurrence?.with_vacation_payroll !== null && (
                  <Tr>
                    <Td>Folha de pagamento - HE Feriado</Td>
                    <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.otherIntercurrence?.with_vacation_payroll ? 'Sim' : 'Não'}</Td>
                  </Tr>
                )}
                {cltIntercurrenceResult?.cltIntercurrence?.otherIntercurrence?.vacation_payroll_description !== null && (
                  <Tr>
                    <Td>Descrição - HE Feriado</Td>
                    <Td isNumeric>{cltIntercurrenceResult?.cltIntercurrence?.otherIntercurrence?.vacation_payroll_description}</Td>
                  </Tr>
                )}
              </>
            )}
          </Tbody>
        </Table>

      </ModalBody>
    </ModalContent>
  )
}
