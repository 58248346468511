import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getExternalSupports } from "api/externalSupports/getExternalSupports";
import { ExternalSupportStatus } from "api/externalSupports/_types/ExternalSupport";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CreateExternalSupport } from "./components/CreateExternalSupport";
import { ExternalSupportsTableRow } from "./components/ExternalSupportsTableRow";
import { ExternalSupportTableFilters } from "./components/ExternalSupportTableFilters";

export function ExternalSupports() {

  const searchParams = useSearchParams()

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanViewExternalSupports = userLogged?.permissions?.includes(
    'view-external-supports'
  )

  const userCanCreateExternalSupport = userLogged?.permissions?.includes(
    'create-external-support'
  )

  const userCanDesignateExternalSupport = userLogged?.permissions.includes(
    'designate-external-support'
  )

  const userCanResolveExternalSupport = userLogged?.permissions.includes(
    'resolve-external-support'
  )

  useEffect(() => {
    if (!userCanViewExternalSupports) history.push('/')
  }, [history, userCanViewExternalSupports])

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status') ?? 'all'
  const reason = searchParams.get('reason')

  const {
    data: externalSupportsResult
  } = useQuery({
    queryKey: ['externalSupports', page, status, reason],
    queryFn: () => {

      const ownerId = userCanDesignateExternalSupport
        ? undefined
        : userCanResolveExternalSupport || userCanViewExternalSupports
        ? userLogged.id
        : undefined;

      const resolutionResponsibleId = userCanDesignateExternalSupport
        ? undefined
        : userCanResolveExternalSupport
        ? userLogged.id
        : undefined;

      return getExternalSupports({
        currentPage: Number(page),
        pageSize: 10,
        status: status as ExternalSupportStatus | 'all',
        reason,
        ownerId,
        resolutionResponsibleId
      })
    }
  })

  const {
    isOpen: isCreateExternalSupportModalOpen,
    onOpen: onOpenCreateExternalSupportModal,
    onClose: onCloseCreateExternalSupportModal
  } = useDisclosure()

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Atendimentos Externos</Heading>
        {userCanCreateExternalSupport && (
          <IconButton
            aria-label="Criar atendimento externo"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            onClick={onOpenCreateExternalSupportModal}
          />
        )}

        <Modal
          isOpen={isCreateExternalSupportModalOpen}
          onClose={onCloseCreateExternalSupportModal}
          isCentered
        >
          <ModalOverlay />
          <CreateExternalSupport
            onCloseModal={onCloseCreateExternalSupportModal}
          />
        </Modal>
      </Flex>
      <ExternalSupportTableFilters />
      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Protocolo</Th>
              <Th color="white">Motivo</Th>
              <Th color="white">Status</Th>
              <Th></Th>

            </Tr>
          </Thead>
          <Tbody>
            {externalSupportsResult?.externalSupports?.map((externalSupport) => {
              return <ExternalSupportsTableRow
                key={externalSupport.id}
                externalSupport={externalSupport}
              />
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}
