
import { Box, Button, Divider, Flex, Heading, Icon, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { usePagination } from "@ajna/pagination";
import { Link, useHistory } from "react-router-dom";
import { FiEdit3, FiEye, FiPlus, FiTrash2 } from "react-icons/fi";
import { useAuth } from "hooks/auth/useAuth";
import { useSwal } from "hooks/swal/useSwal";
import { useToastify } from "hooks/toastify/useToastify";
import { Fragment, useEffect, useMemo, useReducer } from "react";
import { Pagination } from "components/Pagination/Pagination";
import { useForm, useWatch } from "react-hook-form";
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions";
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { situation } from "utils/customLists";
import { TableFilterButton } from "components/Filters/TableFilterButton";
import { FaUndoAlt } from "react-icons/fa";
import { useQueryClient } from "react-query";
import { useProducts } from "hooks/product/useProducts";
import { useProductFunctions } from "hooks/product/useProductFunctions";

interface IProductsFilterInputs {
  q: string
  situation: string
}

const productsListFilterOptions = [
  { key: 'q', value: 'q', checked: false },
  { key: 'situation', value: 'Situação', checked: false },
]

interface ActionPayload {
  productData?: IProductsFilterInputs
  currentPage?: number
}

interface Action {
  type: 'set-product-current-data' | 'set-product-current-page'
  payload: ActionPayload
}

function reducer(state: ActionPayload, action: Action) {
  if (action.type === 'set-product-current-page') {
    return {
      productData: state.productData,
      currentPage: action.payload.currentPage
    }
  }

  return {
    ...state,
    ...action.payload
  }
}

export function ProductList() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  const userLoggedHasPermissionToViewProduct = userLogged?.permissions.includes('view-product')

  const { confirmMessage, standardMessage } = useSwal()
  const { promiseMessage } = useToastify()

  const { register, control } = useForm<IProductsFilterInputs>()

  const [listProductDataState, dispatch] = useReducer(
    reducer,
    {} as ActionPayload
  )

  const { deleteProduct: { mutateAsync: deleteProduct } } = useProductFunctions()

  useEffect(() => {
    if (!userLoggedHasPermissionToViewProduct) {
      redirectTo('/')
    }
  }, [userLoggedHasPermissionToViewProduct, redirectTo])


  const [
    querySearched,
    situationSearched,
  ] = useWatch({
    control,
    name: [
      'q',
      'situation',
    ]
  })

  const filterOptionsWithValuesRenamed = useMemo(() => {
    return productsListFilterOptions.map((option) => {
      if (option.key === 'q') {
        option.value = 'Busca Geral'
      }
      return option
    })
  }, [])

  const {
    filterOptions,
    onLoadSetFilterOptions
  } = useFilterOptions()

  useEffect(() => {
    onLoadSetFilterOptions(filterOptionsWithValuesRenamed)
  }, [filterOptionsWithValuesRenamed, onLoadSetFilterOptions])

  const { data: products, isFetching: isFetchingProducts } = useProducts({
    queryParams: {
      ...listProductDataState.productData,
      current_page: listProductDataState.currentPage,
      page_size: Number(rowsPerPage)
    }
  })

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: products?.totalPages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });



  useEffect(() => {
    dispatch({
      type: 'set-product-current-page',
      payload: {
        currentPage: currentPage
      }
    })
  }, [currentPage])

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      dispatch({
        type: 'set-product-current-data',
        payload: {
          productData: {
            q: querySearched,
            situation: situationSearched,
          }
        }
      })

      setCurrentPage(1)
    }, 1000)

    return () => clearTimeout(debounceTimeout)
  }, [
    querySearched,
    situationSearched,
    dispatch,
    setCurrentPage
  ])

  async function handleDeleteProduct(product_id: string) {
    const hasDeleteProduct = await confirmMessage({ title: "Deseja excluir o produto?" })

    if (hasDeleteProduct) {
      await promiseMessage(deleteProduct(product_id), "Produto excluído com sucesso!")
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  const handleChangePage = (page: number) => setCurrentPage(page)

  const queryClient = useQueryClient()
  const handleRefetchTableData = async () => {
    await queryClient.invalidateQueries(['generalProviders'])
  }

  const filterOptionsChecked = filterOptions.filter((option) => option.checked)
  return (

      <Box
        borderRadius='8px'
        p={4}
        bg='white'
      >
        <Flex
          w="full"
          direction="column"
          gap={6}
        >
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontFamily="poppins">
              Produtos
            </Heading>
            <Link to="/produto/criar">
              <Button
                colorScheme="gray"
                rightIcon={<Icon as={FiPlus} />}
                size="sm"
              >
                Adicionar Produto
              </Button>

            </Link>
          </Flex>
          <Divider />
          <Flex gap={2}>
            <TableFilterButton />
            <Button colorScheme="blue" onClick={handleRefetchTableData}>
              <Icon as={FaUndoAlt} />{' '}
            </Button>
          </Flex>
          {filterOptionsChecked.map((option) => {
            return (
              <Fragment key={option.key}>
                {option.key === 'situation' ? (
                  <Select
                    {...register('situation')}
                    name='situation'
                    label='Situação'
                    situations={situation}
                    placeholder='Selecione uma opção...'
                  />
                ) : (
                  <Input
                    {...register('q')}
                    name='q'
                    label='Buscar por palavra'
                  />
                )}
              </Fragment>
            )
          })}
          {isFetchingProducts ? (
            <Spinner />
          ) : (
            <TableContainer w="full">
              <Table colorScheme="gray" variant="striped" size="sm">
                <Thead>
                  <Tr>
                    <Th>NOME</Th>
                    <Th>ALMOXARIFADO</Th>
                    <Th>FORNECEDOR</Th>
                    <Th>MEDIDA</Th>
                    <Th>CUSTO UNITÁRIO</Th>
                    <Th>ESTOQUE MÍNIMO</Th>
                    <Th>ESTOQUE PADRÃO</Th>
                    <Th>SITUAÇÃO</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {products?.products.map((product) => {
                    return (
                      <Tr key={product.id}>
                        <Td>{product?.material.material_name}</Td>
                        <Td>{product.warehouse.name}</Td>
                        <Td>{product?.generalProvider.company_name}</Td>
                        <Td>{product.measure}</Td>
                        <Td>{new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        }).format(Number(product.unit_cost) / 100)}</Td>
                        <Td>{product.min_stock}</Td>
                        <Td>{product.standard_stock}</Td>
                        <Td>{product.situation}</Td>
                        <Td isNumeric>
                          {userLogged !== null && (
                            <>
                              {
                                userLogged.permissions.includes('view-product') && (
                                  <Link
                                    to={`/produto/visualizar/${product.id}`}
                                  >
                                    <Icon
                                      cursor="pointer"
                                      as={FiEye}
                                      fontSize="20"
                                      ml="4"
                                      mt={['2', '2', '0']}
                                    />
                                  </Link>
                                )
                              }
                              {userLogged.permissions.includes('edit-product') && (
                                <Link
                                  to={`/produto/editar/${product.id}`}
                                >
                                  <Icon
                                    cursor="pointer"
                                    as={FiEdit3}
                                    fontSize="20"
                                    ml="4"
                                    mt={['2', '2', '0']}
                                  />
                                </Link>
                              )}

                              {userLogged.permissions.includes('delete-product') && (
                                <Icon
                                  cursor="pointer"
                                  as={FiTrash2}
                                  fontSize="20"
                                  ml="4"
                                  mt={['2', '2', '0']}
                                  onClick={() =>
                                    handleDeleteProduct(product.id)
                                  }
                                />
                              )}
                            </>
                          )}
                        </Td>
                      </Tr>
                    )

                  })}

                </Tbody>
              </Table>
            </TableContainer>)}

          <Pagination
            currentPage={currentPage}
            pagesQuantity={pagesCount}
            pages={pages}
            handlePageChange={handleChangePage}
          />

        </Flex>
      </Box>

  )
}
