import { useEffect } from "react";
import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { useAuth } from "hooks/auth/useAuth";
import { FaPlus } from "react-icons/fa";
import { CreateOnboarding } from "./components/CreateOnboarding";
import { useHistory } from "react-router-dom";
import { useSearchParams } from "hooks/useSearchParams";
import { getOnboardings } from "api/onboardings/getOnboardings";
import { useQuery } from "react-query";
import { OnboardingStatus, OnboardingType } from "api/onboardings/onboarding";
import { usePagination } from "@ajna/pagination";
import { Pagination } from "components/Pagination/Pagination";
import { OnboardingTableRow } from "./components/OnboardingTableRow";
import { OnboardingsTableFilters } from "./components/OnboardingsTableFilters";

export function Onboardings() {
  const { userLogged } = useAuth()
  const { replace, push: redirect } = useHistory()
  const searchParams = useSearchParams()

  const userHasViewOnboardingsPermission = userLogged?.permissions.includes('view-onboardings')
  const userHasCreateOnboardingPermission = userLogged?.permissions.includes('create-onboarding')

  useEffect(() => {
    if (!userHasViewOnboardingsPermission) {
      redirect('/')
    }
  }, [userHasViewOnboardingsPermission, redirect])

  const page = searchParams.get('page') ?? '1'
  const name = searchParams.get('name')
  const status = searchParams.get('status') ?? 'all'
  const trainingType = searchParams.get('trainingType') ?? 'all'


  const { data: onboardingsData } = useQuery({
    queryKey: ['onboardings',
      page,
      name,
      status,
      trainingType
    ],
    queryFn: () => getOnboardings({
      currentPage: page,
      pageSize: '10',
      name,
      status: status as OnboardingStatus | 'all',
      trainingType: trainingType as OnboardingType | 'all'
    }),
    keepPreviousData: true
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: onboardingsData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: onboardingsData?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }


  const {
    isOpen: isCreateOnboardingModalOpen,
    onOpen: onOpenCreateOnboardingModal,
    onClose: onCloseCreateOnboardingModal
  } = useDisclosure()


  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Onboardings</Heading>
        {/* {userHasCreateOnboardingPermission && (
          <IconButton
            aria-label="Criar Onboarding"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            onClick={onOpenCreateOnboardingModal}
          />
        )} */}
        <Modal
          isOpen={isCreateOnboardingModalOpen}
          onClose={onCloseCreateOnboardingModal}
          isCentered
        >
          <ModalOverlay />
          <CreateOnboarding onCloseModal={onCloseCreateOnboardingModal} />
        </Modal>
      </Flex>
      <OnboardingsTableFilters />
      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Colaborador</Th>
              <Th color="white">Tipo de treinamento</Th>
              <Th color="white">CPF</Th>
              <Th color="white">E-mail</Th>
              <Th color="white">Status</Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {onboardingsData?.onboardings?.map((onboarding) => {
              return (
                <OnboardingTableRow
                  key={onboarding.id}
                  onboarding={onboarding}
                />
              )
            })}

          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>

  )
}
