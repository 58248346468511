import { Button, HStack, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { deleteVacation } from "api/vacations/deleteVacation";
import { dismissVacationCollaborator } from "api/vacations/dismissVacationCollaborator";
import { GetVacationsResponse } from "api/vacations/getVacations";
import { Vacation } from "api/vacations/_types/Vacation";
import { VacationPeriod } from "api/vacations/_types/VacationPeriod";
import { differenceInDays, format, set } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { FaSearch, FaTimes } from "react-icons/fa";
import { FaArrowRight, FaTrash } from "react-icons/fa6";
import { useMutation, useQueryClient } from "react-query";
import { FinishVacationPeriod } from "./FinishVacationPeriod";
import { PayVacation } from "./PayVacation";
import { PlanVacation } from "./PlanVacation";
import { ProceedVacation } from "./ProceedVacation";
import { VacationDetail } from "./VacationDetail";
import { VacationStatus } from "./VacationStatus";

interface VacationsTableRowProps {
  vacation: Vacation & {
    periods: VacationPeriod[]
  }
}

export function VacationsTableRow({ vacation }: VacationsTableRowProps) {
  const { userLogged } = useAuth()

  const userCanPlanVacation = userLogged?.permissions.includes(
    'plan-vacation'
  )

  const userCanPayVacation = userLogged?.permissions.includes(
    'pay-vacation'
  )

  const userCanProceedVacation = userLogged?.permissions.includes(
    'proceed-vacation'
  )

  const userCanFinishVacationPeriod = userLogged?.permissions.includes(
    'finish-vacation-period'
  )

  const userCanDismissVacationCollaborator = userLogged?.permissions.includes(
    'dismiss-vacation-collaborator'
  )

  const userCanDeleteVacation = userLogged?.permissions.includes(
    'delete-vacation'
  )


  const {
    isOpen: isVacationDetailOpen,
    onToggle: onToggleVacationDetail
  } = useDisclosure()

  const {
    isOpen: isPlanVacationOpen,
    onToggle: onTogglePlanVacation
  } = useDisclosure()

  const {
    isOpen: isPayVacationOpen,
    onToggle: onTogglePayVacation
  } = useDisclosure()

  const {
    isOpen: isProceedVacationOpen,
    onToggle: onToggleProceedVacation
  } = useDisclosure()

  const {
    isOpen: isFinishVacationPeriodOpen,
    onToggle: onToggleFinishVacationPeriod
  } = useDisclosure()

  const queryClient = useQueryClient()

  const { promiseMessage } = useToastify()

  const { mutateAsync: dismissVacationCollaboratorFn } = useMutation({
    mutationFn: dismissVacationCollaborator,
    onSuccess(_data, variables) {
      const cached = queryClient.getQueriesData<GetVacationsResponse>({
        queryKey: ['vacations']
      })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        const collaboratorName = cachedValue.vacations.find(
          (vacation) => vacation.id === variables.vacationId
        )?.collaborator_name

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          vacations: cachedValue.vacations.map((vacation) => {
            if (
              vacation.id === variables.vacationId ||
              vacation.collaborator_name === collaboratorName
            ) {
              return { ...vacation, status: 'dismissed' }
            }

            return vacation
          })
        })
      })
    },
  })

  async function handleDismissVacationCollaborator(vacationId: string) {
    await promiseMessage(dismissVacationCollaboratorFn({
      vacationId,
    }), 'Demissão realizada com sucesso!')
  }

  const { mutateAsync: deleteVacationFn } = useMutation({
    mutationFn: deleteVacation,
    onSuccess(_data, variables) {
      const cached = queryClient.getQueriesData<GetVacationsResponse>({
        queryKey: ['vacations']
      })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          vacations: cachedValue.vacations.filter(vacation => vacation.id === variables.vacationId)
        })
      })
    },
  })

  async function handleDeleteVacation(vacationId: string) {
    await promiseMessage(deleteVacationFn({
      vacationId,
    }), 'Férias excluída com sucesso!')
  }

  const activeVacationPeriod = vacation.periods.find(period => period.status === 'active')

  const isCurrentVacationPeriodEnableToProceed = activeVacationPeriod
    ? differenceInDays(
      set(new Date(activeVacationPeriod.start_date), { hours: 11, minutes: 0, seconds: 0 }),
      set(new Date(), { hours: 12, minutes: 0, seconds: 0 })
    ) <= 3
    : false

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Abrir detalhes das férias"
          variant="ghost"
          icon={<FaSearch />}
          size="sm"
          onClick={onToggleVacationDetail}
        />

        <Modal isOpen={isVacationDetailOpen} onClose={onToggleVacationDetail} size="2xl">
          <ModalOverlay />

          <VacationDetail vacationId={vacation.id} />
        </Modal>
      </Td>
      <Td>{vacation.collaborator_name}</Td>
      <Td>{format(new Date(vacation.deadline), 'dd/MM/yyyy')}</Td>
      <Td>
        <VacationStatus status={vacation.status} />
      </Td>
      <Td>
        <HStack spacing={3} align="center">
          {userCanPlanVacation && vacation.status === 'planning' && (
            <>
              <Button
                size="xs"
                rightIcon={<FaArrowRight />}
                onClick={onTogglePlanVacation}
                lineHeight={1}
              >
                Planejar
              </Button>

              <Modal isOpen={isPlanVacationOpen} onClose={onTogglePlanVacation}>
                <ModalOverlay />

                <PlanVacation vacationId={vacation.id} />
              </Modal>
            </>
          )}

          {userCanPayVacation && vacation.status === 'paying' && (
            <>
              <Button
                size="xs"
                rightIcon={<FaArrowRight />}
                onClick={onTogglePayVacation}
                lineHeight={1}
              >
                Pagar
              </Button>

              <Modal isOpen={isPayVacationOpen} onClose={onTogglePayVacation}>
                <ModalOverlay />

                <PayVacation vacationId={vacation.id} />
              </Modal>
            </>
          )}

          {userCanProceedVacation && vacation.status === 'waiting' && isCurrentVacationPeriodEnableToProceed && (
            <>
              <Button
                size="xs"
                rightIcon={<FaArrowRight />}
                onClick={onToggleProceedVacation}
                lineHeight={1}
              >
                Proceder
              </Button>

              <Modal isOpen={isProceedVacationOpen} onClose={onToggleProceedVacation}>
                <ModalOverlay />

                <ProceedVacation vacationId={vacation.id} />
              </Modal>
            </>
          )}

          {userCanFinishVacationPeriod && vacation.status === 'on-vacation' && activeVacationPeriod?.with_pontomais_allowance === null && (
            <>
              <Button
                size="xs"
                rightIcon={<FaArrowRight />}
                onClick={onToggleFinishVacationPeriod}
                lineHeight={1}
              >
                Finalizar período
              </Button>

              <Modal isOpen={isFinishVacationPeriodOpen} onClose={onToggleFinishVacationPeriod}>
                <ModalOverlay />

                <FinishVacationPeriod vacationId={vacation.id} onClose={onToggleFinishVacationPeriod} />
              </Modal>
            </>
          )}

          {userCanDismissVacationCollaborator && (
            <Button
              size="xs"
              rightIcon={<FaTimes />}
              onClick={() => handleDismissVacationCollaborator(vacation.id)}
              lineHeight={1}
            >
              Demitir
            </Button>
          )}

          {userCanDeleteVacation && (
            <Button
              size="xs"
              rightIcon={<FaTrash />}
              onClick={() => handleDeleteVacation(vacation.id)}
              lineHeight={1}
              variant="ghost"
            >
              Excluir
            </Button>
          )}

        </HStack>

      </Td>
    </Tr>
  )
}
