import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface GetInDeliveryServicesResponse {
  services: {
    id: string
    protocol: number,
    customer: string
    vehicle: string
    source_addresses: string[],
    source_cities: string[],
    destination_addresses: {
      id: string
      address: string
    }[]
    going_delivery_addresses: {
      id: string
      address: string
    }[] | null
    delivered_addresses: string[] | null,
    destination_cities: string[],
    collect_hour_start: string
    collect_hour_end: string
    service_type: string
    board_hour: string
    shipping: string
    source_branch: string
    destination_branch: string
    delivery_date: string
    delivery_hour: string
    observations: string
    source_collector: string
    board_volume: number
    cte: number
    tracker: number
    step: string
    destination_collector: string
    source_tz: string
    destination_tz: string
    tracker_link: string | null
    has_occurrence: boolean
  }[]
  meta: {
    totalPages: number
  }
}

interface GetInDeliveryServicesQueryParams {
  pageSize: string
  currentPage: string
  protocol?: string | null
  customer?: string | null
  sourceAddress?: string | null
  destinationAddress?: string | null
  deliveryDate?: string | null
  deliveryHour?: string | null
  withdrawInfo?: string | null
  timezoneOffset: string
  steps: string
  sourceCollector?: string | null
  destinationCollector?: string | null
  collectorId?: string
}

export async function getInDeliveryServices({
  currentPage,
  pageSize,
  protocol,
  customer,
  sourceAddress,
  destinationAddress,
  deliveryDate,
  deliveryHour,
  withdrawInfo,
  timezoneOffset,
  steps,
  sourceCollector,
  destinationCollector,
  collectorId
}: GetInDeliveryServicesQueryParams) {
  try {
    const { data } = await api.get<GetInDeliveryServicesResponse>('/services/deliveries', {
      params: {
        currentPage,
        pageSize,
        protocol,
        customer,
        sourceAddress,
        destinationAddress,
        timezoneOffset,
        deliveryDate,
        deliveryHour,
        withdrawInfo,
        steps,
        sourceCollector,
        destinationCollector,
        collectorId
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
