import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getAdvertences } from "api/advertences/getAdvertences";
import { AdvertenceStatus } from "api/advertences/types/Advertence";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { FaPlus } from "react-icons/fa6";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { AdvertenceTableFilters } from "./components/AdvertenceTableFilters";
import { AdvertenceTableRow } from "./components/AdvertenceTableRow";
import { CreateAdvertence } from "./components/CreateAdvertence";


export function Advertences() {
  const searchParams = useSearchParams()

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanCreateAdvertence = userLogged?.permissions?.includes('create-advertence')

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status')

  const {
    data: advertencesResult
  } = useQuery({
    queryKey: ['advertences', page, status],
    queryFn: () => getAdvertences({
      currentPage: page,
      pageSize: '10',
      status: status as AdvertenceStatus | 'all'
    })
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: advertencesResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: advertencesResult?.meta?.pageSize
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  const {
    isOpen: isCreateAdvertenceModalOpen,
    onOpen: onOpenCreateAdvertenceModal,
    onClose: onCloseCreateAdvertenceModal
  } = useDisclosure()

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Advertências</Heading>
        {userCanCreateAdvertence && (
          <IconButton
            aria-label="Criar Advertência"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            onClick={onOpenCreateAdvertenceModal}
          />
        )}

        <Modal
          isOpen={isCreateAdvertenceModalOpen}
          onClose={onCloseCreateAdvertenceModal}
          isCentered
        >
          <ModalOverlay />
          <CreateAdvertence
            onCloseModal={onCloseCreateAdvertenceModal}
          />
        </Modal>
      </Flex>

      <AdvertenceTableFilters />

      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Nome</Th>
              <Th color="white">Regional</Th>
              <Th color="white">Data da advertência</Th>
              <Th color="white">Motivo da advertência</Th>
              <Th color="white">Status</Th>
              <Th></Th>

            </Tr>
          </Thead>
          <Tbody>
            {advertencesResult?.advertences.map((advertence) => {
              return (
                <AdvertenceTableRow
                  key={advertence.id}
                  advertence={advertence}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />

    </Box>
  )
}
