import { ChangeEvent, createContext, ReactNode, useState } from "react";

interface Filters {
  collector: string
  startDate: string
  endDate: string
}

interface CollectorExtrasDiscountsListContextProps {
  filters: Filters
  handleSetInputFilters: (event: ChangeEvent<HTMLInputElement>) => void
}

interface CollectorExtrasDiscountsListContextProviderProps {
  children: ReactNode
}

export const CollectorExtrasDiscountsListContext = createContext(
  {} as CollectorExtrasDiscountsListContextProps
)

export function CollectorExtrasDiscountsListContextProvider({
  children
}: CollectorExtrasDiscountsListContextProviderProps) {
  const [filters, setFilters] = useState({} as Filters)

  function handleSetInputFilters(event: ChangeEvent<HTMLInputElement>) {
    setFilters(prevFilters => {
      return {
        ...prevFilters,
        [event.target.name]: event.target.value
      }
    })
  }

  return (
    <CollectorExtrasDiscountsListContext.Provider value={{
      filters,
      handleSetInputFilters
    }}>
      {children}
    </CollectorExtrasDiscountsListContext.Provider>
  )
}
