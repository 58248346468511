import { Box, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { Sporadic } from "api/sporadics/_types/Sporadic";
import { format, formatDistanceToNowStrict, isFuture } from "date-fns";
import { FaCalendarAlt, FaExclamationCircle } from "react-icons/fa";
import { ptBR } from "date-fns/locale";
import { SporadicDetail } from "./SporadicDetail";
import { Regional } from "api/regionals/_types/Regional";

interface KanbanSporadicCardProps {
  sporadic: Sporadic & {
    regional: Regional
  }
}

export function KanbanSporadicCard({ sporadic }: KanbanSporadicCardProps) {

  const {
    isOpen: isSporadicDetailModalOpen,
    onToggle: onToggleSporadicDetailModal,
  } = useDisclosure()

  const sporadicFinalDate = new Date(sporadic?.finalDate);
  const formattedSporadicDate = format(sporadicFinalDate, "dd MMM", { locale: ptBR });

  const relativeDate = formatDistanceToNowStrict(sporadicFinalDate, { locale: ptBR });
  const isDateInFuture = isFuture(sporadicFinalDate);
  const relativeDateText = isDateInFuture ? `em ${relativeDate}` : `${relativeDate} atrás`;

  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onToggleSporadicDetailModal}
      cursor='pointer'
    >
      <Text fontWeight="bold" mb={2}>{sporadic.protocol}</Text>

      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">DATA INICIO REALIZAÇÃO</Text>
          </HStack>
          <Text ml={5}>{format(new Date(sporadic?.startDate), "dd/MM/yyyy '-' HH:mm")}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">DATA FIM REALIZAÇÃO</Text>
          </HStack>
          <Text ml={5}>{format(new Date(sporadic?.finalDate), "dd/MM/yyyy '-' HH:mm")}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">REGIONAL</Text>
          </HStack>
          <Text ml={5}>{sporadic?.regional?.name}</Text>
        </Box>

        {sporadic?.status === 'in-billing' && (
          <HStack spacing={2}>
            {isDateInFuture ? (
              <>
                <Icon as={FaCalendarAlt} />
                <Text fontWeight="bold">{`Venc ${formattedSporadicDate}`}</Text>
              </>
            ) : (
              <>
                <Icon as={FaExclamationCircle} color="red.500" />
                <Text color="red.500" fontWeight="bold">{`Venc ${formattedSporadicDate}`}</Text>
              </>
            )}
            <Text>{relativeDateText}</Text>
          </HStack>

        )}

      </VStack>

      <Modal
        isOpen={isSporadicDetailModalOpen}
        onClose={onToggleSporadicDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <SporadicDetail
          sporadicId={sporadic.id}
          onClose={onToggleSporadicDetailModal}
        />
      </Modal>
    </Box>
  )
}
