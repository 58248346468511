import { Box, Flex, IconButton, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react"
import { getPartnerSupport } from "api/partnerSupport/getPartnerSupport"
import { FaExternalLinkAlt } from "react-icons/fa"
import { useQuery } from "react-query"
import { PartnerSupportStatus } from "./PartnerSupportStatus"

interface PartnerSuportDetailProps {
  partnerSupportId: string
}

export function PartnerSuportDetail({ partnerSupportId }: PartnerSuportDetailProps) {

  const {
    data: partnerSupportResult,
    isLoading: isLoadingPartnerSupportResult
  } = useQuery({
    queryKey: ['partnerSupport', partnerSupportId],
    queryFn: () => getPartnerSupport({ partnerSupportId })
  })

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Detalhes do atendimento
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Box
          maxH="800px"
          overflowY="scroll"
          p={3}
        >
          <Table size="sm">
            <Tbody>
              {isLoadingPartnerSupportResult ? (
                Array.from({ length: 10 }).map((_, index) => {
                  return (
                    <Tr>
                      <Td>
                        <Skeleton w="full" h="16px" />
                      </Td>
                      <Td isNumeric>
                        <Skeleton w="full" h="16px" />
                      </Td>
                    </Tr>
                  )
                })
              ) : (
                <>
                  <Tr>
                    <Td>Tipo de atendimento</Td>
                    <Td isNumeric>{partnerSupportResult?.partnerSupport.type}</Td>
                  </Tr>
                  <Tr>
                    <Td>Coletador</Td>
                    <Td isNumeric>{partnerSupportResult?.partnerSupport?.collector?.trading_name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Descrição da solicitação</Td>
                    <Td isNumeric>{partnerSupportResult?.partnerSupport?.description}</Td>
                  </Tr>
                  {partnerSupportResult?.partnerSupport?.type === 'SOLICITAÇÃO DE ALTERAÇÃO DE DADOS CADASTRAIS/CONTA DE PAGAMENTO' && (
                    <>
                      <Tr>
                        <Td>Banco</Td>
                        <Td isNumeric>{partnerSupportResult?.partnerSupport?.bank}</Td>
                      </Tr>
                      <Tr>
                        <Td>Agência</Td>
                        <Td isNumeric>{partnerSupportResult?.partnerSupport?.agency}</Td>
                      </Tr>
                      <Tr>
                        <Td>Conta</Td>
                        <Td isNumeric>{`${String(partnerSupportResult.partnerSupport.account_number).slice(0, -1)}-${String(partnerSupportResult.partnerSupport.account_number).slice(-1)}`}</Td>
                      </Tr>
                    </>
                  )}
                  {partnerSupportResult?.partnerSupport?.requestAttachment && (
                    <Tr>
                      <Td>Anexo da solicitação</Td>
                      <Td isNumeric>
                        <IconButton
                          aria-label=""
                          as={Link}
                          href={partnerSupportResult?.partnerSupport.requestAttachment.link}
                          size="sm"
                          icon={<FaExternalLinkAlt />}
                        />
                      </Td>
                    </Tr>
                  )}
                  <Tr>
                    <Td>Status</Td>
                    <Td isNumeric>
                      <Flex justify="end">
                        <PartnerSupportStatus status={partnerSupportResult?.partnerSupport.status} />
                      </Flex>
                    </Td>
                  </Tr>
                  {partnerSupportResult?.partnerSupport?.attendanceResponsible && (
                    <Tr>
                      <Td>Responsável atendimento</Td>
                      <Td isNumeric>{partnerSupportResult?.partnerSupport?.attendanceResponsible?.firstname} {partnerSupportResult?.partnerSupport?.attendanceResponsible?.lastname}</Td>
                    </Tr>
                  )}
                  {partnerSupportResult?.partnerSupport?.attendance_description && (
                    <Tr>
                      <Td>Retorno do atendimento</Td>
                      <Td isNumeric>{partnerSupportResult?.partnerSupport?.attendance_description} </Td>
                    </Tr>
                  )}
                  {partnerSupportResult?.partnerSupport?.additive_checklist && (
                    <Tr>
                      <Td>Aditivo(s)</Td>
                      <Td isNumeric>
                        {partnerSupportResult?.partnerSupport?.additive_checklist.map(additive => {
                          return (
                            <Text fontSize="sm">{additive}</Text>
                          )
                        })}
                      </Td>
                    </Tr>
                  )}
                  {partnerSupportResult?.partnerSupport?.additiveAttachment && (
                    <Tr>
                      <Td>Anexo aditivo</Td>
                      <Td isNumeric>
                        <IconButton
                          aria-label=""
                          as={Link}
                          href={partnerSupportResult?.partnerSupport.additiveAttachment.link}
                          size="sm"
                          icon={<FaExternalLinkAlt />}
                        />
                      </Td>
                    </Tr>
                  )}
                  {partnerSupportResult?.partnerSupport?.conference_checklist && (
                    <Tr>
                      <Td>Conferência</Td>
                      <Td isNumeric>
                        {partnerSupportResult?.partnerSupport?.conference_checklist.map(conference => {
                          return (
                            <Text fontSize="sm">{conference}</Text>
                          )
                        })}
                      </Td>
                    </Tr>
                  )}
                </>
              )}

            </Tbody>
          </Table>
        </Box>
      </ModalBody>
    </ModalContent>
  )
}
