import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface DesignateExternalSupportBody {
  resolutionResponsibleId: string
  answerDeadline: string
}

interface DesignateExternalSupportProps {
  body: DesignateExternalSupportBody
  routeParams: {
    externalSupportId: string
  }
}
export async function designateExternalSupport({
  body,
  routeParams
}: DesignateExternalSupportProps){
  try {
    const response = await api.put(`/external-supports/${routeParams.externalSupportId}/designate`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
