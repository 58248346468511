import { Button, Flex, FormControl, FormLabel, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Radio, RadioGroup, Select, Stack, Text, Textarea } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup";
import { attendPartnerSupport } from "api/partnerSupport/attendPartnerSupport";
import { useToastify } from "hooks/toastify/useToastify";
import { useLLMUsers } from "hooks/user/useLLMUsers";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { captalize } from "utils/captalize";
import * as yup from "yup"

interface AttendPartnerSupportProps {
  onCloseModal: () => void
  partnerSupportId: string
}

interface AttendPartnerSupportSchema {
  attendanceResponsibleId: string
  attendanceDescription: string
  hasAdditive: string
}

const attendPartnerSupportSchema = yup.object({
  attendanceResponsibleId: yup.string().required(),
  attendanceDescription: yup.string().required(),
  hasAdditive: yup.string().required(),
})


export function AttendPartnerSupport({
  onCloseModal,
  partnerSupportId
}: AttendPartnerSupportProps) {

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const {
    data: llmUsersData
  } = useLLMUsers({
    queryParams: {
      situation: 'ATIVO'
    }
  })

  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<AttendPartnerSupportSchema>({
    resolver: yupResolver(attendPartnerSupportSchema)
  })

  const { mutateAsync: attendPartnerSupportFn } = useMutation({
    mutationFn: attendPartnerSupport,
    onSuccess() {
      queryClient.invalidateQueries(['partnerSupports'])
      onCloseModal()
    }
  })


  async function handleAttendPartnerSupport(values: AttendPartnerSupportSchema) {
    await promiseMessage(attendPartnerSupportFn({
      body: {
        ...values,
        hasAdditive: values.hasAdditive === 'yes'
      },
      routeParams: {
        partnerSupportId
      }
    }), 'Atendimento realizado!')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Atender suporte
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        as="form"
        w="full"
        onSubmit={handleSubmit(handleAttendPartnerSupport)}
      >

        <FormLabel fontSize="sm" mt={3}>
          Responsável
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <FormControl isInvalid={!!errors.attendanceResponsibleId}>
          <Select
            {...register('attendanceResponsibleId')}
            name="attendanceResponsibleId"
            placeholder="Selecione..."
            size="sm"
            rounded="md"
          >
            {llmUsersData?.users?.map((user) => {
              return (
                <option key={user.id} value={user.id}>{captalize(`${user.firstname} ${user.lastname}`)}</option>
              )
            })}
          </Select>
        </FormControl>
        <FormControl isInvalid={!!errors.attendanceDescription} mt={3}>
          <FormLabel fontSize="sm">
            Retorno do atendimento
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>

          <Textarea
            {...register("attendanceDescription")}
            name="attendanceDescription"
            size="sm"
            rounded="md"
          />
        </FormControl>
        <FormControl isInvalid={!!errors.hasAdditive} mt={3}>
          <FormLabel>
            Será gerado aditivo
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Controller
            name="hasAdditive"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Stack direction="row">
                  <Radio value="yes">Sim</Radio>
                  <Radio value="no">Não</Radio>

                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Salvar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>

  )
}
