import request from "axios"
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import { useAuth } from "hooks/auth/useAuth";
import { RequestError } from "utils/errors/RequestErrors";
import { useToastify } from "hooks/toastify/useToastify";
import { ExtraDiscount } from "hooks/extrasDiscounts/dtos/ExtrasDiscounts";
import { CollectorsExtrasDiscountsStatus } from "contexts/CollectorsExtrasDiscountsStatusContext";

interface CollectorsExtrasDiscountsQueryParams {
  status: CollectorsExtrasDiscountsStatus
  pageSize?: number
  currentPage?: number
  collector?: string
  startDate?: string
  endDate?: string
}

interface CollectorsExtrasDiscountsResponse {
  extrasDiscounts: ExtraDiscount[]
  totalPages: number
}

interface GetCollectorsExtrasDiscountsProps {
  queryParams?: CollectorsExtrasDiscountsQueryParams
}

async function getCollectorsExtrasDiscounts({
  queryParams
}: GetCollectorsExtrasDiscountsProps) {
  try {
    const { data } = await api.get(`/list/collectors/extras-discounts`, {
      params: {
        ...queryParams
      }
    })

    return data
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

type QueryOptions = UseQueryOptions<CollectorsExtrasDiscountsResponse, unknown, CollectorsExtrasDiscountsResponse, QueryKey>

interface UseCollectorsExtrasDiscountsOptions {
  queryKey?: QueryKey
  queryParams?: CollectorsExtrasDiscountsQueryParams
  queryOptions?: QueryOptions
}

export function useCollectorsExtrasDiscounts(
  options?: UseCollectorsExtrasDiscountsOptions
) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([
    options?.queryKey ?? 'collectorsExtrasDiscounts',
    { ...options?.queryParams }
  ], () => getCollectorsExtrasDiscounts({
    queryParams: options?.queryParams
  }), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}
