import { Button, FormControl, FormLabel, IconButton, Input, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger, Select, Skeleton, Spinner, Stack, Table, TableContainer, Tbody, Td, Textarea, Tr, useDisclosure } from "@chakra-ui/react"
import { editAdvertenceField } from "api/advertences/editAdvertenceField"
import { getAdvertence } from "api/advertences/getAdvertence"
import { GetAdvertencesResponse } from "api/advertences/getAdvertences"
import { format, set } from "date-fns"
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation"
import { useAuth } from "hooks/auth/useAuth"
import { useUsers } from "hooks/user/useUsers"
import { ChangeEvent } from "react"
import { FaEdit, FaExternalLinkAlt, FaFileImport } from "react-icons/fa"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { advertenceTypeMap, regionalTypeMap, userTypeMap } from "utils/advertenceMapppers"
import { captalize } from "utils/captalize"
import { AdvertenceStatus } from "./AdvertenceStatus"

interface AdvertenceDetailProps {
  advertenceId: string
}

export function AdvertenceDetail({ advertenceId }: AdvertenceDetailProps) {
  const { userLogged } = useAuth()

  const userCanEditAdvertence = userLogged?.permissions?.includes('edit-advertences')

  const {
    data: advertenceResult,
    isLoading: isLoadingAdvertenceResult
  } = useQuery({
    queryKey: ['advertence', advertenceId],
    queryFn: () => getAdvertence({ routeParams: { advertenceId } })
  })

  const { data: users, isLoading: isUsersLoading } = useUsers({
    queryParms: {
      situation: 'ATIVO'
    }
  })

  const queryClient = useQueryClient()

  const { mutateAsync: editAdvertenceFieldFn } = useMutation({
    mutationFn: editAdvertenceField,
    onSuccess: (_data, { routeParams, body }) => {
      queryClient.invalidateQueries({ queryKey: ['advertence', routeParams.advertenceId] })

      const cachedAdvertences = queryClient.getQueriesData<GetAdvertencesResponse>({
        queryKey: ['advertences']
      })

      cachedAdvertences.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          advertences: cachedValue.advertences.map(advertence => {
            if (advertence.id === routeParams.advertenceId) {
              advertence[body.field] = body.value

              return { ...advertence }
            }

            return advertence
          })
        })
      })
    }
  })


  const { mutation: uploadAdvertenceAttachmentFn } = useAttachmentMutation()

  const { mutation: uploadProofOfSendingAdvertenceAttachmentFn } = useAttachmentMutation()

  const {
    isOpen: isAdvertenceAttachmentPopoverOpen,
    onOpen: onAdvertenceAttachmentPopoverOpen,
    onClose: onAdvertenceAttachmentPopoverClose,
  } = useDisclosure();

  const {
    isOpen: isProofOfSendingAdvertenceAttachmentPopoverOpen,
    onOpen: onProofOfSendingAdvertenceAttachmentPopoverOpen,
    onClose: onProofOfSendingAdvertenceAttachmentPopoverClose,
  } = useDisclosure();

  async function handleUploadAdvertenceAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()
    formData.append('attachment', event.target.files[0])
    await uploadAdvertenceAttachmentFn.mutateAsync(formData, {
      onSuccess: (data) => {
        handleEditAdvertenceFieldFn({
          field: 'advertence_attachment_id',
          value: data?.attachment.id
        })
        onAdvertenceAttachmentPopoverClose()
      }
    })
  }

  async function handleUploadProofOfSendingAdvertenceAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()
    formData.append('attachment', event.target.files[0])
    await uploadProofOfSendingAdvertenceAttachmentFn.mutateAsync(formData, {
      onSuccess: (data) => {
        handleEditAdvertenceFieldFn({
          field: 'proof_of_sending_advertence_attachment_id',
          value: data?.attachment.id
        })
        onProofOfSendingAdvertenceAttachmentPopoverClose()
      }
    })
  }

  async function handleEditAdvertenceFieldFn({
    field,
    value
  }: {
    field: string
    value: string | boolean
  }) {
    await editAdvertenceFieldFn({
      body: {
        field,
        value
      },
      routeParams: {
        advertenceId
      }
    })
  }

  if (isLoadingAdvertenceResult) {
    return (
      <ModalContent>
        <ModalHeader>
          <Skeleton w="150px" />
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <Skeleton h="15px" />
          <Skeleton h="15px" />
          <Skeleton h="15px" />
          <Skeleton h="15px" />
          <Skeleton h="15px" />
        </ModalBody>
      </ModalContent>
    )
  }

  return (
    <ModalContent maxW='550px'>
      <ModalHeader>
        Detalhes da Advertência
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <TableContainer>
          <Table size="sm">
            <Tbody>
              <Tr>
                <Td>Tipo de usuário</Td>
                <Td isNumeric>
                  <Select
                    size="sm"
                    rounded="md"
                    onBlur={async (event) => {
                      if (event.target.value !== advertenceResult.advertence.user_type) {
                        await handleEditAdvertenceFieldFn({
                          field: 'user_type',
                          value: event.target.value
                        })
                      }
                    }}
                    defaultValue={advertenceResult.advertence.user_type}
                    isDisabled={!userCanEditAdvertence}
                  >
                    {Object.entries(userTypeMap).map(([key, value]) => {
                      return (
                        <option key={key} value={key}>{value}</option>
                      )
                    })}
                  </Select>
                </Td>
              </Tr>
              <Tr>
                <Td>Nome</Td>

                <Td isNumeric>
                  {isUsersLoading ? (
                    <Spinner />
                  ) : (
                    <Select
                      size="sm"
                      rounded="md"
                      onBlur={async (event) => {
                        if (event.target.value !== advertenceResult?.advertence?.user_id) {
                          await handleEditAdvertenceFieldFn({
                            field: 'user_id',
                            value: event.target.value
                          })
                        }
                      }}
                      defaultValue={advertenceResult?.advertence?.user_id}
                      isDisabled={!userCanEditAdvertence}
                    >
                      {users?.map((user) => {
                        return (
                          <option key={user.id} value={user.id}>{captalize(`${user.firstname} ${user.lastname}`)}</option>
                        )
                      })}
                    </Select>
                  )}

                </Td>
              </Tr>
              <Tr>
                <Td>Regional</Td>
                <Td isNumeric>
                  <Select
                    size="sm"
                    rounded="md"
                    onBlur={async (event) => {
                      if (event.target.value !== advertenceResult.advertence.regional) {
                        await handleEditAdvertenceFieldFn({
                          field: 'regional',
                          value: event.target.value
                        })
                      }
                    }}
                    defaultValue={advertenceResult.advertence.regional}
                    isDisabled={!userCanEditAdvertence}
                  >
                    {Object.entries(regionalTypeMap).map(([key, value]) => {
                      return (
                        <option key={key} value={key}>{value}</option>
                      )
                    })}
                  </Select>
                </Td>
              </Tr>
              <Tr>
                <Td>Data</Td>
                <Td isNumeric>
                  <Input

                    type="date"
                    size="sm"
                    rounded="md"
                    onBlur={async (event) => {
                      const [year, month, day] = event.target.value?.split('-').map(Number)
                      const value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

                      if (event.target.value !== advertenceResult.advertence.date) {
                        await handleEditAdvertenceFieldFn({
                          field: 'date',
                          value: value
                        })
                      }
                    }}
                    defaultValue={String(format(new Date(advertenceResult.advertence.date), 'yyyy-MM-dd'))}
                  />
                </Td>

              </Tr>
              <Tr>
                <Td>Status</Td>
                <Td display="flex" justifyContent="flex-end">
                  <AdvertenceStatus status={advertenceResult?.advertence.status} />
                </Td>
              </Tr>
              <Tr>
                <Td>Motivo da Advertência</Td>
                <Td isNumeric>
                  <Select
                    size="sm"
                    rounded="md"
                    onBlur={async (event) => {
                      if (event.target.value !== advertenceResult.advertence.advertence_type) {
                        await handleEditAdvertenceFieldFn({
                          field: 'advertence_type',
                          value: event.target.value
                        })
                      }
                    }}
                    defaultValue={advertenceResult.advertence.advertence_type}
                    isDisabled={!userCanEditAdvertence}
                  >
                    {Object.entries(advertenceTypeMap).map(([key, value]) => {
                      return (
                        <option key={key} value={key}>{value}</option>
                      )
                    })}
                  </Select>
                </Td>
              </Tr>
              <Tr>
                <Td>Descrição</Td>
                <Td isNumeric>
                  <Textarea
                    size="sm"
                    rounded="md"
                    onBlur={async (event) => {
                      if (event.target.value !== advertenceResult.advertence.description) {
                        await handleEditAdvertenceFieldFn({
                          field: 'description',
                          value: event.target.value
                        })
                      }
                    }}
                    defaultValue={advertenceResult.advertence.description}
                    isDisabled={!userCanEditAdvertence}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Advertência assinada? </Td>
                <Td isNumeric>
                  <Select
                    size="sm"
                    rounded="md"
                    onBlur={async (event) => {

                      if (event.target.value !== String(advertenceResult.advertence.signed_advertence ? 'yes' : 'no')) {
                        await handleEditAdvertenceFieldFn({
                          field: 'signed_advertence',
                          value: event.target.value === 'yes'
                        })
                      }
                    }}
                    defaultValue={advertenceResult.advertence.signed_advertence ? 'yes' : 'no'}
                    isDisabled={!userCanEditAdvertence}
                  >
                    <option value="yes">Sim</option>
                    <option value="no">Não</option>
                  </Select>
                </Td>
              </Tr>

              {advertenceResult?.advertence?.advertence_attachment_id && (
                <Tr >
                  <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">
                    Anexo da advertência
                  </Td>
                  <Td isNumeric>
                    <IconButton
                      aria-label="Abrir anexo"
                      as={Link}
                      href={advertenceResult?.advertence?.advertenceAttachment?.link}
                      icon={<FaExternalLinkAlt />}
                      size="sm"
                      isExternal
                    />

                    <Popover
                      isOpen={isAdvertenceAttachmentPopoverOpen}
                      onClose={onAdvertenceAttachmentPopoverClose}
                    >
                      <PopoverTrigger>

                        <IconButton
                          aria-label="Editar anexo"
                          icon={<FaEdit />}
                          size="sm"
                          ml='2'
                          isDisabled={!userCanEditAdvertence}
                          onClick={onAdvertenceAttachmentPopoverOpen}
                        />
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <Stack direction="column" w="full" mt="3">
                            <Button
                              as={FormLabel}
                              htmlFor="advertenceAttachmentId"
                              lineHeight="1"
                              leftIcon={<FaFileImport />}
                              size="sm"
                              w="min"
                              cursor="pointer"
                            >
                              Anexar Advertência
                            </Button>
                            <FormControl>
                              <Input
                                name="advertenceAttachmentId"
                                id="advertenceAttachmentId"
                                type="file"
                                hidden
                                onChangeCapture={handleUploadAdvertenceAttachment}
                              />
                            </FormControl>
                          </Stack>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Td>
                </Tr>
              )}
              {advertenceResult?.advertence?.proof_of_sending_advertence_attachment_id && (
                <Tr >
                  <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">
                    Anexo do comprovante de envio da advertência
                  </Td>
                  <Td isNumeric>
                    <IconButton
                      aria-label="Abrir anexo"
                      as={Link}
                      href={advertenceResult?.advertence?.proofOfSendingAdvertenceAttachment?.link}
                      icon={<FaExternalLinkAlt />}
                      size="sm"
                      isExternal
                    />
                    <Popover
                      isOpen={isProofOfSendingAdvertenceAttachmentPopoverOpen}
                      onClose={onProofOfSendingAdvertenceAttachmentPopoverClose}
                    >
                      <PopoverTrigger>
                        <IconButton
                          aria-label="Editar anexo"
                          icon={<FaEdit />}
                          size="sm"
                          ml={'2'}
                          isDisabled={!userCanEditAdvertence}
                          onClick={onProofOfSendingAdvertenceAttachmentPopoverOpen}
                        />
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <Stack direction="column" w="full" mt="3">
                            <Button
                              as={FormLabel}
                              htmlFor="proofOfSendingAdvertenceAttachmentId"
                              lineHeight="1"
                              leftIcon={<FaFileImport />}
                              size="sm"
                              w="min"
                              cursor="pointer"
                            >
                              Anexar comprovante
                            </Button>
                            <FormControl>
                              <Input
                                name="proofOfSendingAdvertenceAttachmentId"
                                id="proofOfSendingAdvertenceAttachmentId"
                                type="file"
                                hidden
                                onChangeCapture={handleUploadProofOfSendingAdvertenceAttachment}
                              />
                            </FormControl>
                          </Stack>

                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </ModalBody>
    </ModalContent>
  )
}

