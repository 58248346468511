import { Box, Flex } from "@chakra-ui/react";
import { PartnerSupportStatus as PartnerSupportStatusType } from "api/partnerSupport/_types/PartnerSupport";
import { partnerSupportStatusMap } from "utils/partnerSupportMappers";

interface PartnerSupportStatusProps {
  status: PartnerSupportStatusType;
}

export function PartnerSupportStatus({ status }: PartnerSupportStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {['attending', 'sending-additive', 'checking'].includes(status) && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {status === 'done' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}
      {partnerSupportStatusMap[status]}
    </Flex>
  )
}
