import { Box, Divider, Grid, GridItem, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, Table, Tbody, Td, Tr, } from "@chakra-ui/react";
import { getRefund } from "api/refunds/getRefund";
import { useAuth } from "hooks/auth/useAuth";

import { useQuery } from "react-query";
import { CompletRefund } from "./CompletRefund";
import { RefundDetailTable } from "./RefundDetailTable";

interface RefundDetailProps {
  refundId: string
}

export function RefundDetail({ refundId }: RefundDetailProps) {
  const { userLogged } = useAuth()

  const userCanCompleteRefund = userLogged?.permissions?.includes(
    'complete-refund'
  )

  const {
    data: refundResult,
    isLoading: isLoadingRefundResult
  } = useQuery({
    queryKey: ['refund', refundId],
    queryFn: () => getRefund({ refundId })
  })

  return (
    <ModalContent >
      <ModalHeader>
        Detalhes Reembolso
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        {isLoadingRefundResult ? (
          <Table size="sm">
            <Tbody>
              {Array.from({ length: 10 }).map((_, index) => {
                return (
                  <Tr>
                    <Td>
                      <Skeleton w="full" h="16px" />
                    </Td>
                    <Td isNumeric>
                      <Skeleton w="full" h="16px" />
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>

        ) : (
          <Grid
            templateColumns={
              refundResult?.refund.status === 'refunding'
                ? "1fr auto 1fr"
                : "1fr"
            }
            gap={6}
          >
            <GridItem>
              <RefundDetailTable
                refundResult={refundResult}
              />
            </GridItem>
            {userCanCompleteRefund && refundResult?.refund.status === 'refunding' && (
              <>
                <Box>
                  <Divider orientation="vertical" />
                </Box>
                <GridItem>
                  <CompletRefund
                    refundId={refundId} />
                </GridItem>
              </>
            )}

          </Grid>
        )}
      </ModalBody>
    </ModalContent>
  )
}
