import { useCollectors } from 'hooks/collector/useCollectors'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CreateRequestedUserForm } from '../../components/Form/RequestedUser/CreateRequestedUserForm'
import { DetailEditRequestedUserForm } from '../../components/Form/RequestedUser/DetailEditRequestedUserForm'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useAuth } from '../../hooks/auth/useAuth'
import { useCustomer } from '../../hooks/customer/useCustomer'
import { useDriver } from '../../hooks/driver/useDriver'
import { usePermission } from '../../hooks/permission/usePermission'
import { useProfile } from '../../hooks/profile/useProfile'
import { useRequestedUser } from '../../hooks/requestedUser/useRequestedUser'
import { useRequestedUserFunctions } from '../../hooks/requestedUser/useRequestedUserFunctions'
import { useSwal } from '../../hooks/swal/useSwal'
import { ProfileProps } from '../../utils/RequestFunctions/Profile/requestProfileFunctions'
import {
  CreateRequestedUserInputProps,
  EditRequestedUserInputProps,
  EditRequestedUserReqProps,
} from '../../utils/RequestFunctions/RequestedUser/requestRequestedUserFunctions'

interface IFormInputValues {
  user_type: string
  loglife_employee: string
  customer_id: string
  collector_id: string
  driver_id: string
  permissions: string[]
  email: string
  firstname: string
  lastname: string
  owner: string
  substitute_id: string
}

interface IQueryParams {
  id?: string
  slug: string
}

async function formatDataValuesToBackCreate(
  values: IFormInputValues,
  userId: string,
  profiles: ProfileProps[],
): Promise<CreateRequestedUserInputProps> {
  const permissions = profiles.find(
    (profile) => profile.user_type === values.user_type,
  )?.permissions

  const dataFormated = {
    ...values,
    loglife_employee: values.loglife_employee === 'SIM',
    collector_id: values.collector_id ? values.collector_id : null,
    customer_id: values.customer_id ? values.customer_id : null,
    driver_id: values.driver_id ? values.driver_id : null,
    permissions: permissions || [],
    owner: userId,
    substitute_id: values.substitute_id ? values?.substitute_id : null
  }

  return dataFormated
}

async function formatDataValuesToBackEdit(
  values: IFormInputValues,
  requestedUserOwnerId: string,
  profiles: ProfileProps[],
): Promise<EditRequestedUserInputProps> {
  const permissions = profiles.find(
    (profile) => profile.user_type === values.user_type,
  )?.permissions

  const dataFormated = {
    ...values,
    loglife_employee: values.loglife_employee === 'SIM',
    collector_id: values.collector_id ? values.collector_id : null,
    customer_id: values.customer_id ? values.customer_id : null,
    driver_id: values.driver_id ? values.driver_id : null,
    permissions: permissions || [],
    owner: requestedUserOwnerId,
    substitute_id: values.substitute_id ? values?.substitute_id : null
  }

  return dataFormated
}

export function CreateDetailEditRequestedUser() {
  const { confirmMessage, standardMessage } = useSwal()
  const { id, slug }: IQueryParams = useParams()
  const { push: redirectTo } = useHistory()
  const { userLogged } = useAuth()

  const {
    data: collectors, isLoading: isCollectorsLoading,
  } = useCollectors()
  const {
    customers: { data: customers, isLoading: isCustomerLoading },
  } = useCustomer(null, true)
  const {
    drivers: { data: drivers, isLoading: isDriversLoading },
  } = useDriver(null, true)
  const {
    permissions: { data: permissions, isLoading: isPermissionsLoading },
  } = usePermission(null, true)
  const {
    profiles: { data: profiles, isLoading: isProfilesLoading },
  } = useProfile(null, false)
  const {
    requestedUser: { data: requestedUser, isLoading: isRequestedUserLoading },
  } = useRequestedUser(id ?? null, false)
  const {
    createRequestedUser: {
      mutateAsync: createRequestedUser,
      isSuccess: isCreateRequestedUserSuccess,

    },
    editRequestedUser: {
      mutateAsync: editRequestedUser,
      isLoading: isEditRequestedUserLoading,
      isSuccess: isEditRequestedUserSuccess,
    },
  } = useRequestedUserFunctions()

  useEffect(() => {
    if (slug === 'adicionar') {
      if (!userLogged?.permissions?.includes('add-requested-user')) {
        return redirectTo('/')
      }
    }

    if (slug === 'editar') {
      if (!userLogged?.permissions?.includes('edit-requested-user')) {
        return redirectTo('/')
      }
    }

    if (slug === 'visualizar') {
      if (!userLogged?.permissions?.includes('view-requested-user')) {
        return redirectTo('/')
      }
    }
  }, [slug, userLogged, redirectTo])

  useEffect(() => {
    if (isCreateRequestedUserSuccess || isEditRequestedUserSuccess) {
      redirectTo('/usuarios-solicitados')
    }
  }, [isCreateRequestedUserSuccess, isEditRequestedUserSuccess, redirectTo])

  async function handleCreateRequestedUser(values: IFormInputValues) {
    const hasCreateRequestedUser = await confirmMessage({
      title: 'Deseja solicitar um usuário?',
    })

    if (hasCreateRequestedUser && profiles) {
      const createRequestedUserDataFormated =
        await formatDataValuesToBackCreate(values, userLogged?.id!, profiles)
      await createRequestedUser(createRequestedUserDataFormated)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  async function handleEditRequestedUser(values: IFormInputValues) {
    const hasCreateRequestedUser = await confirmMessage({
      title: 'Deseja editar um usuário solicitado?',
    })

    if (hasCreateRequestedUser && id && requestedUser && profiles) {
      const editRequestedUserDataFormated = await formatDataValuesToBackEdit(
        values,
        requestedUser?.owner,
        profiles,
      )
      const editRequestedUserResponseData: EditRequestedUserReqProps = {
        id,
        input: editRequestedUserDataFormated,
      }
      await editRequestedUser(editRequestedUserResponseData)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (
    isCollectorsLoading ||
    isCustomerLoading ||
    isDriversLoading ||
    isPermissionsLoading ||
    isProfilesLoading ||
    isRequestedUserLoading ||
    isEditRequestedUserLoading
  ) {
    return <GeneralContentLoading />
  }

  return (
    <>
      {slug === 'adicionar' && (
        <CreateRequestedUserForm
          collectors={collectors}
          customers={customers}
          drivers={drivers}
          permissions={permissions}
          profiles={profiles}
          submit={handleCreateRequestedUser}
        />
      )}
      {(slug === 'visualizar' || slug === 'editar') &&
        id &&
        requestedUser &&
        permissions &&
        profiles && (
          <DetailEditRequestedUserForm
            collectors={collectors}
            customers={customers}
            drivers={drivers}
            permissions={permissions}
            profiles={profiles}
            requestedUser={requestedUser}
            slug={slug}
            submit={handleEditRequestedUser}
          />
        )}
    </>
  )
}
