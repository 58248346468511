import { Collector } from 'hooks/collector/dtos/Collector'
import { CustomerProps } from '../../../contexts/CustomerContext'
import { UserProps } from '../../../contexts/UserContext'
import { api } from '../../../services/api'
import { IDriverProps } from '../../../services/getFunctions/driver/getDrivers'

export type RequestedUserProps = {
  id: string
  situation: string
  user_type: string
  loglife_employee: boolean
  customer_id: string | null
  collector_id: string | null
  driver_id: string | null
  permissions: string[]
  email: string
  firstname: string
  lastname: string
  owner: string
  ownerIDRequestedUser: UserProps
  customerIDRequestedUser: CustomerProps
  collectorIDRequestedUser: Collector
  driverIDRequestedUser: IDriverProps
  customers: string[]
  customersNames?: string[]
  substitute_id: string | null
}

export type CreateRequestedUserInputProps = {
  user_type: string
  loglife_employee: boolean
  customer_id: string | null
  collector_id: string | null
  driver_id: string | null
  permissions: string[]
  email: string
  firstname: string
  lastname: string
  owner: string
}

export type EditRequestedUserInputProps = CreateRequestedUserInputProps

export type EditRequestedUserReqProps = {
  id: string
  input: EditRequestedUserInputProps
}

async function getAllRequestedUserReqFunction() {
  try {
    const { data } = await api.get<RequestedUserProps[]>('/requested-user')

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function getOneRequestedUserReqFunction(id: string) {
  try {
    const { data } = await api.get<RequestedUserProps>(`/requested-user/${id}`)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function createRequestedUserReqFunction(
  input: CreateRequestedUserInputProps,
) {
  try {
    const { data } = await api.post('/requested-user', input)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function editRequestedUserReqFunction({
  id,
  input,
}: EditRequestedUserReqProps) {
  try {
    const { data } = await api.put(`/requested-user/${id}`, input)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function validateRequestedUserToCreateReqFunction(id: string) {
  try {
    const { data } = await api.put(`/requested-user/${id}`, {
      hasValidate: true,
    })

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function deleteRequestedUserReqFunction(id: string) {
  try {
    const { data } = await api.delete(`/requested-user/${id}`)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

export {
  getAllRequestedUserReqFunction,
  getOneRequestedUserReqFunction,
  createRequestedUserReqFunction,
  editRequestedUserReqFunction,
  validateRequestedUserToCreateReqFunction,
  deleteRequestedUserReqFunction,
}
