import { Regional } from "api/regionals/_types/Regional";
import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Vaccine, VaccineStatus } from "./vaccine";

interface GetVaccinesParams {
  pageSize: string
  currentPage: string
  status?: VaccineStatus | 'all' | null
  collaborator?: string | null
  timezoneOffset: string
}

export interface GetVaccinesResponse {
  vaccines: Array<Vaccine & { regional: Regional }>
  meta: {
    currentPage: number
    pageSize: number
    totalPages: number
  }
}

export async function getVaccines({
  pageSize,
  currentPage,
  status,
  collaborator,
  timezoneOffset
}: GetVaccinesParams) {
  try {
    const { data } = await api.get<GetVaccinesResponse>('/vaccines', {
      params: {
        pageSize,
        currentPage,
        status,
        collaborator,
        timezoneOffset
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

