import { createContext, ReactNode, useCallback, useState } from "react";

export enum AggregatesExtrasDiscountsStatusEnum {
  'requested' = 'Solicitado',
  'approved' = 'Aprovado',
  'effectivated' = 'Efetivado',
  'rejected' = 'Rejeitado'
}

export type AggregatesExtrasDiscountsStatus = keyof typeof AggregatesExtrasDiscountsStatusEnum

interface AggregatesExtrasDiscountsStatusContextProps {
  status: AggregatesExtrasDiscountsStatus
  handleChangeStatus: (status: AggregatesExtrasDiscountsStatus) => void
}

interface AggregatesExtrasDiscountsStatusContextProviderProps {
  children: ReactNode
}

export const AggregatesExtrasDiscountsStatusContext = createContext(
  {} as AggregatesExtrasDiscountsStatusContextProps
)

export function AggregatesExtrasDiscountsStatusProvider({
  children
}: AggregatesExtrasDiscountsStatusContextProviderProps) {
  const [status, setStatus] = useState<AggregatesExtrasDiscountsStatus>('requested')

  const handleChangeStatus = useCallback((status: AggregatesExtrasDiscountsStatus) => {
    setStatus(status)
  }, [])

  return (
    <AggregatesExtrasDiscountsStatusContext.Provider value={{
      status,
      handleChangeStatus
    }}>
      {children}
    </AggregatesExtrasDiscountsStatusContext.Provider>
  )
}
