import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getVaccines } from "api/vaccines/getVaccines";
import { VaccineStatus } from "api/vaccines/vaccine";
import { Pagination } from "components/Pagination/Pagination";

import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CreateVaccine } from "./components/CreateVaccine";
import { VaccinesTableFilters } from "./components/VaccinesTableFilters";
import { VaccinesTableRow } from "./components/VaccinesTableRow";

export function Vaccines() {

  const { replace, push: redirect } = useHistory()
  const searchParams = useSearchParams()

  const { userLogged } = useAuth()

  const userHasViewVaccinesPermission = userLogged?.permissions.includes('view-vaccines')

  const userHasCreateVaccinePermission = userLogged?.permissions.includes('create-vaccine')

  useEffect(() => {
    if (!userHasViewVaccinesPermission) {
      redirect('/')
    }
  }, [userHasViewVaccinesPermission, redirect])

  const page = searchParams.get('page') ?? '1'
  const collaborator = searchParams.get('collaborator')
  const status = searchParams.get('status') ?? 'all'

  const { data: vaccinesData } = useQuery({
    queryKey: ['vaccines',  page,
    collaborator,
    status],
    queryFn: () => getVaccines({
      currentPage: page,
      pageSize: '10',
      timezoneOffset: String(new Date().getTimezoneOffset() / 3),
      collaborator,
      status: status as VaccineStatus | 'all'
    }),
    keepPreviousData: true
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: vaccinesData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: vaccinesData?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }


  const {
    isOpen: isCreateVaccineModalOpen,
    onOpen: onOpenCreateVaccineModal,
    onClose: onCloseCreateVaccineModal
  } = useDisclosure()

  return (

      <Box
        bg="white"
        p="6"
        rounded="md"
      >
        <Flex
          align="center"
          justify="space-between"
        >
          <Heading letterSpacing="tight">Vacinas</Heading>
          {userHasCreateVaccinePermission && (
            <IconButton
              aria-label="Criar Vacina"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateVaccineModal}
            />
          )}
          <Modal
            isOpen={isCreateVaccineModalOpen}
            onClose={onCloseCreateVaccineModal}
            size="2xl"
            isCentered
          >
            <ModalOverlay />
            <CreateVaccine onCloseModal={onCloseCreateVaccineModal} />
          </Modal>
        </Flex>
        <VaccinesTableFilters />
        <TableContainer
          border="1px solid"
          borderColor="gray.200"
          rounded="md"
          mt="6"
        >
          <Table size="sm">
            <Thead>
              <Tr
                h="40px"
                bg="#38c3fa"
              >
                <Th></Th>
                <Th></Th>
                <Th color="white">Colaborador</Th>
                <Th color="white">Tipo de colaborador</Th>
                <Th color="white">Regional</Th>
                <Th color="white">Vacina</Th>
                <Th color="white">Status</Th>
                <Th color="white">Data próxima dose</Th>
                <Th></Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {vaccinesData?.vaccines?.map((vaccine) => {
                return (
                  <VaccinesTableRow
                    key={vaccine.id}
                    vaccine={vaccine}
                  />
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />
      </Box>

  )
}
