import { Button, Flex, Select } from "@chakra-ui/react";
import { ExternalSupportStatus } from "api/externalSupports/_types/ExternalSupport";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { reasonOptions } from "./CreateExternalSupport";

interface ExternalSupportTableFiltersSchema {
  status: ExternalSupportStatus | 'all'
  reason: string
}

export function ExternalSupportTableFilters() {
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const reason = searchParams.get('reason')
  const status = searchParams.get('status') ?? 'all'

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<ExternalSupportTableFiltersSchema>({
    defaultValues: {
      status: status as ExternalSupportStatus | 'all',
      reason: reason ?? ''
    }
  })

  async function handleFilterPartnerSupport(data: ExternalSupportTableFiltersSchema) {

    if ( data.reason) {
      searchParams.set('reason', data.reason)
    } else {
      searchParams.delete('reason')
    }
    if (data.status) {
      searchParams.set('status', data.status)
    } else {
      searchParams.delete('status')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    reset()
    searchParams.delete('reason')
    searchParams.delete('status')

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }


  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterPartnerSupport)}
    >
      <Select
        {...register('reason')}
        name="reason"
        size="sm"
        rounded="md"
        defaultValue=""
        w={["full", "full", "200px"]}
      >
        <option value="">Todos os motivos</option>
        {Object.entries(reasonOptions).map(([key, value]) => (
          <option key={key} value={value}>{value}</option>
        ))}
      </Select>

      <Select
        {...register("status")}
        defaultValue="all"
        size="sm"
        rounded="md"
        w={["full", "full", "200px"]}
      >
        <option value="all">Todos status</option>
        <option value="designating">Em Designação</option>
        <option value="resolving">Em Resolução</option>
        <option value="done">Concluído</option>
      </Select>

      <Button
        size="sm"
        leftIcon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={["full", "full", "min"]}
      >
        Filtrar resultados
      </Button>

      <Button
        size="sm"
        leftIcon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        onClick={handleRemoveFilters}
        w={["full", "full", "min"]}
      >
        Remover filtros
      </Button>

    </Flex>
  )
}
