import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface AttendPartnerSupportBody {
  attendanceResponsibleId: string
  attendanceDescription: string
  hasAdditive: boolean
}

interface AttendPartnerSupportProps {
  body: AttendPartnerSupportBody
  routeParams: {
    partnerSupportId: string
  }
}
export async function attendPartnerSupport({
  body,
  routeParams
}: AttendPartnerSupportProps){
  try {
    const response = await api.put(`/partner-supports/${routeParams.partnerSupportId}/attend`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
