import { Button, Flex, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Stack, Table, Tbody, Text, Textarea } from "@chakra-ui/react"
import { useToastify } from "hooks/toastify/useToastify"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { set } from "date-fns";
import { useMutation, useQueryClient } from "react-query";
import { useState } from "react";
import { AuditFieldAttachmentField } from "./AuditFieldAttachmentField";
import { completeFieldAudit } from "api/audits/completeFieldAudit";

interface AuditFieldProps {
  onClose: () => void
  auditId: string
}

export interface AuditFieldSchema {
  realizedDate: string
  collaboratorPhotoAttachmentId: FileList
  vehicleRearPhotoAttachmentId: FileList
  tertiaryPackingPhotoAttachmentId: FileList
  internChestPhotoAttachmentId: FileList
  cnhPhotoAttachmentId: FileList
  crlvPhotoAttachmentId: FileList
  lastOilChangeDate: string
  eletricSystem: string
  chestPatrimonyNumber: string
  thermicBoxesPatrimonyNumber: string
  status: string
  nonCompliance?: string
}

const auditFieldSchema = yup.object({
  realizedDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
  collaboratorPhotoAttachmentId: yup.mixed().test((value: FileList) => value.length > 0),
  vehicleRearPhotoAttachmentId: yup.mixed().test((value: FileList) => value.length > 0),
  tertiaryPackingPhotoAttachmentId: yup.mixed().test((value: FileList) => value.length > 0),
  internChestPhotoAttachmentId: yup.mixed().test((value: FileList) => value.length > 0),
  cnhPhotoAttachmentId: yup.mixed().test((value: FileList) => value.length > 0),
  crlvPhotoAttachmentId: yup.mixed().test((value: FileList) => value.length > 0),
  lastOilChangeDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
  eletricSystem: yup.string().required(),
  chestPatrimonyNumber: yup.string().required(),
  thermicBoxesPatrimonyNumber: yup.string().required(),
  status: yup.string().required(),
  nonCompliance: yup.string()
})

export const auditFieldAttachmentsFields = {
  'Foto do colaborador': {
    fieldname: 'collaboratorPhotoAttachmentId',
    attachmentId: undefined
  },
  'Foto da traseira da moto com placa': {
    fieldname: 'vehicleRearPhotoAttachmentId',
    attachmentId: undefined
  },
  'Foto da embalagem terciária': {
    fieldname: 'tertiaryPackingPhotoAttachmentId',
    attachmentId: undefined
  },
  'Foto interna do baú': {
    fieldname: 'internChestPhotoAttachmentId',
    attachmentId: undefined
  },
  'Foto da CNH': {
    fieldname: 'cnhPhotoAttachmentId',
    attachmentId: undefined
  },
  'Foto da CRLV': {
    fieldname: 'crlvPhotoAttachmentId',
    attachmentId: undefined
  },
}


export function AuditField({
  auditId,
  onClose
}: AuditFieldProps) {

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const [auditFieldAttachments, setAuditFieldAttachments] = useState(
    auditFieldAttachmentsFields
  )

  function handleSetAuditFieldAttachmentId(key: string, attachmentId: string) {
    const prevAuditFieldAttachments = { ...auditFieldAttachments }

    prevAuditFieldAttachments[key].attachmentId = attachmentId

    setAuditFieldAttachments(prevAuditFieldAttachments)
  }

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<AuditFieldSchema>({
    resolver: yupResolver(auditFieldSchema)
  })


  const { mutateAsync: completeFieldAuditFn } = useMutation({
    mutationFn: completeFieldAudit,
    onSuccess() {
      queryClient.invalidateQueries(['audits'])
      onClose()
    }
  })

  async function handleAuditField(values: AuditFieldSchema) {
    await promiseMessage(completeFieldAuditFn({
      body: {
        ...values,
        collaboratorPhotoAttachmentId: auditFieldAttachments['Foto do colaborador'].attachmentId,
        vehicleRearPhotoAttachmentId: auditFieldAttachments['Foto da traseira da moto com placa'].attachmentId,
        tertiaryPackingPhotoAttachmentId: auditFieldAttachments['Foto da embalagem terciária'].attachmentId,
        internChestPhotoAttachmentId: auditFieldAttachments['Foto interna do baú'].attachmentId,
        cnhPhotoAttachmentId: auditFieldAttachments['Foto da CNH'].attachmentId,
        crlvPhotoAttachmentId: auditFieldAttachments['Foto da CRLV'].attachmentId
      },
      auditId
    }), 'Auditoria realizada!')
  }
  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Auditoria de Campo
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleAuditField)}
        maxH='600px'
        overflowY='scroll'
      >
        <FormControl isInvalid={!!errors?.realizedDate} mt={3}>
          <FormLabel fontSize="sm">
            Data realizada
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('realizedDate')}
            name="realizedDate"
            type="date"
            size="sm"
            rounded="md"
            w={["full", "full", "sm"]}
          />
        </FormControl>
        <Table mt={3} size="sm">
          <Tbody>
            {Object.entries(auditFieldAttachmentsFields).map(([label, field]) => {
              return (
                <AuditFieldAttachmentField
                  key={label}
                  register={register}
                  errors={errors}
                  label={label}
                  fieldname={field.fieldname}
                  onSetAuditFieldAttachmentId={handleSetAuditFieldAttachmentId}
                />
              )
            })}
          </Tbody>
        </Table>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors?.lastOilChangeDate} >
              <FormLabel fontSize="sm">
                Última data da troca de óleo
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('lastOilChangeDate')}
                name="lastOilChangeDate"
                type="date"
                size="sm"
                rounded="md"
              />
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.eletricSystem}>
              <FormLabel fontSize="sm">
                Sistema elétrico
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Select
                {...register('eletricSystem')}
                name="eletricSystem"
                size="sm"
                rounded="md"
                placeholder="Selecione..."
              >
                <option value="Conforme">Conforme</option>
                <option value="Não Conforme">Não Conforme</option>
              </Select>
            </FormControl>
          </Stack>
        </Stack>

        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.chestPatrimonyNumber}>
              <FormLabel fontSize="sm">
                Número de patrimônio do baú
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('chestPatrimonyNumber')}
                name="chestPatrimonyNumber"
                size="sm"
                rounded="md"
              />
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.thermicBoxesPatrimonyNumber}>
              <FormLabel fontSize="sm">
                Número de patrimônio das caixas térmicas
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('thermicBoxesPatrimonyNumber')}
                name="thermicBoxesPatrimonyNumber"
                size="sm"
                rounded="md"
              />
            </FormControl>
          </Stack>
        </Stack>

        <FormControl isInvalid={!!errors.status} mt={3}>
          <FormLabel fontSize="sm">
            Status
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Select
            {...register('status')}
            name="status"
            size="sm"
            rounded="md"
            placeholder="Selecione..."
            w={["full", "full", "sm"]}
          >
            <option value="approved">Aprovado</option>
            <option value="reproved">Reprovado</option>
          </Select>
        </FormControl>
        <FormControl isInvalid={!!errors.nonCompliance} mt={3}>
          <FormLabel>
            Não conformidade
          </FormLabel>
          <Textarea
            {...register('nonCompliance')}
            placeholder="Descreva a não conformidade..."
            size='sm'
            rounded='md'
          />
        </FormControl>
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Auditar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
