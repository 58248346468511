
import axios from "axios";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { ExternalSupport } from "./_types/ExternalSupport";

interface GetExternalSupportResponse {
  externalSupport: ExternalSupport & {
    requestAttachment: Attachment
    resolutionAttachment: Attachment
  }
}

interface GetExternalSupportParams {
  externalSupportId: string
}

export async function getExternalSupport({
  externalSupportId
}: GetExternalSupportParams) {
  try {
    const response = await api.get<GetExternalSupportResponse>(`/external-supports/${externalSupportId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
