import { Button, Flex, FormControl, FormLabel, IconButton, Input, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Progress, Select, Stack, Table, Tbody, Td, Text, Textarea, Tr } from "@chakra-ui/react"
import { ExternalSupportReasons } from "api/externalSupports/_types/ExternalSupport"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { useForm, useWatch } from "react-hook-form";
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation, useQueryClient } from "react-query";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { ChangeEvent } from "react";
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa";
import { createExternalSupport } from "api/externalSupports/createExternalSupport";
import { isCpfValid } from "utils/cpfCnpjValidateFunctions";
import ReactInputMask from "react-input-mask";
import { handleSwitchMask } from "utils/handleChangeMask";

interface CreateExternalSupportProps {
  onCloseModal: () => void
}

interface CreateExternalSupportSchema {
  reason: ExternalSupportReasons
  requestDescription: string
  requestAttachmentId: FileList
  bank: string
  agency: string
  accountType: string
  accountOwner: string
  cpf: string
  accountNumber: string
}

const createExternalSupportSchema = yup.object({
  reason: yup.string().required(),
  requestDescription: yup.mixed().when('reason', {
    is: (value: ExternalSupportReasons) => value !== 'ALTERAÇÃO DE CONTA DE PAGAMENTO',
    then: yup.string().required(),
    otherwise: yup.string().nullable(),
  }),
  accountType: yup.mixed().when('reason', {
    is: 'ALTERAÇÃO DE CONTA DE PAGAMENTO',
    then: yup.string().required()
  }),
  accountOwner: yup.mixed().when('reason', {
    is: 'ALTERAÇÃO DE CONTA DE PAGAMENTO',
    then: yup.string().required()
  }),
  bank: yup.mixed().when('reason', {
    is: 'ALTERAÇÃO DE CONTA DE PAGAMENTO',
    then: yup.string().required()
  }),
  agency: yup.mixed().when('reason', {
    is: 'ALTERAÇÃO DE CONTA DE PAGAMENTO',
    then: yup.string().required()
  }),
  accountNumber: yup.mixed().when('reason', {
    is: 'ALTERAÇÃO DE CONTA DE PAGAMENTO',
    then: yup.string().required()
  }),
  cpf: yup.mixed().when('reason', {
    is: 'ALTERAÇÃO DE CONTA DE PAGAMENTO',
    then: yup.string().transform(isCpfValid).required(),
  }),
  requestAttachmentId: yup.mixed().when('type', {
    is: (type: string) => [
      'ALTERAÇÃO DE DADOS CADASTRAIS',
      'ENVIO DE ATESTADOS',
      'ENVIO DE DOCUMENTOS (CARTÃO VACINA, ETC)',
      'ENVIO DE REGISTRO DE LIMPEZA',
    ].includes(type),
    then: yup.mixed().test((value: FileList) => value.length > 0)
  }),
})

export const reasonOptions: ExternalSupportReasons[] = [
  'ALTERAÇÃO DE CONTA DE PAGAMENTO',
  'ALTERAÇÃO DE DADOS CADASTRAIS',
  'ENVIO DE ATESTADOS',
  'ENVIO DE DOCUMENTOS (CARTÃO VACINA, ETC)',
  'ENVIO DE REGISTRO DE LIMPEZA',
  'OUVIDORIA (DISQUE DENÚNCIA)',
  'RECLAMAÇÃO',
  'SANAR DÚVIDAS',
  'SOLICITAÇÃO DE ADIANTAMENTO',
  'SOLICITAÇÃO DE CONTRACHEQUE',
  'SOLICITAÇÃO DE MATERIAL (UNIFORMES, ADESIVOS, ETC)',
  'SUGESTÃO'
];

export function CreateExternalSupport({
  onCloseModal
}: CreateExternalSupportProps) {

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const { mutation: uploadRequestAttachmentFn, uploadProggress: uploadProggressRequestAttachment } = useAttachmentMutation()

  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<CreateExternalSupportSchema>({
    resolver: yupResolver(createExternalSupportSchema)
  })

  console.log(errors)

  const { mutateAsync: createExternalSupportFn } = useMutation({
    mutationFn: createExternalSupport,
    onSuccess: () => {
      queryClient.invalidateQueries(['externalSupports'])
      onCloseModal()
    }
  })

  const [requestAttachmentId, reason] = useWatch({
    control,
    name: ['requestAttachmentId', 'reason'],
  })


  const isPaymentAccountChange = reason === 'ALTERAÇÃO DE CONTA DE PAGAMENTO'
  console.log(isPaymentAccountChange)
  const typeRequiresAttachment = [
    'ALTERAÇÃO DE DADOS CADASTRAIS',
    'ENVIO DE ATESTADOS',
    'ENVIO DE DOCUMENTOS (CARTÃO VACINA, ETC)',
    'ENVIO DE REGISTRO DE LIMPEZA'
  ].includes(reason)

  async function handleUploadRequestAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadRequestAttachmentFn.mutateAsync(formData)

  }

  async function handleCreateExternalSupport(values: CreateExternalSupportSchema) {
    await promiseMessage(createExternalSupportFn({
      body: {
        reason: values.reason,
        ...(!isPaymentAccountChange && { requestDescription: values.requestDescription }),
        ...(typeRequiresAttachment && { requestAttachmentId: uploadRequestAttachmentFn?.data?.attachment?.id }),
        ...(isPaymentAccountChange && { accountOwner: values.accountOwner }),
        ...(isPaymentAccountChange && { bank: values.bank }),
        ...(isPaymentAccountChange && { accountType: values.accountType }),
        ...(isPaymentAccountChange && { agency: Number(values.agency.replace('-', '')) }),
        ...(isPaymentAccountChange && { accountNumber: Number(values.accountNumber.replace('-', '')) }),
        ...(isPaymentAccountChange && { cpf: values.cpf }),
      },

    }), 'Atendimento criado!')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Criar Atendimento
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        as="form"
        w="full"
        onSubmit={handleSubmit(handleCreateExternalSupport)}
      >
        <Stack
          mt={3}
          spacing={3}
          w="full"
          direction={{ base: 'column', lg: 'row' }}
        >
          <FormControl isInvalid={!!errors.reason} mt="3">
            <FormLabel fontSize="sm">
              Motivo do Contato
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register('reason')}
              name="reason"
              placeholder="Selecione..."
              size="sm"
              rounded="md"
            >
              {Object.entries(reasonOptions).map(([key, value]) => (
                <option key={key} value={value}>{value}</option>
              ))}
            </Select>

          </FormControl>
        </Stack>
        {isPaymentAccountChange && (
          <>
            <FormControl isInvalid={!!errors.accountOwner} mt={3}>
              <FormLabel fontSize="sm">
                Titular da conta
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('accountOwner')}
                name="accountOwner"
                size="sm"
                rounded="md"
              />
            </FormControl>
            <FormControl isInvalid={!!errors.bank} mt={3}>
              <FormLabel fontSize="sm">
                Banco
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('bank')}
                name="bank"
                size="sm"
                rounded="md"
              />
            </FormControl>
            <FormControl isInvalid={!!errors.accountType} mt={3}>
              <FormLabel fontSize="sm">
                Tipo de conta
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Select
                {...register("accountType")}
                name="accountType"
                placeholder="Selecione..."
                size="sm"
                rounded="md"
              >
                <option value='Conta corrente'>Conta corrente</option>
                <option value='Conta poupança'>Conta poupança</option>

              </Select>
            </FormControl>
            <FormControl isInvalid={!!errors.agency} mt={3}>
              <FormLabel fontSize="sm">
                Agência (com dígito)
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('agency')}
                name="agency"
                size="sm"
                rounded="md"
              />
            </FormControl>
            <FormControl isInvalid={!!errors.accountNumber} mt={3}>
              <FormLabel fontSize="sm">
                Conta (com dígito)
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('accountNumber')}
                name="accountNumber"
                size="sm"
                rounded="md"
              />
            </FormControl>
            <FormControl isInvalid={!!errors.cpf} mt={3}>
              <FormLabel fontSize="sm">
                CPF
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('cpf')}
                name="cpf"
                as={ReactInputMask as any}
                mask={handleSwitchMask('cpf')}
                size="sm"
                rounded="md"
              />
            </FormControl>
          </>
        )}
        {!isPaymentAccountChange && (
          <FormControl isInvalid={!!errors.requestDescription} mt={3}>
            <FormLabel fontSize="sm">
              Escreva aqui a sua solicitação
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Textarea
              {...register("requestDescription")}
              name="requestDescription"
              size="sm"
              rounded="md"
            />
          </FormControl>
        )}
        {typeRequiresAttachment && (
          <>
            <Stack
              direction="column"
              w="full"
              mt="3"
            >
              <Button
                as={FormLabel}
                htmlFor="requestAttachmentId"
                lineHeight="1"
                leftIcon={<FaFileImport />}
                size="sm"
                w="min"
                cursor="pointer"
                border={!!errors?.requestAttachmentId && '2px solid'}
                borderColor={(!!errors?.requestAttachmentId) && 'red.500'}
              >
                Anexar Documento
              </Button>
              <FormControl isInvalid={!!errors?.requestAttachmentId}>
                <Input
                  {...register('requestAttachmentId')}
                  name="requestAttachmentId"
                  id="requestAttachmentId"
                  type="file"
                  hidden
                  onChangeCapture={handleUploadRequestAttachment}
                />
              </FormControl>
            </Stack>

            {requestAttachmentId && (
              <Table size="sm">
                <Tbody>
                  {Object.entries(requestAttachmentId).map(([key, file]) => {
                    return (
                      <Tr key={key}>
                        <Td fontSize="xs" maxW="100px">{file.name}</Td>
                        <Td fontSize="xs" w="200px">
                          <Progress size="sm" rounded="md" value={uploadProggressRequestAttachment} />
                        </Td>
                        <Td fontSize="xs" isNumeric>
                          {uploadRequestAttachmentFn.data && (
                            <IconButton
                              aria-label="Visualizar anexo"
                              as={Link}
                              size="sm"
                              icon={<FaExternalLinkAlt />}
                              href={uploadRequestAttachmentFn?.data?.attachment.link}
                              isExternal
                            />
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            )}
          </>
        )}
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Criar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
