import { Tr, Td, IconButton, Modal, ModalOverlay, Button, useDisclosure } from "@chakra-ui/react"
import { Regional } from "api/regionals/_types/Regional"
import { deleteTraining } from "api/trainings/deleteTraining"
import { GetTrainingsResponse } from "api/trainings/getTrainings"
import { inactivateTraining } from "api/trainings/inactivateTraining"
import { Training } from "api/trainings/training"
import { format } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"
import { useToastify } from "hooks/toastify/useToastify"
import { FaFileImport, FaSearch, FaTimes } from "react-icons/fa"
import { FaTrash } from "react-icons/fa6"
import { useMutation, useQueryClient } from "react-query"
import { trainingCollaboratorTypeMap, trainingTypesMap } from "utils/trainingMappers"
import { SendTrainingAtttachments } from "./SendTrainingAttachments"

import { TrainingDetail } from "./TrainingDetail"
import { TrainingStatus } from "./TrainingStatus"

interface TrainingTableRowProps {
  training: Training & {
    regional: Regional | null
  }
}

export function TrainingsTableRow({ training }: TrainingTableRowProps) {
  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()

  const userHasInactivateTraingingPermission = userLogged?.permissions.includes('inactivate-training')
  const userCanDeleteTraining = userLogged?.permissions.includes('delete-training')
  const userHasSendTrainingAttachmentsPermission = userLogged?.permissions.includes('send-training-attachment')

  const {
    isOpen: isDetailModalOpen,
    onOpen: onOpenDetailModal,
    onClose: onCloseDetailModal,
  } = useDisclosure()

  const {
    isOpen: isSendAttachmentsModalOpen,
    onOpen: onOpenSendAttachmentsModal,
    onClose: onCloseSendAttachmentsModal,
  } = useDisclosure()

  const queryClient = useQueryClient()

  const { mutateAsync: inactivateTrainingFn } = useMutation({
    mutationFn: inactivateTraining,
    onSuccess(_data, { trainingId }) {
      const cachedTraining = queryClient.getQueriesData<GetTrainingsResponse>({
        queryKey: ['trainings']
      })

      cachedTraining.forEach(([cachedKey, cachedData]) => {

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          trainings: cachedData.trainings.map((training) => {
            if (training.id === trainingId) {
              return {
                ...training,
                status: 'inactive',
                inactivated_at: new Date().toISOString(),
              }
            }

            return training
          })
        })
      })
    },
  })

  async function handleInactivateTraining() {
    await promiseMessage(inactivateTrainingFn({
      trainingId: training.id
    }), 'Treinamento inativado!')
  }

  const { mutateAsync: deleteTrainingFn } = useMutation({
    mutationFn: deleteTraining,
    onSuccess(_data, { trainingId }) {
      const cachedTraining = queryClient.getQueriesData<GetTrainingsResponse>({
        queryKey: ['trainings']
      })

      cachedTraining.forEach(([cachedKey, cachedData]) => {

        if (!cachedData) return

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          trainings: cachedData.trainings.filter(
            (training) => training.id !== trainingId
          )
        })
      })
    },
  })

  async function handleDeleteTraining(trainingId: string) {
    await promiseMessage(deleteTrainingFn({ trainingId }), 'Treinamento excluído com sucesso!')
  }

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar Treinamento"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onOpenDetailModal}
        />

        <Modal
          isOpen={isDetailModalOpen}
          onClose={onCloseDetailModal}
          isCentered
        >
          <ModalOverlay />

          <TrainingDetail trainingId={training.id} />

        </Modal>
      </Td>
      <Td>{training.collaborator_name}</Td>
      <Td>{trainingCollaboratorTypeMap[training.collaborator_type]}</Td>
      <Td>{training?.regional ? training?.regional.code.toUpperCase() : '-'}</Td>
      <Td>{trainingTypesMap[training.type]}</Td>
      <Td>{training.due_date ? format(new Date(training.due_date), 'dd/MM/yyyy') : '-'}</Td>
      <Td>{training.realization_date ? format(new Date(training.realization_date), 'dd/MM/yyyy') : '-'}</Td>
      <Td>
        <TrainingStatus status={training.status} />
      </Td>
      <Td>
        {(training.status === 'pending' && userHasSendTrainingAttachmentsPermission) && (
          <Button
            leftIcon={<FaFileImport />}
            lineHeight="1"
            size="xs"
            variant="outline"
            onClick={onOpenSendAttachmentsModal}
          >
            Anexar
          </Button>
        )}

        <Modal
          isOpen={isSendAttachmentsModalOpen}
          onClose={onCloseSendAttachmentsModal}
          isCentered
        >
          <ModalOverlay />

          <SendTrainingAtttachments trainingId={training.id} onCloseModal={onCloseSendAttachmentsModal} />
        </Modal>
      </Td>
      <Td>
        {(training.status !== 'inactive' && userHasInactivateTraingingPermission) && (
          <Button
            leftIcon={<FaTimes />}
            lineHeight="1"
            size="xs"
            variant="ghost"
            onClick={handleInactivateTraining}
          >
            Inativar
          </Button>
        )}
        {userCanDeleteTraining && (
          <Button
            leftIcon={<FaTrash />}
            lineHeight="1"
            size="xs"
            variant="ghost"
            colorScheme="red"
            onClick={() => handleDeleteTraining(training.id)}
          >
            Excluir
          </Button>
        )}
      </Td>
    </Tr>
  )
}

