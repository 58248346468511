import { Button, IconButton, Modal, ModalOverlay, Popover, PopoverTrigger, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { GetOnboardingsResponse } from "api/onboardings/getOnboardings";
import { inactivateOnboarding } from "api/onboardings/inactivateOnboarding";
import { inviteToOnboarding } from "api/onboardings/inviteToOnboarding";
import { Onboarding } from "api/onboardings/onboarding";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { FaArrowRight, FaSearch, FaTimes } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { InviteOnboardingPopover } from "./InviteOnboardingPopover";
import { OnboardingDetail } from "./OnboardingDetail";
import { OnboardingStatus } from "./OnboardingStatus";
import { TestingOnboarding } from "./TestingOnboarding";
import { TrainingOnboarding } from "./TrainingOnboarding";


interface OnboardingTableRowProps {
  onboarding: Onboarding
}
export function OnboardingTableRow({ onboarding }: OnboardingTableRowProps) {
  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const userCanInviteToOnboarding = userLogged?.permissions.includes('invite-to-onboarding')
  const userCanFinishTrainingOnboarding = userLogged?.permissions.includes('finish-training-onboarding')
  const userCanValidateTestOnboarding = userLogged?.permissions.includes('validate-test-onboarding')
  const userHasInactivateOnboardingPermission = userLogged?.permissions.includes('inactivate-onboarding')

  const { mutateAsync: inactivateOnboardingFn } = useMutation({
    mutationFn: inactivateOnboarding,
    onSuccess(_data, { onboardingId }) {
      const cachedOnboardings = queryClient.getQueriesData<GetOnboardingsResponse>({
        queryKey: ['onboardings']
      })
      queryClient.invalidateQueries(['onboardings'])
      cachedOnboardings.forEach(([cachedKey, cachedData]) => {

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          onboardings: cachedData.onboardings.map((onboarding) => {
            if (onboarding.id === onboardingId) {
              return {
                ...onboarding,
                status: 'inactivate',
                inactivated_at: new Date().toISOString(),
              }
            }

            return onboarding
          })
        })
      })
    },
  })

  const { mutateAsync: inviteToOnboardingFn } = useMutation({
    mutationFn: inviteToOnboarding,
    onSuccess(_data, { onboardingId }) {
      const cachedOnboardings = queryClient.getQueriesData<GetOnboardingsResponse>({
        queryKey: ['onboardings']
      })
      queryClient.invalidateQueries(['onboardings'])
      cachedOnboardings.forEach(([cachedKey, cachedData]) => {

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          onboardings: cachedData.onboardings.map((onboarding) => {
            if (onboarding.id === onboardingId) {
              return {
                ...onboarding,
                status: 'inviting',
              }
            }

            return onboarding
          })
        })
      })
    },
  })


  const {
    isOpen: isDetailModalOpen,
    onOpen: onOpenDetailModal,
    onClose: onCloseDetailModal,
  } = useDisclosure()

  const {
    isOpen: isTrainingOnboardingModalOpen,
    onOpen: onOpenTrainingOnboardingModal,
    onClose: onCloseTrainingOnboardingModal,
  } = useDisclosure()

  const {
    isOpen: isTestingOnboardingModalOpen,
    onOpen: onOpenTestingOnboardingModal,
    onClose: onCloseTestingOnboardingModal,
  } = useDisclosure()

  const {
    isOpen: isInviteOnboardingPopoverOpen,
    onOpen: onOpenInviteOnboardingPopover,
    onClose: onCloseInviteOnboardingPoporver
  } = useDisclosure()

  async function handleInactivateOnboarding() {
    await promiseMessage(inactivateOnboardingFn({
      onboardingId: onboarding.id
    }), 'Onboarding inativado!')
  }

  async function handleInviteOnboarding(onboardingId: string) {
    await promiseMessage(inviteToOnboardingFn({
      onboardingId: onboardingId
    }), 'Convite onboarding enviado!')
  }

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar Onboarding"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onOpenDetailModal}
        />

        <Modal
          isOpen={isDetailModalOpen}
          onClose={onCloseDetailModal}
          isCentered
        >
          <ModalOverlay />

          <OnboardingDetail onboardingId={onboarding.id} />

        </Modal>
      </Td>
      <Td>{onboarding?.name}</Td>
      <Td>{onboarding?.training_type}</Td>
      <Td>{onboarding?.cpf}</Td>
      <Td>{onboarding?.email}</Td>
      <Td>
        <OnboardingStatus status={onboarding.status} />
      </Td>
      <Td>
        {onboarding?.status === 'inviting' && userCanInviteToOnboarding && (
        <>
          {onboarding.training_type.includes('LLM') ? (
            <Popover
              onOpen={onOpenInviteOnboardingPopover}
              isOpen={isInviteOnboardingPopoverOpen}
              onClose={onCloseInviteOnboardingPoporver}
              defaultIsOpen={false}
              autoFocus={false}
            >
              <PopoverTrigger>
                <Button
                  lineHeight="1"
                  leftIcon={<FaArrowRight />}
                  size="xs"
                  variant="outline"
                >
                  Convidar
                </Button>
              </PopoverTrigger>

              <InviteOnboardingPopover
                onboardingId={onboarding.id}
                onClose={onCloseInviteOnboardingPoporver}
              />

            </Popover>
          ) : (
            <Button
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              size="xs"
              variant="outline"
              onClick={() => handleInviteOnboarding(onboarding?.id)}
            >
              Convidar
            </Button>
          )}
        </>
        )}
        {onboarding.status === 'training' && userCanFinishTrainingOnboarding && (
          <>
            <Modal
              size="2xl"
              isOpen={isTrainingOnboardingModalOpen}
              onClose={onCloseTrainingOnboardingModal}>
              <ModalOverlay />

              <TrainingOnboarding
                onboardingId={onboarding.id}
                onCloseModal={onCloseTrainingOnboardingModal}
                isTrainingLlm={onboarding.training_type.includes('llm')}
              />

            </Modal>
            <Button
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              size="xs"
              onClick={onOpenTrainingOnboardingModal}
            >
              Finalizar
            </Button>
          </>
        )}
        {onboarding.status === 'testing' && userCanValidateTestOnboarding && (
          <>
            <Modal
              size="2xl"
              isOpen={isTestingOnboardingModalOpen}
              onClose={onCloseTestingOnboardingModal}>
              <ModalOverlay />

              <TestingOnboarding
                onboardingId={onboarding.id}
                onCloseModal={onCloseTestingOnboardingModal}
              />

            </Modal>
            <Button
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              size="xs"
              onClick={onOpenTestingOnboardingModal}
            >
              Validar
            </Button>
          </>
        )}
      </Td>

      <Td>
        {(onboarding.status !== 'inactive' && userHasInactivateOnboardingPermission) && (
          <Button
            leftIcon={<FaTimes />}
            lineHeight="1"
            size="xs"
            variant="ghost"
            onClick={handleInactivateOnboarding}
          >
            Inativar
          </Button>
        )}
      </Td>
    </Tr >
  )
}
