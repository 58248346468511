import axios from "axios"
import { useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";
import { AggregateReport } from "./dtos/AggregateReport";
import { ExtraDiscount } from "hooks/extrasDiscounts/dtos/ExtrasDiscounts";

interface AggregatesReportQueryParams {
  driver_id: string
  start_filter: string
  end_filter: string
}

export interface AggregateReportResponse {
  aggregateReportData: AggregateReport[],
  extrasDiscounts: ExtraDiscount[]
}

type QueryOptions<T = AggregateReport[]> = UseQueryOptions<AggregateReportResponse, unknown, T, ["aggregatesReport"]>

interface UseAggregatesReportOptions<T> {
  queryOptions?: QueryOptions<T>
  queryParams?: AggregatesReportQueryParams
}

async function generateAggregatesReportReqFunction({
  driver_id,
  start_filter,
  end_filter
}: AggregatesReportQueryParams) {
  try {

    const { data } = await api.get(`/report/aggregates`, {
      params: {
        driver_id,
        start_filter,
        end_filter
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError(error.response.data.message)
    }
  }
}

export function useAggregatesReport<T>(options?: UseAggregatesReportOptions<T>) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    errorMessage(message)

    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) await handleLogout()

  }

  return useQuery([
    "aggregatesReport",
    options.queryParams.driver_id,
    options.queryParams.start_filter,
    options.queryParams.end_filter
  ], () => generateAggregatesReportReqFunction(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: onRequestError
  }
  )
}
