import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getTrainings } from "api/trainings/getTrainings";
import { TrainingStatus } from "api/trainings/training";
import { Pagination } from "components/Pagination/Pagination";

import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CreateTraining } from "./components/CreateTraining";
import { TrainingsTableFilter } from "./components/TrainingsTableFilter";
import { TrainingsTableRow } from "./components/TrainingsTableRow";

export function Trainings() {
  const { replace, push: redirect } = useHistory()
  const searchParams = useSearchParams()

  const { userLogged } = useAuth()

  const userHasViewTrainingsPermission = userLogged?.permissions.includes('view-trainings')

  const userHasCreateTrainingPermission = userLogged?.permissions.includes('create-training')

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status') ?? 'all'
  const collaborator = searchParams.get('collaborator')

  const { data: trainingsData } = useQuery({
    queryKey: [
      'trainings',
      page,
      status,
      collaborator,
    ],
    queryFn: () => getTrainings({
      currentPage: page,
      pageSize: '10',
      collaborator,
      status: status as TrainingStatus | 'all'
    }),
    keepPreviousData: true
  })

  useEffect(() => {
    if (!userHasViewTrainingsPermission) {
      redirect('/')
    }
  }, [userHasViewTrainingsPermission, redirect])


  const {
    isOpen: isCreateTrainingModalOpen,
    onOpen: onOpenCreateTrainingModal,
    onClose: onCloseCreateTrainingModal
  } = useDisclosure()

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: trainingsData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: trainingsData?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  return (

    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Treinamentos</Heading>
        {userHasCreateTrainingPermission && (
          <IconButton
            aria-label="Criar Vacina"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            onClick={onOpenCreateTrainingModal}
          />
        )}
        <Modal
          isOpen={isCreateTrainingModalOpen}
          onClose={onCloseCreateTrainingModal}
          size="2xl"
          isCentered
        >
          <ModalOverlay />
          <CreateTraining onCloseModal={onCloseCreateTrainingModal} />
        </Modal>
      </Flex>
      <TrainingsTableFilter />
      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Colaborador</Th>
              <Th color="white">Tipo Colaborador</Th>
              <Th color="white">Regional</Th>
              <Th color="white">Treinamento</Th>
              <Th color="white">Data de Vencimento</Th>
              <Th color="white">Data da Realização</Th>
              <Th color="white">Status</Th>
              <Th color="white">Tipo</Th>

              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>

            {trainingsData?.trainings?.map((training) => {
              return (
                <TrainingsTableRow training={training} />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>



  )
}
