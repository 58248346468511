import { Button, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { Standardization } from "api/standardizations/standardization";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { FaArrowRight, FaSearch } from "react-icons/fa";
import { StandardizationDetail } from "./StandardizationDetail";
import { StandardizationStatus } from "./StandardizationStatus";
import { ValidateStandardization } from "./ValidateStandardization";

interface StandardizationTableRowProps {
  standardization: Standardization
}
export function StandardizationTableRow({ standardization }: StandardizationTableRowProps) {
  const { userLogged } = useAuth()

  const userCanValidateStandardization = userLogged?.permissions.includes('validate-standardization')

  const {
    isOpen: isDetailModalOpen,
    onOpen: onOpenDetailModal,
    onClose: onCloseDetailModal,
  } = useDisclosure()

  const {
    isOpen: isValidateModalOpen,
    onOpen: onOpenValidateModal,
    onClose: onCloseValidateModal,
  } = useDisclosure()

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar Padronização"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onOpenDetailModal}
        />

        <Modal
          isOpen={isDetailModalOpen}
          onClose={onCloseDetailModal}
          isCentered
        >
          <ModalOverlay />

          <StandardizationDetail standardizationId={standardization.id} />

        </Modal>
      </Td>
      <Td>{standardization?.name}</Td>
      <Td>{standardization?.hub?.name}</Td>
      <Td>{format(new Date(standardization.sended_at), 'dd/MM/yyyy')}</Td>
      <Td>
        <StandardizationStatus status={standardization.status} />
      </Td>
      <Td>
        {standardization?.status === 'pending' && userCanValidateStandardization && (
          <>
            <Modal size="2xl" isOpen={isValidateModalOpen} onClose={onCloseValidateModal}>
              <ModalOverlay />

              <ValidateStandardization standardizationId={standardization.id} />

            </Modal>
            <Button
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              size="xs"
              onClick={onOpenValidateModal}
            >
              Validar
            </Button>
          </>
        )}
      </Td>
    </Tr>
  )
}
