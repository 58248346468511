import { Button, Flex, FormControl, FormLabel, IconButton, Input, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Progress, Stack, Table, Tbody, Td, Text, Textarea, Tr } from "@chakra-ui/react"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { resolveExternalSupport } from "api/externalSupports/resolveExternalSupport";
import { useToastify } from "hooks/toastify/useToastify";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { ChangeEvent } from "react";
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa";

interface ResolveExternalSupportProps {
  onCloseModal: () => void
  externalSupportId: string
}

interface ResolveExternalSupportSchema {
  resolution: string
  resolutionAttachmentId: FileList
}

const resolveExternalSupportSchema = yup.object({
  resolution: yup.string().required(),

})

export function ResolveExternalSupport({
  externalSupportId,
  onCloseModal
}: ResolveExternalSupportProps) {

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const { mutation: uploadResolutionAttachmentFn, uploadProggress: uploadProggressResolutionAttachment } = useAttachmentMutation()

  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<ResolveExternalSupportSchema>({
    resolver: yupResolver(resolveExternalSupportSchema)
  })

  const [resolutionAttachmentId] = useWatch({
    control,
    name: ['resolutionAttachmentId'],
  })

  async function handleUploadResolutiontAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadResolutionAttachmentFn.mutateAsync(formData)

  }

  const { mutateAsync: resolveExternalSupportFn } = useMutation({
    mutationFn: resolveExternalSupport,
    onSuccess() {
      queryClient.invalidateQueries(['externalSupports'])
      onCloseModal()
    }
  })

  async function handleResoveExternalSupport(values: ResolveExternalSupportSchema) {
    await promiseMessage(resolveExternalSupportFn({
      body: {
        resolution: values.resolution,
        ...(resolutionAttachmentId && { resolutionAttachmentId: uploadResolutionAttachmentFn?.data?.attachment?.id })
      },
      routeParams: {
        externalSupportId
      }
    }), 'Designação realizada!')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Resolução de atendimento
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        as="form"
        w="full"
        onSubmit={handleSubmit(handleResoveExternalSupport)}
      >
        <FormControl isInvalid={!!errors.resolution} mt={3}>
          <FormLabel fontSize="sm">
            Resolução
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>

          <Textarea
            {...register("resolution")}
            name="resolution"
            size="sm"
            rounded="md"
          />
        </FormControl>
        <Stack
          direction="column"
          w="full"
          mt="3"
        >
          <Button
            as={FormLabel}
            htmlFor="resolutionAttachmentId"
            lineHeight="1"
            leftIcon={<FaFileImport />}
            size="sm"
            w="min"
            cursor="pointer"
            border={!!errors?.resolutionAttachmentId && '2px solid'}
            borderColor={(!!errors?.resolutionAttachmentId) && 'red.500'}
          >
            Anexar Documento
          </Button>
          <FormControl isInvalid={!!errors?.resolutionAttachmentId}>
            <Input
              {...register('resolutionAttachmentId')}
              name="resolutionAttachmentId"
              id="resolutionAttachmentId"
              type="file"
              hidden
              onChangeCapture={handleUploadResolutiontAttachment}
            />
          </FormControl>
        </Stack>

        {resolutionAttachmentId && (
          <Table size="sm">
            <Tbody>
              {Object.entries(resolutionAttachmentId).map(([key, file]) => {
                return (
                  <Tr key={key}>
                    <Td fontSize="xs" maxW="100px">{file.name}</Td>
                    <Td fontSize="xs" w="200px">
                      <Progress size="sm" rounded="md" value={uploadProggressResolutionAttachment} />
                    </Td>
                    <Td fontSize="xs" isNumeric>
                      {uploadResolutionAttachmentFn.data && (
                        <IconButton
                          aria-label="Visualizar anexo"
                          as={Link}
                          size="sm"
                          icon={<FaExternalLinkAlt />}
                          href={uploadResolutionAttachmentFn.data.attachment.link}
                          isExternal
                        />
                      )}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        )}
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Salvar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
