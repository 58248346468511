import { Box, Flex } from "@chakra-ui/react";
import { ExternalSupportStatus as ExternalSupportStatusType } from "api/externalSupports/_types/ExternalSupport"
import { externalSupportStatusMap } from "utils/externalSupportMappers";

interface ExternalSupportStatusProps {
  status: ExternalSupportStatusType
}

export function ExternalSupportStatus({ status }: ExternalSupportStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {['designating', 'resolving'].includes(status) && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {status === 'done' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}
      {externalSupportStatusMap[status]}
    </Flex>
  )
}
