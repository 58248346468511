import { Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Spinner, Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react"
import { getRegionals } from "api/regionals/getRegionals"
import { editTrainingField } from "api/trainings/editTrainingField"
import { getTraining } from "api/trainings/getTraining"
import { GetTrainingsResponse } from "api/trainings/getTrainings"
import { format } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"

import { useMutation, useQuery, useQueryClient } from "react-query"
import { trainingTypesMap, trainingCollaboratorTypeMap } from "utils/trainingMappers"
import { TrainingStatus } from "./TrainingStatus"
import AsyncSelect from "react-select/async"
import { useEffect, useState } from "react"
interface TrainingDetailProps {
  trainingId: string
}

export function TrainingDetail({ trainingId }: TrainingDetailProps) {
  const [selectedRegional, setSelectedRegional] = useState<{ value: string, label: string }>({
    value: '',
    label: ''
  })

  const { userLogged } = useAuth()

  const userCanEditTraining = userLogged?.permissions?.includes('edit-trainings')

  const { data: trainingData, isLoading: isTrainingDataLoading } = useQuery({
    queryKey: ['training', trainingId],
    queryFn: () => getTraining({ trainingId })
  })

  useEffect(() => {
    if (trainingData && trainingData.training.regional) {
      setSelectedRegional({ value: trainingData.training.regional.id, label: trainingData.training.regional.code.toUpperCase() })
    }
  }, [trainingData])

  const queryClient = useQueryClient()

  const { mutateAsync: editTrainingFieldFn } = useMutation({
    mutationFn: editTrainingField,
    onSuccess: (_data, { routeParams, body }) => {
      queryClient.invalidateQueries({ queryKey: ['training', routeParams.trainingId] })

      const cachedTrainings = queryClient.getQueriesData<GetTrainingsResponse>({
        queryKey: ['trainings']
      })

      cachedTrainings.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          trainings: cachedValue.trainings.map(training => {
            if (training.id === routeParams.trainingId) {
              training[body.field] = body.value

              return { ...training }
            }

            return training
          })
        })
      })
    }
  })

  async function handleEditTrainingFieldFn({
    field,
    value
  }: {
    field: string
    value: string
  }) {
    await editTrainingFieldFn({
      body: {
        field,
        value
      },
      routeParams: {
        trainingId
      }
    })
  }

  async function regionalPromiseOptions(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    const response = await getRegionals({ currentPage: 1, pageSize: 10 })

    return response.regionals.filter(regional => regional.code.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())).map(regional => {
      return { value: regional.id, label: regional.code.toUpperCase() }
    })
  }

  return (
    <ModalContent>
      {isTrainingDataLoading ? (
        <ModalBody
          p="6"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner />
        </ModalBody>
      ) : (
        <>
          <ModalHeader letterSpacing="tight">Detalhes do Treinamento</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <TableContainer>
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td>Colaborador</Td>
                    <Td isNumeric>

                      <Input
                        rounded="md"
                        onBlur={async (event) => {
                          if (event.target.value !== trainingData?.training?.collaborator_name) {
                            await handleEditTrainingFieldFn({
                              field: 'collaborator_name',
                              value: event.target.value
                            })
                          }
                        }}
                        defaultValue={trainingData?.training?.collaborator_name}
                        isDisabled={!userCanEditTraining}
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Tipo de Colaborador</Td>
                    <Td isNumeric>
                      <Select
                        rounded="md"
                        onBlur={async (event) => {
                          if (event.target.value !== trainingData.training.collaborator_type) {
                            await handleEditTrainingFieldFn({
                              field: 'collaborator_type',
                              value: event.target.value
                            })
                          }
                        }}
                        defaultValue={trainingData.training.collaborator_type}
                        isDisabled={!userCanEditTraining}
                      >
                        {Object.entries(trainingCollaboratorTypeMap).map(([key, value]) => {
                          return (
                            <option key={key} value={key}>{value}</option>
                          )
                        })}
                      </Select>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Regional</Td>
                    <Td>
                      {trainingData?.training?.regional ? (
                        <AsyncSelect
                          onChange={(event) => setSelectedRegional(event)}
                          onBlur={async () => {
                            if (selectedRegional) {
                              await handleEditTrainingFieldFn({
                                field: 'regional_id',
                                value: selectedRegional.value
                              })
                            }
                          }}
                          value={selectedRegional}
                          isMulti={false}
                          cacheOptions
                          defaultOptions
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              padding: '1px 3px 1px 4px',
                              borderRadius: '6px',
                              border: state.isFocused ? 'none' : state.isDisabled ? '0px' : '',
                              boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : '',
                              background: state.isDisabled ? 'white' : '',
                            })
                          }}
                          noOptionsMessage={() => 'Não há regionais cadastradas ou encontradas!'}
                          placeholder="Selecione uma regional..."
                          loadOptions={regionalPromiseOptions}
                          isClearable={true}
                          isDisabled={!userCanEditTraining}
                        />
                      ) : (
                        '-'
                      )}

                    </Td>
                  </Tr>

                  <Tr>
                    <Td>Treinamento</Td>
                    <Td display="flex" justifyContent="flex-end">
                      <Select
                        rounded="md"
                        onBlur={async (event) => {
                          if (event.target.value !== trainingData?.training?.type) {
                            await handleEditTrainingFieldFn({
                              field: 'type',
                              value: event.target.value
                            })
                          }
                        }}
                        defaultValue={trainingData?.training?.type}
                        isDisabled={!userCanEditTraining}
                      >
                        {Object.entries(trainingTypesMap).map(([key, value]) => {
                          return (
                            <option key={key} value={key}>{value}</option>
                          )
                        })}
                      </Select>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td display="flex" justifyContent="flex-end">
                      <TrainingStatus status={trainingData.training.status} />
                    </Td>
                  </Tr>

                  {trainingData?.training.due_date && (
                    <Tr>
                      <Td>Vencimento em</Td>
                      <Td display="flex" justifyContent="flex-end">
                        {format(new Date(trainingData.training.due_date), 'dd/MM/yyyy')}
                      </Td>
                    </Tr>
                  )}
                  {trainingData?.training.realization_date && (
                    <Tr>
                      <Td>Realizado em</Td>
                      <Td display="flex" justifyContent="flex-end">
                        {format(new Date(trainingData.training.realization_date), 'dd/MM/yyyy')}
                      </Td>
                    </Tr>
                  )}
                  {trainingData?.training.inactivated_at && (
                    <Tr>
                      <Td>Inativado em</Td>
                      <Td display="flex" justifyContent="flex-end">
                        {format(new Date(trainingData.training.inactivated_at), 'dd/MM/yyyy')}
                      </Td>
                    </Tr>
                  )}
                  {/* {trainingData.training.attachments && trainingData.training.attachments.map((attachment) => {
                    return (
                      <Tr key={attachment.id}>
                        <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">
                          {attachment?.type === 'training' ? 'Anexo Treinamento' : 'Anexo Certificado'}</Td>
                        <Td isNumeric>
                          <IconButton
                            aria-label="Abrir anexo"
                            as={Link}
                            href={attachment?.attachment.link}
                            icon={<FaExternalLinkAlt />}
                            size="sm"
                            isExternal
                          />
                        </Td>
                      </Tr>
                    )
                  })} */}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
        </>
      )}

    </ModalContent>
  )
}
