import { ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react"
import { getExperiencePeriod } from "api/experiencePeriods/getExperiencePeriod"
import { addHours, format } from "date-fns"
import { useQuery } from "react-query"
import { standardizationMap } from "utils/experiencePeriodMappers"
import { ExperiencePeriodStatus } from "./ExperiencePeriodStatus"

interface ExperiencePeriodDetailProps {
  experiencePeriodId: string
}

export function ExperiencePeriodDetail({ experiencePeriodId }: ExperiencePeriodDetailProps) {
  const {
    data: experiencePeriodResult, isLoading: isLoadingExperiencePeriodResult
  } = useQuery({
    queryKey: ['experiencePeriod', experiencePeriodId],
    queryFn: () => getExperiencePeriod({ routeParams: { experiencePeriodId: experiencePeriodId } })
  })

  const userTimezone = new Date().getTimezoneOffset() / 60

  if (isLoadingExperiencePeriodResult) {
    return (
      <ModalContent>
        <ModalHeader>
          <Skeleton w="150px" />
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <Skeleton h="15px" />
          <Skeleton h="15px" />
          <Skeleton h="15px" />
          <Skeleton h="15px" />
          <Skeleton h="15px" />
        </ModalBody>
      </ModalContent>
    )
  }

  return (
    <ModalContent maxW='850px'>
      <ModalHeader>
        Detalhes do Periodo de Experiência
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <TableContainer>
          <Table size="sm">
            <Tbody>
              <Tr>
                <Td>Nome</Td>
                <Td isNumeric>{experiencePeriodResult?.experiencePeriod?.name}</Td>
              </Tr>
              <Tr>
                <Td>Data de Admissão</Td>
                <Td isNumeric>{format(addHours(new Date(experiencePeriodResult?.experiencePeriod?.admission_date), userTimezone), 'dd/MM/yyy')}</Td>
              </Tr>
              <Tr>
                <Td>45 dias</Td>
                <Td isNumeric>{format(addHours(new Date(experiencePeriodResult?.experiencePeriod?.period_45_days_end_date), userTimezone), 'dd/MM/yyy')}</Td>
              </Tr>
              <Tr>
                <Td>90 dias</Td>
                <Td isNumeric>{format(addHours(new Date(experiencePeriodResult?.experiencePeriod?.period_90_days_end_date), userTimezone), 'dd/MM/yyy')}</Td>
              </Tr>
              <Tr>
                <Td>Regional</Td>
                <Td isNumeric>{experiencePeriodResult?.experiencePeriod?.regional}</Td>
              </Tr>
              <Tr>
                <Td>Status</Td>
                <Td display="flex" justifyContent="flex-end">
                  <ExperiencePeriodStatus status={experiencePeriodResult?.experiencePeriod.status} />
                </Td>
              </Tr>
              {experiencePeriodResult?.experiencePeriod?.delays_without_sick_note && (
                <Tr>
                  <Td>Atrasos sem atestado</Td>
                  <Td isNumeric>{experiencePeriodResult?.experiencePeriod?.delays_without_sick_note}</Td>
                </Tr>
              )}
              {experiencePeriodResult?.experiencePeriod?.fouls_with_sick_note && (
                <Tr>
                  <Td>Faltas com atestado</Td>
                  <Td isNumeric>{experiencePeriodResult?.experiencePeriod?.fouls_with_sick_note}</Td>
                </Tr>
              )}
              {experiencePeriodResult?.experiencePeriod?.has_unjustified_absense && (
                <Tr>
                  <Td>Falta injustificada</Td>
                  <Td isNumeric>{experiencePeriodResult?.experiencePeriod?.has_unjustified_absense ? 'SIM' : 'NÃO'}</Td>
                </Tr>
              )}
              {experiencePeriodResult?.experiencePeriod?.standardization && (
                <Tr>
                  <Td>Padronização</Td>
                  <Td isNumeric>{standardizationMap[experiencePeriodResult?.experiencePeriod?.standardization]}</Td>
                </Tr>
              )}
              {experiencePeriodResult?.experiencePeriod?.has_rh_pending_document && (
                <Tr>
                  <Td>Possui pendência de documentação da qualidade ou RH ? </Td>
                  <Td isNumeric>{experiencePeriodResult?.experiencePeriod?.has_rh_pending_document ? 'SIM' : 'NÃO'}</Td>
                </Tr>
              )}
              {experiencePeriodResult?.experiencePeriod?.has_behavioral_problems && (
                <Tr>
                  <Td>Teve reclamação do cliente ou problema de postura/atendimento sobre o colaborador ? </Td>
                  <Td isNumeric>{experiencePeriodResult?.experiencePeriod?.has_behavioral_problems ? 'SIM' : 'NÃO'}</Td>
                </Tr>
              )}
              {experiencePeriodResult?.experiencePeriod?.has_behavioral_problems && (
                <Tr>
                  <Td>Descrição porque a pessoa deve ou não continuar pós período de experiência</Td>
                  <Td isNumeric>{experiencePeriodResult?.experiencePeriod?.considerations}</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </ModalBody>

    </ModalContent>
  )
}
