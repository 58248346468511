import { IconButton, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner, Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react"
import { getOnboarding } from "api/onboardings/getOnboarding"
import { FaExternalLinkAlt } from "react-icons/fa"
import { useQuery } from "react-query"
import { OnboardingStatusThemeTypeMap } from "utils/onboardingMappers"
import { OnboardingStatus } from "./OnboardingStatus"

interface OnboardingDetailProps {
  onboardingId: string
}

export function OnboardingDetail({ onboardingId }: OnboardingDetailProps) {

  const { data: onboardingData, isLoading: isOnboardingDataLoading } = useQuery({
    queryKey: ['onboarding', onboardingId],
    queryFn: () => getOnboarding({ onboardingId })
  })

  return (
    <ModalContent maxW={'550px'}>
      {isOnboardingDataLoading ? (
        <ModalBody
          p="6"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner />
        </ModalBody>
      ) : (
        <>
          <ModalHeader letterSpacing="tight">Detalhes do Onboarding</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            maxH='500px'
            overflowY='scroll'
          >
            <TableContainer>
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td>Tipo de treinamento</Td>
                    <Td isNumeric>{onboardingData?.onboarding.training_type}</Td>
                  </Tr>
                  <Tr>
                    <Td>Nome</Td>
                    <Td isNumeric>{onboardingData?.onboarding.name}</Td>
                  </Tr>
                  {onboardingData?.onboarding.collector && (
                    <Tr>
                      <Td>Coletador</Td>
                      <Td isNumeric>{onboardingData?.onboarding.collector}</Td>
                    </Tr>
                  )}
                  <Tr>
                    <Td>CPF</Td>
                    <Td isNumeric>{onboardingData?.onboarding.cpf}</Td>
                  </Tr>
                  <Tr>
                    <Td>E-mail</Td>
                    <Td isNumeric>{onboardingData?.onboarding.email}</Td>
                  </Tr>

                  {onboardingData?.onboarding.collector && (
                    <Tr>
                      <Td>Coletador</Td>
                      <Td isNumeric>{onboardingData?.onboarding.collector}</Td>
                    </Tr>
                  )}
                  {onboardingData?.onboarding.cargo && (
                    <Tr>
                      <Td>Cargo</Td>
                      <Td isNumeric>{onboardingData?.onboarding.cargo}</Td>
                    </Tr>
                  )}
                  {onboardingData?.onboarding.regional && (
                    <Tr>
                      <Td>Regional</Td>
                      <Td isNumeric>{onboardingData?.onboarding.regional.code.toUpperCase()}</Td>
                    </Tr>
                  )}
                  <Tr>
                    <Td>Status</Td>
                    <Td display="flex" justifyContent="flex-end">
                      <OnboardingStatus status={onboardingData?.onboarding.status} />
                    </Td>
                  </Tr>
                  {onboardingData?.onboarding.certificate && (
                    <Tr >
                      <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">Certificado</Td>
                      <Td isNumeric>
                        <IconButton
                          aria-label="Abrir anexo"
                          as={Link}
                          href={onboardingData?.onboarding?.certificate?.link}
                          icon={<FaExternalLinkAlt />}
                          size="sm"
                          isExternal
                        />
                      </Td>
                    </Tr>
                  )}
                  {onboardingData?.onboarding?.llm_training_themes && onboardingData?.onboarding?.llm_training_themes.map((theme, index) => {
                    return (
                      <Tr key={index}>
                        <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">{theme.theme}</Td>
                        <Td isNumeric>
                          {OnboardingStatusThemeTypeMap[theme.status]}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>

          </ModalBody>
        </>
      )}
    </ModalContent>
  )

}
