import { usePagination } from "@ajna/pagination"
import { Box, Flex, Heading, IconButton, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react"
import { getStandardizations } from "api/standardizations/getStandardizations"
import { StandardizationStatus } from "api/standardizations/standardization"
import { Pagination } from "components/Pagination/Pagination"

import { useAuth } from "hooks/auth/useAuth"
import { useSearchParams } from "hooks/useSearchParams"
import { useEffect } from "react"
import { FaPlus } from "react-icons/fa"
import { useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { StandardizationsTableFilters } from "./components/StandardizationsFilterTable"
import { StandardizationTableRow } from "./components/StandardizationTableRow"

export function Standardizations() {
  const { userLogged } = useAuth()
  const { replace, push: redirect } = useHistory()
  const searchParams = useSearchParams()

  const userCanViewStandardizations = userLogged?.permissions.includes('view-standardizations')
  const userCanCreateStandardization = userLogged?.permissions.includes('create-standardization')

  useEffect(() => {
    if (!userCanViewStandardizations) redirect('/')
  }, [redirect, userCanViewStandardizations])

  const page = searchParams.get('page') ?? '1'
  const hub = searchParams.get('hub')
  const status = searchParams.get('status') ?? 'all'

  const { data: standardizationsData } = useQuery({
    queryKey: ['standardizations', page,
      hub,
      status],
    queryFn: () => getStandardizations({
      currentPage: page,
      pageSize: '10',
      hub,
      status: status as StandardizationStatus | 'all'
    }),
    keepPreviousData: true
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: standardizationsData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: standardizationsData?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  return (

      <Box
        bg="white"
        p="6"
        rounded="md"
      >
        <Flex
          align="center"
          justify="space-between"
        >
          <Heading letterSpacing="tight">Padronizações</Heading>

          {userCanCreateStandardization && (
            <IconButton
              aria-label="Criar Padronização"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={() => redirect("/padronizacao/criar")}
            />
          )}

        </Flex>
        <StandardizationsTableFilters />
        <TableContainer
          border="1px solid"
          borderColor="gray.200"
          rounded="md"
          mt="6"
        >
          <Table size="sm">
            <Thead>
              <Tr
                h="40px"
                bg="#38c3fa"
              >
                <Th></Th>
                <Th color="white">Colaborador</Th>
                <Th color="white">Hub</Th>
                <Th color="white">Data de envio</Th>
                <Th color="white">Status</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {standardizationsData?.standardizations?.map((standardization) => {
                return (
                  <StandardizationTableRow
                    key={standardization.id}
                    standardization={standardization}
                  />
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />
      </Box>

  )
}
