import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { InternCltBenefitName, InternCltStatus } from "./_types/internClt";

interface EditInternCltBody {
  name: string
  cpf: string
  status: InternCltStatus
  rg: string
  bornDate: Date
  addressStreet: string
  addressNumber: number
  addressNeighborhood: string
  addressComplement?: string | null
  addressCity: string
  addressCep: string
  addressState: string
  officeId: string
  email: string
  regional: 'sp' | 'rj' | 'bh' | 'df' | 'other'
  admissionDate: Date
  salaryInCents: number
  hasLeadershipGratification: boolean
  leadershipGratificationValueInCents: number
  hasCommission: boolean
  commissionDescription: string
  journeyType: 'days' | '12x36'
  workStartHour: Date
  workEndHour: Date
  workIntervalStartHour: Date
  workIntervalEndHour: Date
  saturdayWorkStartHour?: Date
  saturdayWorkEndHour?: Date
  saturdayWorkIntervalStartHour?: Date
  saturdayWorkIntervalEndHour?: Date
  sundayWorkStartHour?: Date
  sundayWorkEndHour?: Date
  sundayWorkIntervalStartHour?: Date
  sundayWorkIntervalEndHour?: Date
  workWeekDays?: string[] | null
  benefits?: InternCltBenefitName[] | null
  hasHealthInsuranceDependents?: boolean | null
  vrDailyValueInCents?: number | null
  vaDailyValueInCents?: number | null
  fuelMonthlyValueInCents?: number | null
  phoneAssistanceValueInCents?: number | null
  vtTypes?: string[] | null
}

interface EditInternCltProps {
  body: EditInternCltBody
  routeParams: { internCltId: string }
}

export async function editInternClt({
  body,
  routeParams
}: EditInternCltProps) {
  try {
    const response = await api.put(`/intern-clts/${routeParams.internCltId}`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
