import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getPartnerSupports } from "api/partnerSupport/getPartnerSupports";
import { PartnerSupportStatus } from "api/partnerSupport/_types/PartnerSupport";
import { useSearchParams } from "hooks/useSearchParams";
import { FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CreatePartnerSupport } from "./components/CreatePartnerSupport";
import { Pagination } from "components/Pagination/Pagination";
import { PartnerSupportTableFilters } from "./components/PartnerSuppportTableFilters";
import { PartnerSupportsTableRow } from "./components/PartnerSupportsTableRow";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";

export function PartnerSupports() {
  const searchParams = useSearchParams()

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanViewPartnerSupports = userLogged?.permissions?.includes(
    'view-partner-supports'
  )

  const userCanCreatePartnerSupport = userLogged?.permissions?.includes(
    'create-partner-support'
  )

  useEffect(() => {
    if (!userCanViewPartnerSupports) history.push('/')
  }, [history, userCanViewPartnerSupports])

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status') ?? 'all'
  const collector = searchParams.get('collector')

  const {
    data: partnerSupportsResult
  } = useQuery({
    queryKey: ['partnerSupports', page, status, collector],
    queryFn: () => getPartnerSupports({
      currentPage: Number(page),
      pageSize: 10,
      status: status as PartnerSupportStatus | 'all',
      collector
    })
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: partnerSupportsResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: partnerSupportsResult?.meta?.pageSize
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  const {
    isOpen: isCreatePartnerSupportModalOpen,
    onOpen: onOpenCreatePartnerSupportModal,
    onClose: onCloseCreatePartnerSupportModal
  } = useDisclosure()

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Atendimento Parceiros </Heading>
        {userCanCreatePartnerSupport && (
          <IconButton
            aria-label="Criar atendimento"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            onClick={onOpenCreatePartnerSupportModal}
          />
        )}

        <Modal
          isOpen={isCreatePartnerSupportModalOpen}
          onClose={onCloseCreatePartnerSupportModal}
          isCentered
        >
          <ModalOverlay />
          <CreatePartnerSupport
            onCloseModal={onCloseCreatePartnerSupportModal}
          />
        </Modal>
      </Flex>
      <PartnerSupportTableFilters />
      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Tipo de atendimento</Th>
              <Th color="white">Coletador</Th>
              <Th color="white">Status</Th>
              <Th></Th>

            </Tr>
          </Thead>
          <Tbody>
            {partnerSupportsResult?.partnerSupports?.map((partnerSupport) => {
              return <PartnerSupportsTableRow
                key={partnerSupport.id}
                partnerSupport={partnerSupport}
              />
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}
