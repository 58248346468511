import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { PartnerSupportTypes } from "./_types/PartnerSupport";

interface CreatePartnerSupportBody {
  type: PartnerSupportTypes
  description?: string
  requestAttachmentId?: string
  bank?: string
  agency?: number
  accountNumber?: number
}

interface CreatePartnerSupportProps {
  body: CreatePartnerSupportBody
  routeParams: {
    collectorId: string
  }
}

export async function createPartnerSupport({
  body,
  routeParams
}: CreatePartnerSupportProps) {
  try {
    const response = await api.post(`/collectors/${routeParams.collectorId}/partner-supports`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
