import { Button, Flex, FormControl, FormLabel, IconButton, Input, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Progress, Select, Stack, Table, Tbody, Td, Text, Textarea, Tr } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { set } from "date-fns";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { useForm, useWatch } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { ChangeEvent } from "react";
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa";
import { validateHasFile } from "utils/fileValidation";
import { completeMaintenanceAudit } from "api/audits/completeMaintenanceAudit";

interface AuditMaintenanceProps {
  onClose: () => void
  auditId: string
}


interface AuditMaintenanceSchema {
  realizedDate: string
  reportAttachmentId: FileList
  nonCompliance: string
  status: string
}

const auditMaintenanceSchema = yup.object({
  realizedDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
  reportAttachmentId: yup.mixed().test(value => validateHasFile(value)).required(),
  status: yup.string().required(),
  nonCompliance: yup.string()
})


export function AuditMaintenance({
  auditId,
  onClose
}: AuditMaintenanceProps) {

  const { mutation: uploadReportAttachmentFn, uploadProggress: uploadProggressReportAttachmentPhoto } = useAttachmentMutation()

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<AuditMaintenanceSchema>({
    resolver: yupResolver(auditMaintenanceSchema)
  })

  const [reportAttachmentId] = useWatch({
    control,
    name: ['reportAttachmentId'],
  })

  async function handleUploadReportAttachmentPhoto(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadReportAttachmentFn.mutateAsync(formData)

  }


  const { mutateAsync: completeMaintenanceAuditFn } = useMutation({
    mutationFn: completeMaintenanceAudit,
    onSuccess() {
      queryClient.invalidateQueries(['audits'])
      onClose()
    }
  })

  async function handleAuditMaintenance(values: AuditMaintenanceSchema) {
    await promiseMessage(completeMaintenanceAuditFn({
      body: {
        ...values,
        reportAttachmentId: uploadReportAttachmentFn?.data?.attachment?.id,
      },
      auditId
    }), 'Auditoria realizada!')
  }


  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Auditoria de Manutenção
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleAuditMaintenance)}
        maxH='600px'
        overflowY='scroll'
      >
        <FormControl isInvalid={!!errors?.realizedDate} mt={3}>
          <FormLabel fontSize="sm">
            Data realizada
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('realizedDate')}
            name="realizedDate"
            type="date"
            size="sm"
            rounded="md"
            w={["full", "full", "sm"]}
          />
        </FormControl>
        <Stack
          direction="column"
          w="full"
          mt="3"
        >
          <Button
            as={FormLabel}
            htmlFor="reportAttachmentId"
            lineHeight="1"
            leftIcon={<FaFileImport />}
            size="sm"
            w="min"
            cursor="pointer"
            border={!!errors?.reportAttachmentId && '2px solid'}
            borderColor={(!!errors?.reportAttachmentId) && 'red.500'}
          >
            Relatório
          </Button>
          <FormControl isInvalid={!!errors?.reportAttachmentId}>
            <Input
              {...register('reportAttachmentId')}
              name="reportAttachmentId"
              id="reportAttachmentId"
              type="file"
              hidden
              onChangeCapture={handleUploadReportAttachmentPhoto}
            />
          </FormControl>
        </Stack>

        {reportAttachmentId && (
          <Table size="sm">
            <Tbody>
              {Object.entries(reportAttachmentId).map(([key, file]) => {
                return (
                  <Tr key={key}>
                    <Td fontSize="xs" maxW="100px">{file.name}</Td>
                    <Td fontSize="xs" w="200px">
                      <Progress size="sm" rounded="md" value={uploadProggressReportAttachmentPhoto} />
                    </Td>
                    <Td fontSize="xs" isNumeric>
                      {uploadReportAttachmentFn.data && (
                        <IconButton
                          aria-label="Visualizar anexo"
                          as={Link}
                          size="sm"
                          icon={<FaExternalLinkAlt />}
                          href={uploadReportAttachmentFn.data.attachment.link}
                          isExternal
                        />
                      )}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        )}
        <FormControl isInvalid={!!errors.nonCompliance} mt={3}>
          <FormLabel>
            Não conformidade
          </FormLabel>
          <Textarea
            {...register('nonCompliance')}
            placeholder="Descreva a não conformidade..."
            size='sm'
            rounded='md'
          />
        </FormControl>
        <FormControl isInvalid={!!errors.status} mt={3}>
          <FormLabel fontSize="sm">
            Status
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Select
            {...register('status')}
            name="status"
            size="sm"
            rounded="md"
            w={["full", "full", "sm"]}
            placeholder="Selecione..."
          >
            <option value="approved">Aprovado</option>
            <option value="reproved">Reprovado</option>
          </Select>
        </FormControl>
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Auditar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
