import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { getVacations } from "api/vacations/getVacations";
import { VacationStatus } from "api/vacations/_types/Vacation";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { VacationsTableRow } from "./components/VacationsTableRow";
import { VacationsTableFilters } from "./components/VacationTableFilters";


export function Vacations() {
  const searchParams = useSearchParams()

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanViewVacations = userLogged?.permissions.includes(
    'view-vacations'
  )

  useEffect(() => {
    if (!userCanViewVacations) history.push('/')
  }, [userCanViewVacations, history])

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status')
  const collaboratorName = searchParams.get('collaboratorName')

  const {
    data: vacationsResult
  } = useQuery({
    queryKey: [
      'vacations',
      page,
      status,
      collaboratorName,
    ],
    queryFn: () => getVacations({
      currentPage: Number(page),
      pageSize: 10,
      collaboratorName,
      status: status as VacationStatus | 'all'
    })
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: vacationsResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: vacationsResult?.meta?.pageSize
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Férias</Heading>
      </Flex>

      <VacationsTableFilters />

      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Nome</Th>
              <Th color="white">Data limite para gozo</Th>
              <Th color="white">Status</Th>
              <Th></Th>

            </Tr>
          </Thead>
          <Tbody>
            {vacationsResult?.vacations?.map((vacation) => {
              return <VacationsTableRow key={vacation.id} vacation={vacation} />
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}
