import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { AsoStatus } from "api/asos/aso";
import { getAsos } from "api/asos/getAsos";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import { AsosTableFilters } from "./components/AsosTableFilters";
import { AsosTableRow } from "./components/AsosTableRow";
import { CreateAso } from "./components/CreateAso";

export function Asos() {
  const { replace, push: redirect } = useHistory()
  const searchParams = useSearchParams()

  const { userLogged } = useAuth()

  const userHasViewAsosPermission = userLogged?.permissions.includes('view-asos')

  const userHasCreateAsoPermission = userLogged?.permissions.includes('create-aso')

  useEffect(() => {
    if (!userHasViewAsosPermission) {
      redirect('/')
    }
  }, [userHasViewAsosPermission, redirect])

  const hub = searchParams.get('hub')
  const collaborator = searchParams.get('collaborator')
  const status = searchParams.get('status') ?? 'all'
  const page = searchParams.get('page') ?? '1'

  const {
    data: asosData,
  } = useQuery({
    queryKey: ['asos', hub, collaborator, status, page],
    queryFn: () => getAsos({
      collaborator,
      status: status as AsoStatus | 'all',
      currentPage: page,
      pageSize: '10'
    }),
    keepPreviousData: true,
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: asosData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: asosData?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  const {
    isOpen: isCreateAsoModalOpen,
    onOpen: onOpenCreateAsoModal,
    onClose: onCloseCreateAsoModal
  } = useDisclosure()

  return (

      <Box
        bg="white"
        p="6"
        rounded="md"
      >
        <Flex
          align="center"
          justify="space-between"
        >
          <Heading letterSpacing="tight">Asos</Heading>
          {userHasCreateAsoPermission && (
            <IconButton
              aria-label="Criar Aso"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateAsoModal}
            />
          )}

          <Modal
            isOpen={isCreateAsoModalOpen}
            onClose={onCloseCreateAsoModal}
            size="2xl"
            isCentered
          >
            <ModalOverlay />

            <CreateAso onCloseModal={onCloseCreateAsoModal} />
          </Modal>
        </Flex>

        <AsosTableFilters />

        <TableContainer
          border="1px solid"
          borderColor="gray.200"
          rounded="md"
          mt="6"
        >
          <Table size="sm">
            <Thead>
              <Tr
                h="40px"
                bg="#38c3fa"
              >
                <Th></Th>
                <Th color="white">Regional</Th>
                <Th color="white">Status</Th>
                <Th color="white">Data da Solicitação</Th>
                <Th color="white">Tipo</Th>
                <Th color="white">Colaborador</Th>
                <Th color="white">Data da Realização</Th>
                <Th></Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {asosData?.asos?.map((aso) => {
                return (
                  <AsosTableRow
                    key={aso.id}
                    aso={aso}
                  />
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>

        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />

      </Box>

  )
}
