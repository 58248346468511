import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface SendAdditivePartnerSupportBody {
  additiveChecklist: string[]
  additiveAttachmentId?: string
}

interface SendAdditivePartnerSupportProps {
  body: SendAdditivePartnerSupportBody
  routeParams: {
    partnerSupportId: string
  }
}
export async function sendAdditivePartnerSupport({
  body,
  routeParams
}: SendAdditivePartnerSupportProps){
  try {
    const response = await api.put(`/partner-supports/${routeParams.partnerSupportId}/send-additive`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
