import { ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner } from "@chakra-ui/react"
import { getStandardization } from "api/standardizations/getStandardization"
import { useQuery } from "react-query"
import { StandardizationDetailTable } from "./StandardizationDetailTable"

interface StandardizationDetailProps {
  standardizationId: string
}

export function StandardizationDetail({ standardizationId }: StandardizationDetailProps) {

  const { data: standardizationData, isLoading: isStandardizationDataLoading } = useQuery({
    queryKey: ['standardization', standardizationId],
    queryFn: () => getStandardization({ standardizationId })
  })


  return (
    <ModalContent maxW='800px'>
      {isStandardizationDataLoading ? (
        <ModalBody
          p="6"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner />
        </ModalBody>
      ) : (
        <>
          <ModalHeader letterSpacing="tight">Detalhes da Padronização</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            maxH='500px'
            overflowY='scroll'
          >

            <StandardizationDetailTable
              standardization={standardizationData?.standardization}
            />

          </ModalBody>
        </>
      )}
    </ModalContent>
  )
}
