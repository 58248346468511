import { Menu, MenuButton, Button, MenuList, MenuOptionGroup, MenuItemOption } from "@chakra-ui/react";
import { CollectorsExtrasDiscountsStatus, CollectorsExtrasDiscountsStatusEnum } from "contexts/CollectorsExtrasDiscountsStatusContext";
import { useCollectorsExtrasDiscountsStatus } from "hooks/collector/useCollectorsExtrasDiscountsStatus";
import { FaChevronDown } from "react-icons/fa";

interface CollectorsExtrasDiscountsStatusOptions {
  key: keyof typeof CollectorsExtrasDiscountsStatusEnum
  value: keyof typeof CollectorsExtrasDiscountsStatusEnum
  showOption: string
}

const collectorsExtrasDiscountsStatusOptions: CollectorsExtrasDiscountsStatusOptions[] = [
  { key: 'requested', value: 'requested', showOption: CollectorsExtrasDiscountsStatusEnum['requested'] },
  { key: 'approved', value: 'approved', showOption: CollectorsExtrasDiscountsStatusEnum['approved'] },
  { key: 'effectivated', value: 'effectivated', showOption: CollectorsExtrasDiscountsStatusEnum['effectivated'] },
  { key: 'rejected', value: 'rejected', showOption: CollectorsExtrasDiscountsStatusEnum['rejected'] }
]

export function CollectorsExtrasDiscountsTableStatusButton() {
  const { status, handleChangeStatus } = useCollectorsExtrasDiscountsStatus()

  return (
    <Menu>
      <MenuButton fontSize="12px" variant="ghost" size="sm" as={Button} rightIcon={<FaChevronDown />}>
        STATUS
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          defaultValue="requested"
          value={status}
          type="radio"
          onChange={
            (status: CollectorsExtrasDiscountsStatus) => handleChangeStatus(status)
          }
        >
          {collectorsExtrasDiscountsStatusOptions.map((option) => {
            return (
              <MenuItemOption value={option.value} key={option.key}>{option.showOption}</MenuItemOption>
            )
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
