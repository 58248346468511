import { Box, Flex, IconButton, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, Table, Tbody, Td, Tr } from "@chakra-ui/react"
import { getExternalSupport } from "api/externalSupports/getExternalSupport"
import { format } from "date-fns"
import { FaExternalLinkAlt } from "react-icons/fa"
import { useQuery } from "react-query"
import { ExternalSupportStatus } from "./ExternalSupportStatus"

interface ExternalSupportDetailProps {
  externalSupportId: string
}

export function ExternalSupportDetail({ externalSupportId }: ExternalSupportDetailProps) {
  const {
    data: externalSupportResult,
    isLoading: isLoadingExternalSupportResult
  } = useQuery({
    queryKey: ['externalSupport', externalSupportId],
    queryFn: () => getExternalSupport({ externalSupportId })
  })

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Detalhes do atendimento
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Box
          maxH="800px"
          overflowY="scroll"
          p={3}
        >
          <Table size="sm">
            <Tbody>
              {isLoadingExternalSupportResult ? (
                Array.from({ length: 10 }).map((_, index) => {
                  return (
                    <Tr>
                      <Td>
                        <Skeleton w="full" h="16px" />
                      </Td>
                      <Td isNumeric>
                        <Skeleton w="full" h="16px" />
                      </Td>
                    </Tr>
                  )
                })
              ) : (
                <>
                  <Tr>
                    <Td>Motivo do atendimento</Td>
                    <Td isNumeric>{externalSupportResult?.externalSupport.reason}</Td>
                  </Tr>
                  <Tr>
                    <Td>Data da Solicitação</Td>
                    <Td isNumeric>{format(new Date(externalSupportResult?.externalSupport?.created_at), 'dd/MM/yyyy')}</Td>
                  </Tr>
                  {externalSupportResult.externalSupport.reason === 'ALTERAÇÃO DE CONTA DE PAGAMENTO' && (
                    <>
                      <Tr>
                        <Td>Titular da Conta</Td>
                        <Td isNumeric>{externalSupportResult?.externalSupport?.account_owner}</Td>
                      </Tr>
                      <Tr>
                        <Td>Banco</Td>
                        <Td isNumeric>{externalSupportResult?.externalSupport?.bank}</Td>
                      </Tr>
                      <Tr>
                        <Td>Tipo da conta</Td>
                        <Td isNumeric>{externalSupportResult?.externalSupport?.account_type}</Td>
                      </Tr>
                      <Tr>
                        <Td>Agência</Td>
                        <Td isNumeric>{`${String(externalSupportResult?.externalSupport.agency).slice(0, -1)}-${String(externalSupportResult.externalSupport.agency).slice(-1)}`}</Td>
                      </Tr>
                      <Tr>
                        <Td>Conta</Td>
                        <Td isNumeric>{`${String(externalSupportResult?.externalSupport.account_number).slice(0, -1)}-${String(externalSupportResult.externalSupport.account_number).slice(-1)}`}</Td>
                      </Tr>
                      <Tr>
                        <Td>CPF</Td>
                        <Td isNumeric>{externalSupportResult?.externalSupport?.cpf}</Td>
                      </Tr>
                    </>
                  )}
                  <Tr>
                    <Td>Status</Td>
                    <Td isNumeric>
                      <Flex justify="end">
                        <ExternalSupportStatus status={externalSupportResult?.externalSupport.status} />
                      </Flex>
                    </Td>
                  </Tr>

                  {externalSupportResult?.externalSupport?.request_description && (
                    <Tr>
                      <Td>Descrição da solicitação</Td>
                      <Td isNumeric>{externalSupportResult?.externalSupport?.request_description}</Td>
                    </Tr>
                  )}
                  {externalSupportResult?.externalSupport?.requestAttachment && (
                    <Tr>
                      <Td>Anexo da solicitação</Td>
                      <Td isNumeric>
                        <IconButton
                          aria-label=""
                          as={Link}
                          href={externalSupportResult?.externalSupport.requestAttachment.link}
                          size="sm"
                          icon={<FaExternalLinkAlt />}
                        />
                      </Td>
                    </Tr>
                  )}
                  {externalSupportResult?.externalSupport?.resolution_responsible_id && (
                    <Tr>
                      <Td>Responsável pela resolução</Td>
                      <Td isNumeric>{externalSupportResult?.externalSupport?.resolutionResponsible?.firstname} {externalSupportResult?.externalSupport?.resolutionResponsible?.lastname}</Td>
                    </Tr>
                  )}
                  {externalSupportResult?.externalSupport?.answer_deadline && (
                    <Tr>
                      <Td>Prazo para resposta</Td>
                      <Td display="flex" justifyContent="flex-end">
                        {format(new Date(externalSupportResult?.externalSupport?.answer_deadline), 'dd/MM/yyyy')}
                      </Td>
                    </Tr>
                  )}
                  {externalSupportResult?.externalSupport?.resolution && (
                    <Tr>
                      <Td>Prazo para resposta</Td>
                      <Td display="flex" justifyContent="flex-end">
                        {externalSupportResult?.externalSupport?.resolution}
                      </Td>
                    </Tr>
                  )}
                  {externalSupportResult?.externalSupport?.resolutionAttachment && (
                    <Tr>
                      <Td>Anexo da resolução</Td>
                      <Td isNumeric>
                        <IconButton
                          aria-label=""
                          as={Link}
                          href={externalSupportResult?.externalSupport.resolutionAttachment.link}
                          size="sm"
                          icon={<FaExternalLinkAlt />}
                        />
                      </Td>
                    </Tr>
                  )}
                </>
              )}
            </Tbody>
          </Table>
        </Box>
      </ModalBody>
    </ModalContent>
  )
}
