import { Button, HStack, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { PartnerSupport } from "api/partnerSupport/_types/PartnerSupport";
import { useAuth } from "hooks/auth/useAuth";
import { Collector } from "hooks/collector/dtos/Collector";
import { FaArrowRight, FaSearch } from "react-icons/fa";
import { AttendPartnerSupport } from "./AttendPartnerSupport";
import { CheckPartnerSupport } from "./CheckPartnerSupport";
import { PartnerSuportDetail } from "./PartnerSupportDetail";
import { PartnerSupportStatus } from "./PartnerSupportStatus";
import { SendAdditivePartnerSuppport } from "./SendAdditivePartnerSuppport";

interface PartnerSupportsTableRowProps {
  partnerSupport: PartnerSupport & {
    collector: Collector
  }
}

export function PartnerSupportsTableRow({ partnerSupport }: PartnerSupportsTableRowProps) {
  const { userLogged } = useAuth()

  const userCanAttendPartnerSupport = userLogged?.permissions.includes(
    'attend-partner-support'
  )

  const userCanSendAdditivePartnerSupport = userLogged?.permissions.includes(
    'send-additive-partner-support'
  )

  const userCanCheckPartnerSupport = userLogged?.permissions.includes(
    'check-partner-support'
  )

  const {
    isOpen: isPartnerSupportDetailOpen,
    onToggle: onTogglePartnerSupportDetail
  } = useDisclosure()

  const {
    isOpen: isAttendPartnerSupportOpen,
    onToggle: onToggleAttendPartnerSupport
  } = useDisclosure()

  const {
    isOpen: isSendAdditivePartnerSupportOpen,
    onToggle: onToggleSendAdditivePartnerSupport
  } = useDisclosure()

  const {
    isOpen: isCheckPartnerSupportOpen,
    onToggle: onToggleCheckPartnerSupport
  } = useDisclosure()

  return (
    <Tr>
       <Td>
        <IconButton
          aria-label="Abrir detalhes do atendimento"
          variant="ghost"
          icon={<FaSearch />}
          size="sm"
          onClick={onTogglePartnerSupportDetail}
        />

        <Modal isOpen={isPartnerSupportDetailOpen} onClose={onTogglePartnerSupportDetail} size="2xl">
          <ModalOverlay />

          <PartnerSuportDetail partnerSupportId={partnerSupport.id} />
        </Modal>
      </Td>
      <Td>{partnerSupport.type}</Td>
      <Td>{partnerSupport?.collector?.trading_name}</Td>
      <Td>
        <PartnerSupportStatus status={partnerSupport.status} />
      </Td>
      <Td>
        <HStack spacing={3} align="center">
          {userCanAttendPartnerSupport && partnerSupport.status === 'attending' && (
            <>
              <Button
                size="xs"
                rightIcon={<FaArrowRight />}
                onClick={onToggleAttendPartnerSupport}
                lineHeight={1}
              >
                Atender
              </Button>

              <Modal
                isOpen={isAttendPartnerSupportOpen}
                onClose={onToggleAttendPartnerSupport}
              >
                <ModalOverlay />

                <AttendPartnerSupport
                  onCloseModal={onToggleAttendPartnerSupport}
                  partnerSupportId={partnerSupport.id} />
              </Modal>
            </>
          )}
          {userCanSendAdditivePartnerSupport && partnerSupport.status === 'sending-additive' && (
            <>
              <Button
                size="xs"
                rightIcon={<FaArrowRight />}
                onClick={onToggleSendAdditivePartnerSupport}
                lineHeight={1}
              >
                Alterar/Enviar aditivo
              </Button>

              <Modal
                isOpen={isSendAdditivePartnerSupportOpen}
                onClose={onToggleSendAdditivePartnerSupport}
              >
                <ModalOverlay />

                <SendAdditivePartnerSuppport
                  onCloseModal={onToggleSendAdditivePartnerSupport}
                  partnerSupportId={partnerSupport.id} />
              </Modal>
            </>
          )}
          {userCanCheckPartnerSupport && partnerSupport.status === 'checking' && (
            <>
              <Button
                size="xs"
                rightIcon={<FaArrowRight />}
                onClick={onToggleCheckPartnerSupport}
                lineHeight={1}
              >
                Conferir
              </Button>

              <Modal
                isOpen={isCheckPartnerSupportOpen}
                onClose={onToggleCheckPartnerSupport}
              >
                <ModalOverlay />

                <CheckPartnerSupport
                  onCloseModal={onToggleCheckPartnerSupport}
                  partnerSupportId={partnerSupport.id} />
              </Modal>
            </>
          )}
        </HStack>
      </Td>
    </Tr>
  )
}
