import { Box, Button, Flex, FormControl, FormLabel, HStack, IconButton, Input, InputGroup, InputLeftAddon, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, Stack, Table, Tbody, Td, Text, Textarea, Tr } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { getVacation } from "api/vacations/getVacation";
import { GetVacationsResponse } from "api/vacations/getVacations";
import { payVacation } from "api/vacations/payVacation";
import { format } from "date-fns";
import { useToastify } from "hooks/toastify/useToastify";
import { Fragment, useCallback, useState } from "react";
import { useCurrency } from "react-hook-currency";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FaArrowRight, FaDollarSign, FaPlus } from "react-icons/fa6";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { validateHasFile } from "utils/fileValidation";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";
import * as yup from "yup"
import { PaymentAttachmentField } from "./PaymentAttachmentField";

export interface PayVacationSchema {
  paymentDate: string
  paymentValue: string
  costCenter: string
  description: string
  paymentAttachments: {
    attachment: FileList
  }[]
}

interface PayVacationProps {
  vacationId: string
}

const payVacationSchema = yup.object({
  paymentDate: yup.string().required(),
  paymentValue: yup.string().required(),
  costCenter: yup.string().required(),
  description: yup.string().required(),
  paymentAttachments: yup
    .array()
    .min(1)
    .of(yup.object({
      attachment: yup.mixed().test(value => validateHasFile(value))
    }))
})

export interface PaymentAttachment {
  id: string
  attachmentId: string
}

export function PayVacation({ vacationId }: PayVacationProps) {
  const [paymentAttachments, setPaymentAttachments] = useState<PaymentAttachment[]>([])

  const {
    data: vacationResult,
    isLoading: isLoadingVacationResult
  } = useQuery({
    queryKey: ['vacation', vacationId],
    queryFn: () => getVacation({ vacationId })
  })

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<PayVacationSchema>({
    defaultValues: {
      paymentAttachments: [{ attachment: undefined }]
    },
    resolver: yupResolver(payVacationSchema)
  })

  const {
    fields: paymentAttachmentsFields,
    append: appendPaymentAttachmentField,
    remove: removePaymentAttachmentField,
  } = useFieldArray({
    control,
    name: 'paymentAttachments'
  })

  const { format: currencyFormat, onChange: onCurrencyChange } = useCurrency({
    style: 'decimal'
  })

  const queryClient = useQueryClient()

  const { mutateAsync: payVacationFn } = useMutation({
    mutationFn: payVacation,
    onSuccess(_data, variables) {
      const cached = queryClient.getQueriesData<GetVacationsResponse>({
        queryKey: ['vacations']
      })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          vacations: cachedValue.vacations.map((vacation) => {
            if (vacation.id === variables.vacationId) {
              return { ...vacation, status: 'waiting' }
            }

            return vacation
          })
        })
      })
    },
  })

  const { promiseMessage } = useToastify()

  async function handlePayVacation(values: PayVacationSchema) {
    await promiseMessage(payVacationFn({
      body: {
        ...values,
        paymentValueInCents: transformStringToNumber(values.paymentValue) * 100,
        paymentAttachmentsIds: paymentAttachments.map(attachment => attachment.attachmentId)
      },
      vacationId,
    }), 'Pagamento realizado com sucesso!')
  }

  const handleAddPaymentAttachment = useCallback((values: PaymentAttachment) => {
    setPaymentAttachments(prevAttachments => Array.from(new Set([...prevAttachments, values])))
  }, [])

  function handleRemovePaymentAttachment(index: number, fieldId: string) {
    setPaymentAttachments(prevAttachments => prevAttachments.filter(attachment => attachment.id !== fieldId))

    removePaymentAttachmentField(index)
  }

  const activeVacationPeriod = vacationResult?.vacation?.periods?.find(
    (period) => period.status === 'active'
  )

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Planejar férias
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        <Box
          as="form"
          w="full"
          onSubmit={handleSubmit(handlePayVacation)}
        >
          <Table size="sm">
            <Tbody>
              {isLoadingVacationResult ? (
                Array.from({ length: 2 }).map((_, index) => {
                  return (
                    <Tr key={index.toString()}>
                      <Td>
                        <Skeleton w="full" h="16px" />
                      </Td>
                      <Td>
                        <Skeleton w="full" h="16px" />
                      </Td>
                    </Tr>
                  )
                })
              ) : (
                <>
                  <Tr>
                    <Td>Período de férias</Td>
                    <Td isNumeric>
                      <Flex align="center" justify="end" gap={2}>
                        {format(new Date(activeVacationPeriod.start_date), 'dd/MM/yyyy')} <FaArrowRight fontSize="11px" /> {format(new Date(activeVacationPeriod.end_date), 'dd/MM/yyyy')}
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Colaborador</Td>
                    <Td isNumeric>{vacationResult?.vacation?.collaborator_name}</Td>
                  </Tr>
                </>
              )}

            </Tbody>
          </Table>

          <FormControl isInvalid={!!errors.paymentDate} mt={3}>
            <FormLabel fontSize="sm">
              Data de pagamento
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Input
              {...register("paymentDate")}
              name="paymentDate"
              type="date"
              size="sm"
              rounded="md"
            />
          </FormControl>

          <Stack
            spacing={3}
            direction={{ base: 'column', lg: 'row' }}
          >
            <FormControl isInvalid={!!errors.paymentDate} mt={3}>
              <FormLabel fontSize="sm">
                Valor
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Controller
                control={control}
                name="paymentValue"
                render={({ field }) => {
                  return (
                    <InputGroup size="sm">
                      <InputLeftAddon>
                        <FaDollarSign />
                      </InputLeftAddon>
                      <Input
                        {...register("paymentValue")}
                        name={field.name}
                        value={field.value}
                        onChange={event => field.onChange(onCurrencyChange(event))}
                        defaultValue={currencyFormat('000')}
                        rounded="md"
                      />
                    </InputGroup>
                  )
                }}
              />
            </FormControl>

            <FormControl isInvalid={!!errors.paymentDate} mt={3}>
              <FormLabel fontSize="sm">
                Centro de custo
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Input
                {...register("costCenter")}
                name="costCenter"
                size="sm"
                rounded="md"
              />
            </FormControl>

          </Stack>

          <FormControl isInvalid={!!errors.description} mt={3}>
            <FormLabel fontSize="sm">
              Descrição
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Textarea
              {...register("description")}
              name="description"
              size="sm"
              rounded="md"
            />
          </FormControl>

          <FormLabel fontSize="sm" mt={3}>
            Anexos de pagamento
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Table w="full" size="sm">
            <Tbody>
              {paymentAttachmentsFields.map((field, index) => {
                return (
                  <Fragment key={field.id}>
                    <PaymentAttachmentField
                      fieldId={field.id}
                      key={field.id}
                      errors={errors}
                      fieldIndex={index}
                      register={register}
                      onAddPaymentAttachment={handleAddPaymentAttachment}
                      onRemovePaymentAttachment={handleRemovePaymentAttachment}
                    />
                    <Tr>
                      <Td border="none"></Td>
                      <Td border="none"></Td>
                      <Td border="none"></Td>
                      <Td border="none"></Td>
                      <Td border="none"></Td>
                    </Tr>
                  </Fragment>
                )
              })}
            </Tbody>
          </Table>

          <HStack justify="end">
            <IconButton
              aria-label="Adicionar anexo"
              size="sm"
              icon={<FaPlus />}
              colorScheme="blue"
              onClick={() => appendPaymentAttachmentField({ attachment: undefined })}
            />
          </HStack>

          <HStack
            w="full"
            justify="end"
            mt={3}
          >
            <Button
              type="submit"
              colorScheme="blue"
              ml="auto"
              size="sm"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Salvar
            </Button>
          </HStack>
        </Box>


      </ModalBody>
    </ModalContent>
  )
}
