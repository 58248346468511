import { Button, Checkbox, CheckboxGroup, Flex, FormControl, FormLabel, IconButton, Input, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Progress, Stack, Table, Tbody, Td, Text, Tr, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { sendAdditivePartnerSupport } from "api/partnerSupport/sendAdditivePartnerSupport";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { useToastify } from "hooks/toastify/useToastify";
import { ChangeEvent } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup"

interface SendAdditivePartnerSupportProps {
  onCloseModal: () => void
  partnerSupportId: string
}

interface SendAdditivePartnerSupportSchema {
  additiveChecklist: string[]
  additiveAttachmentId: FileList
}

const sendAdditivePartnerSuppportSchema = yup.object({
  additiveChecklist: yup.array().of(yup.string()).nullable().required(),
})

const additives = [
  { name: 'Aditivo gerado' },
  { name: 'Dados alterados sistema' },
  { name: 'Enviado e-mail informativo para a operação' },
  { name: 'Aguardando validação de valores' },
]

export function SendAdditivePartnerSuppport({
  onCloseModal,
  partnerSupportId
}: SendAdditivePartnerSupportProps) {

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const { mutation: uploadAdditiveAttachmentFn, uploadProggress: uploadProggressAdditiveAttachment } = useAttachmentMutation()

  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<SendAdditivePartnerSupportSchema>({
    resolver: yupResolver(sendAdditivePartnerSuppportSchema)
  })

  const [additiveAttachmentId] = useWatch({
    control,
    name: ['additiveAttachmentId'],
  })

  async function handleUploadAdditiveAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadAdditiveAttachmentFn.mutateAsync(formData)

  }

  const { mutateAsync: sendAdditivePartnerSupportFn } = useMutation({
    mutationFn: sendAdditivePartnerSupport,
    onSuccess() {
      queryClient.invalidateQueries(['partnerSupports'])
      onCloseModal()
    }
  })


  async function handleSendAdditivePartnerSupport(values: SendAdditivePartnerSupportSchema) {
    await promiseMessage(sendAdditivePartnerSupportFn({
      body: {
        ...values,
        additiveAttachmentId: uploadAdditiveAttachmentFn?.data?.attachment?.id
      },
      routeParams: {
        partnerSupportId
      }
    }), 'Alteração/Envio aditivo realizada!')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Alteração/Envio aditivo
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        as="form"
        w="full"
        onSubmit={handleSubmit(handleSendAdditivePartnerSupport)}
      >
        <FormControl>
          <FormLabel>
            Aditivo
          </FormLabel>
          <Controller
            control={control}
            name="additiveChecklist"
            render={({ field }) => {
              return (
                <CheckboxGroup
                  value={field.value}
                  onChange={field.onChange}
                >
                  <VStack
                    spacing={3}
                    p={2}
                    border="1px solid"
                    borderColor="gray.200"
                    rounded="md"
                    align="left"
                  >
                    {additives.map(additive => {
                      return (
                        <Checkbox key={additive.name} value={additive.name}>
                          <Text fontSize="sm">
                            {additive.name}
                          </Text>
                        </Checkbox>
                      )
                    })}
                  </VStack>

                </CheckboxGroup>
              )
            }}
          />
        </FormControl>
        <Stack
          direction="column"
          w="full"
          mt="3"
        >
          <Button
            as={FormLabel}
            htmlFor="additiveAttachmentId"
            lineHeight="1"
            leftIcon={<FaFileImport />}
            size="sm"
            w="min"
            cursor="pointer"
            border={!!errors?.additiveAttachmentId && '2px solid'}
            borderColor={(!!errors?.additiveAttachmentId) && 'red.500'}
          >
            Anexar Aditivo
          </Button>
          <FormControl isInvalid={!!errors?.additiveAttachmentId}>
            <Input
              {...register('additiveAttachmentId')}
              name="additiveAttachmentId"
              id="additiveAttachmentId"
              type="file"
              hidden
              onChangeCapture={handleUploadAdditiveAttachment}
            />
          </FormControl>
        </Stack>

        {additiveAttachmentId && (
          <Table size="sm">
            <Tbody>
              {Object.entries(additiveAttachmentId).map(([key, file]) => {
                return (
                  <Tr key={key}>
                    <Td fontSize="xs" maxW="100px">{file.name}</Td>
                    <Td fontSize="xs" w="200px">
                      <Progress size="sm" rounded="md" value={uploadProggressAdditiveAttachment} />
                    </Td>
                    <Td fontSize="xs" isNumeric>
                      {uploadAdditiveAttachmentFn.data && (
                        <IconButton
                          aria-label="Visualizar anexo"
                          as={Link}
                          size="sm"
                          icon={<FaExternalLinkAlt />}
                          href={uploadAdditiveAttachmentFn.data.attachment.link}
                          isExternal
                        />
                      )}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        )}
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Salvar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}


