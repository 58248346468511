import { usePagination } from "@ajna/pagination";
import { Box, Button, Divider, Flex, Heading, Icon, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { Pagination } from "components/Pagination/Pagination";

import { useAuth } from "hooks/auth/useAuth";
import { useCltDriverFunctions } from "hooks/cltDriver/useCltDriverFunctions";
import { useCltDrivers } from "hooks/cltDriver/useCltDrivers";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { FaPen, FaTrash } from "react-icons/fa";
import { useQueryClient } from "react-query";
import { Link, useHistory } from "react-router-dom";

interface CltDriversListInputs {
  search: string
}

export function CltDriversList() {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const [searchDebounced, setSearchDebounced] = useState(undefined)
  const [paginationTotalPages, setPaginationTotalPages] = useState(0)

  const { push: redirect } = useHistory()
  const { userLogged } = useAuth()

  const userHasViewCltDriversPermission = userLogged?.permissions.includes('view-clt-drivers')
  const userHasDeleteCltDriverPermission = userLogged?.permissions.includes('delete-clt-driver')
  const userHasEditCltDriverPermission = userLogged?.permissions.includes('edit-clt-driver')

  useEffect(() => {
    if (!userHasViewCltDriversPermission) {
      redirect('/')
    }
  }, [userHasViewCltDriversPermission, redirect])

  const {
    control,
    register,
  } = useForm<CltDriversListInputs>()

  const [searchValue] = useWatch({
    control,
    name: ['search']
  })


  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: paginationTotalPages,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    });

  useEffect(() => {
    const debounce = setTimeout(() => {
      setSearchDebounced(searchValue)
      setCurrentPage(1)
    }, 1000)

    return () => clearTimeout(debounce)
  }, [searchValue, setCurrentPage])

  const handlePageChange = (page: number) => setCurrentPage(page)

  const {
    data,
    isFetching: isFethingCltDrivers
  } = useCltDrivers({
    queryParams: {
      pageSize: Number(itemLimit),
      currentPage,
      search: searchDebounced
    },
  })

  useEffect(() => {
    setPaginationTotalPages(data?.totalPages)
  }, [data])

  const { promiseMessage } = useToastify()

  const {
    deleteCltDriver: {
      mutateAsync: deleteCltDriver
    }
  } = useCltDriverFunctions()

  const queryClient = useQueryClient()

  async function handleDeleteCltDriver(id: string) {
    await promiseMessage(deleteCltDriver(id, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['cltDrivers'])
      }
    }), 'Cadastro de motorista clt excluído com sucesso')
  }

  return (

      <Box
        borderRadius='8px'
        p={4}
        bg='white'
      >
        <Flex
          gap={4}
          direction='column'
        >

          <Heading size='md'>Motoristas Clt</Heading>

          <Divider />

          <Input
            {...register('search')}
            name='search'
            placeholder='Buscar por palavra chave'
            label='Buscar'
          />

          {isFethingCltDrivers ? (
            <Spinner />
          ) : (
            <TableContainer>
              <Table variant='striped' size='sm'>
                <Thead>
                  <Tr>
                    <Th>MOTORISTA</Th>
                    <Th>REGIONAL</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.cltDrivers?.map(cltDriver => {
                    return (
                      <Tr key={cltDriver.id}>
                        <Td>{`${cltDriver.driver.firstname} ${cltDriver.driver.lastname}`}</Td>
                        <Td>{cltDriver.regional}</Td>
                        <Td isNumeric>
                          {userHasEditCltDriverPermission && (
                            <Button
                              as={Link}
                              to={`/motorista-clt/editar/${cltDriver.id}`}
                              variant='ghost'
                            >
                              <Icon as={FaPen} />
                            </Button>
                          )}
                          {userHasDeleteCltDriverPermission && (
                            <Button
                              colorScheme='red'
                              variant='ghost'
                              onClick={async () => await handleDeleteCltDriver(cltDriver.id)}
                            >
                              <Icon as={FaTrash} />
                            </Button>
                          )}
                        </Td>
                      </Tr>
                    )
                  })}

                </Tbody>
              </Table>
            </TableContainer>
          )}

          <Pagination
            currentPage={currentPage}
            pages={pages}
            pagesQuantity={pagesCount}
            handlePageChange={handlePageChange}
          />
        </Flex>
      </Box>


  )
}
