import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getTrainingsLlm } from "api/trainingLlm/getTrainingsLlm";
import { TrainingLlmStatus } from "api/trainingLlm/trainingLlm";
import { Pagination } from "components/Pagination/Pagination";

import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CreateTrainingLlm } from "./components/CreateTrainingLlm";
import { TrainingsLlmTableFilter } from "./components/TrainingsLlmTableFilter";
import { TrainingsLlmTableRow } from "./components/TrainingsLlmTableRow";

export function TrainingsLlm() {
  const { replace, push: redirect } = useHistory()
  const searchParams = useSearchParams()

  const { userLogged } = useAuth()

  const userHasViewTrainingsLlmPermission = userLogged?.permissions.includes('view-trainings-llm')

  const userHasCreateTrainingLlmPermission = userLogged?.permissions.includes('create-llm-training')

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status') ?? 'all'
  const collaborator = searchParams.get('collaborator')

  const { data: trainingsLlmData } = useQuery({
    queryKey: [
      'trainingsLlm',
      page,
      status,
      collaborator,
    ],
    queryFn: () => getTrainingsLlm({
      currentPage: page,
      pageSize: '10',
      collaborator,
      status: status as TrainingLlmStatus | 'all'
    }),
    keepPreviousData: true
  })

  useEffect(() => {
    if (!userHasViewTrainingsLlmPermission) {
      redirect('/')
    }
  }, [userHasViewTrainingsLlmPermission, redirect])


  const {
    isOpen: isCreateTrainingLlmModalOpen,
    onOpen: onOpenCreateTrainingLlmModal,
    onClose: onCloseCreateTrainingLlmModal
  } = useDisclosure()

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: trainingsLlmData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: trainingsLlmData?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  return (
    <>
      <Box
        bg="white"
        p="6"
        rounded="md"
      >
        <Flex
          align="center"
          justify="space-between"
        >
          <Heading letterSpacing="tight">Treinamentos LLM</Heading>
          {userHasCreateTrainingLlmPermission && (
            <IconButton
              aria-label="Criar Treinamento"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateTrainingLlmModal}
            />
          )}
          <Modal
            isOpen={isCreateTrainingLlmModalOpen}
            onClose={onCloseCreateTrainingLlmModal}
            isCentered
          >
            <ModalOverlay />
            <CreateTrainingLlm onCloseModal={onCloseCreateTrainingLlmModal} />
          </Modal>
        </Flex>
        <TrainingsLlmTableFilter />
        <TableContainer
          borderColor="gray.200"
          rounded="md"
          mt="6"
        >
          <Table size="sm">
            <Thead>
              <Tr
                h="40px"
                bg="#38c3fa"
              >
                <Th></Th>
                <Th color="white">Usuário</Th>
                <Th color="white">Treinamento</Th>
                <Th color="white">Status</Th>
                <Th color="white">Data limite para realização</Th>
                <Th color="white">Data da aprovação</Th>
                <Th color="white">Data da reprovação</Th>
                <Th></Th>
                <Th></Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {trainingsLlmData?.llmTrainings?.map((trainingLlm) => {
                return (
                  <TrainingsLlmTableRow trainingLlm={trainingLlm} />
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </>
  )
}
