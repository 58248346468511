import { ChangeEvent, createContext, ReactNode, useState } from "react";

interface Filters {
  aggregate: string
  startDate: string
  endDate: string
  route: string
}

interface AggregateExtrasDiscountsListContextProps {
  filters: Filters
  handleSetInputFilters: (event: ChangeEvent<HTMLInputElement>) => void
}

interface AggregateExtrasDiscountsListContextProviderProps {
  children: ReactNode
}

export const AggregateExtrasDiscountsListContext = createContext(
  {} as AggregateExtrasDiscountsListContextProps
)

export function AggregateExtrasDiscountsListContextProvider({
  children
}: AggregateExtrasDiscountsListContextProviderProps) {
  const [filters, setFilters] = useState({} as Filters)

  function handleSetInputFilters(event: ChangeEvent<HTMLInputElement>) {
    setFilters(prevFilters => {
      return {
        ...prevFilters,
        [event.target.name]: event.target.value
      }
    })
  }

  return (
    <AggregateExtrasDiscountsListContext.Provider value={{
      filters,
      handleSetInputFilters
    }}>
      {children}
    </AggregateExtrasDiscountsListContext.Provider>
  )
}
