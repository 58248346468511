import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit3, FiEye, FiTrash2 } from 'react-icons/fi'
import { Pagination } from '../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'

import { EmptyContentTableAlert } from '../../Alerts/EmptyContentTableAlert'
import { useSwal } from '../../../hooks/swal/useSwal'
import { useAuth } from '../../../hooks/auth/useAuth'
import { PriceProps } from '../../../utils/RequestFunctions/Price/requestPriceFunctions'
import { usePriceFunctions } from '../../../hooks/price/usePriceFunctions'
import { statesWithUfList } from '../../../utils/CustomLists/statesWithUfList'
import { ProviderProps } from 'contexts/ProviderContext'
import { InputProps } from 'contexts/InputContext'

interface IRequestedMaterialsProps {
  material: string
  quantity: number
  price: number
  totalPrice: string
}

interface IConsultPriceTableProps {
  prices: PriceProps[]
  taxedWeight: number
  materials?: IRequestedMaterialsProps[]
  inputs?: InputProps[]
  providers: ProviderProps[]
  hasGeloSeco: boolean
  geloSecoQuantity: number
}

const priceOptions = [
  { key: 'sourceCityUf', value: 'UF Origem', checked: false },
  { key: 'sourceCity', value: 'Cidade Origem', checked: false },
  { key: 'destinationCityUf', value: 'UF Destino', checked: false },
  { key: 'destinationCity', value: 'Cidade Destino', checked: false },
  { key: 'category', value: 'Tipo de Material', checked: false },
  { key: 'airTotalPrice', value: 'Preço Total Aéreo', checked: false },
  { key: 'airExtraKg', value: 'Preço do KG Extra Aéreo', checked: false },
  { key: 'airDeadline', value: 'Prazo Aéreo', checked: false },
  { key: 'highwayTotalPrice', value: 'Preço Total Rodoviário', checked: false },
  { key: 'highwayExtraKg', value: 'Preço do KG Extra Rodoviário', checked: false },
  { key: 'highwayDeadline', value: 'Prazo Rodoviário', checked: false },
  { key: 'priceEmbalagens', value: 'Valor das Embalagens/Gelox', checked: false },
  { key: 'priceGeloSeco', value: 'Valor do Gelo Seco', checked: false },
  { key: 'materialsTotalPrice', value: 'Valor Total dos Insumos', checked: false },
  { key: 'airTransferBudget', value: 'Budget de transferência Aéreo', checked: false },
  { key: 'highwayTransferBudget', value: 'Budget de transferência Rodoviário', checked: false },
]

export function ConsultPriceTable({
  prices,
  taxedWeight,
  materials,
  inputs,
  providers,
  hasGeloSeco,
  geloSecoQuantity,
}: IConsultPriceTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const {
    deletePrice: { mutateAsync: deletePrice },
  } = usePriceFunctions()

  const { confirmMessage, standardMessage } = useSwal()
  const { userLogged } = useAuth()

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: prices?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  const handlePageChange = (page: number) => {
    return setCurrentPage(page)
  }


  async function handleDeletePrice(id: string) {
    const hasConfirmed = await confirmMessage({
      title: 'Deseja excluir um preço?',
    })

    if (hasConfirmed) {
      await deletePrice(id)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (prices.length <= 0) {
    return (
      <EmptyContentTableAlert
        title="Oops!"
        description="Não há dados para mostrar aqui!"
      />
    )
  }

  const isUserLoggedCustomer = userLogged?.user_type === 'CLIENTE' || userLogged?.user_type === 'REMETENTE'

  return (
    <>
      <Box overflowX="auto" w="full">
        <Table w="full" colorScheme="gray" variant="striped" size="sm">
          <Thead>
            <Tr>
              {priceOptions
                .filter((option) => {
                  const headers = !['airTransferBudget', 'highwayTransferBudget'].includes(option.key)
                  return headers
                })
                .map((option) => {
                  return (
                    <Th key={option.key} fontSize="12" color="blue.900">
                      {option.value.toUpperCase()}
                    </Th>
                  )
                })}
              {materials?.map((material) => (
                <Th key={material.material} fontSize="12" color="blue.900">
                  {material.material.toUpperCase()}
                </Th>
              ))}
              {priceOptions.filter((option) => {
                const headers = userLogged?.loglife_employee && !isUserLoggedCustomer
                  ? ["airTransferBudget", "highwayTransferBudget"].includes(option.key)
                  : [].includes(option.key)

                return headers
              }).map((option) => {
                return (
                  <Th key={option.key} fontSize="12" color="blue.900">
                    {option.value.toUpperCase()}
                  </Th>
                )
              })}
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {prices
              .slice(offset, offset + Number(itemLimit))
              .map((price) => {
                const materialsTotalCost = materials
                  ? materials
                    .reduce((acc, curr) => acc += Number(curr.totalPrice), 0)
                  : 0

                const providerBySourceHub = providers?.find(
                  (provider) => provider.hub_id === price.sourceHubIDPrice.id && provider.is_primary_provider
                )

                const priceGeloSeco = !!providerBySourceHub && hasGeloSeco ? providerBySourceHub.material_price * geloSecoQuantity : 0


                const materialsTotalPrice = priceGeloSeco + materialsTotalCost

                const airTotalPrice = price.air_minimum_price !== null
                  ? taxedWeight > 2
                    ? price.air_minimum_price +
                    (price.air_extra_kg * (taxedWeight - 2)) + materialsTotalCost + priceGeloSeco
                    : price.air_minimum_price + materialsTotalCost + priceGeloSeco
                  : 0

                const highwayTotalPrice = price.highway_minimum_price !== null
                  ? taxedWeight > 10
                    ? price.highway_minimum_price +
                    (price.highway_extra_kg * (taxedWeight - 10)) + materialsTotalCost + priceGeloSeco
                    : price.highway_minimum_price + materialsTotalCost + priceGeloSeco
                  : 0

                const airTransferBudgetValue = taxedWeight > 2
                  ? 150 + 25 * (taxedWeight - 2)
                  : 150

                const highwayTransferBudgetValue = taxedWeight > 10
                  ? 100 + 5 * (taxedWeight - 10)
                  : 100

                return (
                  <Tr key={price.id}>
                    <Td fontSize="12">
                      {
                        Object.entries(statesWithUfList)
                          .filter(
                            ([key, value]) =>
                              key.toUpperCase() === price.sourceCityIDPrice.state,
                          )
                          .map(([key, value]) => value.uf)[0]
                      }
                    </Td>
                    <Td fontSize="12">
                      {price.sourceCityIDPrice.name.toUpperCase()}
                    </Td>
                    <Td fontSize="12">
                      {
                        Object.entries(statesWithUfList)
                          .filter(
                            ([key, value]) =>
                              key.toUpperCase() ===
                              price.destinationCityIDPrice.state,
                          )
                          .map(([key, value]) => value.uf)[0]
                      }
                    </Td>
                    <Td fontSize="12">
                      {price.destinationCityIDPrice.name.toUpperCase()}
                    </Td>
                    <Td fontSize='12'>{price.category}</Td>
                    <Td fontSize="12">
                      {`R$ ${airTotalPrice.toFixed(2).replace('.', ',')}`}
                    </Td>
                    <Td fontSize="12">
                      {price.air_extra_kg !== null ? new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      }).format(price.air_extra_kg) : '-'}
                    </Td>
                    <Td fontSize="12">
                      {price.air_deadline !== null
                        ? `D + ${price.air_deadline}`
                        : '-'
                      }
                    </Td>
                    <Td fontSize="12">
                      {`R$ ${highwayTotalPrice.toFixed(2).replace('.', ',')}`}
                    </Td>
                    <Td fontSize="12">
                      {price.highway_extra_kg !== null
                        ? price.highway_extra_kg
                        : '-'}
                    </Td>
                    <Td fontSize="12">
                      {price.highway_deadline !== null
                        ? `D + ${price.highway_deadline}`
                        : '-'
                      }
                    </Td>
                    <Td fontSize="12">{
                      new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      }).format(materialsTotalCost)
                    }</Td>
                    <Td fontSize="12">{
                      new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      }).format(priceGeloSeco)
                    }</Td>
                    <Td fontSize="12">{
                      new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      }).format(materialsTotalPrice)
                    }</Td>
                    {materials?.map((material) => (
                      <Td key={material.material} fontSize="12">
                        {`R$ ${material.totalPrice}`}
                      </Td>
                    ))}
                    {userLogged?.loglife_employee === true && !isUserLoggedCustomer && (
                      <>
                        <Td fontSize="12">
                          {
                            new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(airTransferBudgetValue)
                          }
                        </Td>
                        <Td fontSize="12">
                          {
                            new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(highwayTransferBudgetValue)
                          }
                        </Td>
                      </>
                    )}

                    <Td fontSize="12" isNumeric>
                      {userLogged !== null && (
                        <>
                          {userLogged?.permissions.includes('view-price') && (
                            <Link to={`/preco/visualizar/${price.id}`}>
                              <Icon cursor="pointer" as={FiEye} fontSize="20" />
                            </Link>
                          )}
                          {userLogged?.permissions.includes('edit-price') && (
                            <Link to={`/preco/editar/${price.id}`}>
                              <Icon
                                cursor="pointer"
                                as={FiEdit3}
                                fontSize="20"
                                ml="4"
                                mt={['2', '2', '0']}
                              />
                            </Link>
                          )}
                          {userLogged?.permissions.includes('remove-price') && (
                            <Icon
                              cursor="pointer"
                              as={FiTrash2}
                              fontSize="20"
                              ml="4"
                              mt={['2', '2', '0']}
                              onClick={async () => handleDeletePrice(price.id)}
                            />
                          )}
                        </>
                      )}
                    </Td>
                  </Tr>
                )
              })}
          </Tbody>
        </Table>
      </Box>
      <Pagination
        handlePageChange={handlePageChange}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />
    </>
  )
}
