import { IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react"
import { Regional } from "api/regionals/_types/Regional"
import { Sporadic } from "api/sporadics/_types/Sporadic"
import { format } from "date-fns"
import { FaSearch } from "react-icons/fa"
import { SporadicDetail } from "./SporadicDetail"
import { SporadicStatus } from "./SporadicStatus"

interface SporadicTableRowProps {
  sporadic: Sporadic & {
    regional: Regional
  }
}

export function SporadicTableRow({ sporadic }: SporadicTableRowProps) {

  const {
    isOpen: isSporadicDetailModalOpen,
    onToggle: onToggleSporadicDetailModal,
  } = useDisclosure()

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar Esporádico"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onToggleSporadicDetailModal}
        />

        <Modal
          isOpen={isSporadicDetailModalOpen}
          onClose={onToggleSporadicDetailModal}
          size='6xl'
        >
          <ModalOverlay />
          <SporadicDetail
            sporadicId={sporadic.id}
            onClose={onToggleSporadicDetailModal}
          />
        </Modal>
      </Td>
      <Td>{sporadic.protocol}</Td>
      <Td>{format(new Date(sporadic?.startDate), "dd/MM/yyyy '-' HH:mm")}</Td>
      <Td>{format(new Date(sporadic?.finalDate), "dd/MM/yyyy '-' HH:mm")}</Td>
      <Td>{sporadic?.regional?.name}</Td>
      <Td>
        <SporadicStatus status={sporadic.status} />
      </Td>

    </Tr>
  )
}
