import { Button, Flex, FormControl, FormLabel, Input, InputGroup, InputLeftAddon, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { approveCltIntercurrence } from "api/cltIntercurrences/approveCltIntercurrence"
import { GetCltIntercurrencesResponse } from "api/cltIntercurrences/getCltIntercurrences"
import { set } from "date-fns"
import { useToastify } from "hooks/toastify/useToastify"
import { useCurrency } from "react-hook-currency"
import { Controller, useForm, useWatch } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber"
import * as yup from "yup"

interface ApproveTicketLogAdditionalValueCltIntercurrenceSchema {
  additionalValueWasReleased: string
  releaseDate: string
  releasedValue: string
}

const approveTicketLogAdditionalValueCltIntercurrenceSchema = yup.object({
  additionalValueWasReleased: yup.string().required(),
  releaseDate: yup.mixed().when('additionalValueWasReleased', {
    is: 'yes',
    then: yup.string().required()
  }),
  releasedValue: yup.mixed().when('additionalValueWasReleased', {
    is: 'yes',
    then: yup.string().required()
  }),
})

interface ApproveTicketLogAdditionalValueCltIntercurrenceProps {
  cltIntercurrenceId: string
}

export function ApproveTicketLogAdditionalValueCltIntercurrence({
  cltIntercurrenceId
}: ApproveTicketLogAdditionalValueCltIntercurrenceProps) {
  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    }
  } = useForm<ApproveTicketLogAdditionalValueCltIntercurrenceSchema>({
    resolver: yupResolver(approveTicketLogAdditionalValueCltIntercurrenceSchema)
  })

  const { format: currencyFormat, onChange: onCurrencyChange } = useCurrency({
    style: 'decimal'
  })

  const additionalValueWasReleased = useWatch({
    control,
    name: 'additionalValueWasReleased'
  })

  const isAdditionalValueWasReleased = additionalValueWasReleased === 'yes'

  const queryClient = useQueryClient()

  const { mutateAsync: approveCltIntercurrenceFn } = useMutation({
    mutationFn: approveCltIntercurrence,
    onSuccess: (_data, { routeParams }) => {
      const cached = queryClient.getQueriesData<GetCltIntercurrencesResponse>({
        queryKey: ['clt-intercurrences']
      })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          cltIntercurrences: cachedValue.cltIntercurrences.map((cltIntercurrence) => {
            if (cltIntercurrence.id === routeParams.cltIntercurrenceId) {
              return { ...cltIntercurrence, status: 'approved' }
            }

            return cltIntercurrence
          })
        })
      })
    }
  })

  const { promiseMessage } = useToastify()

  async function handleApproveCltIntercurrence(values: ApproveTicketLogAdditionalValueCltIntercurrenceSchema) {
    const body = {}

    body['additionalValueWasReleased'] = values.additionalValueWasReleased === 'yes'

    if (values.additionalValueWasReleased === 'yes') {
      const [year, month, day] = values.releaseDate.split('-').map(Number)

      body['releaseDate'] = set(new Date(), {
        year,
        month: month - 1,
        date: day
      })

      body['releasedValueInCents'] = Math.ceil(transformStringToNumber(values.releasedValue) * 100)
    }

    await promiseMessage(approveCltIntercurrenceFn({
      body,
      routeParams: {
        cltIntercurrenceId
      }
    }), 'Intercorrência aprovada com sucesso!')
  }

  return (
    <Flex
      direction="column"
      gap="3"
      as="form"
      onSubmit={handleSubmit(handleApproveCltIntercurrence)}
    >

      <FormControl isInvalid={!!errors.additionalValueWasReleased}>
        <FormLabel>
          Valor adicional liberado?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="additionalValueWasReleased"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="column">
                <Radio value="yes">Sim</Radio>
                <Radio value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>


      {isAdditionalValueWasReleased && (
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing="3"
        >
          <FormControl isInvalid={!!errors.additionalValueWasReleased}>
            <FormLabel>
              Data efetiva da liberação
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Input
              {...register('releaseDate')}
              type="date"
            />

          </FormControl>

          <FormControl isInvalid={!!errors.releasedValue}>
            <FormLabel>
              Valor liberado
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <InputGroup>
              <InputLeftAddon>R$</InputLeftAddon>
              <Input
                {...register('releasedValue')}
                onChange={onCurrencyChange}
                defaultValue={currencyFormat('000')}
              />
            </InputGroup>

          </FormControl>

        </Stack>
      )}

      <Button
        type="submit"
        colorScheme="blue"
        w="min"
        alignSelf="end"
        disabled={isSubmitting}
        isLoading={isSubmitting}
      >
        Salvar
      </Button>
    </Flex>
  )
}
