import { Button, Flex, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { GetDocumentsResponse } from "api/documents/getDocuments";
import { inactivateDocument } from "api/documents/inactivateDocument";
import { Document } from "api/documents/types/Document";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect } from "react";
import { FaArrowRight, FaTimes } from "react-icons/fa";
import { RiSearchLine } from "react-icons/ri";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { DocumentDetail } from "./DocumentDetail";
import { DocumentStatus } from "./DocumentStatus";
import { EvaluateDocumentRequest } from "./EvaluateDocumentRequest";
import { ReviewDocumentRequest } from "./ReviewDocumentRequest";

interface DocumentsTableRowProps {
  document: Document
}

export function DocumentsTableRow({ document }: DocumentsTableRowProps) {

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanViewDocuments = userLogged?.permissions?.includes('view-documents')

  const userCanInactiveDocument = userLogged?.permissions?.includes('inactive-document')
  const userCanReviewDocument = userLogged?.permissions?.includes('review-document')
  const userCanEvaluateDocument = userLogged?.permissions?.includes('evaluate-document')

  useEffect(() => {
    if (!userCanViewDocuments) history.push('/')
  }, [history, userCanViewDocuments])

  const {
    isOpen: isDocumentDetailModalOpen,
    onOpen: onOpenDocumentDetailModal,
    onClose: onCloseDocumentDetailModal,
  } = useDisclosure()

  const {
    isOpen: isAvaluateDocumentRequestModalOpen,
    onOpen: onOpenAvaluateDocumentRequestModal,
    onClose: onCloseAvaluateDocumentRequestModal,
  } = useDisclosure()

  const {
    isOpen: isReviewDocumentRequestModalOpen,
    onOpen: onOpenReviewDocumentRequestModal,
    onClose: onCloseReviewDocumentRequestModal,
  } = useDisclosure()

  const { mutateAsync: inactivateDocumentFn } = useMutation({
    mutationFn: inactivateDocument,
    onSuccess: (_data, { routeParams }) => {
      const cached = queryClient.getQueriesData<GetDocumentsResponse>({
        queryKey: ['documents']
      })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          documents: cachedValue.documents.map((document) => {
            if (document.id === routeParams.documentId) {
              return { ...document, status: 'inactive' }
            }

            return document
          })
        })
      })
    }
  })

  async function handleInactivateDocument() {
    await promiseMessage(inactivateDocumentFn({
      routeParams: {
        documentId: document.id
      }
    }), 'Documento inativado!')
  }

  return (
    <Tr>
      <Td>
        <Modal
          isOpen={isDocumentDetailModalOpen}
          onClose={onCloseDocumentDetailModal}
        >
          <ModalOverlay />

          <DocumentDetail documentId={document.id} />

        </Modal>
        <IconButton
          aria-label="Detalhes do documento"
          icon={<RiSearchLine />}
          onClick={onOpenDocumentDetailModal}
          size="sm"
        />
      </Td>
      <Td>{document.name}</Td>
      <Td>{document.document_type}</Td>
      <Td>{document.classification ?? '-'}</Td>
      <Td>
        <DocumentStatus status={document.status} />
      </Td>
      <Td>
        <Flex align="center" gap="2">
          {userCanEvaluateDocument && document?.status === 'non-evaluated' && (
            <>
              <Modal
                isOpen={isAvaluateDocumentRequestModalOpen}
                onClose={onCloseAvaluateDocumentRequestModal}
                size="4xl"
              >
                <ModalOverlay />
                <EvaluateDocumentRequest
                  documentId={document?.id}
                  onCloseModal={onCloseAvaluateDocumentRequestModal}
                />
              </Modal>
              <Button
                size="xs"
                lineHeight="1"
                leftIcon={<FaArrowRight />}
                onClick={onOpenAvaluateDocumentRequestModal}
              >
                Avaliar
              </Button>
            </>
          )}
          {userCanReviewDocument && document?.status === 'expired' && (
            <>
              <Modal
                isOpen={isReviewDocumentRequestModalOpen}
                onClose={onCloseReviewDocumentRequestModal}
                size="4xl"
              >
                <ModalOverlay />
                <ReviewDocumentRequest
                  onCloseModal={onCloseReviewDocumentRequestModal}
                  documentId={document?.id}
                />
              </Modal>
              <Button
                size="xs"
                lineHeight="1"
                leftIcon={<FaArrowRight />}
                onClick={onOpenReviewDocumentRequestModal}
              >
                Avaliar
              </Button>
            </>
          )}
          {( userCanInactiveDocument && document.status !== 'inactive') && (
            <Button
              leftIcon={<FaTimes />}
              lineHeight="1"
              size="xs"
              variant="ghost"
              onClick={handleInactivateDocument}
            >
              Inativar
            </Button>
          )}
        </Flex>
      </Td>
    </Tr>
  )
}
