import { useProviders } from 'hooks/provider/useProviders'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ConsultPriceForm } from '../../components/Form/Price/ConsultPriceForm'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useAuth } from '../../hooks/auth/useAuth'
import { useCity } from '../../hooks/city/useCity'
import { usePriceFunctions } from '../../hooks/price/usePriceFunctions'

interface IFormConsultInputProps {
  source_cities: {
    source_uf: string
    source_city_id: string
  }[]
  destination_cities: {
    destination_uf: string
    destination_city_id: string
  }[]
  route_type: string
  taxed_weight: number
  vehicle: string
}


export function ConsultPrice() {
  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  const {
    cities: { data: cities, isLoading: isCitiesLoading },
  } = useCity(null, true)
  const {
    consultPrice: {
      mutateAsync: consultPrice,
      isLoading: isConsultPriceLoading,
      isSuccess: isConsultPriceSuccess,
      data: consultPriceData,
    },
  } = usePriceFunctions()

  const { data: providers, isFetching: isFetchingProviders } = useProviders()

  useEffect(() => {
    if (!userLogged?.permissions.includes('consult-price')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  async function handleConsultPrice(values: IFormConsultInputProps) {

    const { source_cities, destination_cities, ...consultPriceDataFormatted } = {
      ...values,
      source_cities_ids: values.source_cities.map((city) => city.source_city_id),
      source_ufs: Array.from(new Set(values.source_cities.map((city) => city.source_uf))),
      destination_cities_ids: values.destination_cities.map((city) => city.destination_city_id),
      destination_ufs: Array.from(new Set(values.destination_cities.map((city) => city.destination_uf))),
    }

    await consultPrice(consultPriceDataFormatted)
  }

  if (isCitiesLoading || isFetchingProviders) {
    return <GeneralContentLoading />
  }

  return (
    <>
      {cities && (
        <ConsultPriceForm
          submit={handleConsultPrice}
          cities={cities}
          providers={providers}
          prices={consultPriceData}
          isConsultPriceLoading={isConsultPriceLoading}
          isConsultPriceSuccess={isConsultPriceSuccess}
        />
      )}
    </>
  )
}
