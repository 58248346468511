import { Flex, Stack } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";

import { Select } from "components/Inputs/SelectInput";
import {

  useFormContext,

} from "react-hook-form";

import { situation } from "utils/customLists";
import { Material } from "hooks/material/dtos/Material";
import { GeneralProviderResponse } from "hooks/generalProvider/dtos/GeneralProvider";
import { useCurrency } from "react-hook-currency";
import { WarehouseResponse } from "hooks/warehouse/dtos/Warehouse";
import { useEffect } from "react";

interface ProductFormProps {
  slug: string
  materials?: Material[]
  generalProviders?: GeneralProviderResponse
  warehouses?: WarehouseResponse
}

export interface ICostCenters {
  name: string
}

export interface ProductFormInputs {
  situation: string
  material_id: string
  general_provider_id: string
  warehouse_id: string,
  measure: string
  unit_cost: number
  min_stock: number
  standard_stock: number
  current_stock: number
}

export function ProductForm({ slug, materials, generalProviders, warehouses }: ProductFormProps) {
  const isViewPage = slug === "visualizar"

  const {
    register,
    setValue,
    formState: {
      errors
    }
  } = useFormContext<ProductFormInputs>()

  useEffect(() => {
    if (slug === 'criar') {
      setValue('situation', 'ATIVO')
    }
  }, [setValue, slug])


  const materialsOptions = materials?.map(material => {
    return { key: material.id, value: material.id, showOption: material.material_name }
  })

  const generalProvidersOptions = generalProviders?.generalProviders?.map(generalProvider => {
    return { key: generalProvider.id, value: generalProvider.id, showOption: generalProvider.trading_name }
  })

  const warehousesOptions = warehouses?.warehouses.map(warehouse => {
    return { key: warehouse.id, value: warehouse.id, showOption: warehouse.name }
  })

  const { onChange, format } = useCurrency({
    style: 'decimal',
  })

  return (
    <Flex direction="column" gap={6} align="center">
      <Stack spacing={6}
        direction={["column", "column", "row"]}
        w="full">
        <Select
          {...register('situation')}
          name="situation"
          label="Situação"
          error={errors.situation}
          isDisabled={isViewPage || slug === 'criar'}
          situations={situation}
          placeholder="Selecione uma opção..."
          required
        />
        <Stack
          w="full"
          spacing="24px"
          mt="4"
          direction={['column', 'column', 'row']}
        >
          <Select
            {...register(`material_id`)}
            name={`material_id`}
            label='Material'
            placeholder='Selecione uma opção...'
            error={errors.material_id}
            options={materialsOptions}
            isDisabled={isViewPage}
            required />
        </Stack>
      </Stack>
      <Stack spacing={6}
        direction={["column", "column", "row"]}
        w="full">
        <Select
          {...register(`warehouse_id`)}
          name={`warehouse_id`}
          label='Almoxarifado'
          placeholder='Selecione uma opção...'
          error={errors.warehouse_id}
          options={warehousesOptions}
          isDisabled={isViewPage}
          required />

        <Select
          {...register('general_provider_id')}
          name={'general_provider_id'}
          label='Fornecedor'
          placeholder='Selecione uma opção...'
          error={errors.general_provider_id}
          options={generalProvidersOptions}
          isDisabled={isViewPage}
          required />

      </Stack>
      <Stack spacing={6}
        direction={["column", "column", "row"]}
        w="full"
      >
        <Input
          {...register('measure')}
          name="measure"
          label="Medida"
          error={errors.measure}
          isDisabled={isViewPage}
          required
        />
        <Input
          {...register('unit_cost')}
          isDisabled={isViewPage}
          name="unit_cost"
          label="Custo Unitário"
          addChildren="R$"
          onChange={onChange}
          defaultValue={format('000')}
          error={errors.unit_cost}
          required
        />
      </Stack>

      <Stack
        w="full"
        spacing="24px"
        direction={["column", "column", "row"]}
      >

        <Stack
          w="full"
          spacing="24px"
          direction={["column", "column", "row"]}
        >
          <Input
            {...register('min_stock')}
            isDisabled={isViewPage}
            name="min_stock"
            type="number"
            label="Estoque Mínimo"
            error={errors.min_stock}
            required
          />
          <Input
            {...register('standard_stock')}
            isDisabled={isViewPage}
            name="standard_stock"
            type="number"
            label="Estoque Padrão"
            error={errors.standard_stock}
            required
          />
        </Stack>
        <Input
          {...register('current_stock')}
          isDisabled={isViewPage}
          name="current_stock"
          type="number"
          label="Estoque Atual"
          error={errors.standard_stock}
          required
        />
      </Stack>

    </Flex>
  )
}
