import { ModalContent, ModalHeader, ModalCloseButton, ModalBody, Flex, Button, Checkbox, FormControl, FormLabel, IconButton, Input, Link, Progress, Radio, RadioGroup, Select, Stack, Table, Tbody, Td, Tr, Text } from "@chakra-ui/react"
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { set } from "date-fns";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { ChangeEvent } from "react";
import { useToastify } from "hooks/toastify/useToastify";
import { validateHasFile } from "utils/fileValidation";
import { createVaccine } from "api/vaccines/createVaccine";
import { useMutation, useQueryClient } from "react-query";
import { VaccineType } from "api/vaccines/vaccine";
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa";
import { captalize } from "utils/captalize";
import { vaccineCollaboratorTypeMap, vaccineTypesMap, doseVaccineTypesMap, vaccinePendingTypesMap } from "utils/vaccineMappers";
import { getCarriers } from "api/carriers/getCarriers";
import AsyncSelect from "react-select/async"
import { getRegionals } from "api/regionals/getRegionals";
import { getCltDrivers } from "hooks/cltDriver/useCltDrivers";
import { getAggregates } from "hooks/aggregate/useAggregates";

interface CreateVaccineProps {
  onCloseModal: () => void
}

interface CreateVaccineSchema {
  collaboratorType: string
  collaborator: { name: string, label: string, email: string }
  type: VaccineType
  regional: { id: string, label: string }
  cardFrontPhotoId: FileList
  cardBackPhotoId: FileList
  doses: string[]
  nextDoseDate: string
  pending: 'yes' | 'no'
}

const createVaccineSchema = yup.object({
  collaboratorType: yup.string().required('Campo Obrigatório'),
  collaborator: yup.object().required('Campo Obrigatório'),
  type: yup.string().required('Campo Obrigatório'),
  regional: yup.object().required('Campo Obrigatório'),
  pending: yup.string().required(),
  nextDoseDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
  doses: yup
    .array()
    .typeError('Campo Obrigatório')
    .min(1, 'Campo Obrigatório')
    .of(yup.string()),
  cardFrontPhotoId: yup.mixed().when('pending', {
    is: 'no',
    then: yup.mixed().test(value => validateHasFile(value)).required('Campo Obrigatório'),
  }),
  cardBackPhotoId: yup.mixed().when('pending', {
    is: 'no',
    then: yup.mixed().test(value => validateHasFile(value)).required('Campo Obrigatório'),
  })
})

export function CreateVaccine({ onCloseModal }: CreateVaccineProps) {

  const { mutation: uploadCardFrontPhotoFn, uploadProggress: uploadProggressCardFrontPhoto } = useAttachmentMutation()

  const { mutation: uploadCardBackPhotoFn, uploadProggress: uploadProggressCardBackPhoto } = useAttachmentMutation()

  const queryClient = useQueryClient()

  const { mutateAsync: createVaccineFn } = useMutation({
    mutationFn: createVaccine,
    onSuccess() {
      queryClient.invalidateQueries(['vaccines'])
      onCloseModal()
    }
  })

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<CreateVaccineSchema>({
    resolver: yupResolver(createVaccineSchema)
  })

  console.log(errors)

  const [cardFrontPhotoId, cardBackPhotoId, collaboratorType, pending] = useWatch({
    control,
    name: ['cardFrontPhotoId', 'cardBackPhotoId', 'collaboratorType', 'pending'],
  })

  async function handleUploadCardFrontPhoto(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadCardFrontPhotoFn.mutateAsync(formData)

  }
  async function handleUploadCardBackPhoto(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadCardBackPhotoFn.mutateAsync(formData)

  }

  const { promiseMessage } = useToastify()


  async function handleCreateVaccine({
    collaborator,
    collaboratorType,
    regional,
    doses,
    nextDoseDate,
    type,
    pending }: CreateVaccineSchema) {

    const isPending = pending === 'yes'

    await promiseMessage(createVaccineFn({
      body: {
        cardBackPhotoId: uploadCardBackPhotoFn?.data?.attachment?.id,
        cardFrontPhotoId: uploadCardFrontPhotoFn?.data?.attachment?.id,
        regionalId: regional.id,
        collaboratorType,
        collaboratorName: collaborator.name,
        collaboratorEmail: collaborator.email,
        doses,
        nextDoseDate,
        type,
        pending: isPending
      }
    }), 'Vacina criada! 🎉')
  }

  async function regionalPromiseOptions(inputValue: string): Promise<Array<{ id: string, label: string }>> {
    const response = await getRegionals({ currentPage: 1, pageSize: 10 })

    return response.regionals.filter(regional => regional.code.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())).map(regional => {
      return { id: regional.id, label: regional.code.toUpperCase() }
    })
  }

  async function cltDriversCollaboratorsPromiseOptions(inputValue: string): Promise<Array<{ name: string, label: string, email: string }>> {
    const response = await getCltDrivers({ currentPage: 1, pageSize: 5, search: inputValue })

    return response.cltDrivers.map(cltDriver => {
      return {
        name: captalize(`${cltDriver.driver.firstname} ${cltDriver.driver.lastname}`),
        label: captalize(`${cltDriver.driver.firstname} ${cltDriver.driver.lastname}`),
        email: cltDriver.driver.email.toLocaleLowerCase()
      }
    })
  }

  async function aggregatesCollaboratorsPromiseOptions(inputValue: string): Promise<Array<{ name: string, label: string, email: string }>> {
    const response = await getAggregates({ status: 'active' })

    return response.filter(aggregate => `${aggregate.driver.firstname} ${aggregate.driver.lastname}`.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())).map(aggregate => {
      return {
        name: captalize(`${aggregate.driver.firstname} ${aggregate.driver.lastname}`),
        label: captalize(`${aggregate.driver.firstname} ${aggregate.driver.lastname}`),
        email: aggregate.driver.email.toLocaleLowerCase()
      }
    })
  }

  async function carriersCollaboratorsPromiseOptions(inputValue: string): Promise<Array<{ name: string, label: string, email: string }>> {
    const response = await getCarriers({ currentPage: 1, pageSize: 5, name: inputValue, status: 'all' })

    return response.carriers.map((carrier) => {
      return {
        name: captalize(carrier.name),
        label: captalize(carrier.name),
        email: carrier.email.toLocaleLowerCase()
      }
    })
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">Criar Vacina</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleCreateVaccine)}
        maxH='600px'
        overflowY='scroll'
      >
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Tipo de colaborador
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.collaboratorType}>
              <Select
                {...register('collaboratorType')}
                name="collaboratorType"
                placeholder="Selecione..."
                rounded="md"
              >
                {Object.entries(vaccineCollaboratorTypeMap).filter(([key, value]) => ['clt-driver', 'aggregate-driver', 'carrier'].includes(key)).map(([key, value]) => {
                  return (
                    <option key={key} value={key}>{value}</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>

          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Colaborador
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.collaborator}>
              {collaboratorType === 'aggregate-driver' && (
                <Controller
                  control={control}
                  name="collaborator"
                  render={({ field }) => (
                    <AsyncSelect
                      {...register('collaborator')}
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                      cacheOptions
                      defaultOptions
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '1px',
                          borderRadius: '6px',
                          borderColor: 'gray.200',
                          border: state.isFocused ? 'none' : '',
                          boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                        }),
                        placeholder: (base, props) => ({
                          ...base,
                          color: 'blackAlpha.900'
                        }),
                        dropdownIndicator(base, props) {
                          return {
                            ...base,
                            color: 'blackAlpha.900',
                            width: '34px',
                          }
                        },
                      }}
                      noOptionsMessage={() => 'Não há colaboradores cadastrados ou encontrados!'}
                      placeholder="Selecione um colaborador..."
                      loadOptions={aggregatesCollaboratorsPromiseOptions}
                      isClearable={true}
                    />
                  )}
                />
              )}

              {collaboratorType === 'clt-driver' && (
                <Controller
                  control={control}
                  name="collaborator"
                  render={({ field }) => (
                    <AsyncSelect
                      {...register('collaborator')}
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                      cacheOptions
                      defaultOptions
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '1px',
                          borderRadius: '6px',
                          borderColor: 'gray.200',
                          border: state.isFocused ? 'none' : '',
                          boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                        }),
                        placeholder: (base, props) => ({
                          ...base,
                          color: 'blackAlpha.900'
                        }),
                        dropdownIndicator(base, props) {
                          return {
                            ...base,
                            color: 'blackAlpha.900',
                            width: '34px',
                          }
                        },
                      }}
                      noOptionsMessage={() => 'Não há colaboradores cadastrados ou encontrados!'}
                      placeholder="Selecione um colaborador..."
                      loadOptions={cltDriversCollaboratorsPromiseOptions}
                      isClearable={true}
                    />
                  )}
                />
              )}

              {collaboratorType === 'carrier' && (
                <Controller
                  control={control}
                  name="collaborator"
                  render={({ field }) => (
                    <AsyncSelect
                      {...register('collaborator')}
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                      cacheOptions
                      defaultOptions
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '1px',
                          borderRadius: '6px',
                          borderColor: 'gray.200',
                          border: state.isFocused ? 'none' : '',
                          boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                        }),
                        placeholder: (base, props) => ({
                          ...base,
                          color: 'blackAlpha.900'
                        }),
                        dropdownIndicator(base, props) {
                          return {
                            ...base,
                            color: 'blackAlpha.900',
                            width: '34px',
                          }
                        },
                      }}
                      noOptionsMessage={() => 'Não há colaboradores cadastrados ou encontrados!'}
                      placeholder="Selecione um colaborador..."
                      loadOptions={carriersCollaboratorsPromiseOptions}
                      isClearable={true}
                    />
                  )}
                />
              )}
            </FormControl>
          </Stack>

        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.type}>
              <FormLabel fontSize="sm">
                Vacina
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Select
                {...register('type')}
                name="type"
                placeholder="Selecione..."
                rounded="md"
              >
                {Object.entries(vaccineTypesMap).map(([key, value]) => {
                  return (
                    <option key={key} value={key}>{value}</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>
        </Stack>

        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Regional
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.regional}>
              <Controller
                control={control}
                name="regional"
                render={({ field }) => (
                  <AsyncSelect
                    {...register('regional')}
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                    isMulti={false}
                    cacheOptions
                    defaultOptions
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: '1px',
                        borderRadius: '6px',
                        borderColor: 'gray.200',
                        border: state.isFocused ? 'none' : '',
                        boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                      }),
                      placeholder: (base, props) => ({
                        ...base,
                        color: 'blackAlpha.900'
                      }),
                      dropdownIndicator(base, props) {
                        return {
                          ...base,
                          color: 'blackAlpha.900',
                          width: '34px',
                        }
                      },
                    }}
                    noOptionsMessage={() => 'Não há regionais cadastradas ou encontradas!'}
                    placeholder="Selecione uma regional..."
                    loadOptions={regionalPromiseOptions}
                    isClearable={true}
                  />
                )}
              />
            </FormControl>
          </Stack>
          {/*
          <Stack
            direction="column"
            spacing="0.25"
            w="full"
          >
            <FormControl isInvalid={!!errors.responsible}>
              <FormLabel fontSize="sm">
                Responsável
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('responsible')}
                name="responsible"
                size="sm"
                rounded="md"
                isDisabled
              />
            </FormControl>
          </Stack> */}
        </Stack>

        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Doses
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.doses}>
              {Object.entries(doseVaccineTypesMap).map(([key, value]) => (
                <Checkbox
                  {...register('doses')}
                  name="doses"
                  rounded='md'
                  mr='6'
                  key={key}
                  value={key}
                >
                  {value}
                </Checkbox>
              ))}
            </FormControl>
          </Stack>
        </Stack>

        <Stack
          direction="column"
          w="full"
          mt="3"
        >
          <FormControl isInvalid={!!errors?.nextDoseDate}>
            <FormLabel fontSize="sm">
              Data próxima dose
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('nextDoseDate')}
              name="nextDoseDate"
              type="date"
              rounded="md"
            />
          </FormControl>
        </Stack>

        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Pendência ?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.pending}>
              <RadioGroup >
                {Object.entries(vaccinePendingTypesMap).map(([key, value]) => (
                  <Radio
                    {...register('pending')}
                    name="pending"
                    rounded='md'
                    mr='7'
                    key={key}
                    value={key}>
                    {value}
                  </Radio>
                ))}
              </RadioGroup>

            </FormControl>
          </Stack>
        </Stack>

        {pending === 'no' && (
          <>
            <Stack
              direction="column"
              w="full"
              mt="3"
            >
              <Button
                as={FormLabel}
                htmlFor="cardFrontPhotoId"
                lineHeight="1"
                leftIcon={<FaFileImport />}
                size="sm"
                w="min"
                cursor="pointer"
                border={!!errors?.cardFrontPhotoId && '2px solid'}
                borderColor={(!!errors?.cardFrontPhotoId) && 'red.500'}
              >
                Anexar Cartão Frente
              </Button>
              <FormControl isInvalid={!!errors?.cardFrontPhotoId}>
                <Input
                  {...register('cardFrontPhotoId')}
                  name="cardFrontPhotoId"
                  id="cardFrontPhotoId"
                  type="file"
                  hidden
                  onChangeCapture={handleUploadCardFrontPhoto}
                />
              </FormControl>
            </Stack>

            {cardFrontPhotoId && (
              <Table size="sm">
                <Tbody>
                  {Object.entries(cardFrontPhotoId).map(([key, file]) => {
                    return (
                      <Tr key={key}>
                        <Td fontSize="xs" maxW="100px">{file.name}</Td>
                        <Td fontSize="xs" w="200px">
                          <Progress size="sm" rounded="md" value={uploadProggressCardFrontPhoto} />
                        </Td>
                        <Td fontSize="xs" isNumeric>
                          {uploadCardFrontPhotoFn.data && (
                            <IconButton
                              aria-label="Visualizar anexo"
                              as={Link}
                              size="sm"
                              icon={<FaExternalLinkAlt />}
                              href={uploadCardFrontPhotoFn.data.attachment.link}
                              isExternal
                            />
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            )}

            <Stack
              direction="column"
              w="full"
              mt="3"
            >
              <Button
                as={FormLabel}
                htmlFor="cardBackPhotoId"
                lineHeight="1"
                leftIcon={<FaFileImport />}
                size="sm"
                w="min"
                cursor="pointer"
                border={!!errors?.cardBackPhotoId && '2px solid'}
                borderColor={(!!errors?.cardBackPhotoId) && 'red.500'}
              >
                Anexar Cartão Verso
              </Button>
              <FormControl isInvalid={!!errors?.cardBackPhotoId}>
                <Input
                  {...register('cardBackPhotoId')}
                  name="cardBackPhotoId"
                  id="cardBackPhotoId"
                  type="file"
                  hidden
                  onChangeCapture={handleUploadCardBackPhoto}
                />
              </FormControl>
            </Stack>

            {cardBackPhotoId && (
              <Table size="sm">
                <Tbody>
                  {Object.entries(cardBackPhotoId).map(([key, file]) => {
                    return (
                      <Tr key={key}>
                        <Td fontSize="xs" maxW="100px">{file.name}</Td>
                        <Td fontSize="xs" w="200px">
                          <Progress size="sm" rounded="md" value={uploadProggressCardBackPhoto} />
                        </Td>
                        <Td fontSize="xs" isNumeric>
                          {uploadCardBackPhotoFn.data && (
                            <IconButton
                              aria-label="Visualizar anexo"
                              as={Link}
                              size="sm"
                              icon={<FaExternalLinkAlt />}
                              href={uploadCardBackPhotoFn.data.attachment.link}
                              isExternal
                            />
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            )}
          </>
        )}


        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Criar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
