import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getAudits } from "api/audits/getAudits";
import { AuditStatus } from "api/audits/_types/Audit";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { AuditsTableRow } from "./components/AuditsTableRow";
import { AuditTableFilters } from "./components/AuditTableFilters";
import { CreateAudit } from "./components/CreateAudit";

export function Audits() {
  const searchParams = useSearchParams()

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanViewAudits = userLogged?.permissions?.includes(
    'view-audits'
  )

  const userCanCreateAudit = userLogged?.permissions?.includes(
    'create-audit'
  )

  // useEffect(() => {
  //   if (!userCanViewAudits) history.push('/')
  // }, [history, userCanViewAudits])

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status') ?? 'all'
  const type = searchParams.get('type')

  const {
    data: auditsResult
  } = useQuery({
    queryKey: ['audits', page, status, type],
    queryFn: () => getAudits({
      currentPage: Number(page),
      pageSize: 10,
      status: status as AuditStatus | 'all',
      type
    })
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: auditsResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: auditsResult?.meta?.pageSize
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  const {
    isOpen: isCreateAuditOpen,
    onOpen: onOpenCreateAudit,
    onClose: onCloseCreateAudit
  } = useDisclosure()

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Auditorias</Heading>
        {userCanCreateAudit && (
          <IconButton
            aria-label="Criar auditoria"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            onClick={onOpenCreateAudit}
          />
        )}
        <Modal
          isOpen={isCreateAuditOpen}
          onClose={onCloseCreateAudit}
          isCentered
          size="2xl"
        >
          <ModalOverlay />
          <CreateAudit onClose={onCloseCreateAudit} />
        </Modal>
      </Flex>
      <AuditTableFilters />
      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Data programada</Th>
              <Th color="white">Tipo de auditoria</Th>
              <Th color="white">Cliente</Th>
              <Th color="white">Colaborador</Th>
              <Th color="white">Status</Th>
              <Th></Th>

            </Tr>
          </Thead>
          <Tbody>
            {auditsResult?.audits?.map((audit) => {
              return (
                <AuditsTableRow
                  key={audit.id}
                  audit={audit}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )

}
