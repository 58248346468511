import { Attachment } from 'hooks/attachment/dtos/Attachment';
import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Refund } from "./_types/Refund";
import { User } from 'hooks/user/dtos/User';

export interface GetRefundResponse {
  refund: Refund & {
    requestAttachment: Attachment,
    emailRequestAttachment: Attachment,
    evidenceAttachment: Attachment | null,
    responsible: User | null
  }
}

interface GetRefundParams {
  refundId: string
}

export async function getRefund({
  refundId
}: GetRefundParams) {
  try {
    const response = await api.get<GetRefundResponse>(`/refunds/${refundId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
