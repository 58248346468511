import { Button, Checkbox, CheckboxGroup, Flex, FormControl, FormLabel, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Text, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { checkPartnerSupport } from "api/partnerSupport/checkPartnerSupport";

import { useToastify } from "hooks/toastify/useToastify";

import { Controller, useForm } from "react-hook-form";

import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup"

interface CheckPartnerSupportProps {
  onCloseModal: () => void
  partnerSupportId: string
}

interface CheckPartnerSupportSchema {
  conferenceChecklist: string[]
}

const checkPartnerSuppportSchema = yup.object({
  conferenceChecklist: yup.array().of(yup.string()).nullable().required(),
})

const checks = [
  { name: 'Aditivo assinado' },
  { name: 'Alterações conferidas' },
  { name: 'Verificar  se aplicável alterar preços no sistema' },
]

export function CheckPartnerSupport({
  onCloseModal,
  partnerSupportId
}: CheckPartnerSupportProps) {

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()


  const {
    handleSubmit,
    control,
    formState: {
      isSubmitting
    }
  } = useForm<CheckPartnerSupportSchema>({
    resolver: yupResolver(checkPartnerSuppportSchema)
  })

  const { mutateAsync: checkPartnerSupportFn } = useMutation({
    mutationFn: checkPartnerSupport,
    onSuccess() {
      queryClient.invalidateQueries(['partnerSupports'])
      onCloseModal()
    }
  })

  async function handleSendAdditivePartnerSupport(values: CheckPartnerSupportSchema) {
    await promiseMessage(checkPartnerSupportFn({
      body: {
        ...values
      },
      routeParams: {
        partnerSupportId
      }
    }), 'Alteração/Envio aditivo realizada!')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Alteração/Envio aditivo
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        as="form"
        w="full"
        onSubmit={handleSubmit(handleSendAdditivePartnerSupport)}
      >
        <FormControl>
          <FormLabel>
            Aditivo
          </FormLabel>
          <Controller
            control={control}
            name="conferenceChecklist"
            render={({ field }) => {
              return (
                <CheckboxGroup
                  value={field.value}
                  onChange={field.onChange}
                >
                  <VStack
                    spacing={3}
                    p={2}
                    border="1px solid"
                    borderColor="gray.200"
                    rounded="md"
                    align="left"
                  >
                    {checks.map(check => {
                      return (
                        <Checkbox key={check.name} value={check.name}>
                          <Text fontSize="sm">
                            {check.name}
                          </Text>
                        </Checkbox>
                      )
                    })}
                  </VStack>

                </CheckboxGroup>
              )
            }}
          />
        </FormControl>

        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Salvar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}


