import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface EvaluateCarrierBody {
  pendencies?: string[]
  observations: string
  hasLoglifeUniversityRegister: boolean
  hasTrainingRegister: boolean
  hasVaccinesRegister: boolean
  hasAsoRegister: boolean
  withPendencies: boolean
}

interface EvaluateCarrierProps {
  body: EvaluateCarrierBody
  carrierId: string
}

export async function evaluateCarrier({
  body,
  carrierId
}: EvaluateCarrierProps) {
  try {
    const response = await api.put(`/carriers/${carrierId}/evaluate`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
