import { Box, Button, Divider, Flex, Heading, Icon, useDisclosure } from "@chakra-ui/react"

import { Link, useHistory } from "react-router-dom"
import { usePagination } from "@ajna/pagination"
import { useEffect, useState } from "react"
import { Pagination } from "components/Pagination/Pagination"
import { useCollectorsFunctions } from "hooks/collector/useCollectorsFunctions"
import { useToastify } from "hooks/toastify/useToastify"
import { useQueryClient } from "react-query"
import { FaCheckCircle, FaPen, FaPlus, FaTimes, FaTrash, FaUndoAlt } from "react-icons/fa"
import { useAuth } from "hooks/auth/useAuth"
import { CollectorsExtrasDiscountsStatusEnum } from "contexts/CollectorsExtrasDiscountsStatusContext"
import { useCollectorsExtrasDiscountsStatus } from "hooks/collector/useCollectorsExtrasDiscountsStatus"
import { TableFilterButton } from "components/Filters/TableFilterButton"
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions"
import { CollectorExtrasDiscountsFilterOptions } from "./components/CollectorExtrasDiscountsFilterOptions"
import { CollectorExtrasDiscountsTable } from "./components/CollectorExtrasDiscountsTable"
import { CollectorExtrasDiscountsListContextProvider } from "contexts/CollectorExtrasDiscountsListContext"
import { ViewExtraDiscountModal } from "components/Modal/ViewExtraDiscountModal"
import { ExtraDiscount } from "hooks/extrasDiscounts/dtos/ExtrasDiscounts"
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton"
import { formatDate } from "utils/DateFunctions/formatDate"


const collectorListSearchOptions = [
  { key: 'collector', value: 'Coletador', checked: false },
  { key: 'period', value: 'Período', checked: false },
]

const headers = [
  { label: 'COLETADOR', key: 'collector' },
  { label: 'TIPO', key: 'type' },
  { label: 'DATA DO SERVIÇO', key: 'service_date' },
  { label: 'VALOR', key: 'value' },
  { label: 'ROTA', key: 'route' },
  { label: 'PROTOCOLO', key: 'protocol' },
  { label: 'DESCRIÇÃO', key: 'description' },
  { label: 'CRIADO POR', key: 'created_by' },
]

function formatValuesToReport(values: ExtraDiscount[]) {
  return values?.map((extraDiscount) => ({
    ...extraDiscount,
    collector: extraDiscount?.collector.trading_name,
    service_date: formatDate.handle(extraDiscount.service_date, 'DateWithoutHourToShow'),
    value: extraDiscount.value / 100,
    route: extraDiscount.service_route ?? '-',
    protocol: extraDiscount.service_protocol ?? '-',
    created_by: extraDiscount?.createdBy ? `${extraDiscount?.createdBy.firstname} ${extraDiscount?.createdBy.lastname}` : '-',
  }))
}

export function CollectorExtrasDiscountsList() {
  const [extraDiscount, setExtraDiscount] = useState({} as ExtraDiscount)
  const [collectorsExtrasDiscountsData, setCollectorsExtrasDiscountsData] = useState([] as ExtraDiscount[])

  const [totalPages, setTotalPages] = useState(1)

  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()

  const { push: redirect, location } = useHistory()

  const {
    onLoadSetFilterOptions
  } = useFilterOptions()

  useEffect(() => {
    onLoadSetFilterOptions(collectorListSearchOptions)
  }, [onLoadSetFilterOptions])

  const { status } = useCollectorsExtrasDiscountsStatus()

  const userHasPermissionToViewCollectorExtraDiscount =
    userLogged?.permissions.includes('view-collector-extra-discount')

  useEffect(() => {
    if (!userHasPermissionToViewCollectorExtraDiscount) {
      redirect('/')
    }
  }, [userHasPermissionToViewCollectorExtraDiscount, redirect])

  const statusFilter = location.search.match(`[?&]status=([^&]+)`)
  const currentPageFilter = location.search.match(`[?&]page=([^&]+)`)

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: totalPages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });

  const handleSetTotalPages = (total: number) => setTotalPages(total)
  const handleSetCollectorsExtrasDiscountsData = (collectorsExtrasDiscounts: ExtraDiscount[]) => setCollectorsExtrasDiscountsData(collectorsExtrasDiscounts)

  const handleChangePage = (page: number) => setCurrentPage(page)

  useEffect(() => {
    if (userHasPermissionToViewCollectorExtraDiscount) {
      redirect({
        pathname: '/extras-descontos/coletadores',
        search: `?page=${currentPage}`
      })
    }
  }, [currentPage, redirect, userHasPermissionToViewCollectorExtraDiscount])

  const {
    approveCollectorExtraDiscount: {
      mutateAsync: approveCollectorExtraDiscount
    },
    rejectCollectorExtraDiscount: {
      mutateAsync: rejectCollectorExtraDiscount
    },
    deleteCollectorExtraDiscount: {
      mutateAsync: deleteCollectorExtraDiscount
    },
    effectiveCollectorExtraDiscount
  } = useCollectorsFunctions()

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  async function handleApproveCollectorExtraDiscount(
    collectorExtraDiscountId: string
  ) {
    await promiseMessage(approveCollectorExtraDiscount(
      collectorExtraDiscountId, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['collectorsExtrasDiscounts'])
      }
    }
    ), 'Extra/Desconto de coletador aprovado com sucesso!')
  }

  async function handleEffectiveCollectorExtraDiscount(
    collectorExtraDiscountId: string
  ) {
    await promiseMessage(effectiveCollectorExtraDiscount.mutateAsync(
      collectorExtraDiscountId, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['collectorsExtrasDiscounts'])
      }
    }
    ), 'Extra/Desconto de coletador aprovado com sucesso!')
  }

  async function handleRejectCollectorExtraDiscount(
    collectorExtraDiscountId: string
  ) {
    await promiseMessage(rejectCollectorExtraDiscount(
      collectorExtraDiscountId, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['collectorsExtrasDiscounts'])
      }
    }
    ), 'Extra/Desconto de coletador recusado com sucesso!')
  }

  async function handleDeleteCollectorExtraDiscount(
    collectorExtraDiscountId: string
  ) {
    await promiseMessage(deleteCollectorExtraDiscount(
      collectorExtraDiscountId, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['collectorsExtrasDiscounts'])
      }
    }
    ), 'Extra/Desconto de coletador excluido com sucesso!')
  }

  function handleUpdateCollectorExtraDiscount(extraDiscountId: string) {
    window.open(`/extra-desconto/${extraDiscountId}/coletador`, '_blank')
  }

  const handleRefetchTableData = async () => {
    await queryClient.invalidateQueries(['collectorsExtrasDiscounts'])
  }

  const collectorsExtrasDiscountsStatusActionsOptions = [
    {
      status: 'requested',
      actions: [
        {
          type: 'Aprovar',
          permission: 'approve-collector-extra-discount',
          icon: FaCheckCircle,
          handle: handleApproveCollectorExtraDiscount,
        },
        {
          type: 'Recusar',
          permission: 'reject-collector-extra-discount',
          icon: FaTimes,
          handle: handleRejectCollectorExtraDiscount
        },
        {
          type: 'Editar',
          permission: 'update-collector-extra-discount',
          icon: FaPen,
          handle: handleUpdateCollectorExtraDiscount
        },
        {
          type: 'Excluir',
          permission: 'delete-collector-extra-discount',
          icon: FaTrash,
          handle: handleDeleteCollectorExtraDiscount
        },
      ]
    },
    {
      status: 'approved',
      actions: [
        {
          type: 'Efetivar',
          permission: 'effective-collector-extra-discount',
          icon: FaCheckCircle,
          handle: handleEffectiveCollectorExtraDiscount
        },
        {
          type: 'Editar',
          permission: 'update-collector-extra-discount',
          icon: FaPen,
          handle: handleUpdateCollectorExtraDiscount
        },
        {
          type: 'Excluir',
          permission: 'delete-collector-extra-discount',
          icon: FaTrash,
          handle: handleDeleteCollectorExtraDiscount
        },
      ]
    },
    {
      status: 'rejected',
      actions: [
        {
          type: 'Excluir',
          permission: 'delete-collector-extra-discount',
          icon: FaTrash,
          handle: handleDeleteCollectorExtraDiscount
        },
      ]
    },
    {
      status: 'effectivated',
      actions: [
        {
          type: 'Editar',
          permission: 'update-collector-extra-discount',
          icon: FaPen,
          handle: handleUpdateCollectorExtraDiscount
        },
        {
          type: 'Excluir',
          permission: 'delete-collector-extra-discount',
          icon: FaTrash,
          handle: handleDeleteCollectorExtraDiscount
        },
      ]
    },
  ]

  const {
    isOpen: isViewExtraDiscountModalOpen,
    onOpen: onOpenViewExtraDiscountModal,
    onClose: onCloseViewExtraDiscountModal,
  } = useDisclosure()

  const handleOpenViewExtraDiscountModal = (extraDiscount: ExtraDiscount) => {
    setExtraDiscount(extraDiscount)
    onOpenViewExtraDiscountModal()
  }

  const csvReportProps = {
    data: formatValuesToReport(collectorsExtrasDiscountsData),
    headers,
    filename: `extras-e-descontos-coletador.csv`,
  }

  return (
    <>

      <ViewExtraDiscountModal
        onClose={onCloseViewExtraDiscountModal}
        isOpen={isViewExtraDiscountModalOpen}
        extraDiscount={extraDiscount}
        type='collector'
      />

      <Box
        p={4}
        bg='white'
        rounded='lg'
      >
        <Flex
          gap={4}
          direction='column'
        >
           <Flex
            align="center"
            gap={6}
            justify={["center", "center", "space-between"]}
            direction={["column", "column", "row"]}
            flex="1"
            w="full"
          >
          <Heading size='lg'>Extras/Descontos de Coletador - {CollectorsExtrasDiscountsStatusEnum[status]}</Heading>
            <Button
              as={Link}
              size="sm"
              w={["full", "full", "min"]}
              colorScheme="gray"
              to="/extra-desconto/coletador/criar"
              leftIcon={<Icon as={FaPlus} />}
            >
              Novo
            </Button>
          </Flex>


          <Divider />

          <Flex gap={2}>
            <TableFilterButton />
            <Button colorScheme="blue" onClick={handleRefetchTableData}>
              <Icon as={FaUndoAlt} />{' '}
            </Button>
          </Flex>
          <CollectorExtrasDiscountsListContextProvider>
            <CollectorExtrasDiscountsFilterOptions />


            <CollectorExtrasDiscountsTable
              collectorsExtrasDiscountsStatusActionsOptions={collectorsExtrasDiscountsStatusActionsOptions}
              rowsPerPage={rowsPerPage}
              statusFilter={statusFilter}
              currentPageFilter={currentPageFilter}
              onSetTotalPages={handleSetTotalPages}
              onOpenViewExtraDiscountModal={handleOpenViewExtraDiscountModal}
              onSetCollectorsExtrasDiscountsData={handleSetCollectorsExtrasDiscountsData}
            />
          </CollectorExtrasDiscountsListContextProvider>
          <GenerateExcelReportButton csvReportProps={csvReportProps} />
          <Pagination
            currentPage={currentPage}
            pages={pages}
            pagesQuantity={pagesCount}
            handlePageChange={handleChangePage}
          />
        </Flex>
      </Box>
    </>
  )
}
