import { Button, Flex, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Radio, RadioGroup, Stack, Text, Textarea } from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { set } from "date-fns";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { completeCommercialAudit } from "api/audits/completeCommercialAudit";

interface AuditCommercialProps {
  onClose: () => void
  auditId: string
}

interface AuditCommercialSchema {
  realizedDate: string
  isSignedValidContract: string
  isUpdatedAndSignedPop: string
  nonCompliance: string
}

const auditCommercialSchema = yup.object({
  realizedDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
  isSignedValidContract: yup.string().required(),
  isUpdatedAndSignedPop: yup.string().required(),
  nonCompliance: yup.string()
})

export function AuditCommercial({
  onClose,
  auditId
}: AuditCommercialProps) {

  const { promiseMessage } = useToastify()

  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<AuditCommercialSchema>({
    resolver: yupResolver(auditCommercialSchema)
  })

  const queryClient = useQueryClient()

  const { mutateAsync: completeCommercialAuditFn } = useMutation({
    mutationFn: completeCommercialAudit,
    onSuccess() {
      queryClient.invalidateQueries(['audits'])
      onClose()
    }
  })

  async function handleCompleteCommercialAudit(values: AuditCommercialSchema) {
    const convertValuesAuditCommercial = {
      isSignedValidContract: values.isSignedValidContract === 'yes',
      isUpdatedAndSignedPop: values.isUpdatedAndSignedPop === 'yes'
    };

    await promiseMessage(completeCommercialAuditFn({
      body: {
        realizedDate: values.realizedDate,
        nonCompliance: values.nonCompliance,
        ...convertValuesAuditCommercial,
      },
      auditId
    }), 'Auditoria realizada!')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Auditoria Comercial
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleCompleteCommercialAudit)}
        maxH='600px'
        overflowY='scroll'
      >
        <FormControl isInvalid={!!errors?.realizedDate} mt={3}>
          <FormLabel fontSize="sm">
            Data realizada
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('realizedDate')}
            name="realizedDate"
            type="date"
            rounded="md"
            w={["full", "full", "sm"]}
          />
        </FormControl>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.isSignedValidContract}>
              <FormLabel fontSize="sm">
                Contrato válido assinado?
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Controller
                name="isSignedValidContract"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="column">
                      <Radio value="yes">Sim</Radio>
                      <Radio value="no">Não</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.isUpdatedAndSignedPop}>
              <FormLabel fontSize="sm">
                POP atualizado e assinado?
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Controller
                name="isUpdatedAndSignedPop"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="column">
                      <Radio value="yes">Sim</Radio>
                      <Radio value="no">Não</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Stack>
        </Stack>
        <FormControl isInvalid={!!errors.nonCompliance} mt={3}>
          <FormLabel fontSize="sm">
            Não conformidade
          </FormLabel>
          <Textarea
            {...register('nonCompliance')}
            placeholder="Descreva a não conformidade..."
            size='sm'
            rounded='md'
          />
        </FormControl>
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Auditar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
