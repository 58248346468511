const slaHeaders = [
  { label: 'PROTOCOLO', key: 'protocol' },
  { label: 'CLIENTE', key: 'customer' },
  { label: 'ETAPA', key: 'step' },
  { label: 'TIPO DE SERVIÇO', key: 'serviceType' },
  { label: 'DATA COLETA', key: 'collectDate' },
  { label: 'DATA REAL COLETA', key: 'collectDates' },
  { label: 'HORÁRIO REAL COLETAS', key: 'collectHours' },
  { label: 'DATA REAL ENTREGA', key: 'deliveryDates' },
  { label: 'HORÁRIO REAL ENTREGA', key: 'deliveryHours' },
  { label: 'CIDADES ORIGEM', key: 'sourceCity' },
  { label: 'ESTADO ORIGEM', key: 'sourceState' },
  { label: 'TRANSPORTADORA / BASE ORIGEM', key: 'sourceBranch' },
  { label: 'CIDADES DESTINO', key: 'destinationCity' },
  { label: 'ESTADO DESTINO', key: 'destinationState' },
  { label: 'TRANSPORTADORA / BASE DESTINO', key: 'destinationBranch' },
  {
    label: 'DATA PREVISTA DISPONIBILIDADE (SOLICITADO)',
    key: 'availabilityForecastDay',
  },
  {
    label: 'HORÁRIO PREVISTO DISPONIBILIDADE (SOLICITADO)',
    key: 'availabilityForecastTime',
  },
  { label: 'DATA REAL DISPONIBILIDADE', key: 'realDateAvailable' },
  { label: 'HORÁRIO REAL DISPONIBILIDADE', key: 'realTimeAvailable' },
  { label: 'SLA TRANSFERÊNCIA', key: 'slaTransfer' },
  { label: 'COLETADOR ORIGEM', key: 'sourceCollector' },
  { label: 'HORÁRIO LIMITE COLETA', key: 'expectedCollectTime' },
  { label: 'SLA COLETAS', key: 'slaCollect' },
  { label: 'COLETADOR DESTINO', key: 'destinationCollector' },
  { label: 'DATA ENTREGA', key: 'deliveryDate' },
  { label: 'HORÁRIO LIMITE ENTREGA', key: 'expectedDeliveryTime' },
  { label: 'SLA ENTREGAS', key: 'slaDelivery' },
]

const stockHeaders = [
  { label: 'CLIENTE', key: 'customer' },
  { label: 'PROTOCOLO', key: 'protocol' },
  { label: 'DATA DA COLETA', key: 'collectDate' },
  { label: 'ETAPA', key: 'step' },
  { label: 'TIPO DE SERVIÇO', key: 'serviceType' },
  { label: 'COLETADOR ORIGEM', key: 'sourceCollectorName' },
  { label: 'CIDADE COLETADOR ORIGEM', key: 'sourceCollectorCity' },
  { label: 'COLETADOR DESTINO', key: 'destinationCollectorName' },
  { label: 'CIDADE COLETADOR DESTINO', key: 'destinationCollectorCity' },
  { label: 'TERCIÁRIA 3L (SERVIÇO)', key: 'serviceTerciaria3l' },
  { label: 'TERCIÁRIA 8L (SERVIÇO)', key: 'serviceTerciaria8l' },
  { label: 'ISOPOR 3L (SERVIÇO)', key: 'serviceIsopor3l' },
  { label: 'ISOPOR 7L (SERVIÇO)', key: 'serviceIsopor7l' },
  { label: 'GELO SECO (SERVIÇO)', key: 'serviceGeloSeco' },
  { label: 'GELOX (SERVIÇO)', key: 'serviceGelox' },
  {
    label: 'EMBALAGEM SECUNDÁRIA (SERVIÇO)',
    key: 'serviceEmbalagemSecundaria',
  },
  {
    label: 'EMBALAGEM SECUNDÁRIA CANISTER 1L (SERVIÇO)',
    key: 'serviceEmbalagemSecundariaPote1l',
  },
  { label: 'LACRE (SERVIÇO)', key: 'serviceLacre' },
  { label: 'ALMOFADA ABSORVENTE (SERVIÇO)', key: 'serviceAlmofadaAbsorvente' },
  { label: 'ADESIVO DRY ICE (SERVIÇO)', key: 'serviceAdesivoDryIce' },
  { label: 'FITA TRANSPARENTE (SERVIÇO)', key: 'serviceFitaTransparente' },
  { label: 'CAIXA TÉRMICA (ORÇAMENTO)', key: 'budgetCaixaTermica' },
  {
    label: 'EMBALAGEM SECUNDÁRIA (ORÇAMENTO)',
    key: 'budgetEmbalagemSecundaria',
  },
  {
    label: 'EMBALAGEM SECUNDÁRIA CANISTER 1L (ORÇAMENTO)',
    key: 'budgetEmbalagemSecundariaPote1l',
  },
  { label: 'GELO SECO (ORÇAMENTO)', key: 'budgetGeloSeco' },
  { label: 'GELOX (ORÇAMENTO)', key: 'budgetGelox' },
  { label: 'ISOPOR 3L (ORÇAMENTO)', key: 'budgetIsopor3l' },
  { label: 'ISOPOR 7L (ORÇAMENTO)', key: 'budgetIsopor7l' },
  { label: 'TERCIÁRIA 3L (ORÇAMENTO)', key: 'budgetTerciaria3l' },
  { label: 'TERCIÁRIA 8L (ORÇAMENTO)', key: 'budgetTerciaria8l' },
  { label: 'MATERIAIS ADICIONAIS', key: 'additionalMaterials' },
  { label: 'MODAL', key: 'modal' },
]

const billingHeaders = [
  { label: 'CLIENTE', key: 'customer' },
  { label: 'ETAPA', key: 'step' },
  { label: 'PROTOCOLO', key: 'protocol' },
  { label: 'DATA DA COLETA', key: 'collectDate' },
  { label: 'CIDADE ORIGEM', key: 'sourceCity' },
  { label: 'CIDADE DESTINO', key: 'destinationCity' },
  { label: 'CTE LOGLIFE', key: 'cteLoglife' },
  { label: 'DATA EMISSÃO CTE LOGLIFE', key: 'cteLoglifeEmissionDate' },
  { label: 'CTE COMPLEMENTAR', key: 'complementaryCte' },
  { label: 'DATA EMISSÃO CTE COMPLEMENTAR', key: 'cteComplementaryEmissionDate' },
  { label: 'TOTAL DE COBRANÇAS ADICIONAIS', key: 'additionalChargesTotalValue' },
  { label: 'VALOR DO ORÇAMENTO', key: 'budgetPrice' },
  { label: 'FRANQUIA', key: 'franchising' },
  { label: 'PESO TAXADO NO SERVIÇO', key: 'taxedWeight' },
  { label: 'VOLUME EMBARQUE', key: 'boardVolume' },
  { label: 'QTD. END. ORIGEM NO SERVIÇO', key: 'serviceSourceAddressQty' },
  { label: 'VALOR TOTAL COLETAS ADICIONAIS', key: 'totalAdditionalCollects' },
  {
    label: 'QTD. END. DESTINO NO SERVIÇO',
    key: 'serviceDestinationAddressQty',
  },
  {
    label: 'VALOR TOTAL ENTREGAS ADICIONAIS',
    key: 'totalAdditionalDeliveries',
  },
  { label: 'QTD. KG EXTRA', key: 'extraKgQuantity' },
  { label: 'VALOR TOTAL KG EXTRA', key: 'totalExtraKGPrice' },
  { label: 'MATERIAL EXTRA (CLIENTE)', key: 'infoExtraMaterials' },
  {
    label: 'CUSTO TOTAL MATERIAL EXTRA (CLIENTE)',
    key: 'totalCostExtraMaterials',
  },
  { label: 'OUTRAS COBRANÇAS', key: 'otherCharges' },
  { label: 'VALOR OUTRAS COBRANÇAS', key: 'otherChargesValue' },
  { label: 'TIPO DE SERVIÇO', key: 'serviceType' },
  { label: 'QUANTIDADE DE AMOSTRAS', key: 'collectSample' },
  { label: 'NOME REMENTENTE', key: 'sourceAddress' },
  { label: 'NOME DESTINATÁRIO', key: 'destinationAddress' },
  { label: 'QTD. END. ORIGEM NO ORÇAMENTO', key: 'budgetSourceAddressQty' },
  { label: 'VALOR COLETA ADICIONAL', key: 'addCollectPrice' },
  {
    label: 'QTD. END. DESTINO NO ORÇAMENTO',
    key: 'budgetDestinationAddressQty',
  },
  { label: 'VALOR ENTREGA ADICIONAL', key: 'addDeliveryPrice' },
  { label: 'VALOR KG EXTRA', key: 'extraKGPrice' },

  { label: 'PREÇO COLETA SEM SUCESSO', key: 'unsuccessPrice' },
  { label: 'TIPO VEÍCULO', key: 'vehicle' },
  { label: 'VALOR TOTAL DO SERVIÇO', key: 'totalPrice' },
  { label: 'OBSERVAÇÕES', key: 'observation' },
]

const cargoHeaders = [
  { label: 'PROTOCOLO', key: 'protocol' },
  { label: 'CLIENTE', key: 'customer' },
  { label: 'DATA DA COLETA', key: 'collectDate' },
  { label: 'CIDADES DE ORIGEM', key: 'sourceCity' },
  { label: 'CIDADES DE DESTINO', key: 'destinationCity' },
  { label: 'CTE', key: 'cte' },
  { label: 'VEÍCULO', key: 'vehicle' },
  { label: 'RASTREADOR', key: 'tracker' },
  { label: 'VOLUME EMBARCADO', key: 'boardVolume' },
  { label: 'TRANSPORTADORA', key: 'shipping' },
  { label: 'BASE ORIGEM', key: 'sourceBranch' },
  { label: 'BASE DESTINO', key: 'destinationBranch' },
  { label: 'COLETADOR DE DESTINO', key: 'destinationCollector' },
  { label: 'DATA LIMITE ENTREGA', key: 'deliveryDate' },
  { label: 'HORA LIMITE ENTREGA', key: 'deliveryHour' },
  { label: 'GELO SECO', key: 'geloSeco' },
  { label: 'ETAPA', key: 'step' },
  { label: 'CROSSDOCKING', key: 'hasCrossdocking' },
  { label: 'RASTREADOR', key: 'tracker' },
  { label: 'RASTREADOR CROSSDOCKING', key: 'crossdockingTracker' },
  { label: 'BASE ORIGEM INTERMEDIÁRIA', key: 'sourceCrossdockingBranch' },
  { label: 'BASE DESTINO INTERMEDIÁRIA', key: 'destinationCrossdockingBranch' },
  { label: 'CTE', key: 'cte' },
  { label: 'CTE CROSSDOCKING', key: 'crossdockingCTE' },
  { label: 'TRANSPORTADORA CROSSDOCKING', key: 'crossdockingShipping' },
  { label: 'IDENTIFICADOR DO EMBARQUE ATUAL', key: 'whichBoard' },
  { label: 'TIPO DE SERVIÇO', key: 'serviceType' },
]

const costHeaders = [
  { label: 'PROTOCOLO', key: 'protocol' },
  { label: 'CLIENTE', key: 'customer' },
  { label: 'ETAPA', key: 'step' },
  { label: 'TIPO DE SERVIÇO', key: 'serviceType' },
  { label: 'DATA DA COLETA', key: 'collectDate' },
  { label: 'DATA DO EMBARQUE', key: 'departureBoardDate' },
  { label: 'TRANSPORTADORA', key: 'shipping' },
  { label: 'HUB ORIGEM', key: 'sourceHub' },
  { label: 'CIDADE ORIGEM', key: 'sourceCity' },
  { label: 'COLETADOR ORIGEM', key: 'sourceCollector' },
  { label: 'BASE ORIGEM', key: 'sourceBranch' },
  { label: 'HUB DESTINO', key: 'destinationHub' },
  { label: 'CIDADE DESTINO', key: 'destinationCity' },
  { label: 'COLETADOR DESTINO', key: 'destinationCollector' },
  { label: 'BASE DESTINO', key: 'destinationBranch' },
  { label: 'QUANTIDADE ENDEREÇOS ENTREGA', key: 'deliveryCount' },
  { label: 'QUANTIDADE ENDEREÇOS COLETA', key: 'collectCount' },
  { label: 'RASTREADOR', key: 'tracker' },
  { label: 'CROSSDOCKING', key: 'hasCrossdocking' },
  { label: 'RASTREADOR', key: 'tracker' },
  { label: 'RASTREADOR CROSSDOCKING', key: 'crossdockingTracker' },
  { label: 'BASE ORIGEM INTERMEDIÁRIA', key: 'sourceCrossdockingBranch' },
  { label: 'BASE DESTINO INTERMEDIÁRIA', key: 'destinationCrossdockingBranch' },
  { label: 'CTE', key: 'cte' },
  { label: 'CTE CROSSDOCKING', key: 'crossdockingCTE' },
  { label: 'TRANSPORTADORA CROSSDOCKING', key: 'crossdockingShipping' },
  { label: 'IDENTIFICADOR DO EMBARQUE ATUAL', key: 'whichBoard' },
  { label: 'BUDGET DE TRANSFERÊNCIA', key: 'transferBudget' },
  { label: 'VALOR CTE TRANSFERÊNCIA', key: 'cteTransferCost' },
  { label: 'VALOR CTE CROSSDOCKING TRANSFERÊNCIA', key: 'crossdockingCteTransferCost' },
  { label: 'VOLUME (VALIDAÇÃO EMBARQUE)', key: 'boardValidateVolume' },
  { label: 'FORNECEDOR', key: 'provider' },
  { label: 'PAPELÃO 3L', key: 'terciaria3l' },
  { label: 'PAPELÃO 8L', key: 'terciaria8l' },
  { label: 'GELO SECO', key: 'geloSeco' },
  { label: 'FRANQUIA', key: 'franchising' },
  { label: 'PESO TAXADO', key: 'taxedWeight' },
  { label: 'CENTRO DE CUSTO', key: 'costCenter' },
  { label: 'CUSTO COLETA', key: 'collectCost' },
  { label: 'CUSTO COLETA ADICIONAL', key: 'additionalCollectCost' },
  { label: 'CUSTO ENTREGA', key: 'deliveryCost' },
  { label: 'CUSTO ENTREGA ADICIONAL', key: 'additionalDeliveryCost' },
  { label: 'DESVIO DE BUDGET', key: 'detour' },
  { label: 'VEÍCULO', key: 'vehicle' },
  { label: 'CTE LOGLIFE', key: 'cteLoglife' },
  { label: 'DATA EMISSÃO CTE LOGLIFE', key: 'cteLoglifeEmissionDate' },
  { label: 'CTE COMPLEMENTAR', key: 'cteComplementary' },
  { label: 'DATA EMISSÃO CTE COMPLEMENTAR', key: 'cteComplementaryEmissionDate' },
  { label: 'MOTORISTAS DE COLETA', key: 'collectDrivers' },
  { label: 'MOTORISTAS DE ENTREGA', key: 'deliveryDrivers' },
  { label: 'CUSTO TOTAL DE GELO SECO', key: 'geloSecoTotalCost' },
]

const scheduleHeaders = [
  { label: 'PROTOCOLO', key: 'protocol' },
  { label: 'ETAPA', key: 'step' },
  { label: 'CLIENTE', key: 'customer' },
  { label: 'HUB ORIGEM', key: 'sourceHub' },
  { label: 'COLETADOR DE ORIGEM', key: 'sourceCollector' },
  { label: 'REMENTENTES', key: 'sourceAddress' },
  { label: 'DATA DA COLETA', key: 'collectDate' },
  { label: 'HORÁRIO INCIAL DA COLETA', key: 'collectHourStart' },
  { label: 'HORÁRIO FINAL DA COLETA', key: 'collectHourEnd' },
  { label: 'HORÁRIO LIMITE PARA EMBARQUE', key: 'boardHour' },
  { label: 'INSUMOS', key: 'inputs' },
  { label: 'BASE TRANSPORTADORA ORIGEM', key: 'sourceBranch' },
  { label: 'BASE TRANSPORTADORA DESTINO', key: 'destinationBranch' },
  { label: 'HUB DESTINO', key: 'destinationHub' },
  { label: 'DESTINATÁRIOS', key: 'destinationAddress' },
  { label: 'DATA DA ENTREGA', key: 'deliveryDate' },
  { label: 'HORÁRIO DE ENTREGA', key: 'deliveryHour' },
  { label: 'VEÍCULO', key: 'vehicle' },
  { label: 'RASTREADOR', key: 'tracker' },
  { label: 'IDENTIFICADOR DO EMBARQUE ATUAL', key: 'whichBoard' },
  { label: 'CROSSDOCKING', key: 'hasCrossdocking' },
  { label: 'COLETADOR INTERMEDIÁRIO', key: 'crossdockingCollector' },
  { label: 'COLETADOR DESTINO', key: 'destinationCollector' },
  {
    label: 'HORÁRIO LIMITE DE EMBARQUE CROSSDOCKING',
    key: 'crossdockingBoardHour',
  },
  {
    label: 'BASE TRANSPORTADORA ORIGEM INTERMEDIÁRIA',
    key: 'sourceCrossdockingBranch',
  },
  {
    label: 'BASE TRANSPORTADORA DESTINO INTERMEDIÁRIA',
    key: 'destinationCrossdockingBranch',
  },
  { label: 'OBSERVAÇÕES', key: 'observation' },
]

const cteHeaders = [
  { label: 'PROTOCOLO', key: 'protocol' },
  { label: 'CLIENTE', key: 'customer' },
  { label: 'ETAPA', key: 'step' },
  { label: 'DATA COLETA', key: 'collectDate' },
  { label: 'FRANQUIA DE PESO', key: 'franchising' },
  { label: 'PESO TAXADO', key: 'taxedWeight' },
  { label: 'PREÇO TRANSPORTE', key: 'transportPrice' },
  { label: 'PREÇO KG EXTRA', key: 'kgExtraPrice' },
  { label: 'NOME REMETENTE', key: 'sourceBranchName' },
  { label: 'CIDADE ORIGEM', key: 'sourceCity' },
  { label: 'ENDEREÇO REMENTENTE', key: 'sourceAddress' },
  { label: 'CNPJ/CPF REMETENTE', key: 'sourceCnpjCpf' },
  { label: 'COLETADOR ORIGEM', key: 'sourceCollectorName' },
  { label: 'NOME DESTINATÁRIO', key: 'destinationBranchName' },
  { label: 'CIDADE DESTINO', key: 'destinationCity' },
  { label: 'ENDEREÇO DESTINATÁRIO', key: 'destinationAddress' },
  { label: 'CNPJ/CPF DESTINATÁRIO', key: 'destinationCnpjCpf' },
  { label: 'COLETADOR DESTINO', key: 'destinationCollectorName' },
]

const stockControllerHeaders = [
  { label: 'COLETADOR', key: 'collector' },
  { label: 'HUBS DO COLETADOR', key: 'collectorHubs' },
  { label: 'INSUMO', key: 'input' },
  { label: 'ESTOQUE MÍNIMO', key: 'minimumStock' },
  { label: 'ESTOQUE PADRÃO', key: 'standardStock' },
  { label: 'ESTOQUE ATUAL', key: 'currentStock' },
]

const occurrenceHeaders = [
  { label: 'PROTOCOLO OCORRÊNCIA', key: 'occurrenceProtocol' },
  { label: 'PROTOCOLO SERVIÇO', key: 'serviceProtocol' },
  { label: 'DATA DA CRIAÇÃO', key: 'createdAt' },
  { label: 'FINALIZADA EM', key: 'updatedAt' },
  { label: 'MOVIDA PARA VALIDAÇÃO (CLIENTE) EM', key: 'movedToValidationDate' },
  { label: 'CLIENTE', key: 'customer' },
  { label: 'COLETADOR RESPONSÁVEL', key: 'collector' },
  { label: 'COLETADOR ORIGEM', key: 'sourceCollector' },
  { label: 'COLETADOR DESTINO', key: 'destinationCollector' },
  { label: 'TRANSPORTADORA', key: 'shipping' },
  { label: 'OCORRÊNCIA', key: 'type' },
  { label: 'INTERCORRÊNCIA', key: 'intercurrence' },
  { label: 'RESPONSÁVEL (ABERTURA)', key: 'owner' },
  { label: 'DESCRIÇÃO', key: 'occurrenceDetail' },
  { label: 'DATA PREVISTA (ATRASO)', key: 'occurrenceDate' },
  { label: 'HORÁRIO PREVISTO (ATRASO)', key: 'occurrenceHour' },
  { label: 'PROCEDE ', key: 'proceed' },
  { label: 'CAUSA', key: 'cause' },
  { label: 'CORRETIVA', key: 'corrective' },
  { label: 'PREVENTIVA', key: 'preventive' },
  { label: 'MOTIVO', key: 'reason' },
  { label: 'ETAPA SERVIÇO', key: 'serviceStep' },
  { label: 'STATUS', key: 'step' },
  { label: 'USUÁRIO FINAL', key: 'user' },
  { label: 'FOTO', key: 'photo' },
  { label: 'RESPONSÁVEIS PELA PREVENTIVA', key: 'occurrencePreventiveResponsibles' },
  { label: 'AÇÕES DE ALINHAMENTO', key: 'occurrenceAlignmentActions' },
  { label: 'EVIDÊNCIAS (ANEXOS)', key: 'occurrenceAttachments' },
  { label: 'VALOR TOTAL MULTAS', key: 'collectorFineValue' },
  { label: 'OBSERVAÇÕES', key: 'observation' },
]

const occurrenceHeadersWithCustomer = [
  { label: 'PROTOCOLO OCORRÊNCIA', key: 'occurrenceProtocol' },
  { label: 'OCORRÊNCIA', key: 'type' },
  { label: 'PROTOCOLO SERVIÇO', key: 'serviceProtocol' },
  { label: 'DATA DA CRIAÇÃO', key: 'createdAt' },
  { label: 'FINALIZADA EM', key: 'updatedAt' },
  { label: 'MOVIDA PARA VALIDAÇÃO (CLIENTE) EM', key: 'movedToValidationDate' },
  { label: 'RESPONSÁVEL (ABERTURA)', key: 'owner' },
  { label: 'CLIENTE', key: 'customer' },
  { label: 'INTERCORRÊNCIA', key: 'intercurrence' },
  { label: 'DESCRIÇÃO', key: 'occurrenceDetail' },
  { label: 'ETAPA SERVIÇO', key: 'serviceStep' },
  { label: 'DATA PREVISTA (ATRASO)', key: 'occurrenceDate' },
  { label: 'HORÁRIO PREVISTO (ATRASO)', key: 'occurrenceHour' },
  { label: 'OBSERVAÇÕES', key: 'observation' },
  { label: 'PROCEDE ', key: 'proceed' },
  { label: 'CAUSA', key: 'cause' },
  { label: 'CORRETIVA', key: 'corrective' },
  { label: 'PREVENTIVA', key: 'preventive' },
  { label: 'MOTIVO NÃO PROCEDENTE', key: 'reason' },
  { label: 'FOTO', key: 'photo' },
  { label: 'RESPONSÁVEIS PELA PREVENTIVA', key: 'occurrencePreventiveResponsibles' },
  { label: 'AÇÕES DE ALINHAMENTO', key: 'occurrenceAlignmentActions' },
  { label: 'EVIDÊNCIAS (ANEXOS)', key: 'occurrenceAttachments' },
  { label: 'STATUS', key: 'step' },
  { label: 'OBSERVAÇÕES', key: 'observation' },
]

const occurrenceHeadersWithShipping = [
  { label: 'PROTOCOLO OCORRÊNCIA', key: 'occurrenceProtocol' },
  { label: 'OCORRÊNCIA', key: 'type' },
  { label: 'PROTOCOLO SERVIÇO', key: 'serviceProtocol' },
  { label: 'DATA DA CRIAÇÃO', key: 'createdAt' },
  { label: 'FINALIZADA EM', key: 'updatedAt' },
  { label: 'MOVIDA PARA VALIDAÇÃO (CLIENTE) EM', key: 'movedToValidationDate' },
  { label: 'CIDADE ORIGEM', key: 'sourceCities' },
  { label: 'CIDADE DESTINO', key: 'destinationCities' },
  { label: 'RESPONSÁVEL (ABERTURA)', key: 'owner' },
  { label: 'CLIENTE', key: 'customer' },
  { label: 'TRANSPORTADORA', key: 'shipping' },
  { label: 'INTERCORRÊNCIA', key: 'intercurrence' },
  { label: 'DESCRIÇÃO', key: 'occurrenceDetail' },
  { label: 'ETAPA SERVIÇO', key: 'serviceStep' },
  { label: 'DATA PREVISTA (ATRASO)', key: 'occurrenceDate' },
  { label: 'HORÁRIO PREVISTO (ATRASO)', key: 'occurrenceHour' },
  { label: 'OBSERVAÇÕES', key: 'observation' },
  { label: 'PROCEDE ', key: 'proceed' },
  { label: 'CAUSA', key: 'cause' },
  { label: 'CORRETIVA', key: 'corrective' },
  { label: 'PREVENTIVA', key: 'preventive' },
  { label: 'MOTIVO NÃO PROCEDENTE', key: 'reason' },
  { label: 'USUÁRIO FINAL', key: 'user' },
  { label: 'FOTO', key: 'photo' },
  { label: 'RESPONSÁVEIS PELA PREVENTIVA', key: 'occurrencePreventiveResponsibles' },
  { label: 'AÇÕES DE ALINHAMENTO', key: 'occurrenceAlignmentActions' },
  { label: 'EVIDÊNCIAS (ANEXOS)', key: 'occurrenceAttachments' },
  { label: 'STATUS', key: 'step' },
  { label: 'OBSERVAÇÕES', key: 'observation' },
]

const occurrenceHeadersWithCollector = [
  { label: 'PROTOCOLO OCORRÊNCIA', key: 'occurrenceProtocol' },
  { label: 'OCORRÊNCIA', key: 'type' },
  { label: 'PROTOCOLO SERVIÇO', key: 'serviceProtocol' },
  { label: 'DATA DA CRIAÇÃO', key: 'createdAt' },
  { label: 'FINALIZADA EM', key: 'updatedAt' },
  { label: 'MOVIDA PARA VALIDAÇÃO (CLIENTE) EM', key: 'movedToValidationDate' },
  { label: 'CIDADE ORIGEM', key: 'sourceCities' },
  { label: 'CIDADE DESTINO', key: 'destinationCities' },
  { label: 'RESPONSÁVEL (ABERTURA)', key: 'owner' },
  { label: 'CLIENTE', key: 'customer' },
  { label: 'COLETADOR', key: 'responsible_collector_id' },
  { label: 'INTERCORRÊNCIA', key: 'intercurrence' },
  { label: 'DESCRIÇÃO', key: 'occurrenceDetail' },
  { label: 'ETAPA SERVIÇO', key: 'serviceStep' },
  { label: 'DATA PREVISTA (ATRASO)', key: 'occurrenceDate' },
  { label: 'HORÁRIO PREVISTO (ATRASO)', key: 'occurrenceHour' },
  { label: 'OBSERVAÇÕES', key: 'observation' },
  { label: 'PROCEDE ', key: 'proceed' },
  { label: 'CAUSA', key: 'cause' },
  { label: 'CORRETIVA', key: 'corrective' },
  { label: 'PREVENTIVA', key: 'preventive' },
  { label: 'MOTIVO NÃO PROCEDENTE', key: 'reason' },
  { label: 'FOTO', key: 'photo' },
  { label: 'RESPONSÁVEIS PELA PREVENTIVA', key: 'occurrencePreventiveResponsibles' },
  { label: 'AÇÕES DE ALINHAMENTO', key: 'occurrenceAlignmentActions' },
  { label: 'EVIDÊNCIAS (ANEXOS)', key: 'occurrenceAttachments' },
  { label: 'STATUS', key: 'step' },
  { label: 'VALOR TOTAL MULTAS', key: 'collectorFineValue' },
  { label: 'OBSERVAÇÕES', key: 'observation' },
]

const occurrenceHeadersWithDriver = [
  { label: 'PROTOCOLO OCORRÊNCIA', key: 'occurrenceProtocol' },
  { label: 'OCORRÊNCIA', key: 'type' },
  { label: 'PROTOCOLO SERVIÇO', key: 'serviceProtocol' },
  { label: 'DATA DA CRIAÇÃO', key: 'createdAt' },
  { label: 'FINALIZADA EM', key: 'updatedAt' },
  { label: 'MOVIDA PARA VALIDAÇÃO (CLIENTE) EM', key: 'movedToValidationDate' },
  { label: 'RESPONSÁVEL (ABERTURA)', key: 'owner' },
  { label: 'CLIENTE', key: 'customer' },
  { label: 'INTERCORRÊNCIA', key: 'intercurrence' },
  { label: 'DESCRIÇÃO', key: 'occurrenceDetail' },
  { label: 'ETAPA SERVIÇO', key: 'serviceStep' },
  { label: 'DATA PREVISTA (ATRASO)', key: 'occurrenceDate' },
  { label: 'HORÁRIO PREVISTO (ATRASO)', key: 'occurrenceHour' },
  { label: 'OBSERVAÇÕES', key: 'observation' },
  { label: 'PROCEDE ', key: 'proceed' },
  { label: 'MOTIVO NÃO PROCEDENTE', key: 'reason' },
  { label: 'USUÁRIO FINAL', key: 'user' },
  { label: 'FOTO', key: 'photo' },
  { label: 'EVIDÊNCIAS (ANEXOS)', key: 'occurrenceAttachments' },
  { label: 'STATUS', key: 'step' },
  { label: 'OBSERVAÇÕES', key: 'observation' },
]

const dispatchStockHeaders = [
  { label: 'PROTOCOLO', key: 'protocol' },
  { label: 'ETAPA', key: 'step' },
  { label: 'RESPONSÁVEL', key: 'sender' },
  { label: 'COLETADOR', key: 'collector' },
  { label: 'TRANSPORTADORA', key: 'shipping' },
  { label: 'BASE', key: 'branch' },
  { label: 'DATA DE EMBARQUE', key: 'boardDate' },
  { label: 'PREVISÃO DE CHEGADA', key: 'arrivalForecast' },
  { label: 'CUSTO DO DESPACHO', key: 'dispatchCost' },
  { label: 'CTE DO DESPACHO', key: 'dispatchCte' },
  { label: 'RASTREADOR DO DESPACHO', key: 'dispatchTracker' },
  { label: 'DATA DA CHEGADA', key: 'arrivalDate' },
  { label: 'MATERIAIS ENVIADOS', key: 'materials' },
  { label: 'RESPONSÁVEL PELA RETIRADA', key: 'withdrawal_responsible' }
]

export const rncHeaders = [
  { label: 'PROTOCOLO', key: 'protocol' },
  { label: 'DIRETORIA', key: 'direction' },
  { label: 'DATA', key: 'date' },
  { label: 'DESCRIÇÃO INTERCORRÊNCIA', key: 'intercurrence_description' },
  { label: 'VALOR PREJUÍZO', key: 'loss_value' },
  { label: 'ABERTA POR', key: 'opened_by' },
  { label: 'RESPONSÁVEL INTERCORRÊNCIA', key: 'intercurrence_responsible' },
  { label: 'POR QUE NÃO PROCEDE?', key: 'why_not_proceed' },
  { label: 'CAUSA RAIZ', key: 'cause' },
  { label: 'CORRETIVA', key: 'corrective' },
  { label: 'PREVENTIVA', key: 'preventive' },
  { label: 'CLASSIFICAÇÃO DE RISCO', key: 'risk_rating' },
  { label: 'TIPO DE RNC', key: 'rnc_type' },
  { label: 'OBSERVAÇÕES DE ALINHAMENTO', key: 'alignment_observation' },
  { label: 'REUNIÃO DE TRATATIVA', key: 'business_meeting' },
  { label: 'RESPONSÁVEL PELO ALINHAMENTO', key: 'aligned_by' },
  { label: 'DATA DO ALINHAMENTO', key: 'alignment_date' },
  { label: 'DESCRIÇÃO DO ALINHAMENTO', key: 'description' },
  { label: 'ANEXOS DO ALINHAMENTO', key: 'attachments' },
  { label: 'STATUS', key: 'status' },
]

const collectorReportHeaders = [
  { label: 'PROTOCOLO', key: 'protocol' },
  { label: 'CLIENTE', key: 'customer' },
  { label: 'CENTRO DE CUSTO', key: 'costCenter' },
  { label: 'HUB ORIGEM', key: 'sourceHub' },
  { label: 'CIDADES ORIGEM', key: 'sourceCities' },
  { label: 'ENDEREÇOS ORIGEM', key: 'sourceAddresses' },
  { label: 'HUB DESTINO', key: 'destinationHub' },
  { label: 'CIDADES DESTINO', key: 'destinationCities' },
  { label: 'ENDEREÇOS DESTINO', key: 'destinationAddresses' },
  { label: 'DATA DA REALIZAÇÃO DO SERVIÇO', key: 'collect_date' },
  { label: 'QUANTIDADE ENDEREÇOS DE COLETA', key: 'source_address_quantity' },
  {
    label: 'QUANTIDADE ENDEREÇOS DE ENTREGA',
    key: 'destination_address_quantity',
  },
  { label: 'REMETENTE', key: 'recipient' },
  { label: 'DESTINATÁRIO', key: 'receiver' },
  { label: 'VEÍCULO', key: 'vehicle' },
  { label: 'OCORRÊNCIAS', key: 'occurrences' },
  {
    label: 'VALOR TOTAL OCORRÊNCIAS',
    key: 'occurrenceTotal',
  },
  { label: 'CUSTO DO SERVIÇO', key: 'serviceTotalCost' },
  { label: 'TOTAL GERAL', key: 'totalCost' },
  { label: 'MOTORISTAS DE COLETA', key: 'collectDrivers' },
  { label: 'MOTORISTAS DE ENTREGA', key: 'deliveryDrivers' },
  { label: 'ETAPA DO SERVIÇO', key: 'serviceStep' },
  { label: 'VALOR TOTAL MULTAS', key: 'collectorFineValue' },
  { label: 'EXTRAS/DESCONTOS', key: 'extrasDiscounts' },
]

const customerManagmentReport = [
  { label: 'PROTOCOLO', key: 'protocol' },
  { label: 'CLIENTE', key: 'customer' },
  { label: 'ETAPA', key: 'step' },
  { label: 'DATA DA COLETA', key: 'collectDate' },
  { label: 'CIDADE(S) ORIGEM', key: 'sourceCities' },
  { label: 'QTD. END. ORIGEM NO SERVIÇO', key: 'sourceAddressQuantity' },
  { label: 'NOME REMETENTE', key: 'recipientName' },
  { label: 'HORÁRIO COLETA', key: 'realCollectHours' },
  { label: 'DATA DA ENTREGA', key: 'deliveryDate' },
  { label: 'CIDADE(S) DESTINO', key: 'destinationCities' },
  { label: 'QTD. END. DESTINO NO SERVIÇO', key: 'destinationAddressQuantity' },
  { label: 'NOME DESTINATÁRIO', key: 'receiverName' },
  { label: 'HORÁRIO ENTREGA', key: 'realDeliveryHours' },
  { label: 'QTD. END. SEM SUCESSO', key: 'unsuccessCollectsCount' },
  { label: 'END. SEM SUCESSO', key: 'unsuccessCollectsAddresses' },
]

const spotReportHeaders = [
  { label: 'PROTOCOLO', key: 'protocol' },
  { label: 'COLETAS COM SUCESSO', key: 'successCollectedAddresses' },
  { label: 'COLETAS SEM SUCESSO', key: 'unsuccessCollectedAddresses' },
]

export function handleChangeHeaders(slug: string, occurrenceType?: string) {
  switch (slug) {
    case 'sla':
      return slaHeaders
    case 'estoque':
      return stockHeaders
    case 'faturamento':
      return billingHeaders
    case 'cargas':
      return cargoHeaders
    case 'custo':
      return costHeaders
    case 'agendamentos':
      return scheduleHeaders
    case 'cte':
      return cteHeaders
    case 'controle-estoque':
      return stockControllerHeaders
    case 'ocorrencias':
      switch (occurrenceType) {
        case 'CLIENTE':
          return occurrenceHeadersWithCustomer
        case 'MOTORISTA':
          return occurrenceHeadersWithDriver
        case 'TRANSPORTADORA':
          return occurrenceHeadersWithShipping
        case 'COLETADOR':
          return occurrenceHeadersWithCollector
        default:
          return occurrenceHeaders
      }
    case 'despacho-estoque':
      return dispatchStockHeaders
    case 'rnc':
      return rncHeaders
    case 'coletador':
      return collectorReportHeaders
    case 'gerencial-cliente':
      return customerManagmentReport
    case 'pontos':
      return spotReportHeaders
    default:
      return []
  }
}
