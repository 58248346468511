import { Flex, Stack } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { SearchBox } from "components/SearchBox/SearchBox";
import { useCollectorExtrasDiscountsListContext } from "hooks/collector/useCollectorExtrasDiscountsListContext";
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions";

export function CollectorExtrasDiscountsFilterOptions() {

  const { filterOptions } = useFilterOptions()

  const { handleSetInputFilters } = useCollectorExtrasDiscountsListContext()

  return (
    <Stack mt="4" direction="column">
      {filterOptions
        .filter((option) => option.checked === true)
        .map((option) => (
          <Flex key={option.key}>
              {option.key === 'period' ? (
              <Stack
              spacing={4}
              direction={['column', 'column', 'row']}
            >
              <Input
                name='startDate'
                label='Data inicial do filtro'
                type='date'
                required
                onChange={(e) => handleSetInputFilters(e)}
              />
              <Input
                name='endDate'
                label='Data final do filtro'
                type='date'
                required
                onChange={(e) => handleSetInputFilters(e)}
              />
            </Stack>
            ) : (
              <SearchBox
                name={option.key}
                placeholder={`Buscar ${option.value}...`}
                handleSearch={(e) =>
                  handleSetInputFilters(e)
                }
              />
            )}
          </Flex>
        ))}
    </Stack>
  )
}
