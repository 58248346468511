import { Button, Flex, FormControl, FormLabel, IconButton, Input, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Progress, Select, Stack, Table, Tbody, Td, Text, Textarea, Tr } from "@chakra-ui/react";
import { PartnerSupportTypes } from "api/partnerSupport/_types/PartnerSupport";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { useForm, useWatch } from "react-hook-form";
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation, useQueryClient } from "react-query";
import { createPartnerSupport } from "api/partnerSupport/createPartnerSupport";
import { useCollectors } from "hooks/collector/useCollectors";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { ChangeEvent } from "react";
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa";

interface CreatePartnerSupportProps {
  onCloseModal: () => void
}

interface CreatePartnerSupportSchema {
  type: PartnerSupportTypes
  description: string
  collectorId: string
  requestAttachmentId?: FileList
  bank?: string
  agency?: number
  accountNumber?: string
}

const createPartnerSupportSchema = yup.object({
  type: yup.string().required(),
  collectorId: yup.string().required(),
  description: yup.string(),
  bank: yup.mixed().when('type', {
    is: 'SOLICITAÇÃO DE ALTERAÇÃO DE DADOS CADASTRAIS/CONTA DE PAGAMENTO',
    then: yup.string().required()
  }),
  agency: yup.mixed().when('type', {
    is: 'SOLICITAÇÃO DE ALTERAÇÃO DE DADOS CADASTRAIS/CONTA DE PAGAMENTO',
    then: yup.string().required()
  }),
  accountNumber: yup.mixed().when('type', {
    is: 'SOLICITAÇÃO DE ALTERAÇÃO DE DADOS CADASTRAIS/CONTA DE PAGAMENTO',
    then: yup.string().required()
  }),
  requestAttachmentId: yup.mixed().when('type', {
    is: (type: string) => [
      'ENVIO DE FATURA',
      'ENVIO DE NF',
      'ENVIO DE REGISTRO DE LIMPEZA',
    ].includes(type),
    then: yup.mixed().test((value: FileList) => value.length > 0)
  }),
})

const supportOptions: PartnerSupportTypes[] = [
  'TIRAR DÚVIDAS',
  'FALAR SOBRE CONTRATO/REAJUSTE',
  'FALAR SOBRE FATURA DE SERVIÇOS',
  'FALAR SOBRE NOTA FISCAL/PAGAMENTO',
  'SOLICITAÇÃO DE INSUMOS',
  'SOLICITAÇÃO DE ALTERAÇÃO DE DADOS CADASTRAIS/CONTA DE PAGAMENTO',
  'ENVIO DE FATURA',
  'ENVIO DE NF',
  'OUVIDORIA E DENÚNCIA',
  'SOLICITAÇÃO DE COTAÇÃO COM O PARCEIRO',
  'ENVIO DE REGISTRO DE LIMPEZA',
];

export function CreatePartnerSupport({ onCloseModal }: CreatePartnerSupportProps) {
  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const { mutation: uploadRequestAttachmentFn, uploadProggress: uploadProggressRequestAttachment } = useAttachmentMutation()

  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<CreatePartnerSupportSchema>({
    resolver: yupResolver(createPartnerSupportSchema)
  })

  const [requestAttachmentId, type] = useWatch({
    control,
    name: ['requestAttachmentId', 'type'],
  })


  const isRequestToChangeRegistrationData = type === 'SOLICITAÇÃO DE ALTERAÇÃO DE DADOS CADASTRAIS/CONTA DE PAGAMENTO'

  const typeRequiresAttachment = ['ENVIO DE FATURA',
    'ENVIO DE NF', 'ENVIO DE REGISTRO DE LIMPEZA'].includes(type)


  const {
    data: collectorsResult,
  } = useCollectors({
    queryParams: {
      situation: 'ATIVO',
    }
  })


  const { mutateAsync: createPartnerSupportFn } = useMutation({
    mutationFn: createPartnerSupport,
    onSuccess: () => {
      queryClient.invalidateQueries(['partnerSupports'])
      onCloseModal()
    }
  })

  async function handleUploadRequestAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadRequestAttachmentFn.mutateAsync(formData)

  }


  async function handleCreatePartnerSupport(values: CreatePartnerSupportSchema) {

    await promiseMessage(createPartnerSupportFn({
      body: {
        type: values.type,
        description: values.description,
        ...(typeRequiresAttachment && { requestAttachmentId: uploadRequestAttachmentFn?.data?.attachment?.id }),
        ...(isRequestToChangeRegistrationData && { bank: values.bank }),
        ...(isRequestToChangeRegistrationData && { agency: values.agency }),
        ...(isRequestToChangeRegistrationData && { accountNumber: Number(values.accountNumber.replace('-', ''))  }),
      },
      routeParams: {
        collectorId: values.collectorId
      }
    }), 'Atendimento criado!')
  }
  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Atendimento Parceiros
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        as="form"
        w="full"
        onSubmit={handleSubmit(handleCreatePartnerSupport)}
      >

        <Stack
          mt={3}
          spacing={3}
          w="full"
          direction={{ base: 'column', lg: 'row' }}
        >
          <FormControl isInvalid={!!errors.type} mt="3">
            <FormLabel fontSize="sm">
              Tipo de Atendimento
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register('type')}
              name="type"
              placeholder="Selecione..."
              size="sm"
              rounded="md"
            >
              {Object.entries(supportOptions).map(([key, value]) => (
                <option key={key} value={value}>{value}</option>
              ))}
            </Select>

          </FormControl>
        </Stack>
        <Stack
          mt={3}
          spacing={3}
          w="full"
          direction={{ base: 'column', lg: 'row' }}
        >
          <FormControl isInvalid={!!errors.collectorId}>

            <FormLabel fontSize="sm">
              Coletador
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register("collectorId")}
              name="collectorId"
              placeholder="Selecione uma opção..."
              size='sm'
              rounded="md"
            >
              {collectorsResult?.map((collector) => {
                return <option key={collector.id} value={collector.id}>{collector.trading_name}</option>
              })}
            </Select>
          </FormControl>
        </Stack>
        {isRequestToChangeRegistrationData && (
          <>
            <FormControl isInvalid={!!errors.bank} mt={3}>
              <FormLabel fontSize="sm">
                Banco
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('bank')}
                name="bank"
                size="sm"
                rounded="md"
              />
            </FormControl>
            <FormControl isInvalid={!!errors.agency} mt={3}>
              <FormLabel fontSize="sm">
                Agência
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('agency')}
                name="agency"
                size="sm"
                rounded="md"
              />
            </FormControl>
            <FormControl isInvalid={!!errors.accountNumber} mt={3}>
              <FormLabel fontSize="sm">
                Conta
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('accountNumber')}
                name="accountNumber"
                size="sm"
                rounded="md"
              />
            </FormControl>
          </>
        )}
        <FormControl isInvalid={!!errors.description} mt={3}>
          <FormLabel fontSize="sm">
            Descreva sua solicitação
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>

          <Textarea
            {...register("description")}
            name="description"
            size="sm"
            rounded="md"
          />
        </FormControl>
        {typeRequiresAttachment && (
          <>
            <Stack
              direction="column"
              w="full"
              mt="3"
            >
              <Button
                as={FormLabel}
                htmlFor="requestAttachmentId"
                lineHeight="1"
                leftIcon={<FaFileImport />}
                size="sm"
                w="min"
                cursor="pointer"
                border={!!errors?.requestAttachmentId && '2px solid'}
                borderColor={(!!errors?.requestAttachmentId) && 'red.500'}
              >
                Anexar Documento
              </Button>
              <FormControl isInvalid={!!errors?.requestAttachmentId}>
                <Input
                  {...register('requestAttachmentId')}
                  name="requestAttachmentId"
                  id="requestAttachmentId"
                  type="file"
                  hidden
                  onChangeCapture={handleUploadRequestAttachment}
                />
              </FormControl>
            </Stack>

            {requestAttachmentId && (
              <Table size="sm">
                <Tbody>
                  {Object.entries(requestAttachmentId).map(([key, file]) => {
                    return (
                      <Tr key={key}>
                        <Td fontSize="xs" maxW="100px">{file.name}</Td>
                        <Td fontSize="xs" w="200px">
                          <Progress size="sm" rounded="md" value={uploadProggressRequestAttachment} />
                        </Td>
                        <Td fontSize="xs" isNumeric>
                          {uploadRequestAttachmentFn.data && (
                            <IconButton
                              aria-label="Visualizar anexo"
                              as={Link}
                              size="sm"
                              icon={<FaExternalLinkAlt />}
                              href={uploadRequestAttachmentFn.data.attachment.link}
                              isExternal
                            />
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            )}
          </>
        )}

        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Criar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
