import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CreateExternalSupportBody {
  reason: string
  requestDescription?: string
  requestAttachmentId?: string
  bank?: string
  agency?: number
  accountOwner?: string
  cpf?: string
  accountNumber?: number
  accountType?: string
}

interface CreateExternalSupportProps {
  body: CreateExternalSupportBody
}

export async function createExternalSupport({
  body
}: CreateExternalSupportProps) {
  try {
    const response = await api.post(`/external-supports`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
