import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { useHistory, useParams, Link } from "react-router-dom"
import { FormProvider, useForm } from "react-hook-form";
import { Button, Flex, Spinner } from "@chakra-ui/react"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useAuth } from "hooks/auth/useAuth"
import { useToastify } from "hooks/toastify/useToastify";
import { ProductForm, ProductFormInputs } from "./components/ProductForm";
import { useEffect } from "react";
import { useMaterials } from "hooks/material/useMaterials";
import { useSwal } from "hooks/swal/useSwal";
import { useProduct } from "hooks/product/useProduct";
import { useProductFunctions } from "hooks/product/useProductFunctions";
import { useGeneralProviders } from "hooks/generalProvider/useGeneralProviders";
import { useWarehouses } from "hooks/warehouse/useWarehouses";

interface QueryParams {
  slug: string
  id: string
}

const editProductFormSchema = yup.object().shape({
  situation: yup.string().required("Campo Obrigatório"),
  material_id: yup.string().required("Campo Obrigatório"),
  general_provider_id: yup.string().required("Campo Obrigatório"),
  warehouse_id: yup.string().required("Campo Obrigatório"),
  measure: yup.string().required("Campo Obrigatório"),
  unit_cost: yup.string().required("Campo Obrigatório"),
  min_stock: yup.number().typeError("Campo Obrigatório").required("Campo Obrigatório"),
  standard_stock: yup.number().typeError("Campo Obrigatório").required("Campo Obrigatório"),
  current_stock: yup.number().typeError("Campo Obrigatório").required("Campo Obrigatório"),
})

export function EditProductPage() {
  const formMethods = useForm<ProductFormInputs>({
    resolver: yupResolver(editProductFormSchema)
  })

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  const { slug, id } = useParams<QueryParams>()

  const { promiseMessage } = useToastify()
  const { confirmMessage, standardMessage } = useSwal()

  const { setValue, handleSubmit, formState: { isSubmitting } } = formMethods

  const { data: product, isFetching: isFetchingProducts } = useProduct({ id: id, queryOptions: { enabled: !!id } })


  useEffect(() => {
    if (!userLogged.permissions.includes('edit-product')) {
      redirectTo("/")
    }
  }, [userLogged, redirectTo])

  const { data: materials, isFetching: isFetchingMaterials } = useMaterials()
  const { data: generalProviders, isFetching: isFetchingGeneralProviders } = useGeneralProviders()
  const { data: warehouses, isFetching: isFetchingWarehouses } = useWarehouses()

  const { updateProduct: { mutateAsync: updateProduct } } = useProductFunctions()

  useEffect(() => {
    if (product !== undefined) {
      setValue('situation', product.situation)
      setValue('material_id', product.material_id)
      setValue('general_provider_id', product.general_provider_id)
      setValue('warehouse_id', product.warehouse_id)
      setValue('measure', product.measure)
      setValue('unit_cost', product.unit_cost / 100)
      setValue('min_stock', product.min_stock)
      setValue('standard_stock', product.standard_stock)
      setValue('current_stock', product.current_stock)
    }

  }, [product, setValue])

  async function handleEditProduct({ ...values }: ProductFormInputs) {

    const hasUpdateProduct = await confirmMessage({ title: "Deseja editar o produto?" })

    if (hasUpdateProduct) {
      await promiseMessage(updateProduct({ id: id, input: values }, {
        onSuccess: () => redirectTo("/produtos")
      }), "Produto editado com sucesso!")
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  const isViewPage = slug === "visualizar"
  const formTitle = isViewPage ? "Visualizar Produto" : "Editar Produto"

  return (
    <StandardBackgroundForm title={formTitle} onSubmit={handleSubmit(handleEditProduct)}>

      {(
        isFetchingMaterials ||
        isFetchingGeneralProviders ||
        isFetchingProducts ||
        isFetchingWarehouses
      ) ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <ProductForm
            slug={slug}
            materials={materials}
            generalProviders={generalProviders}
            warehouses={warehouses} />
        </FormProvider>
      )}
      <Flex
        mt={6}
        gap={4}
        direction={["column", "column", "row"]}
        align="center"
        justify={["center", "center", "flex-end"]}
      >
        <Button
          as={Link}
          colorScheme='gray'
          w={['full', 'full', 'min']}
          isLoading={isSubmitting}
          to='/produtos'
        >
          Lista de produtos
        </Button>
        {isViewPage ? (
          <Button
            as={Link}
            to={`/produto/editar/${id}`}
            type="button"
            colorScheme="blue"
            w={["full", "full", "min"]}
            isLoading={isFetchingMaterials}
          >
            Editar
          </Button>
        ) : (<Button
          w={["full", "full", "min"]}
          colorScheme="blue"
          type="submit"
          isLoading={isSubmitting}
        >
          Salvar
        </Button>)}

      </Flex>
    </StandardBackgroundForm>
  )
}
