import { IconButton, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner, Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react";
import { getVaccine } from "api/vaccines/getVaccine";
import { format } from "date-fns";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useQuery } from "react-query";
import { captalize } from "utils/captalize";
import { vaccineCollaboratorTypeMap, vaccineTypesMap } from "utils/vaccineMappers";
import { VaccineStatus } from "./VaccineStatus";

interface VaccineDetailProps {
  vaccineId: string
}

export function VaccineDetail({
  vaccineId,
}: VaccineDetailProps) {

  const { data: vaccineData, isLoading: isVaccineDataLoading } = useQuery({
    queryKey: ['vaccine', vaccineId],
    queryFn: () => getVaccine({ vaccineId })
  })

  return (
    <ModalContent>
      {isVaccineDataLoading ? (
        <ModalBody
          p="6"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner />
        </ModalBody>
      ) : (
        <>
          <ModalHeader letterSpacing="tight">Detalhes da Vacina</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <TableContainer>
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td>Nome</Td>
                    <Td isNumeric>{vaccineData?.vaccine?.collaborator_name}</Td>
                  </Tr>
                  <Tr>
                    <Td>Tipo de Colaborador</Td>
                    <Td isNumeric>{vaccineCollaboratorTypeMap[vaccineData.vaccine.collaborator_type]}</Td>
                  </Tr>
                  <Tr>
                    <Td>Regional</Td>
                    <Td isNumeric>{vaccineData.vaccine.regional ? vaccineData.vaccine.regional.code.toUpperCase() : '-'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Responsável</Td>
                    <Td isNumeric>{vaccineData.vaccine.regional ? captalize(`${vaccineData.vaccine.regional.responsible.firstname} ${vaccineData.vaccine.regional.responsible.lastname}`) : '-'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Vacina</Td>
                    <Td display="flex" justifyContent="flex-end">
                      {vaccineTypesMap[vaccineData.vaccine.type]}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td display="flex" justifyContent="flex-end">
                      <VaccineStatus status={vaccineData.vaccine.status} />
                    </Td>
                  </Tr>
                  {vaccineData?.vaccine.next_dose_date && (
                    <Tr>
                      <Td>Próxima dose</Td>
                      <Td display="flex" justifyContent="flex-end">
                        {format(new Date(vaccineData.vaccine.next_dose_date), 'dd/MM/yyyy')}
                      </Td>
                    </Tr>
                  )}
                  {vaccineData.vaccine.cardFrontPhotoAttachment && (
                    <Tr>
                      <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">
                        Frente Cartão
                      </Td>
                      <Td isNumeric>
                        <IconButton
                          aria-label="Abrir anexo"
                          as={Link}
                          href={vaccineData.vaccine.cardFrontPhotoAttachment.link}
                          icon={<FaExternalLinkAlt />}
                          size="sm"
                          isExternal
                        />
                      </Td>
                    </Tr>
                  )}
                  {vaccineData.vaccine.cardBackPhotoAttachment && (
                    <Tr>
                      <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">
                        Verso Cartão
                      </Td>
                      <Td isNumeric>
                        <IconButton
                          aria-label="Abrir anexo"
                          as={Link}
                          href={vaccineData.vaccine.cardBackPhotoAttachment.link}
                          icon={<FaExternalLinkAlt />}
                          size="sm"
                          isExternal
                        />
                      </Td>
                    </Tr>
                  )}
                  {vaccineData?.vaccine.inactivated_at && (
                    <Tr>
                      <Td>Inativado em</Td>
                      <Td display="flex" justifyContent="flex-end">
                        {format(new Date(vaccineData.vaccine.inactivated_at), 'dd/MM/yyyy')}
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
        </>
      )}

    </ModalContent>
  )
}
