import { Button, Flex, FormControl, FormLabel, IconButton, Input, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Progress, Select, Stack, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { validateHasFile } from "utils/fileValidation";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { Controller, useForm, useWatch } from "react-hook-form";
import { ChangeEvent } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { createSporadic } from "api/sporadics/createSporadic";
import AsyncSelect from 'react-select/async';
import { getRegionals } from "api/regionals/getRegionals";
import { ReasonTypesSporadic, ServiceTypeSporadic, VehicleTypes } from "api/sporadics/_types/Sporadic";
import { captalize } from "utils/captalize";
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa";

interface CreateSporadicProps {
  onClose: () => void
}
const vehicleOptions: VehicleTypes[] = [
  'CARRO (FIORINO 2M³)',
  'MOTO (BAÚ 32X49X44 CM)',
  'DUCATO (10 M³)'
]

const serviceTypeOptions: ServiceTypeSporadic[] = [
  "OUTRO",
  "ENTREGA EMERGENCIAL DE RESULTADO DIRETAMENTE AO CLIENTE",
  "TRANSPORTE ALMOXARIFADO - URGÊNCIA",
  "TRANSPORTE DE CABOS E ACESSÓRIOS",
  "MATERIAL BIOLÓGICO FORA DA ESTRUTURA CONTRATADA",
  "MANUTENÇÃO CORRETIVA - HOSPITALAR",
  "FATURAMENTO DE CONVÊNIOS - FORA DA ESTRUTURA CONTRATADA",
  "MANUTENÇÃO PREVENTIVA - HOSPITALAR",
  "MOVIMENTAÇÃO DE EQUIPAMENTOS/MOBILIÁRIO DESCARTE",
  "DOCUMENTOS ADMINISTRATIVOS",
  "ENTREGA EMERGENCIAL DE RESULTADO ENTRE UNIDADES"
]

const reasonOptions: ReasonTypesSporadic[] = [
  "UNIDADE NÃO SOLICITOU O MATERIAL",
  "ITEM EM RUPTURA",
  "ALTERAÇÃO NO CONSUMO",
  "FALTA DE PEDIDO",
  "ALMOXARIFADO NÃO ATENDEU A REMESSA EM TEMPO HÁBIL",
  "REQUISIÇÃO NÃO APROVADA"
]

export interface CreateSporadicSchema {
  protocol: string
  applicant: string
  regionalId: {
    label: string
    value: string
  }
  startDate: string
  finalDate: string
  vehicle: string
  serviceType: string
  reason: string
  costCenter: string
  requestEmailAttachmentId: FileList
  responsible: string
}

const createSporadicSchema = yup.object({
  protocol: yup.string().required(),
  applicant: yup.string().required(),
  regionalId: yup.mixed().required(),
  startDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null
    value = new Date(value).toISOString()
    return value
  }),
  finalDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null
    value = new Date(value).toISOString()
    return value
  }),
  vehicle: yup.string().required(),
  serviceType: yup.string().required(),
  reason: yup.string().required(),
  costCenter: yup.string().required(),
  requestEmailAttachmentId: yup.mixed().test(value => validateHasFile(value)).required(),
  responsible: yup.string().required(),

})

export function CreateSporadic({ onClose }: CreateSporadicProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const { mutation: uploadRequestEmailAttachmentFn, uploadProggress: uploadProggressRequestEmailAttachment } = useAttachmentMutation()


  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<CreateSporadicSchema>({
    resolver: yupResolver(createSporadicSchema)
  })


  const [requestEmailAttachmentId] = useWatch({
    control,
    name: ['requestEmailAttachmentId'],
  })

  async function handleUploadRequestEmailAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadRequestEmailAttachmentFn.mutateAsync(formData)

  }

  async function promiseOptionsRegionals(inputValue: string): Promise<Array<{ value: string, label: string }>> {

    const response = await getRegionals({ currentPage: 1, pageSize: 10 })

    return response.regionals.map(regional => {
      return { value: regional.id, label: regional.code.toUpperCase() }
    })
  }

  const { mutateAsync: createSporadicFn } = useMutation({
    mutationFn: createSporadic,
    onSuccess(_data, _variables, _context) {
      queryClient.invalidateQueries({ queryKey: 'in-billing' })
      queryClient.invalidateQueries({ queryKey: 'sporadics' })
      onClose()
    }
  })


  async function handleCreateSporadic(values: CreateSporadicSchema) {
    await promiseMessage(createSporadicFn({
      body: {
        ...values,
        requestEmailAttachmentId: uploadRequestEmailAttachmentFn?.data?.attachment?.id,
        regionalId: values.regionalId?.value
      }
    }), 'Esporádico criado!')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Criar Esporádico
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleCreateSporadic)}
        maxH='600px'
        overflowY='scroll'
      >
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.protocol}>
              <FormLabel fontSize="sm">
                Código (Protocolo) do Esporádico
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('protocol')}
                name="protocol"
                rounded="md"
                size="sm"
              />
            </FormControl>
          </Stack>

          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.applicant}>
              <FormLabel fontSize="sm">
                Solicitante
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('applicant')}
                name="applicant"
                rounded="md"
                size="sm"
              />
            </FormControl>
          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors?.regionalId}>
              <FormLabel w="full" fontSize='sm'>
                Regional
                <Text as="sup" color="red.500">*</Text>

              </FormLabel>
              <Controller
                control={control}
                name="regionalId"
                render={({ field }) => (
                  <AsyncSelect
                    {...register('regionalId')}
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                    cacheOptions
                    defaultOptions
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: '1px',
                        borderRadius: '6px',
                        borderColor: 'gray.200',
                        border: state.isFocused ? 'none' : '',
                        boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                      }),
                      placeholder: (base, props) => ({
                        ...base,
                        color: 'blackAlpha.900'
                      }),
                      dropdownIndicator(base, props) {
                        return {
                          ...base,
                          color: 'blackAlpha.900',
                          width: '34px',
                        }
                      },
                    }}
                    noOptionsMessage={() => 'Não há regionais cadastradas ou encontradas!'}
                    placeholder="Selecione uma regional..."
                    loadOptions={promiseOptionsRegionals}
                    isClearable={true}

                  />
                )}
              />
            </FormControl>
          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors?.startDate}>
              <FormLabel fontSize="sm">
                Data inicial para Realização
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('startDate')}
                name="startDate"
                type="datetime-local"
                rounded="md"
                size='sm'
              />
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors?.finalDate}>
              <FormLabel fontSize="sm">
                Data final para Realização
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('finalDate')}
                name="finalDate"
                type="datetime-local"
                rounded="md"
                size='sm'
              />
            </FormControl>
          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.vehicle}>
              <FormLabel fontSize="sm">
                Tipo de Veículo
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Select
                {...register('vehicle')}
                name="vehicle"
                placeholder="Selecione..."
                rounded="md"
                size='sm'
              >
                {Object.entries(vehicleOptions).map(([key, value]) => (
                  <option key={key} value={value}>{captalize(value)}</option>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.serviceType}>
              <FormLabel fontSize="sm">
                Tipo de Serviço
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Select
                {...register('serviceType')}
                name="serviceType"
                placeholder="Selecione..."
                rounded="md"
                size='sm'
              >
                {Object.entries(serviceTypeOptions).map(([key, value]) => (
                  <option key={key} value={value}>{captalize(value)}</option>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.reason}>
              <FormLabel fontSize="sm">
                Motivo
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Select
                {...register('reason')}
                name="reason"
                placeholder="Selecione..."
                rounded="md"
                size='sm'
              >
                {Object.entries(reasonOptions).map(([key, value]) => (
                  <option key={key} value={value}>{captalize(value)}</option>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.costCenter}>
              <FormLabel fontSize="sm">
                Centro de Custo
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('costCenter')}
                name="costCenter"
                rounded="md"
                size="sm"
              />
            </FormControl>
          </Stack>
        </Stack>
        <Stack
          direction="column"
          w="full"
          mt="3"
        >
          <Button
            as={FormLabel}
            htmlFor="requestEmailAttachmentId"
            lineHeight="1"
            leftIcon={<FaFileImport />}
            size="sm"
            w="min"
            cursor="pointer"
            border={!!errors?.requestEmailAttachmentId && '2px solid'}
            borderColor={(!!errors?.requestEmailAttachmentId) && 'red.500'}
          >
            E-mail da Solicitação
          </Button>
          <FormControl isInvalid={!!errors?.requestEmailAttachmentId}>
            <Input
              {...register('requestEmailAttachmentId')}
              name="requestEmailAttachmentId"
              id="requestEmailAttachmentId"
              type="file"
              hidden
              onChangeCapture={handleUploadRequestEmailAttachment}
            />
          </FormControl>
        </Stack>

        {requestEmailAttachmentId && (
          <Table size="sm">
            <Tbody>
              {Object.entries(requestEmailAttachmentId).map(([key, file]) => {
                return (
                  <Tr key={key}>
                    <Td fontSize="xs" maxW="100px">{file.name}</Td>
                    <Td fontSize="xs" w="200px">
                      <Progress size="sm" rounded="md" value={uploadProggressRequestEmailAttachment} />
                    </Td>
                    <Td fontSize="xs" isNumeric>
                      {uploadRequestEmailAttachmentFn.data && (
                        <IconButton
                          aria-label="Visualizar anexo"
                          as={Link}
                          size="sm"
                          icon={<FaExternalLinkAlt />}
                          href={uploadRequestEmailAttachmentFn.data.attachment.link}
                          isExternal
                        />
                      )}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        )}
        <FormControl isInvalid={!!errors.responsible} mt={3}>
          <FormLabel fontSize="sm">
            Responsável por Realizar (Empresa ou Colaborador)
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('responsible')}
            name="responsible"
            rounded="md"
            size="sm"
          />
        </FormControl>
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Criar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
