import { Button, Flex, Icon, Spinner } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import * as yup from "yup"
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { FaList } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { ExtrasAndDiscountsForm, ExtrasAndDiscountsFormInputs } from "./components/ExtrasAndDiscountsForm";
import { useExtrasAndDiscountsFunctions } from "hooks/extrasAndDiscounts/useExtrasAndDiscountsFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";
import { useBusinessBudgetRoutes } from "hooks/businessBudget/useBusinessBudgetsRoutes";
import { useNonInactiveCustomers } from "hooks/customer/useNonInactiveCustomers";

const createExtrasAndDiscountsFormSchema = yup.object().shape({
  customer_id: yup.string().required('Campo obrigatório.'),
  type: yup.string().required('Campo obrigatório.'),
  service_type: yup.string().required('Campo obrigatório.'),
  protocol: yup.string().when('service_type', {
    is: 'EXPRESS',
    then: yup.string().required('Campo obrigatório.')
  }),
  route: yup.string().when('service_type', {
    is: 'BUSINESS',
    then: yup.string().required('Campo obrigatório.')
  }),
  occurrence_date:yup.string().when('service_type', {
    is: 'BUSINESS',
    then: yup.string().required('Campo obrigatório.')
  }),
  description: yup.string().required('Campo obrigatório.'),
  value: yup.string().required('Campo obrigatório.'),
})

export function CreateExtrasAndDiscounts() {

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  const formMethods = useForm<ExtrasAndDiscountsFormInputs>({
    resolver: yupResolver(createExtrasAndDiscountsFormSchema)
  })

  const { control, handleSubmit } = formMethods

  const userLoggedHasPermissionToCreateExtrasAndDiscounts =
    userLogged?.permissions.includes('create-extras-and-discounts')

  useEffect(() => {
    if (!userLoggedHasPermissionToCreateExtrasAndDiscounts) {
      redirect('/')
    }
  }, [userLoggedHasPermissionToCreateExtrasAndDiscounts, redirect])

  const {
    data: customersData,
    isFetching: isCustomersDataFetching
  } = useNonInactiveCustomers()

  const [customerSelected, serviceType] = useWatch({
    control,
    name: ['customer_id', 'service_type']
  })

  const isBusinessServiceType = serviceType === 'BUSINESS'

  const {
    data: businessBudgetRoutesData,
    isFetching: isFetchingBusinessBudgetRoutesData
  } = useBusinessBudgetRoutes({
    customer_id: customerSelected,
    queryOptions: {
      enabled: !!customerSelected && isBusinessServiceType
    }
  })

  const businessBudgetRoutesOptions = businessBudgetRoutesData?.routes.map(
    (route) => {
      return {
        key: route.id,
        value: route.route_nickname,
        showOption: route.route_nickname
      }
    }
  )

  const { promiseMessage } = useToastify()

  const {
    createCustomerExtrasAndDiscounts: {
      mutateAsync: createCustomerExtrasAndDiscounts
    }
  } = useExtrasAndDiscountsFunctions()

  async function handleCreateExtrasAndDiscounts(
    formValues: ExtrasAndDiscountsFormInputs
  ) {
    const successMessage = formValues.type === 'DESCONTO'
      ? 'Desconto criado com sucesso!'
      : 'Extra criado com sucesso!'

    const formData = new FormData()

    formData.append('type', formValues.type)
    formData.append('service_type', formValues.service_type)
    if (formValues.protocol) {
      formData.append('protocol', formValues.protocol.toString())
    }
    if (formValues.route) {
      formData.append('route', formValues.route)
    }
    if(formValues.occurrence_date){
      formData.append('occurrence_date', formValues.occurrence_date)
    }
    formData.append('description', formValues.description)
    formData.append('value', formValues.value)
    formData.append('attachment', formValues.attachment[0])

    await promiseMessage(createCustomerExtrasAndDiscounts({
      customer_id: formValues.customer_id,
      input: formData
    }, {
      onSuccess: () => {
        redirect('/extras-e-descontos')
      }
    }), successMessage)
  }

  const customersOptions = customersData?.customers.map((customer) => {
    return {
      key: customer.id,
      value: customer.id,
      showOption: customer.trading_firstname
    }
  }).sort((a, b) => a.showOption.localeCompare(b.showOption))

  return (
    <StandardBackgroundForm
      onSubmit={handleSubmit(handleCreateExtrasAndDiscounts)}
      title='Adicionar extras e descontos'
    >

      {isCustomersDataFetching ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <ExtrasAndDiscountsForm
            slug={'create'}
            customerOptions={customersOptions}
            businessBudgetRoutesOptions={businessBudgetRoutesOptions}
            isFetchingBusinessBudgetRoutesData={isFetchingBusinessBudgetRoutesData}
          />
        </FormProvider>
      )}

      <Flex w='full' mt={4} gap={2} justify='flex-end'>
        <Button
          type='submit'
          colorScheme='blue'
        >
          Cadastrar
        </Button>
        <Button
          as={Link}
          to='/extras-e-descontos'
          leftIcon={<Icon as={FaList} />}
        >
          Extras e descontos
        </Button>
      </Flex>

    </StandardBackgroundForm>
  )
}
