import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, IconButton, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { getCltIntercurrences } from "api/cltIntercurrences/getCltIntercurrences";
import { CltIntercurrenceStatus } from "api/cltIntercurrences/types/CltIntercurrence";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { FaPlus } from "react-icons/fa6";
import { useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { CltIntercurrenceTableFilters } from "./components/CltIntercurrenceTableFilters";
import { CltIntercurrenceTableRow } from "./components/CltIntercurrenceTableRow";

export function CltIntercurrences() {
  const searchParams = useSearchParams()

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanCreateCltIntercurrence = userLogged?.permissions?.includes('create-clt-intercurrence')

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status')

  const {
    data: cltIntercurrencesResult
  } = useQuery({
    queryKey: ['clt-intercurrences', page, status],
    queryFn: () => getCltIntercurrences({
      currentPage: page,
      pageSize: '10',
      status: status as CltIntercurrenceStatus | 'all'
    })
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: cltIntercurrencesResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: cltIntercurrencesResult?.meta?.pageSize
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Intercorrências CLT</Heading>

        {userCanCreateCltIntercurrence && (
          <IconButton
            aria-label="Criar intercorrência clt"
            as={Link}
            to="/intercorrencia-clt/criar"
            icon={<FaPlus />}
            colorScheme="blue"
          />
        )}
      </Flex>

      <CltIntercurrenceTableFilters />

      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Tipo de intercorrência</Th>
              <Th color="white">Data da intercorrência</Th>
              <Th color="white">Regional</Th>
              <Th color="white">Nome do Colaborador</Th>
              <Th color="white">Status</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {cltIntercurrencesResult?.cltIntercurrences.map((cltIntercurrence) => {
              return (
                <CltIntercurrenceTableRow
                  key={cltIntercurrence.id}
                  cltIntercurrence={cltIntercurrence}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />

    </Box>
  )
}
