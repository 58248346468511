import { Box, Button, Checkbox, CheckboxGroup, Flex, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Stack, Text } from "@chakra-ui/react";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { captalize } from "utils/captalize";
import { useToastify } from "hooks/toastify/useToastify";
import { createPatrimonyRequest } from "api/patrimonies/createPatrimonyRequest";
import { getPatrimonyTypes } from "api/patrimonies/getPatrimonyTypes";
import { useSearchParams } from "hooks/useSearchParams";
import { useHistory } from "react-router-dom";


interface CreateRequestPatrimonyProps {
  onCloseModal: () => void
  required?: boolean
}

interface CreateRequestPatrimonySchema {
  limitDate: string
  patrimonyTypesIds: string[]
  receiptAttachmentId: FileList
  regional: string
  responsibleName: string
  sector: string
}

const createRequestPatrimonySchema = yup.object({
  limitDate: yup.string().required(),
  regional: yup.string().required(),
  responsibleName: yup.string().required(),
  sector: yup.string().required(),
  patrimoniesId: yup.array()
    .min(1)
    .of(yup.string()),
})

const sectors = [
  'Administrativo',
  'Financeiro',
  'Almoxarifado',
  'Comercial',
  'Copa',
  'Lifebox',
  'Operacional',
  'Qualidade',
  'RH',
  'Sala de Reunião',
  'Sala Executivo'
]

const regionals = [
  'MG',
  'RJ',
  'DF',
  'SP',
  'OUTRA'
]
export function CreateRequestPatrimony({
  onCloseModal,
  required = false
}: CreateRequestPatrimonyProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const searchParams = useSearchParams()
  const { replace } = useHistory()


  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    }
  } = useForm<CreateRequestPatrimonySchema>({
    resolver: yupResolver(createRequestPatrimonySchema),
    defaultValues: {
      patrimonyTypesIds: [],
    },
  })

  const { data: patrimonyTypesResult } = useQuery({
    queryKey: ['patrimonyTypes'],
    queryFn: () => getPatrimonyTypes({}),
    keepPreviousData: true
  })


  const { mutateAsync: createPatrimonyRequestFn } = useMutation({
    mutationFn: createPatrimonyRequest,
    onSuccess() {
      queryClient.invalidateQueries(['requestsPatrimony'])

      if (required) {
        searchParams.set('patrimonyRequested', 'true')
        replace({ search: searchParams.toString() })
      }
      onCloseModal()
    }
  })

  async function handleRequestPatrimony({
    limitDate,
    patrimonyTypesIds,
    regional,
    responsibleName,
    sector
  }: CreateRequestPatrimonySchema) {
    await promiseMessage(createPatrimonyRequestFn({
      body: {
        limitDate,
        patrimonyTypesIds,
        regional,
        responsibleName,
        sector
      }
    }), 'Solicitação de patrimônio criada! 🎉')
  }


  return (
    <ModalContent>
      <ModalHeader>Solicitar Patrimônio</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleRequestPatrimony)}
        maxH='600px'
        overflowY='scroll'
      >
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Regional
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.regional}>
              <Select
                {...register("regional")}
                name="regional"
                placeholder="Selecione..."
                size="sm"
                rounded="md"
              >
                {regionals?.map((regional, index) => {
                  return (
                    <option key={index} value={regional}>{regional}</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>

        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            spacing="0.25"
            w="full"
          >
            <FormControl isInvalid={!!errors?.responsibleName}>
              <FormLabel fontSize="sm">
                Nome Responsável
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('responsibleName')}
                name="responsibleName"
                size="sm"
                rounded="md"
              />
            </FormControl>

          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Tipo de patrimônio
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Box
              overflowY="auto"
              height="100px"
              w='400px'
              borderWidth="1px"
              borderRadius="lg"
              p="2">

              <FormControl isInvalid={!!errors.patrimonyTypesIds}>
                <Controller
                  name="patrimonyTypesIds"
                  control={control}
                  render={({ field }) => {
                    return (
                      <CheckboxGroup
                        {...field}
                        onChange={field.onChange}
                      >
                        <Stack
                          direction="column"
                        >
                          {patrimonyTypesResult?.patrimonyTypes.map((patrimonyType) => {
                            return (
                              <Checkbox
                                size='sm'
                                rounded='md'
                                mr='6'
                                key={patrimonyType.id}
                                value={patrimonyType.id}
                              >
                                {patrimonyType?.type}
                              </Checkbox>
                            )
                          })}
                        </Stack>
                      </CheckboxGroup>
                    )
                  }}
                />

              </FormControl>
            </Box>
          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <FormControl isInvalid={!!errors?.limitDate}>
            <FormLabel fontSize="sm">
              Data limite
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('limitDate')}
              name="limitDate"
              type="date"
              size="sm"
              rounded="md"
            />
          </FormControl>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            spacing="0.25"
            w="full"
          >
            <FormLabel fontSize="sm">
              Setor
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.sector}>
              <Select
                {...register('sector')}
                name="sector"
                placeholder="Selecione..."
                size="sm"
                rounded="md"
              >
                {sectors?.map((sector, index) => {
                  return (
                    <option key={index} value={sector}>{captalize(sector)}</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>
        </Stack>

        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Criar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent >
  )
}
