import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { UserForm } from './UserForm'
import { NestedValue } from 'react-hook-form'
import { useUserFunctions } from '../../hooks/user/useUserFunctions'
import { useAuth } from '../../hooks/auth/useAuth'
import { useSwal } from '../../hooks/swal/useSwal'
import { Modal, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import { CreateOnboarding } from 'pages/Onboarding/components/CreateOnboarding'

interface IFormInputProps {
  user_type: string
  situation: string
  email: string
  firstname: string
  lastname: string
  loglife_employee: string
  password: string
  permissions: NestedValue<string[]>
  customers: string[]
  collector_id: string | null
  driver_id: string | null
  substitute_id: string
}

interface QueryParams {
  slug: string
  id: string
}

async function formatDataToBack(values: IFormInputProps) {
  const dataPermissions =
    values.permissions.toString().split(',').length > 1
      ? values.permissions
      : values.permissions.toString().split('  ')

  const newUserFormatedData = {
    user_type: values.user_type,
    situation: values.situation,
    email: values.email,
    firstname: values.firstname,
    lastname: values.lastname,
    loglife_employee: values.loglife_employee === "SIM" ? true : false,
    password: values.password,
    permissions: dataPermissions,
    customers: values.customers,
    collector_id: values.collector_id === '' ? null : values.collector_id,
    driver_id: values.driver_id === '' ? null : values.driver_id,
    substitute_id: values.substitute_id,
  }

  return newUserFormatedData
}

export function UserAddDetailEdit() {
  const { confirmMessage, standardMessage } = useSwal()
  const { slug, id }: QueryParams = useParams()
  const { userLogged } = useAuth()

  const {
    createUser: {
      mutateAsync: createUser,
      isLoading: isCreateUserLoading,
    },
    editUser: {
      mutateAsync: editUser,
      isLoading: isEditUserLoading,
    },
  } = useUserFunctions()

  const { push: redirectTo } = useHistory()

  useEffect(() => {
    const permissions = userLogged?.permissions
    try {
      if (permissions !== undefined) {
        if (slug === 'adicionar') {
          if (!permissions?.includes('add-user')) {
            redirectTo('/')
          }
        }
        if (slug === 'editar') {
          if (!permissions?.includes('edit-user')) {
            redirectTo('/')
          }
        }
        if (slug === 'visualizar') {
          if (!permissions?.includes('view-user')) {
            redirectTo('/')
          }
        }
      }
    } catch {
      redirectTo('/')
    }
  }, [slug, redirectTo, userLogged])

  const {
    isOpen: isCreateOnboardingModalOpen,
    onOpen: onOpenCreateOnboardingModal,
    onClose: onCloseCreeateOnboardingModal,
  } = useDisclosure()

  async function handleCreateUser(values: IFormInputProps) {
    const hasCreateUser = await confirmMessage({
      title: 'Deseja criar um usuário?',
    })

    if (hasCreateUser) {
      const valuesFormatedToBack = await formatDataToBack(values)
      await createUser(valuesFormatedToBack, {
        onSuccess: () => {
          if (values.loglife_employee === 'SIM' || values.user_type === 'MOTORISTA') {
            onOpenCreateOnboardingModal()
          }
        }
      })
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  async function handleEditUser(values: IFormInputProps) {
    const hasEditUser = await confirmMessage({
      title: 'Deseja editar um usuário?',
    })

    if (hasEditUser) {
      const valuesFormatedToBack = await formatDataToBack(values)
      const editUserReqData = { id, input: valuesFormatedToBack }

      await editUser(editUserReqData, {
        onSuccess: () => redirectTo('/usuarios')
      })
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (isCreateUserLoading || isEditUserLoading) {
    return <GeneralContentLoading />
  }

  if (slug === 'adicionar') {
    return (
      <>
        <Modal
          isOpen={isCreateOnboardingModalOpen}
          onClose={onCloseCreeateOnboardingModal}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />

          <CreateOnboarding redirectPath='/usuarios' isRequired={true} onCloseModal={onCloseCreeateOnboardingModal} />
        </Modal>
        <UserForm
          slug={slug}
          href=""
          submit={handleCreateUser}
          title="Adicionar Usuário"
          action="Salvar"
        />
      </>
    )
  } else if (slug === 'editar') {
    return (
      <UserForm
        slug={slug}
        href=""
        id={id}
        submit={handleEditUser}
        title="Editar Usuário"
        action="Salvar"
      />
    )
  } else {
    return (
      <UserForm
        slug={slug}
        href={`/usuario/editar/${id}`}
        id={id}
        submit={async () => { }}
        title="Visualizar Usuário"
        isDisabled={true}
        action="Editar"
      />
    )
  }
}
