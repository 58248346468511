import { Box, Button, FormControl, FormLabel, HStack, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Radio, RadioGroup, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { finishVacationPeriod } from "api/vacations/finishVacationPeriod";
import { useToastify } from "hooks/toastify/useToastify";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import * as yup from "yup"

interface FinishVacationPeriodSchema {
  withPontomaisAllowance: 'yes' | 'no'
  isFuelBenefitBlocked: 'yes' | 'no'
}

interface FinishVacationPeriodProps {
  vacationId: string
  onClose: () => void
}

const finishVacationPeriodSchema = yup.object({
  withPontomaisAllowance: yup.string().required(),
  isFuelBenefitBlocked: yup.string().required(),
})

export function FinishVacationPeriod({ vacationId, onClose }: FinishVacationPeriodProps) {
  const {
    control,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<FinishVacationPeriodSchema>({
    resolver: yupResolver(finishVacationPeriodSchema)
  })

  const { mutateAsync: finishVacationPeriodFn } = useMutation({
    mutationFn: finishVacationPeriod,
    onSuccess: onClose,
  })

  const { promiseMessage } = useToastify()

  async function handleFinishVacationPeriod(values: FinishVacationPeriodSchema) {
    await promiseMessage(finishVacationPeriodFn({
      body: {
        isFuelBenefitBlocked: values.isFuelBenefitBlocked === 'yes',
        withPontomaisAllowance: values.withPontomaisAllowance === 'yes'
      },
      vacationId,
    }), 'Período de férias finalizado com sucesso!')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Finalizar período
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        <Box
          as="form"
          w="full"
          onSubmit={handleSubmit(handleFinishVacationPeriod)}
        >
          <FormControl isInvalid={!!errors.withPontomaisAllowance}>
            <FormLabel fontSize="sm">
              Abonou ponto mais?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Controller
              control={control}
              name="withPontomaisAllowance"
              render={({ field }) => {
                return (
                  <RadioGroup
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                  >
                    <HStack spacing={3} fontSize="sm">
                      <Radio value="yes">
                        <Text fontSize="sm">Sim</Text>
                      </Radio>
                      <Radio value="no">
                        <Text fontSize="sm">Não</Text>
                      </Radio>
                    </HStack>

                  </RadioGroup>
                )
              }}
            />
          </FormControl>

          <FormControl isInvalid={!!errors.isFuelBenefitBlocked} mt={3}>
            <FormLabel fontSize="sm">
              Beneficio de combustível bloqueado?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Controller
              control={control}
              name="isFuelBenefitBlocked"
              render={({ field }) => {
                return (
                  <RadioGroup
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                  >
                    <HStack spacing={3} fontSize="sm">
                      <Radio value="yes">
                        <Text fontSize="sm">Sim</Text>
                      </Radio>
                      <Radio value="no">
                        <Text fontSize="sm">Não</Text>
                      </Radio>
                    </HStack>

                  </RadioGroup>
                )
              }}
            />
          </FormControl>

          <HStack
            w="full"
            justify="end"
            mt={3}
          >
            <Button
              type="submit"
              colorScheme="blue"
              ml="auto"
              size="sm"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Salvar
            </Button>
          </HStack>
        </Box>


      </ModalBody>
    </ModalContent>
  )
}
