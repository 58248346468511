import { Spinner, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Menu, MenuButton, IconButton, MenuList, MenuGroup, MenuItem, Icon, Button } from "@chakra-ui/react"
import { AggregatesExtrasDiscountsStatusEnum } from "contexts/AggregatesExtrasDiscountsStatusContext"
import { useAggregateExtrasDiscountsListContext } from "hooks/aggregate/useAggregateExtrasDiscountsListContext"
import { useAggregatesExtrasDiscounts } from "hooks/aggregate/useAggregatesExtrasDiscounts"
import { useAggregatesExtrasDiscountsStatus } from "hooks/aggregate/useAggregatesExtrasDiscountsStatus"
import { useAuth } from "hooks/auth/useAuth"
import { ExtraDiscount } from "hooks/extrasDiscounts/dtos/ExtrasDiscounts"
import { useEffect, useState } from "react"
import { FaClone, FaEllipsisH, FaEye } from "react-icons/fa"
import { AggregatesExtrasDiscountsTableStatusButton } from "./AggregatesExtrasDiscountsTableStatusButton"

interface AggregateExtrasDiscountsTableProps {
  rowsPerPage: string
  statusFilter: RegExpMatchArray
  currentPageFilter: RegExpMatchArray
  aggregatesExtrasDiscountsStatusActionsOptions: any
  onSetTotalPages: (total: number) => void
  onOpenViewExtraDiscountModal: (extraDiscount: ExtraDiscount) => void
  onSetAggregatesExtrasDiscountsData: (aggregatesExtrasDiscounts: ExtraDiscount[]) => void
}

export function AggregateExtrasDiscountsTable({
  rowsPerPage,
  statusFilter,
  currentPageFilter,
  aggregatesExtrasDiscountsStatusActionsOptions,
  onSetTotalPages,
  onOpenViewExtraDiscountModal,
  onSetAggregatesExtrasDiscountsData
}: AggregateExtrasDiscountsTableProps) {
  const [debouncedFilters, setDebouncedFilters] = useState({
    aggregate: '',
    startDate: '',
    endDate: '',
    route: ''
  })

  const { userLogged } = useAuth()

  const { status } = useAggregatesExtrasDiscountsStatus()
  const { filters } = useAggregateExtrasDiscountsListContext()

  useEffect(() => {
    const debounce = setTimeout(() => {
      setDebouncedFilters({
        aggregate: filters.aggregate,
        startDate: filters.startDate,
        endDate: filters.endDate,
        route: filters.route
      })
    }, 1000)

    return () => clearTimeout(debounce)
  }, [filters])


  const {
    data: aggregatesExtrasDiscountsData,
    isFetching: isFetchingAggregatesExtrasDiscountsData
  } = useAggregatesExtrasDiscounts({
    queryParams: {
      pageSize: Number(rowsPerPage),
      status: statusFilter ? statusFilter[1] as keyof typeof AggregatesExtrasDiscountsStatusEnum : status,
      currentPage: currentPageFilter ? Number(currentPageFilter[1]) : 1,
      aggregate: debouncedFilters.aggregate ? debouncedFilters?.aggregate : undefined,
      startDate: debouncedFilters.startDate ? debouncedFilters?.startDate : undefined,
      endDate: debouncedFilters.endDate ? debouncedFilters?.endDate : undefined,
      route: debouncedFilters.route ? debouncedFilters?.route : undefined
    }
  })

  useEffect(() => {
    if (aggregatesExtrasDiscountsData) {
      onSetTotalPages(aggregatesExtrasDiscountsData.totalPages)
      onSetAggregatesExtrasDiscountsData(aggregatesExtrasDiscountsData.extrasDiscounts)
    }
  }, [aggregatesExtrasDiscountsData, onSetAggregatesExtrasDiscountsData, onSetTotalPages])

  return (
    <>
      {isFetchingAggregatesExtrasDiscountsData ? (
        <Spinner />
      ) : (
        <TableContainer>
          <Table size='sm' variant='striped'>
            <Thead>
              <Tr>
                <Th></Th>
                <Th>MOTORISTA</Th>
                <Th>TIPO</Th>
                <Th>DATA DO SERVIÇO</Th>
                <Th>VALOR</Th>
                <Th>ROTA</Th>
                <Th>PROTOCOLO</Th>
                <Th>CRIADO POR</Th>
                <Th>
                  <AggregatesExtrasDiscountsTableStatusButton />
                </Th>
                <Th></Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {aggregatesExtrasDiscountsData?.extrasDiscounts.map((extraDiscount) => {

                const extraDiscountStatus = AggregatesExtrasDiscountsStatusEnum[status]

                const [serviceYear, serviceMonth, serviceDay] = extraDiscount.service_date.split('-')

                return (
                  <Tr key={extraDiscount.id}>
                    <Td>
                      {extraDiscount.is_duplicated && (
                        <Icon as={FaClone} mx="2px" title="Extra/Desconto duplicado" color='blue.800' />
                      )}
                    </Td>
                    <Td>{`${extraDiscount.aggregate.driver.firstname} ${extraDiscount.aggregate.driver.lastname} `}</Td>
                    <Td>{extraDiscount.type}</Td>
                    <Td>{`${serviceDay}/${serviceMonth}/${serviceYear}`}</Td>
                    <Td>{
                      new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      }).format(extraDiscount.value / 100)
                    }</Td>
                    <Td>{extraDiscount.service_route ?? '-'}</Td>
                    <Td>{extraDiscount.service_protocol ?? '-'}</Td>
                    <Td>{extraDiscount?.createdBy ? `${extraDiscount?.createdBy.firstname} ${extraDiscount?.createdBy.lastname}` : '-'}</Td>
                    <Td>{extraDiscountStatus}</Td>
                    <Td>
                      <Button
                        as={IconButton}
                        icon={<Icon as={FaEye} />}
                        onClick={() => onOpenViewExtraDiscountModal(extraDiscount)}
                      />
                    </Td>
                    <Td>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          variant='ghost'
                          aria-label='Options'
                          icon={<FaEllipsisH />}
                        />
                        <MenuList>
                          <MenuGroup title="Ações">
                            {aggregatesExtrasDiscountsStatusActionsOptions
                              ?.find((option) => option.status === status)?.actions.filter((action) => userLogged?.permissions.includes(action.permission)).map((action) => {
                                return (
                                  <MenuItem
                                    key={action.type}
                                    onClick={() => action.handle(extraDiscount.id)}
                                    display="flex"
                                    alignItems="center"
                                    gap="2"
                                  >
                                    <Icon as={action.icon} />
                                    {action.type}
                                  </MenuItem>

                                )
                              })}
                          </MenuGroup>

                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}
