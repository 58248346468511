import { Button, Flex, FormLabel, Icon, Stack, Link as ChakraLink, Text, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { TextArea } from "components/Inputs/TextInput";
import { useAttachmentFunctions } from "hooks/attachment/AttachmentFunctions";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { useBusinessBudgetRoutes } from "hooks/businessBudget/useBusinessBudgetsRoutes";
import { useCustomers } from "hooks/customer/useCustomers";
import { ExtraDiscountAttachments } from "hooks/extrasDiscounts/dtos/ExtrasDiscounts";
import { useToastify } from "hooks/toastify/useToastify";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { useCurrency } from "react-hook-currency";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { FaEye, FaPlus, FaTrash } from "react-icons/fa";
import { FiFile } from "react-icons/fi";
import { serviceTypeOptions } from "utils/CustomLists/serviceTypesOptions";

interface ExtrasDiscountsFormProps {
  children?: ReactNode,
  onSetAttachmentsIds: (attachmentsIds: string[]) => void
  extraDiscountAttachments?: ExtraDiscountAttachments[]
}

export interface ExtrasDiscountsFormInputs {
  type: 'EXTRA' | 'DESCONTO'
  serviceDate: string
  value: string
  description: string
  serviceType: 'EXPRESS' | 'BUSINESS'
  customerId: string
  serviceRoute: string
  serviceProtocol: number
  extraDiscountAttachments: {
    attachment: FileList
  }[]
}



const extrasDiscountsTypeSelectOptions = [
  { key: '0', value: 'EXTRA', showOption: 'EXTRA' },
  { key: '1', value: 'DESCONTO', showOption: 'DESCONTO' },
]

export function ExtrasDiscountsForm({
  children,
  onSetAttachmentsIds,
  extraDiscountAttachments
}: ExtrasDiscountsFormProps) {
  const { promiseMessage } = useToastify()
  const [attachmentsIds, setAttachmentsIds] = useState<string[]>([]);
  const [attachments, setAttachments] = useState<Attachment[]>([])

  const { format: currencyFormat, onChange } = useCurrency({
    style: 'decimal'
  })

  const {
    control,
    register,
    formState: {
      errors
    }
  } = useFormContext<ExtrasDiscountsFormInputs>()

  const [serviceType, selectedCustomer] = useWatch({
    control,
    name: ['serviceType', 'customerId'],
  })

  const { createAttachment: { mutateAsync: createAttachment }, deleteAttachment: { mutateAsync: deleteAttachment } } = useAttachmentFunctions()


  const {
    data: customers,
    isFetching: isFetchingCustomers
  } = useCustomers({
    queryOptions: {
      enabled: serviceType === 'BUSINESS'
    }
  })

  const customersSelectOptions = customers?.map((customer) => {
    return {
      key: customer.id,
      value: customer.id,
      showOption: customer.trading_firstname
    }
  })

  const isExpressServiceType = serviceType === 'EXPRESS'
  const isCustomerSelected = !!selectedCustomer

  const {
    data: businessBudgetsRoutesData,
    isFetching: isFetchingBusinessBudgetsRoutesData
  } = useBusinessBudgetRoutes({
    customer_id: selectedCustomer,
    queryOptions: {
      enabled: isCustomerSelected
    }
  })

  const businessBudgetsRoutesSelectOptions =
    businessBudgetsRoutesData?.routes.map((route) => {
      return {
        key: route.id,
        value: route.route_nickname,
        showOption: route.route_nickname
      }
    })

  const {
    fields: extraDiscountAttachmentsFields,
    append: appendExtraDiscountAttachmentField,
    remove: removeExtraDiscountAttachmentField
  } = useFieldArray({
    name: 'extraDiscountAttachments',
  })


  const handleRemoveExtraDiscountAttachmentField = (index: number) => {
    removeExtraDiscountAttachmentField(index)
  }

  const handleAppendExtraDiscountAttachmentField = () => {
    appendExtraDiscountAttachmentField({
      attachment: undefined
    })
  }

  useEffect(() => {
    onSetAttachmentsIds(attachmentsIds)
  }, [attachmentsIds, onSetAttachmentsIds])

  useEffect(() => {
    if(extraDiscountAttachments !== undefined){
      const registeredAttachments = extraDiscountAttachments.map(attachment => attachment.id);
      setAttachmentsIds(registeredAttachments)
    }
  },[extraDiscountAttachments])

  async function handleSendAttachment(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files.length) {
      const formData = new FormData()
      formData.append("attachment", e.target.files[0])
      await promiseMessage(createAttachment({ input: formData }, {
        onSuccess: (data) => {
          setAttachmentsIds(prevIds => [...prevIds, data.attachment.id]);
          setAttachments(prevAttachments => [...prevAttachments, data.attachment])
        }
      }), 'Anexo adicionado com sucesso!')
    }
  }

  async function handleDeleteAttachment(attachmentId: string) {
    await promiseMessage(deleteAttachment(attachmentId, {
      onSuccess: () => {
        setAttachmentsIds(prevIds => prevIds.filter(id => id !== attachmentId));
        const newAttachments = attachments.filter(attachment => attachment.id !== attachmentId)
        setAttachments(newAttachments)
      }
    }), 'Anexo removido com sucesso!')
  }


  return (
    <Flex
      gap={4}
      direction='column'
    >
      {children}

      <Select
        {...register('type')}
        name='type'
        options={extrasDiscountsTypeSelectOptions}
        label='Tipo'
        placeholder='Selecione uma opção...'
        error={errors.type}
        required
      />

      <Stack
        spacing={4}
        direction={['column', 'column', 'row']}
      >
        <Input
          {...register('serviceDate')}
          name='serviceDate'
          label='Data do serviço'
          type='date'
          error={errors.serviceDate}
          required
        />

        <Input
          {...register('value')}
          name="value"
          addChildren="R$"
          onChange={onChange}
          defaultValue={currencyFormat('000')}
          error={errors.value}
          label="Valor do Prejuízo"
        />
      </Stack>

      <TextArea
        {...register('description')}
        name='description'
        error={errors.description}
        label='Descrição'
        required
      />

      <Select
        {...register('serviceType')}
        name='serviceType'
        options={serviceTypeOptions}
        label='Tipo de serviço'
        placeholder='Selecione uma opção...'
        error={errors.type}
        required
      />

      {!!serviceType && (
        <>
          {isExpressServiceType ? (
            <Input
              {...register('serviceProtocol')}
              name="serviceProtocol"
              error={errors.serviceProtocol}
              label="Protocolo do serviço"
              type='number'
              required
            />
          ) : (
            <Stack
              spacing={4}
              direction={['column', 'column', 'row']}
            >
              <Select
                {...register('customerId')}
                name='customerId'
                options={customersSelectOptions}
                label='Cliente'
                placeholder='Selecione uma opção...'
                error={errors.customerId}
                isDisabled={isFetchingCustomers}
                required
              />
              {isCustomerSelected && (
                <Select
                  {...register('serviceRoute')}
                  name='serviceRoute'
                  options={businessBudgetsRoutesSelectOptions}
                  label='Rota'
                  placeholder='Selecione uma opção...'
                  isDisabled={isFetchingBusinessBudgetsRoutesData}
                  error={errors.serviceRoute}
                  required
                />
              )}
            </Stack>
          )}
        </>
      )}

      {extraDiscountAttachments !== undefined && extraDiscountAttachments.length > 0 && (
        <TableContainer w='full'>
          <Table size='sm' mt={4}>
            <Thead>
              <Tr>
                <Th>Anexos Cadastrados</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {extraDiscountAttachments?.map((attachment)=>{
                return(
                  <Tr key={attachment.id}>
                    <Th>{attachment.title}</Th>
                    <Th isNumeric>
                    <Button
                        as={ChakraLink}
                        href={attachment.link}
                        variant='ghost'
                        isExternal
                      >
                        <Icon as={FaEye} />
                      </Button>
                      <Button
                        variant='ghost'
                        onClick={() => {
                          handleDeleteAttachment(
                            attachment.id
                          )
                        }}
                      >
                        <Icon as={FaTrash} />
                      </Button>
                    </Th>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      {extraDiscountAttachmentsFields.map((field, index) => {
        return (
          <Flex key={field.id} w="full" direction="column">
            <Flex align="center" w="full">
              <Input
                {...register(`extraDiscountAttachments.${index}.attachment`)}
                name={`extraDiscountAttachments.${index}.attachment`}
                error={
                  errors?.extraDiscountAttachments
                    ? errors?.extraDiscountAttachments[index]?.attachment
                    : undefined
                }
                type='file'
                label={`Anexo ${index + 1}`}
                hidden
                onChange={handleSendAttachment}
              >
                <Button
                  leftIcon={<Icon as={FiFile} />}
                  as={FormLabel}
                  htmlFor={`extraDiscountAttachments.${index}.attachment`}
                  h='48px'
                  _hover={{
                    cursor: 'pointer'
                  }}
                  size='sm'
                >
                  Escolha um arquivo
                </Button>
              </Input>

              {/* {extraDiscountAttachments && extraDiscountAttachments.length > 0 && (
                extraDiscountAttachments[index]?.attachment[0] && (
                  <ChakraLink
                    href={URL.createObjectURL(extraDiscountAttachments[index]?.attachment[0])}
                    isExternal
                  >
                    {extraDiscountAttachments[index]?.attachment[0].name} (Visualizar)
                  </ChakraLink>
                )
              )} */}
              {attachments && attachments.length > 0 && (
                attachments[index] && (
                  <ChakraLink
                    href={attachments[index].link}
                    isExternal
                  >
                    {attachments[index].title} (Visualizar)
                  </ChakraLink>
                )
              )}
            </Flex>

            <Button
              leftIcon={<Icon as={FaTrash} />}
              variant="outline"
              colorScheme="red"
              onClick={() => {
                handleRemoveExtraDiscountAttachmentField(index);
                handleDeleteAttachment(attachments[index].id)
              }
              }
            >
              Remover
            </Button>
          </Flex>
        )
      })}
      <Flex
        w="full"
        direction="column"
        justify="flex-start"
      >
        <Button
          leftIcon={<Icon as={FaPlus} />}
          onClick={handleAppendExtraDiscountAttachmentField}
        >
          Adicionar anexo
        </Button>
        {errors?.extraDiscountAttachments && (
          <Text
            color="red.500"
            fontSize="sm"
            mt="2"
          >
            {errors?.extraDiscountAttachments.message}
          </Text>
        )}
      </Flex>

    </Flex>
  )
}
