import {  Product } from './dtos/Product';

import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";

type QueryOptions = UseQueryOptions<Product, unknown, Product, QueryKey>

interface UseProductOptions {
  id: string
  queryKey?: string
  queryOptions?: QueryOptions
}

export function useProduct(options?: UseProductOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getProduct() {
    try {
      const { data } = await api.get(`/products/${options.id}`)

      return data.product
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new Error("Erro inesperado!")
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery(options?.queryKey || 'product', getProduct, {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}
