import { usePagination } from "@ajna/pagination"
import { Box, Flex, Heading, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react"
import { getExperiencePeriods } from "api/experiencePeriods/getExperiencePeriods"
import { ExperiencePeriodStatus } from "api/experiencePeriods/types/ExperiencePeriod"
import { Pagination } from "components/Pagination/Pagination"
import { useSearchParams } from "hooks/useSearchParams"
import { useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { ExperiencePeriodTableFilters } from "./components/ExperiencePeriodTableFilters"
import { ExperiencePeriodTableRow } from "./components/ExperiencePeriodTableRow"

export function ExperiencePeriods(){
  const searchParams = useSearchParams()
  const history = useHistory()

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status')
  const name = searchParams.get('name')

  const {
    data: experiencePeriodsResult
  } = useQuery({
    queryKey: ['experience-periods', page, status, name],
    queryFn: () => getExperiencePeriods({
      currentPage: page,
      pageSize: '10',
      status: status as ExperiencePeriodStatus | 'all',
      name
    }),
    keepPreviousData: true
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: experiencePeriodsResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: experiencePeriodsResult?.meta?.pageSize
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }
  return(
    <Box
    p="4"
    rounded="md"
    bg="white"
  >
    <Flex justify="space-between">
      <Heading letterSpacing="tight">Periodos de Experiência</Heading>
    </Flex>
    <ExperiencePeriodTableFilters/>
    <TableContainer
      rounded="md"
      mt="6"
    >
      <Table size="sm">
        <Thead>
          <Tr
            h="40px"
            bg="#38c3fa"
          >
            <Th></Th>
            <Th color="white">Nome Funcionário</Th>
            <Th color="white">Data de Admissão</Th>
            <Th color="white">45 dias</Th>
            <Th color="white">90 dias</Th>
            <Th color="white">Regional</Th>
            <Th color="white">Status</Th>
            <Th></Th>

          </Tr>
        </Thead>
        <Tbody>
          {experiencePeriodsResult?.experiencePeriods?.map((experience) => {
            return (
              <ExperiencePeriodTableRow
                key={experience.id}
                experiencePeriod={experience}
              />
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
    <Pagination
      currentPage={currentPage}
      pages={pages}
      pagesQuantity={pagesCount}
      handlePageChange={handleChangePage}
    />
  </Box>
  )
}
