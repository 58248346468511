import { Button, FormControl, FormLabel, IconButton, Input, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Progress, Stack, Table, Tbody, Td, Tr, Text, Textarea, Flex, Spinner } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { validateHasFile } from "utils/fileValidation";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { useForm, useWatch } from "react-hook-form";
import { FaFileImport, FaExternalLinkAlt } from "react-icons/fa";
import { ChangeEvent } from "react";
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { sendTrainingAttachments } from "api/trainings/sendTrainingAttachments";
import { GetTrainingsResponse } from "api/trainings/getTrainings";
import { set } from "date-fns";
import { getTraining } from "api/trainings/getTraining";
import { captalize } from "utils/captalize";
import { trainingCollaboratorTypeMap, trainingTypesMap } from "utils/trainingMappers";

interface SendTrainingAttachmentsProps {
  onCloseModal: () => void
  trainingId: string
}

interface SendTrainingAttachmentsSchema {
  trainingAttachmentId: FileList
  certificateAttachmentId: FileList
  notes: string
  dueDate: string
  realizationDate: string
}

const sendTrainingAttachmentSchema = yup.object({
  trainingAttachmentId: yup.mixed().test(value => validateHasFile(value)).required(),
  certificateAttachmentId: yup.mixed().test(value => validateHasFile(value)).required(),
  notes: yup.string(),
  dueDate: yup.string().required().transform((value: string) => {
    const [year, month, day] = value.split('-').map(Number)
    return set(new Date(), {
      year,
      month: month - 1,
      date: day,
      hours: 12,
      minutes: 0,
    }).toISOString()
  }),
  realizationDate: yup.string().required().transform((value: string) => {
    const [year, month, day] = value.split('-').map(Number)
    return set(new Date(), {
      year,
      month: month - 1,
      date: day,
      hours: 12,
      minutes: 0,
    }).toISOString()
  }),
})

export function SendTrainingAtttachments({ onCloseModal, trainingId }: SendTrainingAttachmentsProps) {
  const { promiseMessage } = useToastify()

  const { data: trainingData, isLoading: isTrainingDataLoading } = useQuery({
    queryKey: ['training', trainingId],
    queryFn: () => getTraining({ trainingId })
  })


  const { mutation: uploadTrainingAttachmentFn, uploadProggress: uploadProggressTrainingAttachment } = useAttachmentMutation()

  const { mutation: uploadCertificateAttachmentFn, uploadProggress: uploadProggressCertificateAttachmentId } = useAttachmentMutation()

  const queryClient = useQueryClient()

  const { mutateAsync: sendTrainingAttachmentsFn } = useMutation({
    mutationFn: sendTrainingAttachments,
    onSuccess(_data, { body, trainingId }) {
      const cachedTrainings = queryClient.getQueriesData<GetTrainingsResponse>({
        queryKey: ['trainings']
      })
      queryClient.invalidateQueries(['trainings'])
      cachedTrainings.forEach(([cachedKey, cachedData]) => {
        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          trainings: cachedData.trainings.map((training) => {
            if (training.id === trainingId) {
              return {
                ...training
              }
            }

            return training
          })
        })
      })

      onCloseModal()
    }
  })

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<SendTrainingAttachmentsSchema>({
    resolver: yupResolver(sendTrainingAttachmentSchema),
  })

  const [trainingAttachmentId, certificateAttachmentId] = useWatch({
    control,
    name: ['trainingAttachmentId', 'certificateAttachmentId'],
  })

  async function handleUploadTrainingAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()
    formData.append('attachment', event.target.files[0])
    await uploadTrainingAttachmentFn.mutateAsync(formData)
  }

  async function handleUploadCertificateAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()
    formData.append('attachment', event.target.files[0])
    await uploadCertificateAttachmentFn.mutateAsync(formData)
  }

  async function handleSendTrainingAttachments(values: SendTrainingAttachmentsSchema) {
    await promiseMessage(sendTrainingAttachmentsFn({
      trainingId: trainingId,
      body: {
        certificateAttachmentId: uploadCertificateAttachmentFn?.data.attachment.id,
        trainingAttachmentId: uploadTrainingAttachmentFn?.data.attachment.id,
        notes: values.notes,
        dueDate: values.dueDate,
        realizationDate: values.realizationDate
      }
    }), 'Anexos salvos com sucesso! 🎉')
  }

  return (
    <ModalContent>
      {isTrainingDataLoading ? (
        <ModalBody p="6" margin="0 auto">
          <Spinner />
        </ModalBody>
      ) : (
        <>
          <ModalHeader letterSpacing="tight">Anexar treinamento</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            as='form'
            onSubmit={handleSubmit(handleSendTrainingAttachments)}
          >
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Td fontSize="xs">Colaborador</Td>
                  <Td fontSize="xs" isNumeric>{captalize(`${trainingData?.training?.collaborator_name}`)}</Td>
                </Tr>
                <Tr>
                  <Td fontSize="xs">Tipo de Colaborador</Td>
                  <Td fontSize="xs" isNumeric>{trainingCollaboratorTypeMap[trainingData?.training?.collaborator_type]}</Td>
                </Tr>
                <Tr>
                  <Td fontSize="xs">Treinamento</Td>
                  <Td fontSize="xs" isNumeric>{trainingTypesMap[trainingData?.training?.type]}</Td>
                </Tr>
              </Tbody>
            </Table>
            <Stack
              direction="column"
              w="full"
              mt="3"
            >
              <Button
                as={FormLabel}
                htmlFor="trainingAttachmentId"
                lineHeight="1"
                leftIcon={<FaFileImport />}
                size="sm"
                w="min"
                cursor="pointer"
                border={!!errors?.trainingAttachmentId && '2px solid'}
                borderColor={!!errors?.trainingAttachmentId && 'red.500'}
              >
                Anexar Treinamento
              </Button>
              <FormControl isInvalid={!!errors?.trainingAttachmentId}>
                <Input
                  {...register('trainingAttachmentId')}
                  name="trainingAttachmentId"
                  id="trainingAttachmentId"
                  type="file"
                  hidden
                  onChangeCapture={handleUploadTrainingAttachment}
                />
              </FormControl>
            </Stack>

            {trainingAttachmentId && (
              <Table size="sm">
                <Tbody>
                  {Object.entries(trainingAttachmentId).map(([key, file]) => {
                    return (
                      <Tr key={key}>
                        <Td fontSize="xs" maxW="100px">{file.name}</Td>
                        <Td fontSize="xs" w="200px">
                          <Progress size="sm" rounded="md" value={uploadProggressTrainingAttachment} />
                        </Td>
                        <Td fontSize="xs" isNumeric>
                          {uploadTrainingAttachmentFn.data && (
                            <IconButton
                              aria-label="Visualizar anexo"
                              as={Link}
                              size="sm"
                              icon={<FaExternalLinkAlt />}
                              href={uploadTrainingAttachmentFn.data.attachment.link}
                              isExternal
                            />
                          )}
                        </Td>
                      </Tr>
                    )
                  })}

                </Tbody>
              </Table>
            )}

            <Stack
              direction="column"
              w="full"
              mt="3"
            >
              <Button
                as={FormLabel}
                htmlFor="certificateAttachmentId"
                lineHeight="1"
                leftIcon={<FaFileImport />}
                size="sm"
                w="min"
                cursor="pointer"
                border={!!errors?.certificateAttachmentId && '2px solid'}
                borderColor={!!errors?.certificateAttachmentId && 'red.500'}
              >
                Anexar Certificado
              </Button>
              <FormControl isInvalid={!!errors?.certificateAttachmentId}>
                <Input
                  {...register('certificateAttachmentId')}
                  name="certificateAttachmentId"
                  id="certificateAttachmentId"
                  type="file"
                  hidden
                  onChangeCapture={handleUploadCertificateAttachment}
                />
              </FormControl>
            </Stack>

            {certificateAttachmentId && (
              <Table size="sm">
                <Tbody>
                  {Object.entries(certificateAttachmentId).map(([key, file]) => {
                    return (
                      <Tr key={key}>
                        <Td fontSize="xs" maxW="100px">{file.name}</Td>
                        <Td fontSize="xs" w="200px">
                          <Progress size="sm" rounded="md" value={uploadProggressCertificateAttachmentId} />
                        </Td>
                        <Td fontSize="xs" isNumeric>
                          {uploadCertificateAttachmentFn.data && (
                            <IconButton
                              aria-label="Visualizar anexo"
                              as={Link}
                              size="sm"
                              icon={<FaExternalLinkAlt />}
                              href={uploadCertificateAttachmentFn.data.attachment.link}
                              isExternal
                            />
                          )}
                        </Td>
                      </Tr>
                    )
                  })}

                </Tbody>
              </Table>
            )}
            <Stack
              direction="column"
              w="full"
              mt="3"
            >
              <FormLabel fontSize="sm">
                Notas
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <FormControl isInvalid={!!errors.notes}>
                <Textarea
                  {...register("notes")}
                  name='notes'
                  size='sm'
                  rounded='md'
                />
              </FormControl>
            </Stack>
            <Stack
              direction="column"
              w="full"
              mt="3"
            >
              <FormControl isInvalid={!!errors?.realizationDate}>
                <FormLabel fontSize="sm">
                  Data de Realização
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register('realizationDate')}
                  name="realizationDate"
                  type="date"
                  size="sm"
                  rounded="md"
                />
              </FormControl>
              <FormControl isInvalid={!!errors?.dueDate}>
                <FormLabel fontSize="sm">
                  Data de Vencimento
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register('dueDate')}
                  name="dueDate"
                  type="date"
                  size="sm"
                  rounded="md"
                />
              </FormControl>
            </Stack>
            {trainingAttachmentId && certificateAttachmentId && (
              <Flex
                mt="6"
                w="full"
                justify="flex-end"
              >
                <Button
                  type="submit"
                  size="sm"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                >
                  Criar
                </Button>
              </Flex>
            )}
          </ModalBody>
        </>
      )}


    </ModalContent>
  )
}
