import { Spinner, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Menu, MenuButton, IconButton, MenuList, MenuGroup, MenuItem, Icon, Button } from "@chakra-ui/react"
import { CollectorsExtrasDiscountsStatusEnum } from "contexts/CollectorsExtrasDiscountsStatusContext"
import { useAuth } from "hooks/auth/useAuth"
import { useCollectorExtrasDiscountsListContext } from "hooks/collector/useCollectorExtrasDiscountsListContext"
import { useCollectorsExtrasDiscounts } from "hooks/collector/useCollectorsExtrasDiscounts"
import { useCollectorsExtrasDiscountsStatus } from "hooks/collector/useCollectorsExtrasDiscountsStatus"
import { ExtraDiscount } from "hooks/extrasDiscounts/dtos/ExtrasDiscounts"
import { useEffect, useState } from "react"
import { FaClone, FaEllipsisH, FaEye } from "react-icons/fa"
import { CollectorsExtrasDiscountsTableStatusButton } from "./CollectorExtrasDiscountsTableStatusButton"

interface CollectorExtrasDiscountsTableProps {
  rowsPerPage: string
  statusFilter: RegExpMatchArray
  currentPageFilter: RegExpMatchArray
  collectorsExtrasDiscountsStatusActionsOptions: any
  onSetTotalPages: (total: number) => void
  onOpenViewExtraDiscountModal: (extraDiscount: ExtraDiscount) => void
  onSetCollectorsExtrasDiscountsData: (collectorsExtrasDiscounts: ExtraDiscount[]) => void
}

export function CollectorExtrasDiscountsTable({
  rowsPerPage,
  statusFilter,
  currentPageFilter,
  collectorsExtrasDiscountsStatusActionsOptions,
  onSetTotalPages,
  onOpenViewExtraDiscountModal,
  onSetCollectorsExtrasDiscountsData
}: CollectorExtrasDiscountsTableProps) {
  const [debouncedFilters, setDebouncedFilters] = useState({
    collector: '',
    startDate: '',
    endDate: ''
  })

  const { userLogged } = useAuth()

  const { status } = useCollectorsExtrasDiscountsStatus()
  const { filters } = useCollectorExtrasDiscountsListContext()

  useEffect(() => {
    const debounce = setTimeout(() => {
      setDebouncedFilters({
        collector: filters.collector,
        startDate: filters.startDate,
        endDate: filters.endDate
      })
    }, 1000)

    return () => clearTimeout(debounce)
  }, [filters])


  const {
    data: collectorsExtrasDiscountsData,
    isFetching: isFetchingCollectorsExtrasDiscountsData
  } = useCollectorsExtrasDiscounts({
    queryParams: {
      pageSize: Number(rowsPerPage),
      status: statusFilter ? statusFilter[1] as keyof typeof CollectorsExtrasDiscountsStatusEnum : status,
      currentPage: currentPageFilter ? Number(currentPageFilter[1]) : 1,
      collector: debouncedFilters?.collector ? debouncedFilters?.collector : undefined,
      startDate: debouncedFilters?.startDate ? debouncedFilters?.startDate : undefined,
      endDate: debouncedFilters?.endDate ? debouncedFilters?.endDate : undefined
    }
  })

  useEffect(() => {
    if (collectorsExtrasDiscountsData) {
      onSetTotalPages(collectorsExtrasDiscountsData.totalPages)
      onSetCollectorsExtrasDiscountsData(collectorsExtrasDiscountsData.extrasDiscounts)
    }
  }, [collectorsExtrasDiscountsData, onSetCollectorsExtrasDiscountsData, onSetTotalPages])

  return (
    <>
      {isFetchingCollectorsExtrasDiscountsData ? (
        <Spinner />
      ) : (
        <TableContainer>
          <Table size='sm' variant='striped'>
            <Thead>
              <Tr>
                <Th></Th>
                <Th>COLETADOR</Th>
                <Th>TIPO</Th>
                <Th>DATA DO SERVIÇO</Th>
                <Th>VALOR</Th>
                <Th>ROTA</Th>
                <Th>PROTOCOLO</Th>
                <Th>CRIADO POR</Th>
                <Th>
                  <CollectorsExtrasDiscountsTableStatusButton />
                </Th>
                <Th></Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {collectorsExtrasDiscountsData?.extrasDiscounts.map((extraDiscount) => {

                const collectorExtraDiscountStatus = CollectorsExtrasDiscountsStatusEnum[status]

                const [serviceYear, serviceMonth, serviceDay] = extraDiscount.service_date.split('-')

                return (
                  <Tr key={extraDiscount.id}>
                    <Td>
                      {extraDiscount.is_duplicated && (
                        <Icon as={FaClone} mx="2px" title="Extra/Desconto duplicado" color='blue.800' />
                      )}
                    </Td>
                    <Td>{extraDiscount.collector.trading_name}</Td>
                    <Td>{extraDiscount.type}</Td>
                    <Td>{`${serviceDay}/${serviceMonth}/${serviceYear}`}</Td>
                    <Td>{
                      new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      }).format(extraDiscount.value / 100)
                    }</Td>
                    <Td>{extraDiscount.service_route ?? '-'}</Td>
                    <Td>{extraDiscount.service_protocol ?? '-'}</Td>
                    <Td>{extraDiscount?.createdBy ? `${extraDiscount?.createdBy.firstname} ${extraDiscount?.createdBy.lastname}` : '-'}</Td>
                    <Td>{collectorExtraDiscountStatus}</Td>
                    <Td>
                      <Button
                        as={IconButton}
                        icon={<Icon as={FaEye} />}
                        onClick={() => onOpenViewExtraDiscountModal(extraDiscount)}
                      />
                    </Td>
                    <Td>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          variant='ghost'
                          aria-label='Options'
                          icon={<FaEllipsisH />}
                        />
                        <MenuList>
                          <MenuGroup title="Ações">
                            {collectorsExtrasDiscountsStatusActionsOptions
                              ?.find((option) => option.status === status)
                              ?.actions.filter((action) => userLogged?.permissions.includes(action.permission))
                              .map((action) => {
                                return (
                                  <MenuItem
                                    key={action.type}
                                    onClick={() => action.handle(extraDiscount.id)}
                                    display="flex"
                                    alignItems="center"
                                    gap="2"
                                  >
                                    <Icon as={action.icon} />
                                    {action.type}
                                  </MenuItem>

                                )
                              })}
                          </MenuGroup>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}
