import { Button, Divider, Flex, Icon, Spinner, Stack, Text, useDisclosure } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm"
import { GeneralCheckbox } from "components/Inputs/GeneralCheckbox"
import { Input } from "components/Inputs/Input"
import { Select } from "components/Inputs/SelectInput"
import { TextArea } from "components/Inputs/TextInput"
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading"
import { set } from "date-fns"
import { useAddresses } from "hooks/address/useAddresses"
import { useAuth } from "hooks/auth/useAuth"
import { useBranch } from "hooks/branch/useBranch"
import { useBranches } from "hooks/branch/useBranches"
import { useBudget } from "hooks/budget/useBudget"
import { useCity } from "hooks/city/useCity"
import { useCollectors } from "hooks/collector/useCollectors"
import { useInput } from "hooks/input/useInput"
import { useOccurrenceFunctions } from "hooks/occurrences/useOccurrenceFunctions"
import { useService } from "hooks/services/service"
import { useSwal } from "hooks/swal/useSwal"
import { useEffect, useState } from "react"
import { useCurrency } from "react-hook-currency"
import { useFieldArray, Controller } from "react-hook-form"
import { useForm } from "react-hook-form"
import { FaPlus, FaTimes } from "react-icons/fa"
import { useHistory, useLocation } from "react-router-dom"
import { negative_positive, service_steps } from "utils/customLists"
import { switchIntercurrenceTypes } from "utils/CustomLists/switchIntercurrenceTypes"
import { switchOccurrenceTypes } from "utils/CustomLists/switchOccurrenceTypes"
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber"
import * as yup from "yup"
import { OccurrenceAttachPhotoModal } from "./components/OccurrenceAttachPhotoModal"

export interface OccurrenceMaterials {
  input_id: string
  quantity: number
}

interface CreateOccurrenceInputs {
  type: string
  intercurrence: string
  service_step: string
  occurrence_date: string
  occurrence_hour: string
  occurrence_detail: string
  occurrence_delay: number
  materials: OccurrenceMaterials[]
  observation: string
  responsible_collector_id: string
  shipping_id: string
  attachment_photo: FileList
  has_generate_fine: string
  collector_fine_value: string
  service_new_source_branch_id: string
  service_new_destination_branch_id: string
  unsuccess_delivery_address_id: string
  shipping_ticket_number: number
  cause: string
  corrective: string
  preventive: string
  checkbook: string[]
}

interface LocationProps {
  state: {
    service_id: string
  }
}

const checkbook_options = [
  "VERIFICAR SE O PORTADOR FEZ A RESSALVA NO DESEMBARQUE",
  "CONSULTAR SISTEMA INTERNO DA TRANSPORTADORA + SITE DA TRANSPORTADORA (SOMENTE GOL)",
  "ABRIR TICKET/MANDAR E-MAIL PARA OS CONTATOS DA CIA AEREA",
  "MANDAR WHATSAPP PARA OS CONTATOS DA CIA AEREA",
  "LIGAR NA CENTRAL (SAC) DA CIA AEREA",
  "LIGAR NA BASE DA CIA AEREA",
  "ABRIR COMUNICAÇÃO PARA O LAST MILE DE DESTINO INFORMANDO SOBRE O FATO. SOLICITAR ENVIO DE OUTRO VEÍCULO APÓS 3H DO ANTERIOR PARA VERIFICAR CARGA.",
  "ABRIR COMUNICAÇÃO PARA AS RESPONSÁVEIS PELOS CLIENTES ENVOLVIDOS (INFORMAR DESEMBARQUE PARCIAL E NÃO EXTRAVIO) - TALVEZ E-MAIL AUTOMATICO CLIENTE?",
  "LEMBRETE PARA VERIFICAR STATUS A CADA 1 H E ATUALIZAR LAST MILE E ATENDIMENTO E COLOCAR NO ESSAS INFORMAÇÕES EM UM ARRAY, NESSE MODELO",
]

const noContentDeclarationCheckbook = [
  "Solicitar ao portador que pergunte se o remetente teria a possibilidade de imprimir a DC",
  "Caso sim, solicitar o e-mail do remetente e enviar o modelo de DC e o manual de preenchimento.",
  "Caso não, informar Suporte de Clientes (AVISOS) sobre o caso.",
  "Solicitar que o portador retorne a base para imprimir uma declaração.",
  "Abrir uma ocorrência no serviço."
]

const declarationContentIncompleteCheckbook = [
  "Solicitar ao portador que não retire o material até que a mesma esteja completamente preenchida",
  "Solicitar ao portador que instrua o remetente sobre o preenchimento dos campos faltantes. Peça o mesmo que mostre o MANUAL DE PREENCHIMENTO DE DECLARAÇÃO.",
  "Caso o remetente se recuse a preencher, informar Suporte de Clientes (AVISOS) sobre o caso."
]

const createOccurrenceSchema = yup.object().shape({
  type: yup.string().required("Campo obrigatório"),
  intercurrence: yup.string().required("Campo obrigatório"),
  service_step: yup.string().required("Campo obrigatório"),
  responsible_collector_id: yup.string().when("type", {
    is: 'COLETADOR',
    then: yup.string().required("Campo obrigatório")
  }),
  has_generate_fine: yup.string().when("type", {
    is: (type: string) => type === 'COLETADOR',
    then: yup.string().required("Campo obrigatório")
  }),
  collector_fine_value: yup.string().when("type", {
    is: (type: string) => type === 'COLETADOR',
    then: yup.string().required("Campo obrigatório")
  }),
  shipping_id: yup.string().when("type", {
    is: 'TRANSPORTADORA',
    then: yup.string().required("Campo obrigatório")
  }),
  occurrence_delay: yup.number().when("intercurrence", {
    is: (intercurrence: string) => [
      'TEMPO DE ESPERA PELA MATRIZ',
      'ESPERA ACIMA DE 10 MIN'
    ].includes(intercurrence),
    then: yup.number().required("Campo obrigatório").typeError("Campo obrigatório!")
  }),
  occurrence_detail: yup.string().when("intercurrence", {
    is: (intercurrence: string) => [
      'SERVIÇO EXTRA',
      'OUTRAS COBRANÇAS',
      'DESVIO DE BUDGET DE TRANSFERÊNCIA',
      'CANCELAMENTO DE VOO',
      'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)',
      'ATRASO NA COLETA',
      'NO SHOW',
      'ATRASO NA ENTREGA',
      'RECLAMAÇÃO DE CLIENTE',
      'INSUMOS ERRADOS',
      'RETENÇÃO FISCAL',
      'ATRASO NA TRANSFERÊNCIA',
      'ATRASO NA LIBERAÇÃO',
    ].includes(intercurrence),
    then: yup.string().required("Campo obrigatório").typeError("Campo obrigatório!")
  }),
  occurrence_date: yup.string().when("intercurrence", {
    is: (intercurrence: string) => [
      'CANCELAMENTO DE VOO',
      'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)',
      'NO SHOW',
      'ATRASO NA ENTREGA',
      'ATRASO NA TRANSFERÊNCIA',
      'ATRASO NA LIBERAÇÃO',
    ].includes(intercurrence),
    then: yup.string().required("Campo obrigatório").typeError("Campo obrigatório!")
  }),
  occurrence_hour: yup.string().when("intercurrence", {
    is: (intercurrence: string) => [
      'CANCELAMENTO DE VOO',
      'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)',
      'ATRASO NA COLETA',
      'NO SHOW',
      'ATRASO NA ENTREGA',
      'ATRASO NA TRANSFERÊNCIA',
      'ATRASO NA LIBERAÇÃO',
    ].includes(intercurrence),
    then: yup.string().required("Campo obrigatório").typeError("Campo obrigatório!")
  }),
  materials: yup.array().when("intercurrence", {
    is: (intercurrence: string) => [
      'REPOSIÇÃO DE INSUMOS',
      'ALTERAÇÃO DE INSUMOS UTILIZADOS'
    ].includes(intercurrence),
    then: yup.array().of(yup.object({
      input_id: yup.string().required("Campo obrigatório"),
      quantity: yup.number().min(0, "A quantidade não pode ser inferior a zero").typeError("")
    })).min(1, "Campo obrigatório")
  }),
  service_new_source_branch_id: yup.string().when("intercurrence", {
    is: (intercurrence: string) => [
      'ALTERAÇÃO DE EMBARQUE',
    ].includes(intercurrence),
    then: yup.string().required("Campo obrigatório")
  }),
  service_new_destination_branch_id: yup.string().when("intercurrence", {
    is: (intercurrence: string) => [
      'ALTERAÇÃO DE EMBARQUE',
    ].includes(intercurrence),
    then: yup.string().required("Campo obrigatório")
  }),
  unsuccess_delivery_address_id: yup.string().when("intercurrence", {
    is: (intercurrence: string) => [
      'TENTATIVA DE ENTREGA SEM SUCESSO',
    ].includes(intercurrence),
    then: yup.string().required("Campo obrigatório")
  }),
  shipping_ticket_number: yup.number().when("intercurrence", {
    is: (intercurrence: string) => [
      'CANCELAMENTO DE VOO',
      'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)',
      'ATRASO NA TRANSFERÊNCIA',
    ].includes(intercurrence),
    then: yup.number().required("Campo obrigatório")
  }),
  cause: yup.string().when("intercurrence", {
    is: (intercurrence: string) => [
      'CANCELAMENTO DE VOO',
      'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)',
      'ATRASO NA COLETA',
      'NO SHOW',
      'ATRASO NA ENTREGA',
      'ATRASO NA TRANSFERÊNCIA',
      'ATRASO DEVIDO AO SISTEMA',
      'TENTATIVA DE ENTREGA SEM SUCESSO'
    ].includes(intercurrence),
    then: yup.string().required("Campo obrigatório").typeError("Campo obrigatório!")
  }),
  corrective: yup.string().when("intercurrence", {
    is: (intercurrence: string) => [
      'CANCELAMENTO DE VOO',
      'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)',
      'ATRASO NA COLETA',
      'NO SHOW',
      'ATRASO NA ENTREGA',
      'ATRASO NA TRANSFERÊNCIA',
      'ATRASO DEVIDO AO SISTEMA',
      'TENTATIVA DE ENTREGA SEM SUCESSO'
    ].includes(intercurrence),
    then: yup.string().required("Campo obrigatório").typeError("Campo obrigatório!")
  }),
  preventive: yup.string(),
  observation: yup.string().required("Campo obrigatório")
})

export function CreateOccurrence() {
  const [occurrenceId, setOccurrenceId] = useState<string | null>(null)

  const { state: { service_id } }: LocationProps = useLocation()

  const { userLogged } = useAuth()
  const { goBack, push: redirectTo } = useHistory()

  useEffect(() => {
    if (!userLogged.permissions.includes("add-occurrence")) {
      redirectTo("/")
    }
  }, [redirectTo, userLogged])

  const formProps = useForm<CreateOccurrenceInputs>({
    resolver: yupResolver(createOccurrenceSchema)
  })

  const { onChange, format: currencyFormat } = useCurrency({
    style: 'decimal',
  })

  const {
    watch,
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors }
  } = formProps

  const occurrenceTypesByUserLogged = switchOccurrenceTypes.handle(
    userLogged.user_type
  )

  const {
    service: { data: service, isLoading: isLoadingService, isFetching: isFetchingService }
  } = useService(service_id, false)

  const { data: addresses, isFetching: isFetchingAddresses } = useAddresses()

  const occurrenceTypeSelected = watch("type")

  const isCrossdockingService = service?.serviceIDRequested.crossdocking_collector_id !== null

  const isCrossdockingBoard = isCrossdockingService
    ? !service?.serviceIDBoard?.find(
      (board) => board.collector_id === service?.serviceIDRequested.source_collector_id
    )
    : false
  const serviceModal = isCrossdockingService
    ? isCrossdockingBoard
      ? service?.serviceIDRequested.modal
      : service?.serviceIDRequested.crossdocking_modal
    : service?.serviceIDRequested.modal


  const intercurrenceTypesBySelectedOccurrence = switchIntercurrenceTypes.handle(
    occurrenceTypeSelected, service?.step, serviceModal
  )?.map(intercurrence => {
    return {
      ...intercurrence,
      showOption: intercurrence.value
    }
  })

  useEffect(() => {
    if (userLogged.user_type === "COLETADOR") {
      setValue('type', "COLETADOR")
    }

    if (userLogged.user_type === "CLIENTE") {
      setValue('type', "CLIENTE")
    }

    if (userLogged.user_type === "MOTORISTA") {
      setValue('type', "MOTORISTA")
    }

    if (userLogged.user_type === "TRANSPORTADORA") {
      setValue('type', "TRANSPORTADORA")
    }
  }, [userLogged, setValue])



  const isDisabledSelectOccurrenceButton = [
    "COLETADOR",
    "CLIENTE",
    "MOTORISTA",
    "TRANSPORTADORA"
  ].some(value => value === userLogged.user_type)

  const serviceCollectors = []

  serviceCollectors.push(service?.serviceIDRequested.source_collector_id)
  serviceCollectors.push(service?.serviceIDRequested.crossdocking_collector_id)
  serviceCollectors.push(service?.serviceIDRequested.destination_collector_id)

  const intercurrenceSelected = watch("intercurrence")

  const {
    fields: materialFields,
    update: updateMaterialFields,
    append: appendMaterialField,
    remove: removeMaterialField
  } = useFieldArray({
    control,
    name: 'materials'
  })

  function handleRemoveMaterialField(index: number) {
    removeMaterialField(index)
  }

  function handleAppendMaterialField() {
    appendMaterialField({ input_id: '', quantity: 0 })
  }

  const isFinishedService = service?.step === "finishedService"


  useEffect(() => {
    if (service?.step !== "finishedService") {
      setValue("service_step", service?.step)
    }
  }, [service, setValue])

  const isShippingForm = occurrenceTypeSelected === "TRANSPORTADORA"

  const {
    branches: { data: branches, isFetching: isFetchingBranches }
  } = useBranch(null, isShippingForm)

  const { data: branchesData, isFetching: isFetchingBranchesData } = useBranches({
    queryParams: {
      modal: service?.serviceIDRequested.modal,
    }
  })

  const selectedSourceBranch = watch('service_new_source_branch_id')
  const sourceBranchInfo = branches?.find(branch => branch.id === selectedSourceBranch)

  const destinationBranchesOptions = branches?.filter(
    branch =>
      branch.hub_id === sourceBranchInfo?.hub_id &&
      branch?.shipping_id === sourceBranchInfo?.shipping_id)
    .map(branch => {
      return {
        key: branch.id,
        value: branch.id,
        showOption: `${branch.nickname} (${branch.shippingIDBranch.trading_name})`
      }
    })

  const branchesOptions = !isFetchingBranchesData ? branchesData?.map(branch => {
    return {
      key: branch.id,
      value: branch.id,
      showOption: `${branch.nickname} (${branch.shippingIDBranch.trading_name})`
    }
  }) : []

  const serviceSourceBranches = []

  serviceSourceBranches.push(service?.serviceIDRequested.source_branch_id)
  serviceSourceBranches.push(service?.serviceIDRequested.source_crossdocking_branch_id)

  const shippingsFilteredByServiceBranches = !isFetchingBranches
    ? branches?.filter(branch =>
      serviceSourceBranches.includes(branch.id)
    ).map(branch => {
      return {
        key: branch.shipping_id,
        value: branch.shipping_id,
        showOption: branch.shippingIDBranch.trading_name
      }
    })
    : []


  const checkbookOptions = checkbook_options.map((item) => {
    return {
      key: item,
      value: item,
      showOption: item,
    };
  });

  const noContentDeclarationCheckbookOptions = noContentDeclarationCheckbook.map((item) => {
    return {
      key: item,
      value: item,
      showOption: item,
    };
  });

  const declarationContentIncompleteCheckbookOptions = declarationContentIncompleteCheckbook.map((item) => {
    return {
      key: item,
      value: item,
      showOption: item,
    };
  });

  const intercorrrenceCheckbookOptions = {
    'CLIENTE SEM DECLARAÇÃO DE CONTEÚDO': noContentDeclarationCheckbookOptions,
    'DESEMBARQUE PARCIAL': checkbookOptions,
    'DECLARAÇÃO DE CONTEÚDO INCOMPLETA': declarationContentIncompleteCheckbookOptions
  };


  const isIntercurrenceCustomerWithoutContentDeclaration = intercurrenceSelected === 'CLIENTE SEM DECLARAÇÃO DE CONTEÚDO'

  const isIntercurrencePartialLanding = intercurrenceSelected === 'DESEMBARQUE PARCIAL'
  const isIntercurrenceDeclarationContentIncomplete = intercurrenceSelected === 'DECLARAÇÃO DE CONTEÚDO INCOMPLETA'

  const hasCheckbookForIntercurrence = isIntercurrenceCustomerWithoutContentDeclaration || isIntercurrencePartialLanding || isIntercurrenceDeclarationContentIncomplete

  const selectedCheckbookOptions = intercorrrenceCheckbookOptions[intercurrenceSelected] || [];

  const checkbookItemsSelected = watch('checkbook');
  const quantitySelectedItemseIsEqualCheckbookOptionsLength = checkbookItemsSelected?.length === selectedCheckbookOptions.length;

  const activateButtonCreateOccurrence = checkbookItemsSelected !== undefined ? (checkbookItemsSelected.length > 0 && quantitySelectedItemseIsEqualCheckbookOptionsLength) : false;

  const isCollectorForm = occurrenceTypeSelected === "COLETADOR"
  const hasGenerateFine = watch('has_generate_fine')

  useEffect(() => {
    if (hasGenerateFine === 'SIM') {
      setValue('collector_fine_value', '5,00')
    }
    if (hasGenerateFine === 'NÃO') {
      setValue('collector_fine_value', '0,00')
    }
  }, [hasGenerateFine, setValue])

  const {
    data: collectors, isFetching: isFechingCollectors
  } = useCollectors({
    queryOptions: { enabled: isCollectorForm }
  })


  const collectorsFilteredByServiceCollectors = collectors?.filter(collector => {
    return serviceCollectors.includes(collector.id)
  }).map(collector => {
    return {
      key: collector.id,
      value: collector.id,
      showOption: collector.trading_name
    }
  })

  const isCurrencyIntercurrence = [
    'SERVIÇO EXTRA',
    'OUTRAS COBRANÇAS',
    'DESVIO DE BUDGET DE TRANSFERÊNCIA'
  ].includes(intercurrenceSelected)

  const isReplaceMaterialsIntercurrence =
    intercurrenceSelected === 'REPOSIÇÃO DE INSUMOS'

  const isAlterMaterialsIntercurrence =
    intercurrenceSelected === 'ALTERAÇÃO DE INSUMOS UTILIZADOS'

  const isAlterBoardingIntercurrence = intercurrenceSelected === 'ALTERAÇÃO DE EMBARQUE'
  const isUnsuccessfulDeliveryAttempt = intercurrenceSelected === 'TENTATIVA DE ENTREGA SEM SUCESSO'

  const destinationAddresses = addresses
    ?.filter(address => service?.serviceIDRequested.destination_address_id?.includes(address.id))

  const {
    inputs: { data: inputs, isFetching: isFetchingInputs }
  } = useInput(null, true)

  useEffect(() => {
    if (isAlterMaterialsIntercurrence) {
      const requestedMaterials = {
        'CAIXA TÉRMICA': { quantity: service.serviceIDRequested.caixa_termica },
        'EMBALAGEM SECUNDÁRIA ZIPLOCK': {
          quantity: service.serviceIDRequested.embalagem_secundaria,
        },
        'GELO SECO': { quantity: service.serviceIDRequested.gelo_seco },
        GELOX: { quantity: service.serviceIDRequested.gelox },
        'ISOPOR 3L': { quantity: service.serviceIDRequested.isopor3l },
        'ISOPOR 7L': { quantity: service.serviceIDRequested.isopor7l },
        'TERCIÁRIA 3L': { quantity: service.serviceIDRequested.terciaria3l },
        'TERCIÁRIA 8L': { quantity: service.serviceIDRequested.terciaria8l },
      }

      const inputsByRequestedMaterials = Object
        .entries(requestedMaterials)
        .reduce((acc, [key, value]) => {
          const input = inputs.find(input => input.name === key)

          if (key === "GELO SECO") {
            acc.push({
              id: "GELO SECO",
              quantity: value.quantity
            })
          } else if (input) {
            acc.push({
              id: input.id,
              quantity: value.quantity
            })
          }
          return acc
        }, [])

      inputsByRequestedMaterials.forEach((input, index) => {
        updateMaterialFields(index, {
          input_id: input.id,
          quantity: input.quantity
        })
      })
    }
  }, [service, inputs, updateMaterialFields, isAlterMaterialsIntercurrence])

  const isDateHourWithDetailIntercurrence = [
    'CANCELAMENTO DE VOO',
    'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)',
    'ATRASO NA COLETA',
    'NO SHOW',
    'ATRASO NA ENTREGA',
    'ATRASO NA TRANSFERÊNCIA',
    'ATRASO DEVIDO AO SISTEMA',
    'TENTATIVA DE ENTREGA SEM SUCESSO',
    'ATRASO NA LIBERAÇÃO',
  ].includes(intercurrenceSelected)

  const isDelayIntercurrence = [
    'TEMPO DE ESPERA PELA MATRIZ',
    'ESPERA ACIMA DE 10 MIN'
  ].includes(intercurrenceSelected)

  const isOnlyDetailIntercurrence = [
    'RECLAMAÇÃO DE CLIENTE',
    'INSUMOS ERRADOS',
    'RETENÇÃO FISCAL'
  ].includes(intercurrenceSelected)

  const isEnableShippingTicketNumber = [
    'CANCELAMENTO DE VOO',
    'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)',
    'ATRASO NA TRANSFERÊNCIA',
  ].includes(intercurrenceSelected)

  function onIntercurrenceChangeResetFieldValues() {
    if (isReplaceMaterialsIntercurrence) {
      setValue('materials', [])
    }

    setValue('occurrence_date', '')
    setValue('occurrence_delay', 0)
    setValue('occurrence_hour', '')
    setValue('occurrence_detail', '')
    setValue('responsible_collector_id', '')
    setValue('shipping_id', '')
  }

  const {
    budget: { data: budget, isFetching: isFetchingBudget }
  } = useBudget(service?.serviceIDRequested.budget_id, false)

  const {
    cities: { data: cities, isFetching: isFetchingCities }
  } = useCity(null, true)

  const {
    createOccurrence: {
      mutateAsync: createOccurrence
    }
  } = useOccurrenceFunctions()

  const { confirmMessage, standardMessage } = useSwal()

  const {
    onOpen: onOpenOccurrenceAttachPhotoModal,
    isOpen: isOccurrenceAttachPhotoModalOpen,
    onClose: onCloseOccurrenceAttachPhotoModal
  } = useDisclosure()

  async function handleCreateOccurrence({
    type,
    intercurrence,
    materials,
    observation,
    occurrence_date,
    occurrence_delay,
    occurrence_detail,
    occurrence_hour,
    responsible_collector_id,
    service_step,
    shipping_id,
    collector_fine_value,
    service_new_source_branch_id,
    service_new_destination_branch_id,
    unsuccess_delivery_address_id,
    shipping_ticket_number,
    cause,
    corrective,
    preventive
  }: CreateOccurrenceInputs) {

    if (intercurrence === "ALTERAÇÃO DE INSUMOS UTILIZADOS") {
      const requestedMaterials = {
        'CAIXA TÉRMICA': { quantity: service.serviceIDRequested.caixa_termica },
        'EMBALAGEM SECUNDÁRIA ZIPLOCK': {
          quantity: service.serviceIDRequested.embalagem_secundaria,
        },
        'GELO SECO': { quantity: service.serviceIDRequested.gelo_seco },
        GELOX: { quantity: service.serviceIDRequested.gelox },
        'ISOPOR 3L': { quantity: service.serviceIDRequested.isopor3l },
        'ISOPOR 7L': { quantity: service.serviceIDRequested.isopor7l },
        'TERCIÁRIA 3L': { quantity: service.serviceIDRequested.terciaria3l },
        'TERCIÁRIA 8L': { quantity: service.serviceIDRequested.terciaria8l },
      }

      const occurrenceMaterials = materials.map(material => {
        const input = inputs.find(input => {


          return material.input_id === input.id
        })

        if (material.input_id === "GELO SECO") {
          return {
            materialName: material.input_id,
            quantity: material.quantity
          }
        }

        return {
          materialName: input.name,
          quantity: material.quantity
        }
      })

      const occurrenceMaterialWithDifferenceRequestedQuantity =
        Object.entries(requestedMaterials)
          .filter(([key, requestedMaterial]) => {
            const occurrenceMaterialEqualToRequestedMaterial = occurrenceMaterials.find(
              ocurrenceMaterial => ocurrenceMaterial.materialName === key
            )

            if (occurrenceMaterialEqualToRequestedMaterial) {
              return occurrenceMaterialEqualToRequestedMaterial.quantity !== requestedMaterial.quantity
            }

            return false
          })


      if (!occurrenceMaterialWithDifferenceRequestedQuantity.length) {
        return standardMessage(
          'É necessário que ao menos um insumo tenha o valor alterado!',
        )
      }
    }

    const hasCreateOccurrence = await confirmMessage({
      title: "Deseja criar uma ocorrência?",
    })


    if (hasCreateOccurrence) {

      let occurrenceDateTime: string | null = null

      if (occurrence_date && occurrence_hour) {
        const [occurrenceYear, occurrenceMonth, occurrenceDay] = occurrence_date
          .split('-').map(value => Number(value))
        const [occurrenceHours, occurrenceMinutes] = occurrence_hour
          .split(':').map(value => Number(value))

        occurrenceDateTime = set(new Date(), {
          date: occurrenceDay,
          month: occurrenceMonth - 1,
          year: occurrenceYear,
          hours: occurrenceHours,
          minutes: occurrenceMinutes
        }).toISOString()

      } else if (occurrence_hour) {
        const [occurrenceHours, occurrenceMinutes] = occurrence_hour
          .split(':').map(value => Number(value))

        occurrenceDateTime = set(new Date(), {
          hours: occurrenceHours,
          minutes: occurrenceMinutes
        }).toISOString()

      }

      const sourceCitiesNames = cities
        .filter(city => budget.source_cities.includes(city.id))
        .map(city => city.name)
      const destinationCitiesNames = cities
        .filter(city => budget.destination_cities.includes(city.id))
        .map(city => city.name)

      await createOccurrence({
        service_id: service.id,
        customer_id: service.customer_id,
        shipping_id: shipping_id ? shipping_id : null,
        source_collector_id: service.serviceIDRequested.source_collector_id,
        destination_collector_id: service.serviceIDRequested.destination_collector_id,
        owner: userLogged.id,
        type,
        intercurrence,
        occurrence_hour: occurrence_hour ? occurrenceDateTime : null,
        occurrence_date: occurrence_date ? occurrenceDateTime : null,
        responsible_collector_id: responsible_collector_id ? responsible_collector_id : null,
        service_step,
        observation,
        occurrence_detail: occurrence_detail ?? null,
        occurrence_delay,
        source_city: JSON.stringify(sourceCitiesNames),
        destination_city: JSON.stringify(destinationCitiesNames),
        materials: materials.length ? JSON.stringify(materials) : null,
        collector_fine_value: collector_fine_value ? transformStringToNumber(collector_fine_value) : null,
        service_new_source_branch_id: service_new_source_branch_id ? service_new_source_branch_id : null,
        service_new_destination_branch_id: service_new_destination_branch_id ? service_new_destination_branch_id : null,
        unsuccess_delivery_address_id,
        shipping_ticket_number: shipping_ticket_number ? shipping_ticket_number : null,
        cause: cause ? cause : null,
        corrective: corrective ? corrective : null,
        preventive: preventive ? preventive : null
      }, {
        onSuccess: async (data) => {
          setOccurrenceId(data.id)

          const hasAttachOccurrencePhoto = await confirmMessage({
            title: "Gostaria de adicionar uma foto à ocorrência?",
            buttons: ['Não', 'Sim'],
            text: 'Clique em cancelar para retornar aos detalhes do serviço.'
          })

          if (hasAttachOccurrencePhoto) {
            onOpenOccurrenceAttachPhotoModal()
          } else {
            goBack()
          }
        }
      })
    } else {
      standardMessage("Ação cancelada com êxito!")
    }

  }

  if (isFetchingService || isLoadingService || isFetchingInputs || isFetchingBudget || isFetchingCities || isFetchingAddresses) {
    return <GeneralContentLoading />
  }

  return (
    <>
      <OccurrenceAttachPhotoModal
        isOccurrenceAttachPhotoModalOpen={isOccurrenceAttachPhotoModalOpen}
        onCloseOccurrenceAttachPhotoModal={onCloseOccurrenceAttachPhotoModal}
        occurrenceId={occurrenceId}
      />
      <StandardBackgroundForm title="Criar ocorrência" onSubmit={handleSubmit(handleCreateOccurrence)}>
        <Flex
          direction="column"
          gap={6}
        >
          <Stack direction={['column', 'column', 'row']} spacing={6}>
            <Input
              name="customer"
              value={service.customerIDService.trading_firstname}
              label="Cliente"
              isDisabled={true}
            />
            <Input
              name="protocolo"
              value={service.protocol}
              label="Protocolo do serviço"
              isDisabled={true}
            />
          </Stack>

          <Stack direction={['column', 'column', 'row']} spacing={6}>
            <Input
              name="source_hub"
              value={budget.sourceHubIDBudget.name}
              label="Hub origem"
              isDisabled={true}
            />
            <Input
              name="destination_hub"
              value={budget.destinationHubIDBudget.name}
              label="Hub destino"
              isDisabled={true}
            />
          </Stack>

          <Select
            {...register("type")}
            name="type"
            label="Tipo de ocorrência"
            placeholder="Selecione uma opção..."
            isDisabled={isDisabledSelectOccurrenceButton}
            error={errors.type}
            options={occurrenceTypesByUserLogged}
            required
          />

          <Select
            {...register("intercurrence")}
            name="intercurrence"
            label="Intercorrência"
            onChangeCapture={onIntercurrenceChangeResetFieldValues}
            placeholder="Selecione uma opção..."
            error={errors.intercurrence}
            options={intercurrenceTypesBySelectedOccurrence}
            required
          />

          <Select
            {...register('service_step')}
            name="service_step"
            placeholder="Selecione uma opção..."
            error={errors.service_step}
            service_steps={service_steps}
            isDisabled={!isFinishedService}
            label="Etapa do Serviço"
            required
          />
          {isAlterBoardingIntercurrence && (
            <Select
              {...register("service_new_source_branch_id")}
              name="service_new_source_branch_id"
              label="Base de Origem"
              placeholder="Selecione uma opção..."
              error={errors.service_new_source_branch_id}
              options={branchesOptions}
              required
            />
          )}
          {selectedSourceBranch && (
            <Select
              {...register("service_new_destination_branch_id")}
              name="service_new_destination_branch_id"
              label="Base de Destino"
              placeholder="Selecione uma opção..."
              error={errors.service_new_destination_branch_id}
              options={destinationBranchesOptions}
              required
            />
          )}

          {isCollectorForm && (
            isFechingCollectors ? (
              <Spinner />
            ) : (
              <Select
                {...register("responsible_collector_id")}
                name="responsible_collector_id"
                options={collectorsFilteredByServiceCollectors}
                error={errors.responsible_collector_id}
                label="Coletador responsável"
                placeholder="Selecione uma opção..."
                required
              />
            )
          )}
          {isCollectorForm && (
            <Stack direction={['column', 'column', 'row']} spacing={6}>
              <Select
                {...register("has_generate_fine")}
                name="has_generate_fine"
                label="Gera multa?"
                placeholder="Selecione uma opção..."
                negative_positive={negative_positive}
                defaultValue='SIM'
                error={errors.has_generate_fine}
                required
              />
              <Input
                {...register('collector_fine_value')}
                name="collector_fine_value"
                label="Valor da multa"
                addChildren="R$"
                onChange={onChange}
                defaultValue={currencyFormat('000')}
                error={errors.collector_fine_value}
                required
              />
            </Stack>
          )}
          {isShippingForm && (
            isFetchingBranches ? (
              <Spinner />
            ) : (
              <Stack direction={['column', 'column', 'row']} spacing={6}>

                <Select
                  {...register("shipping_id")}
                  name="shipping_id"
                  options={shippingsFilteredByServiceBranches}
                  error={errors.shipping_id}
                  label="Transportadora responsável"
                  placeholder="Selecione uma opção..."
                  required
                />
                {isEnableShippingTicketNumber && (
                  <Input
                    {...register("shipping_ticket_number")}
                    name="shipping_ticket_number"
                    label="Número do chamado/ticket"
                    error={errors.shipping_ticket_number}
                    required
                  />
                )}
              </Stack>
            )
          )}
          {isIntercurrencePartialLanding && (
            <Stack w="full" mt="4">
              <Controller
                control={control}
                name="checkbook"
                render={({ field }) => (
                  <GeneralCheckbox
                    name="ckeckbook"
                    onCheckboxChange={field.onChange}
                    checkboxOptions={checkbookOptions}
                    label="Carga recebida faltando volume"
                  />
                )}
              />
            </Stack>
          )}
          {isIntercurrenceCustomerWithoutContentDeclaration && (
            <Stack w="full" mt="4">
              <Controller
                control={control}
                name="checkbook"
                render={({ field }) => (
                  <GeneralCheckbox
                    name="ckeckbook"
                    onCheckboxChange={field.onChange}
                    checkboxOptions={noContentDeclarationCheckbookOptions}
                    label="Remetente sem declaração de conteúdo e o coletador não levou uma extra"
                  />
                )}
              />
            </Stack>
          )}
          {isIntercurrenceDeclarationContentIncomplete && (
            <Stack w="full" mt="4">
              <Controller
                control={control}
                name="checkbook"
                render={({ field }) => (
                  <GeneralCheckbox
                    name="ckeckbook"
                    onCheckboxChange={field.onChange}
                    checkboxOptions={declarationContentIncompleteCheckbookOptions}
                    label="Declaração de conteúdo incompleta"
                  />
                )}
              />
            </Stack>
          )}

          {isCurrencyIntercurrence && (
            <Input
              {...register('occurrence_detail')}
              name="occurrence_detail"
              label="Valor"
              addChildren="R$"
              onChange={onChange}
              defaultValue={currencyFormat('000')}
              error={errors.occurrence_detail}
              required
            />
          )}

          {isAlterMaterialsIntercurrence && (
            materialFields.map((field, index) => {
              return (
                <Flex key={field.input_id} w="full" align="center" direction="column">
                  <Stack
                    w="full"
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    {field.input_id === "GELO SECO" ? (
                      <Input
                        {...register(`materials.${index}.input_id`)}
                        isDisabled={true}
                        name={`materials.${index}.input_id`}
                        label="Insumo"
                        defaultValue="GELO SECO"
                        error={
                          errors.materials
                            ? errors?.materials[index]?.input_id
                            : undefined
                        }
                        required
                      />
                    ) : (
                      <Select
                        {...register(`materials.${index}.input_id`)}
                        name={`materials.${index}.input_id`}
                        label="Insumo"
                        isDisabled={true}
                        error={
                          errors.materials
                            ? errors?.materials[index]?.input_id
                            : undefined
                        }
                        inputs={inputs}
                        placeholder="Selecione uma opção..."
                        required
                      />
                    )}

                    <Input
                      {...register(`materials.${index}.quantity`)}
                      name={`materials.${index}.quantity`}
                      label="Quantidade"
                      error={
                        errors.materials
                          ? errors?.materials[index]?.quantity
                          : undefined
                      }
                      required
                    />
                  </Stack>
                  <Divider my="6" borderColor="gray.700" />
                </Flex>
              )
            }
            )
          )}

          {isReplaceMaterialsIntercurrence && (
            <Flex direction="column">
              {materialFields.map((field, index) => {
                return (
                  <Flex key={field.input_id} w="full" align="center" direction="column">
                    <Stack
                      w="full"
                      spacing="24px"
                      mt="4"
                      direction={['column', 'column', 'row']}
                    >
                      <Select
                        {...register(`materials.${index}.input_id`)}
                        name={`materials.${index}.input_id`}
                        label="Insumo"
                        error={
                          errors.materials
                            ? errors?.materials[index]?.input_id
                            : undefined
                        }
                        inputs={inputs}
                        placeholder="Selecione uma opção..."
                        required
                      />

                      <Input
                        {...register(`materials.${index}.quantity`)}
                        name={`materials.${index}.quantity`}
                        label="Quantidade"
                        error={
                          errors.materials
                            ? errors?.materials[index]?.quantity
                            : undefined
                        }
                        required
                      />

                      <Stack pt={8}>
                        <Button
                          leftIcon={<Icon as={FaTimes} />}
                          variant="ghost"
                          onClick={() => handleRemoveMaterialField(index)}
                          h="48px"
                        >
                          Remover
                        </Button>
                      </Stack>
                    </Stack>
                    <Divider my="6" borderColor="gray.700" />
                  </Flex>
                )
              }
              )}
              <Button
                leftIcon={<Icon as={FaPlus} />}
                alignSelf="flex-start"
                onClick={handleAppendMaterialField}
              >
                Adicionar
              </Button>
              <Text
                mt={4}
                fontSize="sm"
                color="red"
              >{errors.materials && errors.materials.message}</Text>
            </Flex>
          )}

          {isDateHourWithDetailIntercurrence && (
            <>
              <Stack
                spacing={6}
                direction={['column', 'column', 'row']}
              >
                <Stack
                  w="full"
                  spacing={6}
                  direction={['column', 'column', 'row']}
                >
                  {intercurrenceSelected !== 'ATRASO NA COLETA' && (
                    <Input
                      {...register('occurrence_date')}
                      name="occurrence_date"
                      label={intercurrenceSelected === 'NO SHOW'
                        ? 'Previsão de coleta(Data)'
                        : isUnsuccessfulDeliveryAttempt ? 'Data da tentativa' : 'Previsão de entrega (Data)'}
                      type="date"
                      error={errors.occurrence_date}
                      required />
                  )}
                  <Input
                    {...register('occurrence_hour')}
                    name="occurrence_hour"
                    label={(intercurrenceSelected === 'NO SHOW' || intercurrenceSelected === 'ATRASO NA COLETA')
                      ? 'Previsão de coleta (Horário)'
                      : isUnsuccessfulDeliveryAttempt ? 'Horário da tentativa' : 'Previsão de entrega (Horário)'}
                    type="time"
                    error={errors.occurrence_hour}
                    required />
                </Stack>
                <Input
                  {...register('occurrence_detail')}
                  name="occurrence_detail"
                  label={isUnsuccessfulDeliveryAttempt ? 'Motivo' : 'Motivo do Atraso'}
                  error={errors.occurrence_detail}
                  required />

              </Stack>
              <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
                <TextArea
                  {...register('cause')}
                  name="cause"
                  label="Causa Raiz"
                  error={errors.cause}
                  required />
              </Stack>
              <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
                <TextArea
                  {...register('corrective')}
                  name="corrective"
                  label="Ação Corretiva"
                  error={errors.corrective}
                  required />
              </Stack>
              <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
                <TextArea
                  {...register('preventive')}
                  name="preventive"
                  label="Ação Preventiva"
                  error={errors.preventive} />
              </Stack>
            </>
          )}
          {isUnsuccessfulDeliveryAttempt && (
            <Flex direction="column" gap="4">
              <Select
                {...register("unsuccess_delivery_address_id")}
                name={'unsuccess_delivery_address_id'}
                label="Endereço"
                addresses={destinationAddresses}
                placeholder="Selecione uma opção..."
                error={errors.unsuccess_delivery_address_id}
                required
              />
            </Flex>
          )}

          {isDelayIntercurrence && (
            <Input
              {...register('occurrence_delay')}
              name="occurrence_delay"
              type="number"
              label={intercurrenceSelected === 'ESPERA ACIMA DE 10 MIN'
                ? 'Tempo acima de 10 minutos'
                : 'Tempo'
              }
              error={errors.occurrence_delay}
              required
            />
          )}

          {isOnlyDetailIntercurrence && (
            <TextArea
              {...register('occurrence_detail')}
              name="occurrence_detail"
              label={intercurrenceSelected === 'RETENÇÃO FISCAL' ? 'Motivo da retenção' : "Descrição"}
              error={errors.occurrence_detail}
              required
            />
          )}
          <TextArea
            {...register("observation")}
            name="observation"
            label="Observações"
            error={errors.observation}
            required
          />
          <Stack
            alignSelf={['center', 'center', 'flex-end']}
            w={['full', 'full', 'min']}
          >
            <Button
              type="submit"
              colorScheme="blue"
              isDisabled={hasCheckbookForIntercurrence ? !activateButtonCreateOccurrence : false}
            >
              Abrir ocorrência
            </Button>
          </Stack>
        </Flex>
      </StandardBackgroundForm>
    </>
  )
}
