import { Box, Button, FormControl, FormLabel, HStack, IconButton, Input, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Progress, Radio, RadioGroup, Stack, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { GetVacationsResponse } from "api/vacations/getVacations";
import { proceedVacation } from "api/vacations/proceedVacation";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { useToastify } from "hooks/toastify/useToastify";
import { ChangeEvent } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FaFileImport } from "react-icons/fa6";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup"

interface ProceedVacationSchema {
  isSignedReceipt: 'yes' | 'no'
  receiptAttachment: FileList
}

interface ProceedVacationProps {
  vacationId: string
}

const proceedVacationSchema = yup.object({
  isSignedReceipt: yup.string().required(),
  receiptAttachment: yup.mixed().when('isSignedReceipt', {
    is: 'yes',
    then: yup.mixed().test((value: FileList) => !!value.length)
  })
})

export function ProceedVacation({ vacationId }: ProceedVacationProps) {
  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<ProceedVacationSchema>({
    resolver: yupResolver(proceedVacationSchema)
  })

  const { mutation, uploadProggress } = useAttachmentMutation()

  const queryClient = useQueryClient()

  const { mutateAsync: proceedVacationFn } = useMutation({
    mutationFn: proceedVacation,
    onSuccess(_data, variables) {
      const cached = queryClient.getQueriesData<GetVacationsResponse>({
        queryKey: ['vacations']
      })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          vacations: cachedValue.vacations.map((vacation) => {
            if (vacation.id === variables.vacationId) {
              return { ...vacation, status: 'on-vacation' }
            }

            return vacation
          })
        })
      })
    },
  })

  const { promiseMessage } = useToastify()

  async function handleProceedVacation(values: ProceedVacationSchema) {
    await promiseMessage(proceedVacationFn({
      body: {
        ...values,
        receiptAttachmentId: mutation?.data ? mutation.data.attachment.id : undefined
      },
      vacationId,
    }), 'Férias procedidas com sucesso!')
  }

  async function handleUploadReceiptAttachment(
    event: ChangeEvent<HTMLInputElement>
  ) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await mutation.mutateAsync(formData)
  }

  const isSignedReceipt = useWatch({ control, name: 'isSignedReceipt' }) === 'yes'

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Proceeder férias
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        <Box
          as="form"
          w="full"
          onSubmit={handleSubmit(handleProceedVacation)}
        >
          <FormControl isInvalid={!!errors.isSignedReceipt}>
            <FormLabel fontSize="sm">
              Recibo assinado
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Controller
              control={control}
              name="isSignedReceipt"
              render={({ field }) => {
                return (
                  <RadioGroup
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                  >
                    <HStack spacing={3} fontSize="sm">
                      <Radio value="yes">
                        <Text fontSize="sm">Sim</Text>
                      </Radio>
                      <Radio value="no">
                        <Text fontSize="sm">Não</Text>
                      </Radio>
                    </HStack>

                  </RadioGroup>
                )
              }}
            />
          </FormControl>

          {isSignedReceipt && (

            <Table>
              <Tbody>
                <Tr>
                  <Td border="none">
                    <Stack
                      direction="column"
                      w="full"
                    >
                      <Button
                        as={FormLabel}
                        htmlFor="receiptAttachment"
                        lineHeight="1"
                        leftIcon={<FaFileImport />}
                        size="sm"
                        w="min"
                        cursor="pointer"
                        border={!!errors?.receiptAttachment && '2px solid'}
                        borderColor={errors?.receiptAttachment && 'red.500'}
                      >
                        Anexar
                      </Button>
                      <FormControl isInvalid={!!errors?.receiptAttachment}>
                        <Input
                          {...register('receiptAttachment')}
                          name="receiptAttachment"
                          id="receiptAttachment"
                          type="file"
                          hidden
                          onChangeCapture={handleUploadReceiptAttachment}
                        />
                      </FormControl>
                    </Stack>
                  </Td>
                  <Td fontSize="xs" w="200px" border="none">
                    {uploadProggress > 0 && (
                      <Progress size="sm" rounded="md" value={uploadProggress} />
                    )}
                  </Td>
                  <Td fontSize="xs" border="none" isNumeric>
                    {mutation.data && (
                      <IconButton
                        aria-label="Visualizar anexo"
                        as={Link}
                        size="sm"
                        icon={<FaExternalLinkAlt />}
                        href={mutation.data.attachment.link}
                        isExternal
                      />
                    )}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          )}

          <HStack
            w="full"
            justify="end"
            mt={3}
          >
            <Button
              type="submit"
              colorScheme="blue"
              ml="auto"
              size="sm"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Salvar
            </Button>
          </HStack>
        </Box>


      </ModalBody>
    </ModalContent>
  )
}
